import { FiTrash2 } from 'react-icons/fi';
import { currencyMask } from 'functions/mask';
import { currencyFormat } from 'functions/formatters';
import { IconButton } from './IconButton';

import styles from './PlanosTable.module.scss';

interface PlanosTableProps {
  planos: { planoId: string; nome: string; preco: number }[];
  onRemovePlano?: (index: number) => void;
}

export const PlanosTable: React.FC<PlanosTableProps> = ({
  planos,
  onRemovePlano,
}) => {
  return (
    <table className={styles['planos-table']}>
      <thead>
        <tr>
          <td>Nome</td>
          <td style={{ textAlign: 'right' }}>Valor</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {planos.length === 0 ? (
          <tr>
            <td
              colSpan={3}
              style={{ textAlign: 'center', borderRadius: '4px' }}
            >
              Nenhum plano selecionado.
            </td>
          </tr>
        ) : (
          planos.map((plano, index) => (
            <tr key={`plano-${index}`}>
              <td>{plano.nome}</td>
              <td style={{ textAlign: 'right' }}>
                {currencyFormat(plano.preco)}
              </td>
              <td>
                {onRemovePlano && (
                  <IconButton
                    title="Remover Plano"
                    icon={() => <FiTrash2 size={22} />}
                    onClick={() => onRemovePlano(index)}
                    toolTipDirection="left"
                    className="hover-red"
                  />
                )}
              </td>
            </tr>
          ))
        )}
      </tbody>
      {planos.length > 0 && (
        <tfoot>
          <tr>
            <td>Total:</td>
            <td style={{ textAlign: 'right' }}>
              {currencyMask(
                `${planos
                  .reduce((preco, curr) => preco + curr.preco, 0)
                  .toFixed(2)}`,
              )}
            </td>
            <td></td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

import styles from './PrintFieldset.module.scss';

interface PrintFieldsetProps {
  legend: string;
  breakPage?: boolean;
}

export const PrintFieldset: React.FC<PrintFieldsetProps> = ({
  breakPage,
  legend,
  children,
}) => {
  return (
    <fieldset
      className={`${styles['fieldset-container']} ${
        breakPage ? styles['break-page'] : ''
      }`}
    >
      <legend>{legend}</legend>
      <div>{children}</div>
    </fieldset>
  );
};

import { Layout } from 'components/Layout';
import { ProfileView } from 'views/profile';

export const ProfilePage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Seu Perfil' }] }}>
      <ProfileView />
    </Layout>
  );
};

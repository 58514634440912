import { warnToast } from 'utils/toast';
import { Endereco } from 'utils/types/Endereco';

import { cepMask } from 'functions/mask';
import { getAddress } from 'functions/getAddress';

import { Fieldset, Input } from 'components/Form';

interface EnderecoCobrancaProps {
  fields: Endereco;
  setFields: (value: Endereco) => void;
  errors: any;
}

export const FieldsetEnderecoCobranca: React.FC<EnderecoCobrancaProps> = ({
  fields,
  setFields,
  errors,
}) => {
  const searchCep = async (cep: string) => {
    try {
      if (cep === '') return;

      const address = await getAddress(cep);

      setFields({
        ...fields,
        bairro: address.bairro,
        cidade: address.localidade,
        complemento: address.complemento,
        logradouro: address.logradouro,
        estado: address.uf,
        ibge: address.ibge,
      });
    } catch (err: any) {
      warnToast('Não foi possível encontrar o CEP.', err.message);
    }
  };

  return (
    <Fieldset legend="Endereço de cobrança" columns={3}>
      <Input
        className="column-start"
        maxLength={9}
        inputMode="numeric"
        name="cobrancaCep"
        label="CEP"
        error={errors?.cep}
        value={cepMask(fields.cep)}
        onChange={value => setFields({ ...fields, cep: value })}
        onBlur={e => searchCep(e.target.value)}
      />
      <Input
        type="number"
        inputMode="numeric"
        min="0"
        name="cobrancaNumero"
        label="Número"
        error={errors?.numero}
        value={fields.numero}
        onChange={value => setFields({ ...fields, numero: value })}
      />
      <Input
        name="cobrancaCidade"
        label="Cidade"
        error={errors?.cidade}
        value={fields.cidade}
        onChange={value => setFields({ ...fields, cidade: value })}
      />
      <Input
        name="cobrancaEstado"
        label="Estado"
        error={errors?.estado}
        value={fields.estado}
        onChange={value => setFields({ ...fields, estado: value })}
      />
      <Input
        name="cobrancaLogradouro"
        label="Logradouro"
        error={errors?.logradouro}
        value={fields.logradouro}
        onChange={value => setFields({ ...fields, logradouro: value })}
      />
      <Input
        name="cobrancaBairro"
        label="Bairro"
        error={errors?.bairro}
        value={fields.bairro}
        onChange={value => setFields({ ...fields, bairro: value })}
      />
      <Input
        name="cobrancaComplemento"
        label="Complemento"
        error={errors?.complemento}
        value={fields.complemento}
        onChange={value => setFields({ ...fields, complemento: value })}
      />
      <Input
        name="cobrancaPontoReferencia"
        label="Ponto de Referência"
        error={errors?.pontoReferencia}
        value={fields.pontoReferencia}
        onChange={value => setFields({ ...fields, pontoReferencia: value })}
      />
    </Fieldset>
  );
};

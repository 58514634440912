import { DesempenhoPedidoItem } from '../usuarios/desempenhoPedidos/list';

export interface TotalDesempenhoVenda {
  sumQuantidadePedidos: number;
  sumQuantidadeDiasPeriodo: number;
  sumQuantidadePedidosPorQuantidadeDiasPeriodo: number;
  sumPrecoTotalPlanosPedidos: number;
  sumPrecoTotalPlanosPedidosPorQuantidadeDiasPeriodo: number;
}

export const getTotaisDesempenhoVendas = (
  items: DesempenhoPedidoItem[],
): TotalDesempenhoVenda => {
  return {
    sumQuantidadePedidos: items.reduce(
      (tot, curr) => tot + curr.quantidadePedidos,
      0,
    ),
    sumQuantidadeDiasPeriodo: items.reduce(
      (tot, curr) => tot + curr.quantidadeDiasPeriodo,
      0,
    ),
    sumQuantidadePedidosPorQuantidadeDiasPeriodo: items.reduce(
      (tot, curr) => tot + curr.quantidadePedidosPorQuantidadeDiasPeriodo,
      0,
    ),
    sumPrecoTotalPlanosPedidos: items.reduce(
      (tot, curr) => tot + curr.precoTotalPlanosPedidos,
      0,
    ),
    sumPrecoTotalPlanosPedidosPorQuantidadeDiasPeriodo: items.reduce(
      (tot, curr) => tot + curr.precoTotalPlanosPedidosPorQuantidadeDiasPeriodo,
      0,
    ),
  };
};

import { useEffect } from 'react';
import { useParams } from 'react-router';
import logoImg from 'assets/logo-dark.svg';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import {
  currencyFormat,
  dateFormat,
  dateFormatWithHours,
} from 'functions/formatters';
import { gql } from 'functions/gql';
import { cpfMask, phoneMask } from 'functions/mask';

import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { EnderecoResult } from 'utils/types/Endereco';
import { RedeSocialEnum } from 'utils/enums/RedeSocialEnum';

import { Fallback } from 'components/Fallback';
import { PrintText } from 'components/Print/PrintText';
import { PrintImage } from 'components/Print/PrintImage';
import { FlexContainer } from 'components/FlexContainer';
import { PrintSection } from 'components/Print/PrintSection';
import { PrintFieldset } from 'components/Print/PrintFieldset';
import { PrintValidacao } from 'components/Print/PrintValidacao';

interface PrintPedidoPessoaFisicaQueryVariables {
  id: { pedidoId: string };
}

interface PrintPedidoPessoaFisicaQuery {
  pedido: {
    pedidoId: string;
    clientePessoaFisicaNome: string;
    clientePessoaFisicaCpf: string;
    clientePessoaFisicaRg: string;
    clientePessoaFisicaDataNascimento: string;
    clientePessoaFisicaSexo?: string;
    clientePessoaFisicaEstadoCivilId?: string;
    allPedidosRedesSociais: {
      redeSocial: RedeSocialEnum;
      link: string;
    }[];
    allTelefones: Telefone[];
    allEmails: Email[];
    enderecoInstalacao: EnderecoResult;
    enderecoCobranca: EnderecoResult;
    consulta: { dataConsulta: string; resultado: string; url: string };
    allPedidosPlanos: { planoId: string; nome: string; preco: number }[];
    diaVencimento: { diaVencimentoId: string; nome: string };
    fidelidade: { fidelidadeId: string; nome: string };
    allFinalidades: { finalidadeId: string; nome: string }[];
    rede: string;
    senha: string;
    clientePessoaFisicaVinculoEmpregaticio: {
      vinculoEmpregaticioId: string;
      nome: string;
    };
    clientePessoaFisicaRegistradoLocalTrabalho?: string;
    clientePessoaFisicaRegistradoRenda?: number;
    clientePessoaFisicaRegistradoTelefoneTrabalho?: {
      telefoneId: string;
      nome: string;
      telefone: string;
      whatsapp: boolean;
    };
    clientePessoaFisicaAutonomoAtividadeExercida?: string;
    clientePessoaFisicaTipoResidencia?: string;
    clientePessoaFisicaQuantidadeMoradores?: number;
    clientePessoaFisicaTempoMoradia?: string;
    clientePessoaFisicaInternetAnterior?: boolean;
    clientePessoaFisicaInternetAnteriorOperadora: {
      operadoraId: string;
      nome: string;
    };
    clientePessoaFisicaInternetAnteriorMotivoCancelamento?: string;
    status: { statusId: string; nome: string };
    validacaoCliente: number;
    allAnexos: { nome: string; url: string; mimetype: string }[];
    vendedor: { usuarioId: string; nome: string };
  };
}

const PRINT_PEDIDO_PESSOA_FISICA_QUERY = gql`
  query ($id: PedidoID!) {
    pedido(id: $id) {
      pedidoId
      clientePessoaFisicaNome
      clientePessoaFisicaCpf
      clientePessoaFisicaRg
      clientePessoaFisicaDataNascimento
      clientePessoaFisicaSexo
      clientePessoaFisicaEstadoCivilId
      allPedidosRedesSociais {
        redeSocial
        link
      }
      allTelefones {
        nome
        telefone
        whatsapp
      }
      allEmails {
        nome
        email
      }
      enderecoInstalacao {
        cep
        numero
        enderecoId
        logradouro
        bairro
        cidade {
          nome
          estado {
            nome
          }
        }
        complemento
        pontoReferencia
      }
      enderecoCobranca {
        cep
        numero
        enderecoId
        logradouro
        bairro
        cidade {
          nome
          estado {
            nome
          }
        }
        complemento
        pontoReferencia
      }
      consulta {
        dataConsulta
        resultado
        url
      }
      allPedidosPlanos {
        planoId
        nome
        preco
      }
      diaVencimento {
        diaVencimentoId
        nome
      }
      fidelidade {
        fidelidadeId
        nome
      }
      allFinalidades {
        finalidadeId
        nome
      }
      rede
      senha
      clientePessoaFisicaVinculoEmpregaticio {
        vinculoEmpregaticioId
        nome
      }
      clientePessoaFisicaRegistradoLocalTrabalho
      clientePessoaFisicaRegistradoRenda
      clientePessoaFisicaRegistradoTelefoneTrabalho {
        telefoneId
        nome
        telefone
        whatsapp
      }
      clientePessoaFisicaAutonomoAtividadeExercida
      clientePessoaFisicaTipoResidencia
      clientePessoaFisicaQuantidadeMoradores
      clientePessoaFisicaTempoMoradia
      clientePessoaFisicaInternetAnterior
      clientePessoaFisicaInternetAnteriorOperadora {
        operadoraId
        nome
      }
      clientePessoaFisicaInternetAnteriorMotivoCancelamento
      validacaoCliente
      allAnexos {
        nome
        url
        mimetype
      }
      status {
        statusId
        nome
      }
      vendedor {
        usuarioId
        nome
      }
    }
  }
`;

export const PrintPedidoPessoaFisicaView: React.FC = () => {
  const params = useParams();
  const { auth, setAuth } = useAuth();

  const pedidoId = params.pedidoId || '';

  const query = useQueryAuth<
    PrintPedidoPessoaFisicaQuery,
    PrintPedidoPessoaFisicaQueryVariables
  >({
    auth,
    setAuth,
    query: PRINT_PEDIDO_PESSOA_FISICA_QUERY,
    variables: { id: { pedidoId } },
  });

  useEffect(() => {
    if (query.data) {
      const timer = setTimeout(() => {
        window.print();
      }, 1200);

      return () => clearTimeout(timer);
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const {
    data: { pedido },
  } = query;

  const allPedidoAnexos = pedido.allAnexos.filter(anexo =>
    anexo.mimetype.includes('image'),
  );

  return (
    <PrintSection>
      <FlexContainer style={{ marginBottom: '0.375rem' }}>
        <img src={logoImg} width={40} height={40} alt="Fibratech" />
        <h2>Fibratech</h2>
        <p className="body4" style={{ marginLeft: 'auto' }}>
          Código do pedido: {pedido.pedidoId}
        </p>
      </FlexContainer>

      <FlexContainer>
        <PrintFieldset legend="Pedido">
          <PrintText label="Status" text={pedido.status.nome} />
          <PrintText label="Vendedor" text={pedido.vendedor?.nome} />
          <PrintText
            label="Validação"
            text={
              pedido.validacaoCliente > 0
                ? `${pedido.validacaoCliente}`
                : 'Não informado'
            }
          />
          <PrintValidacao />
        </PrintFieldset>

        <PrintFieldset legend="Resultado da Consulta">
          <PrintText
            label="Status"
            text={pedido.consulta.resultado.replace(/[_]/g, ' ')}
          />
          <PrintText
            label="Data da consulta"
            text={dateFormatWithHours(pedido.consulta.dataConsulta)}
          />
        </PrintFieldset>
      </FlexContainer>

      <PrintFieldset legend="Dados">
        <PrintText label="Nome" text={pedido.clientePessoaFisicaNome} />
        <PrintText label="CPF" text={cpfMask(pedido.clientePessoaFisicaCpf)} />
        <PrintText label="RG" text={pedido.clientePessoaFisicaRg} />
        <PrintText
          label="Data Nasc."
          text={dateFormat(pedido.clientePessoaFisicaDataNascimento)}
        />
        <PrintText label="Sexo" text={pedido.clientePessoaFisicaSexo} />
        <PrintText
          label="Estado Civil"
          text={pedido.clientePessoaFisicaEstadoCivilId}
        />

        <h4 style={{ fontWeight: 500, width: '100%' }}>Redes Sociais:</h4>

        {pedido.allPedidosRedesSociais.length > 0 ? (
          pedido.allPedidosRedesSociais.map((redeSocial, index) => (
            <PrintText
              key={`rede-social-${index}`}
              label={redeSocial.redeSocial?.toLocaleLowerCase()}
              text={redeSocial.link}
            />
          ))
        ) : (
          <PrintText label="" text="Nenhum telefone adicionado." />
        )}

        <h4 style={{ fontWeight: 500, width: '100%' }}>Telefones:</h4>

        {pedido.allTelefones.length > 0 ? (
          pedido.allTelefones.map((telefone, index) => (
            <PrintText
              key={`telefone-${index}`}
              label={telefone.nome}
              text={phoneMask(telefone.telefone)}
            />
          ))
        ) : (
          <PrintText label="" text="Nenhum telefone adicionado." />
        )}

        <h4 style={{ fontWeight: 500, width: '100%' }}>E-mails:</h4>

        {pedido.allEmails.length > 0 ? (
          pedido.allEmails.map((email, index) => (
            <PrintText
              key={`email-${index}`}
              label={email.nome}
              text={email.email}
            />
          ))
        ) : (
          <PrintText label="" text="Nenhum e-mail adicionado." />
        )}
      </PrintFieldset>

      <PrintFieldset legend="Endereço Instalação">
        <PrintText label="CEP" text={pedido.enderecoInstalacao.cep} />
        <PrintText label="Número" text={pedido.enderecoInstalacao.numero} />
        <PrintText
          label="Cidade"
          text={pedido.enderecoInstalacao?.cidade?.nome}
        />
        <PrintText
          label="Estado"
          text={pedido.enderecoInstalacao?.cidade?.estado?.nome}
        />
        <PrintText
          label="Logradouro"
          text={pedido.enderecoInstalacao.logradouro}
        />
        <PrintText label="Bairro" text={pedido.enderecoInstalacao.bairro} />
        <PrintText
          label="Complemento"
          text={pedido.enderecoInstalacao.complemento}
        />
        <PrintText
          label="Ponto referência"
          text={pedido.enderecoInstalacao.pontoReferencia}
        />
      </PrintFieldset>

      <PrintFieldset legend="Endereço Cobrança">
        <PrintText label="CEP" text={pedido.enderecoCobranca.cep} />
        <PrintText label="Número" text={pedido.enderecoCobranca.numero} />
        <PrintText
          label="Cidade"
          text={pedido.enderecoCobranca?.cidade?.nome}
        />
        <PrintText
          label="Estado"
          text={pedido.enderecoCobranca?.cidade?.estado?.nome}
        />
        <PrintText
          label="Logradouro"
          text={pedido.enderecoCobranca.logradouro}
        />
        <PrintText label="Bairro" text={pedido.enderecoCobranca.bairro} />
        <PrintText
          label="Complemento"
          text={pedido.enderecoCobranca.complemento}
        />
        <PrintText
          label="Ponto referência"
          text={pedido.enderecoCobranca.pontoReferencia}
        />
      </PrintFieldset>

      <PrintFieldset legend="Informações Residenciais">
        <PrintText
          label="Tipo residência"
          text={pedido.clientePessoaFisicaTipoResidencia}
        />
        <PrintText
          label="Qtd. moradores"
          text={`${Number(pedido.clientePessoaFisicaQuantidadeMoradores)}`}
        />
        <PrintText
          label="Tempo moradia"
          text={pedido.clientePessoaFisicaTempoMoradia}
        />

        {pedido.clientePessoaFisicaInternetAnterior ? (
          <>
            <PrintText label="Já possui internet" text="Sim" />
            <PrintText
              label="Operadora"
              text={pedido.clientePessoaFisicaInternetAnteriorOperadora.nome}
            />
            <PrintText
              label="Motivo cancelamento"
              text={
                pedido.clientePessoaFisicaInternetAnteriorMotivoCancelamento
              }
            />
          </>
        ) : (
          <PrintText label="Já possui internet" text="Não" />
        )}
      </PrintFieldset>

      <PrintFieldset legend="Dados Empregatícios">
        <PrintText
          label="Vínculo empregatício"
          text={pedido.clientePessoaFisicaVinculoEmpregaticio.nome}
        />

        {pedido.clientePessoaFisicaVinculoEmpregaticio.vinculoEmpregaticioId !==
          'REGISTRADO' && (
          <PrintText
            label="Atividade exercída"
            text={pedido.clientePessoaFisicaAutonomoAtividadeExercida}
          />
        )}

        {pedido.clientePessoaFisicaVinculoEmpregaticio.vinculoEmpregaticioId !==
          'AUTONOMO' && (
          <>
            <PrintText
              label="Local de trabalho"
              text={pedido.clientePessoaFisicaRegistradoLocalTrabalho}
            />
            <PrintText
              label="Renda"
              text={currencyFormat(
                Number(pedido.clientePessoaFisicaRegistradoRenda),
              )}
            />
            <PrintText
              label="Telefone Trabalho"
              text={pedido.clientePessoaFisicaRegistradoTelefoneTrabalho?.nome}
            />
          </>
        )}
      </PrintFieldset>

      <PrintFieldset legend="Planos">
        {pedido.allPedidosPlanos.length > 0 ? (
          pedido.allPedidosPlanos.map((plano, index) => (
            <div key={`plano-${index}`} style={{ width: '100%' }}>
              <PrintText label="Plano" text={plano.nome} />
              <PrintText label="Preço" text={currencyFormat(plano.preco)} />
            </div>
          ))
        ) : (
          <PrintText label="" text="Nenhum e-mail adicionado." />
        )}
      </PrintFieldset>

      <PrintFieldset legend="Informações do Serviço">
        <PrintText label="Dia vencimento" text={pedido.diaVencimento.nome} />
        <PrintText label="Fidelidade" text={pedido.fidelidade.nome} />

        <h4 style={{ fontWeight: 500, width: '100%' }}>Finalidades:</h4>

        <div style={{ width: '100%' }}>
          {pedido.allFinalidades.length > 0 ? (
            pedido.allFinalidades.map((finalidade, index) => (
              <PrintText
                key={`finalidade-${index}`}
                label=""
                text={finalidade.nome}
              />
            ))
          ) : (
            <PrintText label="" text="Nenhuma finalidade adicionada." />
          )}
        </div>

        <PrintText label="Nome do wi-fi" text={pedido.rede} />
        <PrintText label="Senha" text={pedido.senha} />
      </PrintFieldset>

      <PrintFieldset legend="Anexos" breakPage>
        {allPedidoAnexos.length > 0 ? (
          allPedidoAnexos.map((anexo, index) => (
            <PrintImage key={`anexo-${index}`} {...anexo} />
          ))
        ) : (
          <PrintText label="" text="Nenhum anexo adicionado." />
        )}
      </PrintFieldset>
    </PrintSection>
  );
};

import { cpfMask } from 'functions/mask';

import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';

import { FlexContainer } from 'components/FlexContainer';
import { Form, Input, InputPhone, Select } from 'components/Form';

interface DadosProps {
  nome: string;
  cpf: string;
  rg: string;
  dataNascimento: string;
  sexo: string;
  estadoCivilId: string;
  telefones: Telefone[];
  emails: Email[];
}

export const DadosPessoaFisica: React.FC<DadosProps> = props => {
  return (
    <>
      <Form columns={3}>
        <Input readOnly name="nome" label="Nome" value={props.nome} />
        <Input readOnly name="cpf" label="CPF" value={cpfMask(props.cpf)} />
        <Input readOnly name="rg" label="RG" value={props.rg} />
        <Input
          readOnly
          name="dataNascimento"
          type="date"
          label="Data Nascimento"
          value={props.dataNascimento}
        />
        <Select
          readOnly
          name="sexo"
          label="Sexo"
          value={props.sexo}
          options={[
            { label: 'Masculino', value: 'MASCULINO' },
            { label: 'Feminino', value: 'FEMININO' },
            { label: 'Outros', value: 'OUTROS' },
          ]}
        />
        <Select
          readOnly
          name="estadoCivil"
          label="Estado Civil"
          value={props.estadoCivilId}
          options={[
            { value: 'CASADO', label: 'Casado(a)' },
            { value: 'DIVORCIADO', label: 'Divorciado(a)' },
            { value: 'OUTROS', label: 'Outros' },
            { value: 'SEPARADO', label: 'Separado(a)' },
            { value: 'SOLTEIRO', label: 'Solteiro(a)' },
            { value: 'VIUVO', label: 'Viúvo(a)' },
          ]}
        />

        <h4 className="heading4 title span-3">Telefones</h4>

        {props.telefones.length ? (
          props.telefones.map((telefone, index) => (
            <FlexContainer key={index} className="span-2">
              <Input
                readOnly
                name="descricaoTelefone"
                label="Descrição Telefone"
                value={telefone.nome}
              />
              <InputPhone
                readOnly
                id={`telefone-${index}`}
                isWhatsapp={telefone.whatsapp}
                value={telefone.telefone}
              />
            </FlexContainer>
          ))
        ) : (
          <p className="body2 text">Nenhum telefone adicionado.</p>
        )}

        <h4 className="heading4 title span-3">E-mails</h4>

        {props.emails.length ? (
          props.emails.map((email, index) => (
            <FlexContainer key={index} className="span-2">
              <Input
                readOnly
                name="descricaoEmail"
                label="Descrição E-mail"
                value={email.nome}
              />
              <Input
                readOnly
                name={`email-${index}`}
                label={`E-mail ${index + 1}`}
                value={email.nome}
              />
            </FlexContainer>
          ))
        ) : (
          <p className="body2 text">Nenhum e-mail adicionado.</p>
        )}
      </Form>
    </>
  );
};

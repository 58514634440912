import { Form, Input, MultiSelect, Select } from 'components/Form';

interface PedidoDetailInformacoesServicoProps {
  diaVencimentoId: string;
  fidelidadeId: string;
  pedidoFinalidades: { finalidadeId: string; nome: string }[];
  rede: string;
  senha: string;
  diasVencimentos: { diaVencimentoId: string; nome: string }[];
  fidelidades: { fidelidadeId: string; nome: string }[];
  finalidades: { finalidadeId: string; nome: string }[];
}

export const PedidoDetailInformacoesServico: React.FC<
  PedidoDetailInformacoesServicoProps
> = props => {
  return (
    <Form columns={2}>
      <Select
        name="diaVencimento"
        label="Dia Vencimento"
        value={props.diaVencimentoId}
        options={props.diasVencimentos.map(({ diaVencimentoId, nome }) => ({
          label: nome,
          value: diaVencimentoId,
        }))}
      />
      <Select
        name="fidelidade"
        label="Fidelidade"
        value={props.fidelidadeId}
        options={props.fidelidades.map(({ fidelidadeId, nome }) => ({
          label: nome,
          value: fidelidadeId,
        }))}
      />
      <MultiSelect
        name="finalidades"
        label="Finalidades"
        value={props.pedidoFinalidades.map(({ finalidadeId }) => finalidadeId)}
        options={props.finalidades.map(({ finalidadeId, nome }) => ({
          label: nome,
          value: finalidadeId,
        }))}
      />
      <Input name="rede" label="Nome do wi-fi" value={props.rede} />
      <Input name="senha" label="Senha" value={props.senha} />
    </Form>
  );
};

import styles from './ToolTip.module.scss';

type ToolTipProps = {
  title: string;
  direction?: 'top' | 'bottom' | 'left' | 'right';
};

export const ToolTip: React.FC<ToolTipProps> = ({
  title,
  children,
  direction = 'top',
}) => {
  return (
    <div className={styles['tooltip-container']}>
      {children}
      <span className={`body3 ${styles[`${direction}`]}`}>{title}</span>
    </div>
  );
};

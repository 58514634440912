import {
  FcAddDatabase,
  FcBullish,
  FcDocument,
  FcFinePrint,
  FcRating,
} from 'react-icons/fc';
import { Link } from 'react-router-dom';
import styles from './ShortcutList.module.scss';

interface ShorcutListProps {
  acessos: string[];
}

export const ShortcutList: React.FC<ShorcutListProps> = ({ acessos }) => {
  return (
    <section className={styles['shortcut-card-section']}>
      {acessos.includes('CADASTROS.PEDIDOS.VISUALIZAR') && (
        <div className={styles['shortcut-card']}>
          <Link to="/pedidos">
            <figure>
              <FcDocument size={32} />
            </figure>
            <h6 className="body2">PEDIDOS</h6>
          </Link>
        </div>
      )}
      {acessos.includes('CADASTROS.PEDIDOS.INCLUIR') && (
        <div className={styles['shortcut-card']}>
          <Link to="/pedidos/new-pedido">
            <figure>
              <FcAddDatabase size={32} />
            </figure>
            <h6 className="body2">NOVO PEDIDO</h6>
          </Link>
        </div>
      )}
      {acessos.includes('MEUS_PEDIDOS') && (
        <div className={styles['shortcut-card']}>
          <Link to="/me/meus-pedidos">
            <figure>
              <FcBullish size={32} />
            </figure>
            <h6 className="body2">MEUS PEDIDOS</h6>
          </Link>
        </div>
      )}
      {acessos.includes('RELATORIOS.CONSULTAS') && (
        <div className={styles['shortcut-card']}>
          <Link to="/relatorios/consultas-sophus">
            <figure>
              <FcFinePrint size={32} />
            </figure>
            <h6 className="body2">CONSULTAS</h6>
          </Link>
        </div>
      )}
      {acessos.includes('CADASTROS.PEDIDOS.AUDITAR') && (
        <div className={styles['shortcut-card']}>
          <Link to="/auditoria">
            <figure>
              <FcRating size={32} />
            </figure>
            <h6 className="body2">AUDITORIA</h6>
          </Link>
        </div>
      )}
    </section>
  );
};

import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { useAuth } from 'hooks/useAuth';
import { Button } from 'components/Form';
import { Layout } from 'components/Layout';
import { ListOperadoraView } from 'views/operadoras/list';

export const ListOperadoraPage = () => {
  const { acessos } = useAuth();
  const navigate = useNavigate();

  return (
    <Layout
      breadcrumb={{ items: [{ label: 'Operadoras' }] }}
      component={
        acessos.includes('CADASTROS.OPERADORAS.INCLUIR') ? (
          <Button type="button" onClick={() => navigate('/operadoras/new')}>
            <FiPlus size={20} /> Novo
          </Button>
        ) : undefined
      }
    >
      <ListOperadoraView />
    </Layout>
  );
};

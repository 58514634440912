export const parseError = (err: any) => {
  const name = err.name;
  const message = err.message;
  const stack = err.stack;

  return {
    name,
    message,
    ...err,
    stack,
  };
};

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Log } from 'utils/Log';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';
import { gql } from 'functions/gql';
import { dateFormat } from 'functions/formatters';
import { StatusEmpregaticioEnum } from 'utils/enums/StatusEmpregaticioEnum';

import { TabList } from 'components/TabList';
import { Fallback } from 'components/Fallback';
import { LogsTable } from 'components/LogsTable';
import { Button, Form, Input, PreviewFile, Select } from 'components/Form';

interface UsuarioEditDataQuery {
  usuario: {
    usuarioId: string;
    ativo: boolean;
    avatar: {
      url: string;
      nome: string;
      mimetype: string;
    };
    login: string;
    nome: string;
    grupoAcesso: {
      grupoAcessoId: string;
      nome: string;
    };
    dataAdmissao: string;
    dataDesligamento: string;
    statusEmpregaticio: StatusEmpregaticioEnum;
    perfil: {
      perfilId: string;
      nome: string;
    };
    logs: { items: Log[] };
  };
}

interface UsuarioEditDataQueryVariables {
  id: { usuarioId: string };
}

const USUARIO_EDIT_DATA_QUERY = gql`
  query ($id: UsuarioID!) {
    usuario(id: $id) {
      usuarioId
      ativo
      avatar {
        nome
        url
        mimetype
      }
      login
      nome
      grupoAcesso {
        grupoAcessoId
        nome
      }
      perfil {
        perfilId
        nome
      }
      dataAdmissao
      dataDesligamento
      statusEmpregaticio
      logs {
        items {
          logId
          tipo
          descricao
          createdAt
          usuario {
            nome
            avatar {
              url
            }
          }
        }
      }
    }
  }
`;

export const DetailUsuarioView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [fields, setFields] = useState({
    nome: '',
    login: '',
    grupoAcesso: '',
    perfil: '',
    dataAdmissao: '',
    dataDesligamento: '',
    statusEmpregaticio: '',
    avatar: { nome: '', url: '', mimetype: '' },
  });

  const usuarioId = params.usuarioId || '';

  const query = useQueryAuth<
    UsuarioEditDataQuery,
    UsuarioEditDataQueryVariables
  >({
    auth,
    setAuth,
    query: USUARIO_EDIT_DATA_QUERY,
    variables: { id: { usuarioId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({
        nome: query.data.usuario.nome,
        login: query.data.usuario.login,
        perfil: query.data.usuario.perfil.nome,
        dataAdmissao: query.data.usuario.dataAdmissao,
        dataDesligamento: query.data.usuario.dataDesligamento,
        statusEmpregaticio: query.data.usuario.statusEmpregaticio,
        grupoAcesso: query.data.usuario.grupoAcesso.nome,
        avatar: query.data.usuario.avatar,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  return (
    <TabList labels={['Detalhes', 'Logs']}>
      <Form
        columns={2}
        footerComponent={
          <Button
            type="button"
            style={{ alignSelf: 'flex-end', width: '200px' }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        }
      >
        <Input readOnly name="nome" label="Nome" value={fields.nome} />
        <Input readOnly name="login" label="Login" value={fields.login} />
        <Input
          readOnly
          name="grupoAcesso"
          label="Grupo de Acesso"
          value={fields.grupoAcesso}
        />
        <Input readOnly name="perfil" label="Perfil" value={fields.perfil} />
        <Input
          readOnly
          name="dataAdmissao"
          label="Data de admissão"
          value={dateFormat(fields.dataAdmissao)}
        />
        <Input
          readOnly
          name="dataDesligamento"
          label="Data de desligamento"
          value={dateFormat(fields.dataDesligamento)}
        />
        <Select
          readOnly
          name="statusEmpregaticio"
          label="Status empregatício"
          value={fields.statusEmpregaticio}
          options={[
            { label: 'Admitido', value: 'ADMITIDO' },
            { label: 'Desligado', value: 'DESLIGADO' },
          ]}
        />

        <h4 className="heading4 text span-2" style={{ margin: '1rem 0' }}>
          Avatar:
        </h4>

        <PreviewFile
          files={[
            {
              fileName: fields.avatar.nome,
              url: fields.avatar.url,
              mimetype: fields.avatar.mimetype,
            },
          ]}
        />
      </Form>
      <LogsTable logs={query.data.usuario.logs.items} />
    </TabList>
  );
};

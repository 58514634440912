import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';

import { useAuth } from 'hooks/useAuth';

import ilustration from 'assets/default-dashboard.svg';
import styles from './DefaultDashboard.module.scss';

export const DefaultDashboard: React.FC = () => {
  const { auth } = useAuth();

  return (
    <section className={styles['default-dashboard-section']}>
      <h4 className="heading4 title">Bem vindo ao Fibratech.</h4>
      <article className={styles['default-dashboard-container']}>
        <h5 className="heading5 title">Logado como:</h5>
        <figure>
          <img
            src={auth?.usuario.avatar.url}
            alt="Foto de perfil"
            title="Foto de perfil"
          />
          <p className="body2 text">{auth?.usuario.nome}</p>
          <Link to="/perfil" title="Ver perfil">
            <FiExternalLink size={22} />
          </Link>
        </figure>
      </article>
      <figure className={styles['illustration-container']}>
        <img
          src={ilustration}
          alt="Ilustração de dashboard"
          title="Imagem ilustrativa"
        />
      </figure>
    </section>
  );
};

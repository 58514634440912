import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FiAlertTriangle, FiLock } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';

import {
  Fieldset,
  Form,
  FormFooter,
  Input,
  InputWithIcon,
} from 'components/Form';
import { Fallback } from 'components/Fallback';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { editConfiguracoesSchema } from './utils/editConfiguracoesSchema';

interface ConfiguracaoUpdateQuery {
  configuracaoUpdate: { configuracaoId: string };
}

interface ConfiguracaoUpdateVariables {
  id: { configuracaoId: string };
  input: {
    serasaSophusEntidade?: string;
    serasaSophusCodigo?: string;
    serasaSophusSenha?: string;
    serasaSophusMaximoDiasReutilizarConsulta?: number;
  };
}

const CONFIGURACAO_UPDATE_QUERY = gql`
  mutation ($id: ConfiguracaoID!, $input: ConfiguracaoUpdateInput!) {
    configuracaoUpdate(id: $id, input: $input) {
      configuracaoId
      serasaSophusEntidade
      serasaSophusCodigo
      serasaSophusSenha
      serasaSophusMaximoDiasReutilizarConsulta
    }
  }
`;

interface ConfiguracaoDataQueryVariables {
  id: { configuracaoId: string };
}

interface ConfiguracaoDataQuery {
  configuracao: {
    configuracaoId: string;
    serasaSophusEntidade: string;
    serasaSophusCodigo: string;
    serasaSophusSenha: string;
    serasaSophusMaximoDiasReutilizarConsulta: number;
  };
}

const CONFIGURACAO_DATA_QUERY = gql`
  query ($id: ConfiguracaoID!) {
    configuracao(id: $id) {
      configuracaoId
      serasaSophusEntidade
      serasaSophusCodigo
      serasaSophusSenha
      serasaSophusMaximoDiasReutilizarConsulta
    }
  }
`;

export const EditConfiguracaoView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    serasaSophusEntidade: '',
    serasaSophusCodigo: '',
    serasaSophusSenha: '',
    serasaSophusMaximoDiasReutilizarConsulta: undefined as number | undefined,
  });

  const configuracaoId = params.configuracaoId || '';

  const query = useQueryAuth<
    ConfiguracaoDataQuery,
    ConfiguracaoDataQueryVariables
  >({
    auth,
    setAuth,
    query: CONFIGURACAO_DATA_QUERY,
    variables: { id: { configuracaoId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({
        ...fields,
        serasaSophusCodigo: query.data.configuracao.serasaSophusCodigo,
        serasaSophusEntidade: query.data.configuracao.serasaSophusEntidade,
        serasaSophusSenha: query.data.configuracao.serasaSophusSenha,
        serasaSophusMaximoDiasReutilizarConsulta:
          query.data.configuracao.serasaSophusMaximoDiasReutilizarConsulta,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return (
      <Fallback
        errors={query.errors}
        loading={query.loading}
        goBackURL={'/configuracoes'}
      />
    );
  }

  const handleSubmit = submitHandler({
    validateSchema: editConfiguracoesSchema,
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { errors } = await graphqlAuth<
          ConfiguracaoUpdateQuery,
          ConfiguracaoUpdateVariables
        >({
          auth,
          setAuth,
          query: CONFIGURACAO_UPDATE_QUERY,
          variables: {
            id: { configuracaoId },
            input: {
              serasaSophusCodigo: values.serasaSophusCodigo,
              serasaSophusEntidade: values.serasaSophusEntidade,
              serasaSophusSenha: values.serasaSophusSenha,
              serasaSophusMaximoDiasReutilizarConsulta:
                values.serasaSophusMaximoDiasReutilizarConsulta,
            },
          },
        });

        if (errors) {
          throw new GraphQLError('Falha ao alterar configuração', errors);
        }

        successToast('Configuração alterada com sucesso!');

        navigate('/configuracoes');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Fieldset legend="Sophus" columns={2}>
          <Input
            autoFocus
            label="Código Sophus"
            name="serasaSophusCodigo"
            error={errors.serasaSophusCodigo}
            value={fields.serasaSophusCodigo}
            onChange={value =>
              setFields({ ...fields, serasaSophusCodigo: value })
            }
          />
          <Input
            name="serasaSophusEntidade"
            label="Entidade Sophus"
            error={errors.serasaSophusEntidade}
            value={fields.serasaSophusEntidade}
            onChange={value =>
              setFields({ ...fields, serasaSophusEntidade: value })
            }
          />
          <InputWithIcon
            autoComplete="new-password"
            type="password"
            name="serasaSophusSenha"
            label="Senha Sophus"
            icon={() => <FiLock size={22} />}
            error={errors.serasaSophusSenha}
            value={fields.serasaSophusSenha}
            onChange={value =>
              setFields({ ...fields, serasaSophusSenha: value })
            }
          />
          <Input
            type="number"
            min={1}
            name="serasaSophusMaximoDiasReutilizarConsulta"
            label="Máx. Dias Reutilizar Consulta"
            error={errors.serasaSophusMaximoDiasReutilizarConsulta}
            value={`${fields.serasaSophusMaximoDiasReutilizarConsulta}`}
            onChange={value =>
              setFields({
                ...fields,
                serasaSophusMaximoDiasReutilizarConsulta: Number(value),
              })
            }
          />
        </Fieldset>
      </Form>
    </>
  );
};

import { Layout } from 'components/Layout';
import { AjudaView } from 'views/ajuda/ajuda';

export const AjudaPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Ajuda' }] }}>
      <AjudaView />
    </Layout>
  );
};

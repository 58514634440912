import { currencyMask } from 'functions/mask';

import {
  PedidoAuditarDataQuery,
  PedidoAuditarQueryVariables,
} from '../pessoa-fisica/auditar';

import { Email } from 'utils/types/Email';
import { Endereco } from 'utils/types/Endereco';
import { Telefone } from 'utils/types/Telefone';
import { RedeSocial } from 'utils/types/RedeSocial';
import { PedidoAnexo } from 'utils/types/PedidoAnexo';
import { PedidoPlano } from 'utils/types/PedidoPlano';
import { RedeSocialEnum } from 'utils/enums/RedeSocialEnum';
import { TipoPessoaEnum } from 'utils/enums/TipoPessoaEnum';
import { EstadoCivilEnum } from 'utils/enums/EstadoCivilEnum';
import { PedidoStatusEnum } from 'utils/enums/PedidoStatusEnum';

interface ValuesFields {
  tipoPessoa: string;
  dados: {
    nome: string;
    cpf: string;
    rg: string;
    dataNascimento: string;
    sexo: string;
    estadoCivilId: string;
    redesSociais: RedeSocial[];
    telefones: Telefone[];
    emails: Email[];
  };
  enderecoInstalacao: Endereco;
  enderecoCobranca: Endereco;
  consulta: { dataConsulta: string; resultado: string; url: string };
  planos: { plano: string; planosSelecionados: PedidoPlano[] };
  informacoesServico: {
    diaVencimentoId: string;
    fidelidadeId: string;
    pedidoFinalidades: string[];
    rede: string;
    senha: string;
  };
  dadosEmpregaticios: {
    vinculoEmpregaticioId: string;
    localTrabalho: string;
    telefoneTrabalho: { nome: string; telefone: string; whatsapp: boolean };
    renda: string;
    atividadeExercida: string;
    tipoResidencia: string;
    quantidadeMoradores: string;
    tempoMoradia: string;
    possuiInternet: boolean;
    operadoraId: string;
    motivoCancelamento: string;
  };
  anexos: { anexos: File[]; pedidoAnexos: PedidoAnexo[] };
  informacoesTecnicas: {
    dataAgendamento: Date | null;
    tecnico: string;
  };
  dadosPedido: {
    statusPedido: string;
    validacaoCliente: number;
  };
}

export const parseQueryData = (data: PedidoAuditarDataQuery) => {
  const pedidoFields = {
    tipoPessoa: data.pedido.tipoPessoa,
    dados: {
      nome: data.pedido.clientePessoaFisicaNome,
      cpf: data.pedido.clientePessoaFisicaCpf,
      rg: data.pedido.clientePessoaFisicaRg || '',
      dataNascimento: data.pedido.clientePessoaFisicaDataNascimento,
      sexo: data.pedido.clientePessoaFisicaSexo || '',
      estadoCivilId: data.pedido.clientePessoaFisicaEstadoCivilId || '',
      redesSociais:
        data.pedido.allPedidosRedesSociais.length > 0
          ? data.pedido.allPedidosRedesSociais
          : [{ link: '', redeSocial: '' as RedeSocialEnum }],
      telefones: data.pedido.allTelefones,
      emails:
        data.pedido.allEmails.length > 0
          ? data.pedido.allEmails
          : [{ nome: '', email: '' }],
    },
    enderecoInstalacao: {
      cep: data.pedido.enderecoInstalacao.cep,
      numero: data.pedido.enderecoInstalacao.numero,
      logradouro: data.pedido.enderecoInstalacao.logradouro,
      bairro: data.pedido.enderecoInstalacao.bairro,
      cidade: data.pedido.enderecoInstalacao?.cidade?.nome || '',
      estado: data.pedido.enderecoInstalacao?.cidade?.estado?.nome || '',
      complemento: data.pedido.enderecoInstalacao.complemento || '',
      pontoReferencia: data.pedido.enderecoInstalacao.pontoReferencia || '',
      ibge: `${data.pedido.enderecoInstalacao.cidadeId}`,
    },
    enderecoCobranca: {
      cep: data.pedido.enderecoCobranca.cep,
      numero: data.pedido.enderecoCobranca.numero,
      logradouro: data.pedido.enderecoCobranca.logradouro,
      bairro: data.pedido.enderecoCobranca.bairro,
      cidade: data.pedido.enderecoCobranca?.cidade?.nome || '',
      estado: data.pedido.enderecoCobranca?.cidade?.estado?.nome || '',
      complemento: data.pedido.enderecoCobranca.complemento || '',
      pontoReferencia: data.pedido.enderecoCobranca.pontoReferencia || '',
      ibge: `${data.pedido.enderecoCobranca.cidadeId}`,
    },
    consulta: {
      dataConsulta: data.pedido.consulta.dataConsulta,
      resultado: data.pedido.consulta.resultado,
      url: data.pedido.consulta.url,
    },
    planos: {
      plano: '',
      planosSelecionados: data.pedido.allPedidosPlanos,
    },
    informacoesServico: {
      diaVencimentoId: data.pedido.diaVencimentoId,
      fidelidadeId: data.pedido.fidelidadeId,
      pedidoFinalidades: data.pedido.allFinalidades.map(
        ({ finalidadeId }) => finalidadeId,
      ),
      rede: data.pedido.rede,
      senha: data.pedido.senha,
    },
    dadosEmpregaticios: {
      vinculoEmpregaticioId:
        data.pedido.clientePessoaFisicaVinculoEmpregaticioId,
      localTrabalho:
        data.pedido.clientePessoaFisicaRegistradoLocalTrabalho || '',
      renda: data.pedido.clientePessoaFisicaRegistradoRenda
        ? currencyMask(
            `${data.pedido.clientePessoaFisicaRegistradoRenda.toFixed(2)}`,
          )
        : '',
      telefoneTrabalho: {
        nome:
          data.pedido.clientePessoaFisicaRegistradoTelefoneTrabalho?.nome || '',
        telefone:
          data.pedido.clientePessoaFisicaRegistradoTelefoneTrabalho?.telefone ||
          '',
        whatsapp:
          data.pedido.clientePessoaFisicaRegistradoTelefoneTrabalho?.whatsapp ||
          false,
      },
      atividadeExercida:
        data.pedido.clientePessoaFisicaAutonomoAtividadeExercida || '',
      tipoResidencia: data.pedido.clientePessoaFisicaTipoResidencia || '',
      quantidadeMoradores:
        `${data.pedido.clientePessoaFisicaQuantidadeMoradores}` || '',
      tempoMoradia: data.pedido.clientePessoaFisicaTempoMoradia || '',
      possuiInternet: data.pedido.clientePessoaFisicaInternetAnterior || false,
      operadoraId:
        data.pedido.clientePessoaFisicaInternetAnteriorOperadoraId || '',
      motivoCancelamento:
        data.pedido.clientePessoaFisicaInternetAnteriorMotivoCancelamento || '',
    },
    anexos: {
      anexos: [],
      pedidoAnexos: data.pedido.allAnexos,
    },
    informacoesTecnicas: {
      tecnico: data.pedido.tecnicoId || '',
      dataAgendamento: data.pedido.dataAgendamentoInstalacao
        ? new Date(data.pedido.dataAgendamentoInstalacao)
        : null,
      statusPedido: data.pedido.status?.statusId || '',
      validacaoCliente: data.pedido.validacaoCliente || 0,
    },
    dadosPedido: {
      statusPedido: data.pedido.status.statusId || '',
      validacaoCliente: data.pedido.validacaoCliente || 0,
    },
    instalacao: {
      tecnicoId: data.pedido.tecnicoId,
      dataAgendamentoInstalacao: data.pedido.dataAgendamentoInstalacao,
      numeroSerieOnu: data.pedido?.numeroSerieOnu || '',
      cto: data.pedido?.cto || '',
      ctoPorta: data.pedido?.ctoPorta || '',
      pedidoMateriais: data.pedido.pedidosMateriais.items.map(item => ({
        materialId: item.material.materialId,
        nome: item.material.nome,
        quantidade: item.quantidade,
      })),
    },
  };

  const parsedInitialData = {
    diasVencimentos: data.diasVencimentos.items,
    fidelidades: data.fidelidades.items,
    finalidades: data.finalidades.items,
    operadoras: data.operadoras.items,
    planos: data.planos.items,
    vinculosEmpregaticios: data.vinculosEmpregaticios.items,
    tecnicos: data.usuarios.items,
    materiais: data.materiais.items,
  };

  return { pedidoFields, parsedInitialData };
};

export const parseFieldsToQueryVariables = (
  pedidoId: string,
  values: ValuesFields,
): PedidoAuditarQueryVariables => {
  return {
    id: { pedidoId },
    input: {
      clientePessoaFisicaNome: values.dados.nome,
      clientePessoaFisicaCpf: values.dados.cpf,
      clientePessoaFisicaRg: values.dados?.rg || undefined,
      clientePessoaFisicaDataNascimento: values.dados.dataNascimento,
      clientePessoaFisicaSexo: values.dados?.sexo || undefined,
      clientePessoaFisicaEstadoCivilId:
        (values.dados?.estadoCivilId as EstadoCivilEnum) || undefined,
      redesSociais: values.dados?.redesSociais.filter(
        redeSocial => redeSocial.link !== '',
      ),
      telefones: values.dados.telefones,
      emails: values.dados?.emails.filter(email => email.nome !== ''),
      enderecoInstalacao: {
        bairro: values.enderecoInstalacao.bairro,
        cep: values.enderecoInstalacao.cep,
        logradouro: values.enderecoInstalacao.logradouro,
        nome: 'Endereço Instalação',
        numero: values.enderecoInstalacao.numero,
        cidadeId: Number(values.enderecoInstalacao.ibge) || undefined,
        complemento: values.enderecoInstalacao.complemento || undefined,
        pontoReferencia: values.enderecoInstalacao.pontoReferencia || undefined,
      },
      enderecoCobranca: {
        bairro: values.enderecoCobranca.bairro,
        cep: values.enderecoCobranca!.cep,
        logradouro: values.enderecoCobranca.logradouro,
        nome: 'Endereço Cobrança',
        numero: values.enderecoCobranca!.numero,
        cidadeId: Number(values.enderecoInstalacao.ibge) || undefined,
        complemento: values.enderecoCobranca.complemento || undefined,
        pontoReferencia: values.enderecoCobranca.pontoReferencia || undefined,
      },
      planos: values.planos.planosSelecionados.map(({ planoId }) => ({
        planoId,
      })),
      diaVencimentoId: values.informacoesServico.diaVencimentoId,
      fidelidadeId: values.informacoesServico.fidelidadeId,
      finalidades: values.informacoesServico.pedidoFinalidades.map(
        finalidadeId => ({ finalidadeId }),
      ),
      rede: values.informacoesServico.rede,
      senha: values.informacoesServico.senha,
      pontoAdicional: false,
      clientePessoaFisicaVinculoEmpregaticioId:
        values.dadosEmpregaticios.vinculoEmpregaticioId,
      clientePessoaFisicaAutonomoAtividadeExercida:
        values.dadosEmpregaticios.atividadeExercida || undefined,
      clientePessoaFisicaRegistradoRenda:
        Number(
          values.dadosEmpregaticios.renda
            .replace(/[R$.\s]/g, '')
            .replace(',', '.'),
        ) || undefined,
      clientePessoaFisicaRegistradoLocalTrabalho:
        values.dadosEmpregaticios.localTrabalho || undefined,
      clientePessoaFisicaRegistradoTelefoneTrabalho: values.dadosEmpregaticios
        .telefoneTrabalho.telefone
        ? {
            nome: values.dadosEmpregaticios.telefoneTrabalho.nome,
            telefone: values.dadosEmpregaticios.telefoneTrabalho.telefone,
            whatsapp: values.dadosEmpregaticios.telefoneTrabalho.whatsapp,
          }
        : undefined,
      clientePessoaFisicaInternetAnterior:
        values.dadosEmpregaticios.possuiInternet,
      clientePessoaFisicaInternetAnteriorOperadoraId:
        values.dadosEmpregaticios.operadoraId || undefined,
      clientePessoaFisicaInternetAnteriorMotivoCancelamento:
        values.dadosEmpregaticios.motivoCancelamento || undefined,
      clientePessoaFisicaTipoResidencia:
        values.dadosEmpregaticios.tipoResidencia || undefined,
      clientePessoaFisicaQuantidadeMoradores: Number(
        values.dadosEmpregaticios.quantidadeMoradores,
      ),
      statusId: values.dadosPedido.statusPedido as PedidoStatusEnum,
      validacaoCliente:
        values.dadosPedido.validacaoCliente > 0
          ? values.dadosPedido.validacaoCliente
          : undefined,
      tipoPessoa: values.tipoPessoa as TipoPessoaEnum,
      anexos: [],
      dataAgendamentoInstalacao:
        values.informacoesTecnicas.dataAgendamento?.toISOString() || '',
      tecnicoId: values.informacoesTecnicas.tecnico,
    },
  };
};

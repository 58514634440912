import { useState } from 'react';
import { FiCheckCircle } from 'react-icons/fi';

import { Email } from 'utils/types/Email';
import { SexoEnum } from 'utils/enums/SexoEnum';
import { Telefone } from 'utils/types/Telefone';
import { GraphQLError } from 'utils/GraphQLError';
import { EnderecoInput } from 'utils/types/Endereco';
import { TipoPessoaEnum } from 'utils/enums/TipoPessoaEnum';
import { EstadoCivilEnum } from 'utils/enums/EstadoCivilEnum';
import { NewPedidoPessoaFisica } from 'views/pedidos/utils/NewPedidoPessoaFisica';

import { useAuth } from 'hooks/useAuth';
import { useError } from 'hooks/useError';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { Button } from 'components/Form';
import { StepTitle } from '../../components/StepTitle';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { NextStepFooter } from 'views/pedidos/components/NextStepFooter';

import consultaNaoRealizadaImg from 'assets/consulta-nao-realizada.svg';
import styles from './NewPedidoConsulta.module.scss';

interface ConsultaResult {
  consultaId: string;
  resultado: string;
}

interface ConsultaState {
  status: 'NAO_REALIZADO' | 'CONSULTANDO' | 'REALIZADO' | 'ERRO';
  resultadoConsulta: ConsultaResult | null;
}

interface ConsultaQueryVariables {
  input: {
    tipoPessoa: TipoPessoaEnum;
    clientePessoaFisicaNome: string;
    clientePessoaFisicaCpf: string;
    clientePessoaFisicaRg?: string;
    clientePessoaFisicaDataNascimento: string;
    clientePessoaFisicaSexo?: SexoEnum;
    clientePessoaFisicaEstadoCivilId?: EstadoCivilEnum;
    documento: string;
    telefones: Telefone[];
    emails?: Email[];
    enderecoInstalacao: EnderecoInput;
    enderecoCobranca: EnderecoInput;
  };
}

interface ConsultaQuery {
  actions: { consultar: ConsultaResult };
}

const CONSULTA_QUERY = gql`
  mutation ($input: ConsultarInput!) {
    actions {
      consultar(input: $input) {
        consultaId
        resultado
      }
    }
  }
`;

interface ConsultaProps {
  newPedido: NewPedidoPessoaFisica;
  setNewPedido: (values: NewPedidoPessoaFisica) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export const NewPedidoConsulta: React.FC<ConsultaProps> = ({
  newPedido,
  setNewPedido,
  nextStep,
  prevStep,
}) => {
  const { withErrorHandling } = useError();
  const { auth, setAuth } = useAuth();
  const [consulta, setConsulta] = useState<ConsultaState>({
    status: newPedido.consulta?.status || 'NAO_REALIZADO',
    resultadoConsulta: newPedido.consulta?.resultadoConsulta || null,
  });

  const handleConsult = withErrorHandling(async () => {
    setConsulta({
      status: 'CONSULTANDO',
      resultadoConsulta: null,
    });

    const { data, errors } = await graphqlAuth<
      ConsultaQuery,
      ConsultaQueryVariables
    >({
      auth,
      setAuth,
      query: CONSULTA_QUERY,
      variables: {
        input: {
          tipoPessoa: 'PESSOA_FISICA',
          documento: newPedido.dados!.cpf,
          clientePessoaFisicaNome: newPedido.dados!.nome,
          clientePessoaFisicaCpf: newPedido.dados!.cpf,
          clientePessoaFisicaRg: newPedido.dados?.rg,
          clientePessoaFisicaDataNascimento: newPedido.dados!.dataNascimento,
          clientePessoaFisicaSexo: newPedido.dados?.sexo as SexoEnum,
          clientePessoaFisicaEstadoCivilId: newPedido.dados
            ?.estadoCivil as EstadoCivilEnum,
          emails: newPedido.dados?.emails.filter(email => email.nome !== ''),
          telefones: newPedido.dados!.telefones,
          enderecoInstalacao: {
            bairro: newPedido.enderecoInstalacao?.bairro,
            cep: newPedido.enderecoInstalacao!.cep,
            logradouro: newPedido.enderecoInstalacao?.logradouro,
            nome: 'Endereço Instalação',
            numero: newPedido.enderecoInstalacao!.numero,
            cidadeId: Number(newPedido.enderecoInstalacao?.ibge) || undefined,
            complemento: newPedido.enderecoInstalacao?.complemento,
            pontoReferencia: newPedido.enderecoInstalacao?.pontoReferencia,
          },
          enderecoCobranca: {
            bairro: newPedido.enderecoCobranca?.bairro,
            cep: newPedido.enderecoCobranca!.cep,
            logradouro: newPedido.enderecoCobranca?.logradouro,
            nome: 'Endereço Cobrança',
            numero: newPedido.enderecoCobranca!.numero,
            cidadeId: Number(newPedido.enderecoInstalacao?.ibge) || undefined,
            complemento: newPedido.enderecoCobranca?.complemento,
            pontoReferencia: newPedido.enderecoCobranca?.pontoReferencia,
          },
        },
      },
    });

    if (errors) {
      setConsulta({ status: 'ERRO', resultadoConsulta: null });
      throw new GraphQLError('Falha ao consultar documento,', errors);
    }

    setConsulta({
      status: 'REALIZADO',
      resultadoConsulta: data.actions.consultar,
    });
  });

  const handleNextStep = () => {
    setNewPedido({
      consulta: {
        resultadoConsulta: consulta.resultadoConsulta!,
        status: consulta.status,
      },
    });
    nextStep();
  };

  return (
    <>
      <StepTitle title="Consulta" onGoBack={prevStep} />
      <section className={styles['consulta-section']}>
        <Button
          autoFocus
          type="button"
          background="secondary"
          title="Clique para realizar a consulta."
          onClick={handleConsult}
          disabled={
            consulta.status === 'CONSULTANDO' ||
            consulta.resultadoConsulta?.resultado === 'SEM_RESTRICAO'
          }
        >
          Realizar Consulta
        </Button>

        {consulta.status === 'NAO_REALIZADO' && (
          <article className={styles['consulta-nao-realizada']}>
            <h2 className="text">Consulta não realizada</h2>
            <figure>
              <img
                src={consultaNaoRealizadaImg}
                alt="Ilustração de consulta não realizada."
              />
            </figure>
          </article>
        )}

        {consulta.status === 'CONSULTANDO' && (
          <div className={styles['consulta-em-andamento']}>
            <LoadingSpinner size="large" />
            <p className="body2 title">Consultando...</p>
          </div>
        )}

        {consulta.status === 'REALIZADO' && (
          <article className={styles['consulta-realizada']}>
            <>
              <FiCheckCircle size={72} color="var(--success)" />
              <h2 className="success">Consulta realizada com sucesso!</h2>
            </>
          </article>
        )}

        {consulta.status === 'ERRO' && (
          <article className={styles['consulta-erro']}>
            <h2 className="text">Tente novamente.</h2>
            <p className="body2 text">Erro ao tentar consultar documento.</p>
          </article>
        )}
      </section>

      <NextStepFooter
        disableNextButton={consulta.status !== 'REALIZADO'}
        onNextStep={handleNextStep}
      />
    </>
  );
};

import { useEffect, useState } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { Column } from '.';
import { Sort } from '../../utils/Sort';
import styles from './TableHead.module.scss';

interface TableHeadProps<T> {
  columns: Column<T>[];
  sort?: Sort | null;
  onSort?: (sort: Sort | null) => void;
}

interface CurrentOrder {
  field: string;
  icon: () => JSX.Element;
}

export const TableHead = <T,>({ columns, sort, onSort }: TableHeadProps<T>) => {
  const [currentOrder, setCurrentOrder] = useState<CurrentOrder | null>(null);

  useEffect(() => {
    if (sort) {
      setCurrentOrder({
        field: sort.field,
        icon:
          sort.order === 'ASC'
            ? () => <FiArrowUp size={18} />
            : () => <FiArrowDown size={18} />,
      });
    } else {
      setCurrentOrder(null);
    }
  }, [sort]);

  const handleSort = (sort: Sort | null | undefined, column: Column<T>) => {
    if (!column.sortable || !onSort) {
      return;
    }

    if (sort && sort.field === column.key) {
      switch (sort.order) {
        case 'ASC':
          setCurrentOrder({
            field: column.key,
            icon: () => <FiArrowUp size={18} />,
          });
          onSort(null);
          break;
        case 'DESC':
          setCurrentOrder({
            field: column.key,
            icon: () => <FiArrowDown size={18} />,
          });
          onSort({ field: column.key, order: 'ASC' });
          break;
      }
    } else {
      setCurrentOrder(null);
      onSort({ field: column.key, order: 'DESC' });
    }
  };

  return (
    <thead className={styles['table-head']}>
      <tr>
        {columns.map(column => {
          if (column.renderHeadColumn) {
            return (
              <th key={`th-${column.key}`}>
                {column.renderHeadColumn(column)}
              </th>
            );
          }

          return (
            <th
              key={`th-${column.key}`}
              onClick={() => handleSort(sort, column)}
            >
              <p
                className={column.sortable ? `${styles.sortable}` : ''}
                title={column.sortable ? 'Clique para ordenar' : ''}
              >
                {column.label}

                {currentOrder &&
                  currentOrder.field === column.key &&
                  currentOrder.icon()}
              </p>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

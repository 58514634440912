import * as Yup from 'yup';
import { cpfValidator, phoneValidator } from 'functions/validators';

export const pedidoDadosPessoaFisicaSchema = Yup.object({
  nome: Yup.string().required('Nome obrigatório.'),
  cpf: Yup.string()
    .test({
      test: value => cpfValidator.isValid(`${value}`),
      message: 'CPF Inválido.',
    })
    .required('CPF obrigatório.'),
  rg: Yup.string(),
  dataNascimento: Yup.string().required('Data Nascimento obrigatório.'),
  sexo: Yup.string(),
  estadoCivil: Yup.string(),
  redesSociais: Yup.array().of(
    Yup.object({
      redeSocial: Yup.string(),
      link: Yup.string().when('redeSocial', {
        is: (value: string) => value !== '',
        then: Yup.string().required('Link obrigatório.'),
      }),
    }),
  ),
  telefones: Yup.array()
    .of(
      Yup.object({
        nome: Yup.string().required('Descrição do Telefone obrigatório.'),
        telefone: Yup.string()
          .test({
            test: value => phoneValidator.isValid(value),
            message: 'Telefone inválido',
          })
          .required('Telefone obrigatório.'),
        whatsapp: Yup.boolean(),
      }).required(),
    )
    .min(2, 'No mínimo ${min} telefones devem ser cadastrados.')
    .required(),
  emails: Yup.array().of(
    Yup.object({
      nome: Yup.string(),
      email: Yup.string().when('nome', {
        is: (value: string) => value !== '',
        then: Yup.string()
          .email('E-mail inválido')
          .required('E-mail obrigatório.'),
      }),
    }),
  ),
});

import { Layout } from 'components/Layout';
import { DetailDiaVencimentoView } from 'views/dia-vencimento/detail';

export const DetailDiaVencimentoPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Dias Vencimentos', href: '/dia-vencimento' },
          { label: 'Detalhes' },
        ],
      }}
    >
      <DetailDiaVencimentoView />
    </Layout>
  );
};

import { Button } from './Button';

import styles from './FormFooter.module.scss';

type FormFooterProps = {
  disableButtonSubmit?: boolean;
  onCancel: () => void;
};

export const FormFooter: React.FC<FormFooterProps> = ({
  disableButtonSubmit,
  onCancel,
}) => {
  return (
    <footer className={styles['btn-container']}>
      <div>
        <Button
          type="submit"
          background="success"
          disabled={disableButtonSubmit}
        >
          Confirmar
        </Button>
        <Button type="button" background="error" isOutlined onClick={onCancel}>
          Cancelar
        </Button>
      </div>
    </footer>
  );
};

import { ResponsiveBar } from '@nivo/bar';

import styles from './BarChart.module.scss';
import { BarChartLegend } from './BarChartLegend';

interface BarChartProps {
  data: Record<string, string | number>[];
  keys: string[];
  indexBy: string;
}

export const BarChart: React.FC<BarChartProps> = ({ data, indexBy, keys }) => {
  return (
    <>
      <BarChartLegend
        items={data.map(item => ({
          color: `${item.color}`,
          label: `${item.label}`,
        }))}
      />

      <figure className={styles['bar-chart-container']}>
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy={indexBy}
          borderWidth={5}
          borderRadius={3}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          axisBottom={null}
          colors={({ data }) => `${data.color}`}
          labelTextColor="white"
          margin={{ top: 20, right: 0, bottom: 40, left: 40 }}
          padding={0.5}
        />
      </figure>
    </>
  );
};

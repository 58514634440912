import * as Yup from 'yup';
import { cnpjValidator, cpfValidator } from 'functions/validators';

export const editPedidoPessoaJuridicaSchema = Yup.object({
  dados: Yup.object({
    razaoSocial: Yup.string().required('Razão Social obrigatório.'),
    nomeFantasia: Yup.string().required('Nome Fantasia obrigatório.'),
    cnpj: Yup.string()
      .test({
        test: value => cnpjValidator.isValid(`${value}`),
        message: 'CNPJ Inválido.',
      })
      .required('CNPJ obrigatório.'),
    dataAbertura: Yup.string().required('Data Abertura obrigatório.'),
    inscricaoEstadual: Yup.string(),
    inscricaoMunicipal: Yup.string(),
    tipoRetencaoNotaFiscal: Yup.string(),
    ramoEmpresa: Yup.string(),
    representanteNome: Yup.string().required('Nome obrigatório.'),
    representanteCpf: Yup.string()
      .test({
        test: value => cpfValidator.isValid(`${value}`),
        message: 'CNPJ Inválido.',
      })
      .required('CPF obrigatório'),
    representanteRg: Yup.string(),
    representanteEstadoCivilId: Yup.string(),
    representanteDataNascimento: Yup.string().required(
      'Data Nascimento obrigatório.',
    ),
    redesSociais: Yup.array().of(
      Yup.object({
        redeSocial: Yup.string(),
        link: Yup.string().when('redeSocial', {
          is: (value: string) => value !== '',
          then: Yup.string().required('Link obrigatório.'),
        }),
      }),
    ),
    telefones: Yup.array()
      .of(
        Yup.object({
          nome: Yup.string().required('Descrição do Telefone obrigatório.'),
          telefone: Yup.string().required('Telefone obrigatório.'),
          whatsapp: Yup.boolean(),
        }).required(),
      )
      .min(2, 'No mínimo ${min} telefones devem ser cadastrados.')
      .required(),
    emails: Yup.array().of(
      Yup.object({
        nome: Yup.string(),
        email: Yup.string().when('nome', {
          is: (value: string) => value !== '',
          then: Yup.string()
            .email('E-mail inválido')
            .required('E-mail obrigatório.'),
        }),
      }),
    ),
  }),
  enderecoInstalacao: Yup.object({
    cep: Yup.string().required('CEP obrigatório.'),
    numero: Yup.string().required('Número obrigatório.'),
    estado: Yup.string(),
    logradouro: Yup.string(),
    bairro: Yup.string(),
    cidade: Yup.string().required('Cidade obrigatório.'),
    complemento: Yup.string().nullable(),
    pontoReferencia: Yup.string().nullable(),
  }),
  enderecoCobranca: Yup.object({
    cep: Yup.string().required('CEP obrigatório.'),
    numero: Yup.string().required('Número obrigatório.'),
    estado: Yup.string(),
    logradouro: Yup.string(),
    bairro: Yup.string(),
    cidade: Yup.string().required('Cidade obrigatório.'),
    complemento: Yup.string().nullable(),
    pontoReferencia: Yup.string().nullable(),
  }),
  informacoesServico: Yup.object({
    diaVencimentoId: Yup.string().required('Dia Vencimento obrigatório.'),
    fidelidadeId: Yup.string().required('Fidelidade obrigatório.'),
    finalidades: Yup.array().of(Yup.string()),
    rede: Yup.string().required('Nome do wi-fi obrigatório.'),
    senha: Yup.string()
      .min(8, 'Mínimo de ${min} caracteres.')
      .required('Senha obrigatório.'),
  }),
  dadosPedido: Yup.object({
    statusPedido: Yup.string().required('Status do Pedido obrigatório.'),
  }),
});

import { useState } from 'react';
import { useNavigate } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';

import { gql } from 'functions/gql';
import { currencyMask } from 'functions/mask';
import { graphqlAuth } from 'functions/graphqlAuth';

import { Form, FormFooter, Input } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

interface PlanoCreateQueryVariables {
  input: {
    nome: string;
    preco: number;
  };
}

interface PlanoCreateQuery {
  planoCreate: {
    planoId: string;
    nome: string;
  };
}

const PLANO_CREATE_QUERY = gql`
  mutation ($input: PlanoCreateInput!) {
    planoCreate(input: $input) {
      planoId
      nome
    }
  }
`;

export const NewPlanoView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    nome: '',
    preco: '',
  });

  const handleSubmit = submitHandler({
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const parsedPreco = Number(
          values.preco.replace(/[R$.\s]/g, '').replace(',', '.'),
        );

        const { data, errors } = await graphqlAuth<
          PlanoCreateQuery,
          PlanoCreateQueryVariables
        >({
          query: PLANO_CREATE_QUERY,
          variables: { input: { nome: values.nome, preco: parsedPreco } },
          auth,
          setAuth,
        });

        if (errors) {
          throw new GraphQLError('Falha ao criar plano', errors);
        }

        successToast(`Plano: ${data.planoCreate.nome}`, 'Criado com sucesso!');

        navigate('/planos');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        columns={2}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
        <Input
          inputMode="numeric"
          step="0.01"
          min="0.00"
          name="preco"
          label="Valor do Plano (R$)"
          placeholder="R$ 0.00"
          error={errors.preco}
          value={fields.preco}
          onChange={value =>
            setFields({ ...fields, preco: currencyMask(value) })
          }
        />
      </Form>
    </>
  );
};

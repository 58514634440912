import * as Yup from 'yup';
import { useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { GraphQLError } from 'utils/GraphQLError';
import { successToast, warnToast } from 'utils/toast';
import { TipoPessoaEnum } from 'utils/enums/TipoPessoaEnum';
import { PedidoStatusEnum } from 'utils/enums/PedidoStatusEnum';

import { gql } from 'functions/gql';
import { cnpjMask, cpfMask } from 'functions/mask';
import { dateFormatWithHours } from 'functions/formatters';
import { graphqlFormDataAuth } from 'functions/graphqlAuth';

import {
  Form,
  Input,
  Signature,
  FormFooter,
  PreviewFile,
  InputMultiFile,
  Fieldset,
  Select,
  Button,
  Textarea,
} from 'components/Form';
import { Fallback } from 'components/Fallback';
import { FlexContainer } from 'components/FlexContainer';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { MateriaisTable } from '../../../components/MateriaisTable';

import styles from './instalar.module.scss';

interface Material {
  materialId: string;
  nome: string;
}

interface MaterialSelecionado extends Material {
  quantidade: number;
}

interface PedidoInstalarQueryVariables {
  id: { pedidoId: string };
  input: {
    numeroSerieOnu: string | null;
    cto: string | null;
    ctoPorta: string | null;
    comunicacao?: string;
    anexos: File[];
    materiais: { materialId: string; quantidade: number }[];
  };
}

interface PedidoInstalarQuery {
  actions: {
    pedidos: {
      instalar: { pedidoId: string };
    };
  };
}

const PEDIDO_INSTALAR_QUERY = gql`
  mutation ($id: PedidoID!, $input: PedidoInstalarInput!) {
    actions {
      pedidos {
        instalar(id: $id, input: $input) {
          pedidoId
        }
      }
    }
  }
`;

interface PedidoInstalacaoDataQueryVariables {
  id: { pedidoId: string };
}

interface PedidoInstalacaoDataQuery {
  pedido: {
    pedidoId: string;
    tipoPessoa: TipoPessoaEnum;
    clientePessoaFisicaNome: string | null;
    clientePessoaFisicaCpf: string | null;
    clientePessoaJuridicaRazaoSocial: string | null;
    clientePessoaJuridicaCnpj: string | null;
    dataAgendamentoInstalacao: string;
    statusId: PedidoStatusEnum;
  };
  materiais: {
    items: { materialId: string; nome: string }[];
  };
}

const PEDIDO_INSTALACAO_DATA_QUERY = gql`
  query ($id: PedidoID!) {
    pedido(id: $id) {
      pedidoId
      tipoPessoa
      clientePessoaFisicaNome
      clientePessoaFisicaCpf
      clientePessoaJuridicaRazaoSocial
      clientePessoaJuridicaCnpj
      dataAgendamentoInstalacao
      statusId
    }
    materiais(filter: { equals: { ativo: true } }) {
      items {
        materialId
        nome
      }
    }
  }
`;

export const PedidoInstalacaoView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    numeroSerieOnu: '',
    cto: '',
    ctoPorta: '',
    comunicacao: '',
    material: '',
    quantidade: 1,
    materiaisSelecionados: [] as MaterialSelecionado[],
    anexos: [] as File[],
    assinatura: null as File | null,
  });

  const pedidoId = params.pedidoId || '';

  const query = useQueryAuth<
    PedidoInstalacaoDataQuery,
    PedidoInstalacaoDataQueryVariables
  >({
    query: PEDIDO_INSTALACAO_DATA_QUERY,
    variables: { id: { pedidoId } },
    auth,
    setAuth,
  });

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  const addMaterial = async (
    material: Material | undefined,
    quantidade: number,
  ) => {
    const handleAddMaterial = submitHandler({
      validateSchema: Yup.object({
        material: Yup.string().required('Material obrigatório.'),
        quantidade: Yup.number()
          .min(1, 'Quantidade deve ser maior do que 0.')
          .required('Quantidade obrigatório.'),
      }),
      callback: async () => {
        setFields({
          ...fields,
          materiaisSelecionados: [
            ...fields.materiaisSelecionados,
            {
              materialId: material!.materialId,
              nome: material!.nome,
              quantidade,
            },
          ],
        });
      },
    });

    await handleAddMaterial();
  };

  const removeMaterial = (index: number) => {
    setFields({
      ...fields,
      materiaisSelecionados: fields.materiaisSelecionados.filter(
        (_, i) => index !== i,
      ),
    });
  };

  const handleSubmit = submitHandler({
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        if (values.anexos.length < 5) {
          warnToast('No mínimo 5 anexos devem ser adicionados.', '', {
            position: 'top-right',
          });
          return;
        }

        if (values.assinatura === null) {
          warnToast('Assinatura não informada!', '', {
            position: 'top-right',
          });
          return;
        }

        const allFiles = values.anexos
          .map(file => file)
          .concat(values.assinatura);

        const { errors } = await graphqlFormDataAuth<
          PedidoInstalarQuery,
          PedidoInstalarQueryVariables
        >({
          query: PEDIDO_INSTALAR_QUERY,
          variables: {
            id: { pedidoId },
            input: {
              numeroSerieOnu: values.numeroSerieOnu || null,
              cto: values.cto || null,
              ctoPorta: values.ctoPorta || null,
              comunicacao: values.comunicacao || undefined,
              materiais: values.materiaisSelecionados.map(item => ({
                materialId: item.materialId,
                quantidade: item.quantidade,
              })),
              anexos: [],
            },
          },
          files: allFiles.map((anexo, index) => [
            `variables.input.anexos.${index}`,
            anexo,
          ]),
          auth,
          setAuth,
        });

        if (errors) {
          throw new GraphQLError('Erro ao instalar o pedido.', errors);
        }

        successToast('Pedido instalado com sucesso!', '', {
          position: 'top-right',
        });

        navigate('/pedidos/instalacoes');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  const { data } = query;

  return (
    <>
      <div className={styles['card-container']}>
        <div className={styles['card-header']}>
          <h5 className="heading5 title">
            {data.pedido.tipoPessoa === 'PESSOA_FISICA'
              ? 'Pessoa Física'
              : 'Pessoa Jurídica'}
          </h5>
        </div>

        <div className={styles['card-main']}>
          <p className="body2">
            STATUS:{' '}
            <span className="warning">
              {data.pedido.statusId.replace(/[_]/g, ' ')}
            </span>
          </p>

          <div className={styles['pedido-details']}>
            <p className={`${styles['text-column']} body4 textLight`}>
              CÓD. PEDIDO:
              <span className="body2 text">{data.pedido.pedidoId}</span>
            </p>
            <p className={`${styles['text-column']} body4 textLight`}>
              DOCUMENTO:
              <span className="body2 text">
                {data.pedido.tipoPessoa === 'PESSOA_FISICA'
                  ? cpfMask(data.pedido.clientePessoaFisicaCpf)
                  : cnpjMask(data.pedido.clientePessoaJuridicaCnpj)}
              </span>
            </p>
            <p className={`${styles['text-column']} body4 textLight`}>
              NOME:
              <span className="body2 text">
                {data.pedido.tipoPessoa === 'PESSOA_FISICA'
                  ? data.pedido.clientePessoaFisicaNome
                  : data.pedido.clientePessoaJuridicaRazaoSocial}
              </span>
            </p>
            <p className={`${styles['text-column']} body4 textLight`}>
              AGEND. INSTALAÇÃO:
              <span className="body2 text">
                {data.pedido.dataAgendamentoInstalacao
                  ? dateFormatWithHours(data.pedido.dataAgendamentoInstalacao)
                  : '----'}
              </span>
            </p>
          </div>
        </div>
      </div>

      <hr className={styles['divider']} />

      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />

      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          label="Número de série ONU"
          name="numeroSerieOnu"
          error={errors?.numeroSerieOnu}
          value={fields.numeroSerieOnu}
          onChange={value => setFields({ ...fields, numeroSerieOnu: value })}
        />
        <FlexContainer>
          <Input
            type="number"
            label="CTO"
            name="cto"
            error={errors?.cto}
            value={fields.cto}
            onChange={value => setFields({ ...fields, cto: value })}
          />
          <Input
            type="number"
            label="CTO Porta"
            name="ctoPorta"
            error={errors?.ctoPorta}
            value={fields.ctoPorta}
            onChange={value => setFields({ ...fields, ctoPorta: value })}
          />
        </FlexContainer>

        <Textarea
          label="Comunicação interna"
          name="comunicacao"
          value={fields.comunicacao}
          onChange={value => setFields({ ...fields, comunicacao: value })}
        />

        <Fieldset legend="Materiais">
          <div className={styles['pedido-materiais-fields']}>
            <Select
              name="materiais"
              label="Material"
              error={errors?.material}
              value={fields.material}
              onChange={value => setFields({ ...fields, material: value })}
              options={data.materiais.items
                .map(({ materialId, nome }) => ({
                  label: nome,
                  value: materialId,
                }))
                .sort((a, b) => (a.label < b.label ? -1 : 1))}
            />
            <Input
              type="number"
              name="quantidade"
              label="Quantidade"
              min={1}
              error={errors?.quantidade}
              value={`${fields.quantidade}`}
              onChange={value =>
                setFields({ ...fields, quantidade: Number(value) })
              }
            />
            <Button
              type="button"
              background="success"
              className="column-start"
              onClick={() => {
                const material = data.materiais.items.find(
                  material => material.materialId === fields.material,
                );

                addMaterial(material, fields.quantidade);
              }}
            >
              Adicionar material
            </Button>
          </div>

          {fields.materiaisSelecionados.length > 0 ? (
            <MateriaisTable
              materiais={fields.materiaisSelecionados}
              onRemoveMaterial={removeMaterial}
            />
          ) : (
            <p className="body2 text" style={{ textAlign: 'center' }}>
              Nenhum material adicionado.
            </p>
          )}
        </Fieldset>

        <Fieldset legend="Anexos">
          <InputMultiFile
            className={styles['input-multi-file']}
            label="Adicionar anexos"
            name="anexos"
            value={fields.anexos}
            onChange={files =>
              files &&
              setFields({
                ...fields,
                anexos: [...fields.anexos, ...Array.from(files)],
              })
            }
          />

          {fields.anexos.length > 0 ? (
            <PreviewFile
              files={fields.anexos.map(anexo => ({
                fileName: anexo.name,
                url: URL.createObjectURL(anexo),
                mimetype: anexo.type,
              }))}
              removeFiles={index =>
                setFields(state => ({
                  ...state,
                  anexos: state.anexos.filter((_, i) => i !== index),
                }))
              }
            />
          ) : (
            <p className="body2 text" style={{ textAlign: 'center' }}>
              Nenhum anexo adicionado.
            </p>
          )}
        </Fieldset>

        <Fieldset legend="Assinatura do cliente">
          <Signature
            onChange={value => setFields({ ...fields, assinatura: value })}
          />
        </Fieldset>
      </Form>
    </>
  );
};

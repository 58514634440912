import { Layout } from 'components/Layout';
import { NewMaterialView } from 'views/materiais/new';

export const NewMaterialPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [{ label: 'Materiais', href: '/materiais' }, { label: 'Novo' }],
      }}
    >
      <NewMaterialView />
    </Layout>
  );
};

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  FiChevronsDown,
  FiHome,
  FiLogOut,
  FiMenu,
  FiUser,
} from 'react-icons/fi';

import { useAuth } from 'hooks/useAuth';

import { Menu } from './menuItems';
import { NavbarList } from './NavbarList';

import logo from 'assets/logo-dark.svg';
import styles from './MenuMobile.module.scss';

type MenuMobileProps = {
  menuItems: Menu[];
};

export const MenuMobile: React.FC<MenuMobileProps> = ({ menuItems }) => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div
      className={`${isNavOpen ? styles['menu-mobile-overlay'] : ''}`}
      onClick={() => setIsNavOpen(!isNavOpen)}
    >
      <div
        className={styles['menu-mobile-container']}
        onClick={e => e.stopPropagation()}
      >
        <div className={styles['tab-bar-container']}>
          <Link to="/perfil" className={styles['tab-option']}>
            <FiUser size={22} />
          </Link>

          <div className={styles['home-button']}>
            <Link to="/">
              <FiHome size={38} />
            </Link>
          </div>

          <button
            type="button"
            className={styles['tab-option']}
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <FiMenu size={22} />
          </button>
        </div>

        <nav
          className={`${styles['tab-bar-nav']} ${
            isNavOpen ? styles['is-nav-open'] : ''
          }`}
        >
          <div className={styles['tab-bar-nav-header']}>
            <figure>
              <img src={logo} alt="Fibratech" />
            </figure>

            <div style={{ display: 'flex', gap: '1.5rem' }}>
              <button
                className={styles['logout-button']}
                onClick={() => {
                  setAuth(null);
                  navigate('/');
                }}
              >
                Sair <FiLogOut size={22} />
              </button>
              <button
                type="button"
                aria-label="Fechar menu"
                className={styles['hide-nav-button']}
                onClick={() => setIsNavOpen(false)}
              >
                <FiChevronsDown size={22} />
              </button>
            </div>
          </div>

          <NavbarList items={menuItems} />
        </nav>
      </div>
    </div>
  );
};

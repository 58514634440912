import styles from './UserAvatar.module.scss';

interface UserAvatarProps {
  url: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ url }) => {
  return (
    <figure className={styles['user-avatar-container']}>
      <img src={url} alt="Foto de Perfil" />
    </figure>
  );
};

import { FiPlus } from 'react-icons/fi';

import { PedidoPlano } from 'utils/types/PedidoPlano';

import { PlanosTable } from 'components/PlanosTable';
import { Button, Fieldset, Select } from 'components/Form';

interface Fields {
  plano: string;
  planosSelecionados: PedidoPlano[];
}

interface PlanosProps {
  allPlanos: PedidoPlano[];
  fields: Fields;
  setFields: (value: Fields) => void;
}

export const FieldsetPlanos: React.FC<PlanosProps> = ({
  allPlanos,
  fields,
  setFields,
}) => {
  const addPlano = (plano: PedidoPlano | undefined) => {
    if (!plano) {
      return;
    }

    setFields({
      ...fields,
      planosSelecionados: [...fields.planosSelecionados, plano],
    });
  };

  const removePlano = (index: number) => {
    setFields({
      ...fields,
      planosSelecionados: fields.planosSelecionados.filter(
        (_, i) => index !== i,
      ),
    });
  };

  return (
    <Fieldset legend="Planos" columns={2}>
      <Select
        name="planos"
        label="Planos"
        value={fields.plano}
        onChange={value => setFields({ ...fields, plano: value })}
        options={allPlanos.map(({ nome, planoId }) => ({
          label: nome,
          value: planoId,
        }))}
      />
      <Button
        type="button"
        background="success"
        className="column-start"
        onClick={() =>
          addPlano(allPlanos.find(plano => plano.planoId === fields.plano))
        }
      >
        <FiPlus size={22} />
        Adicionar
      </Button>

      <section className="span-2">
        <h4 className="heading4 title">Planos Selecionados:</h4>
        <PlanosTable
          planos={fields.planosSelecionados}
          onRemovePlano={removePlano}
        />
      </section>
    </Fieldset>
  );
};

import { Fieldset, Input, MultiSelect, Select } from 'components/Form';

interface Fields {
  diaVencimentoId: string;
  fidelidadeId: string;
  pedidoFinalidades: string[];
  rede: string;
  senha: string;
}

interface InformacoesServicoProps {
  allDiasVencimentos: { diaVencimentoId: string; nome: string }[];
  allFidelidades: { fidelidadeId: string; nome: string }[];
  allFinalidades: { finalidadeId: string; nome: string }[];
  fields: Fields;
  setFields: (value: Fields) => void;
  errors: any;
}

export const FieldsetInformacoesServico: React.FC<InformacoesServicoProps> = ({
  allDiasVencimentos,
  allFidelidades,
  allFinalidades,
  fields,
  setFields,
  errors,
}) => {
  return (
    <Fieldset legend="Informações do Serviço" columns={2}>
      <Select
        name="diaVencimento"
        label="Dia Vencimento"
        error={errors?.diaVencimentoId}
        value={fields.diaVencimentoId}
        onChange={value => setFields({ ...fields, diaVencimentoId: value })}
        options={allDiasVencimentos.map(({ diaVencimentoId, nome }) => ({
          label: nome,
          value: diaVencimentoId,
        }))}
      />
      <Select
        name="fidelidade"
        label="Fidelidade"
        error={errors?.fidelidadeId}
        value={fields.fidelidadeId}
        onChange={value => setFields({ ...fields, fidelidadeId: value })}
        options={allFidelidades.map(({ fidelidadeId, nome }) => ({
          label: nome,
          value: fidelidadeId,
        }))}
      />
      <MultiSelect
        name="finalidades"
        label="Finalidades"
        error={errors?.pedidoFinalidades}
        value={fields.pedidoFinalidades}
        onChange={value => setFields({ ...fields, pedidoFinalidades: value })}
        options={allFinalidades.map(({ finalidadeId, nome }) => ({
          label: nome,
          value: finalidadeId,
        }))}
      />
      <Input
        name="rede"
        label="Nome do wi-fi"
        error={errors?.rede}
        value={fields.rede}
        onChange={value => setFields({ ...fields, rede: value })}
      />
      <Input
        name="senha"
        label="Senha"
        error={errors?.senha}
        value={fields.senha}
        onChange={value => setFields({ ...fields, senha: value })}
      />
    </Fieldset>
  );
};

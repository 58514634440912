import { Router } from './Router';
import { AuthContextProvider } from './contexts/AuthContext';
import { ErrorProvider } from 'contexts/ErrorContext';

import { ToastContainer } from 'react-toastify';

import './styles/global.scss';
import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
  return (
    <AuthContextProvider>
      <ErrorProvider>
        <ToastContainer />
        <Router />
      </ErrorProvider>
    </AuthContextProvider>
  );
};

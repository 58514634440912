import { Layout } from 'components/Layout';
import { NewFinalidadeView } from 'views/finalidades/new';

export const NewFinalidadePage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Finalidade', href: '/finalidades' },
          { label: 'Novo' },
        ],
      }}
    >
      <NewFinalidadeView />
    </Layout>
  );
};

import { LoadingSpinner } from 'components/LoadingSpinner';
import { TableWrapper } from 'components/Table/TableWrapper';
import { TableHeader } from 'components/Table/TableHeader';
import { Sort } from 'utils/Sort';
import { Column } from 'components/Table';

interface RelatorioTableProps<T> {
  title: string;
  totalCount: number;
  columns: Column<T>[];
  items: T[];
  isLoading: boolean;
  getRowId: (item: T) => string;
  sort?: Sort | null;
  onChangeSort?: (sort: Sort | null) => void;
  sideComponent?: () => JSX.Element;
  tFootComponent?: () => JSX.Element;
}

export const RelatorioTable = <T,>({
  columns,
  getRowId,
  items,
  isLoading,
  onChangeSort,
  sort,
  title,
  totalCount,
  sideComponent,
  tFootComponent,
}: RelatorioTableProps<T>) => {
  return (
    <section>
      <TableHeader
        title={`${title} (${totalCount})`}
        sideComponent={sideComponent}
      />
      {isLoading ? (
        <LoadingSpinner size="small" />
      ) : (
        <TableWrapper
          columns={columns}
          items={items}
          getRowId={getRowId}
          sort={sort}
          onSort={onChangeSort}
          tFootComponent={tFootComponent}
        />
      )}
    </section>
  );
};

import { Layout } from 'components/Layout';
import { DashboardView } from 'views/dashboard/dashboard';

export const DashboardPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Dashboard' }] }}>
      <DashboardView />
    </Layout>
  );
};

import { AppMenu } from './AppMenu/AppMenu';
import { Breadcrumb, BreadcrumbProps } from './Breadcrumb';

import styles from './Layout.module.scss';

type LayoutProps = {
  breadcrumb: BreadcrumbProps;
  component?: JSX.Element;
};

export const Layout: React.FC<LayoutProps> = ({
  children,
  breadcrumb,
  component,
}) => {
  return (
    <div className={styles['layout-container']}>
      <div className={styles['blue-background']} />
      <div
        className={`${styles['layout-wrapper-container']} centralized-container`}
      >
        <AppMenu />
        <main className={styles['main-content-container']}>
          <div className={styles['main-content-title']}>
            <Breadcrumb items={breadcrumb.items} />
            {component && (
              <div className={styles['main-content-components-container']}>
                {component}
              </div>
            )}
          </div>
          <hr className={styles['main-divider']} />
          {children}
        </main>
      </div>
    </div>
  );
};

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useForm } from 'hooks/useForm';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';

import { Form, FormFooter, Input } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

interface OperadoraUpdateQuery {
  operadoraUpdate: {
    operadoraId: number;
    nome: string;
  };
}

interface OperadoraUpdateVariables {
  id: { operadoraId: string };
  input: { nome: string };
}

const OPERADORA_UPDATE_QUERY = gql`
  mutation ($input: OperadoraUpdateInput!, $id: OperadoraID!) {
    operadoraUpdate(input: $input, id: $id) {
      operadoraId
      nome
    }
  }
`;

interface OperadoraEditDataQuery {
  operadora: {
    operadoraId: string;
    nome: string;
  };
}

interface OperadoraEditDataQueryVariables {
  id: { operadoraId: string };
}

const OPERADORA_EDIT_DATA_QUERY = gql`
  query ($id: OperadoraID!) {
    operadora(id: $id) {
      operadoraId
      nome
    }
  }
`;

export const EditOperadoraView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({ nome: '' });

  const operadoraId = params.operadoraId || '';

  const query = useQueryAuth<
    OperadoraEditDataQuery,
    OperadoraEditDataQueryVariables
  >({
    auth,
    setAuth,
    query: OPERADORA_EDIT_DATA_QUERY,
    variables: { id: { operadoraId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({ ...fields, nome: query.data.operadora.nome });
    }
  }, [query.data]);

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      nome: Yup.string().required('Nome obrigatório.'),
    }),
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { errors } = await graphqlAuth<
          OperadoraUpdateQuery,
          OperadoraUpdateVariables
        >({
          auth,
          setAuth,
          query: OPERADORA_UPDATE_QUERY,
          variables: { id: { operadoraId }, input: { nome: values.nome } },
        });

        if (errors) {
          throw new GraphQLError('Falha ao alterar operadora', errors);
        }

        successToast('Operadora alterado com sucesso!');

        navigate('/operadoras');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
      </Form>
    </>
  );
};

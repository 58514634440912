import { FaWhatsapp } from 'react-icons/fa';
import { FiMail, FiPhoneCall } from 'react-icons/fi';

import allcomLogoImg from 'assets/allcom-logo.svg';
import teamviewerLogoImg from 'assets/teamviewer.svg';
import styles from './ajuda.module.scss';

export const AjudaView: React.FC = () => {
  return (
    <section className={styles['help-section-container']}>
      <article className={styles['help-section-description']}>
        <h5 className="heading5 title">
          Clique em uma das opções abaixo para iniciar um suporte:
        </h5>
        <p className="body4">
          A opção{' '}
          <a
            href="https://www.teamviewer.com/pt-br/download"
            target="_blank"
            rel="noreferrer"
            className="primary"
          >
            TeamViewer
          </a>{' '}
          é necessário ter o aplicativo instalado.
        </p>

        <div className={styles['help-button-container']}>
          <a
            href="https://api.whatsapp.com/send?phone=551441411395&text=Estou%20entrando%20em%20contato%20pois%20estou%20com%20problemas%20com%20o%20Fibra%20Tech."
            target="_blank"
            rel="noreferrer"
          >
            <FaWhatsapp size={30} color="var(--green)" />
            WhatsApp
          </a>
          <a href="teamviewer8://remotecontrol">
            <img src={teamviewerLogoImg} alt="Team Viewer" />
            TeamViewer
          </a>
        </div>
      </article>

      <hr className={styles['divider']} />

      <div className={styles['help-footer-container']}>
        <article className={styles['help-contact-container']}>
          <h5 className="heading5 title">Contato</h5>
          <p className="body2 text">
            <FiMail size={18} /> suporte@allcomsistemas.com.br
          </p>
          <p className="body2 text">
            <FiPhoneCall size={18} /> (14) 4141-1395
          </p>
        </article>

        <div className={styles['logo-container']}>
          <figure title="Allcom Sistemas">
            <img src={allcomLogoImg} alt="AllCom Sistemas" />
          </figure>
          <span className="body2 text">
            Versão do app: {process.env.REACT_APP_VERSION}
          </span>
        </div>
      </div>
    </section>
  );
};

import styles from './Card.module.scss';

interface CardProps {
  title: string;
  headerIcon?: () => JSX.Element;
}

export const Card: React.FC<CardProps> = ({ title, headerIcon, children }) => {
  return (
    <section className={styles['card-container']}>
      <div className={styles['card-header']}>
        <h6 className="heading6 title">{title}</h6>
        {headerIcon && <i>{headerIcon()}</i>}
      </div>
      <div className={styles['card-content']}>{children}</div>
    </section>
  );
};

import { useState } from 'react';
import { FiEye, FiPrinter } from 'react-icons/fi';
import { useNavigate } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { Sort } from 'utils/Sort';
import { Filter } from 'utils/Filter';
import { Pagination } from 'utils/Pagination';

import { gql } from 'functions/gql';
import { paginate } from 'functions/paginate';
import { pedidoFilter } from 'functions/pedidoFilter';
import { dateFormatWithHours } from 'functions/formatters';
import { cnpjMask, cpfMask, currencyMask } from 'functions/mask';

import { Table } from 'components/Table';
import { Button } from 'components/Form';
import { Fallback } from 'components/Fallback';
import { IconButton } from 'components/IconButton';
import { FlexContainer } from 'components/FlexContainer';
import { PedidosFilterFields } from 'components/PedidosFilter';
import { PedidosFilterByStatus } from 'components/PedidosFilterByStatus';
import { IconLink } from 'components/IconLink';
import { Badge } from 'components/Badge';

interface AuditoriaListQueryVariables {
  filter?: Filter;
  pagination: Pagination;
  sort?: Sort[];
}

interface AuditoriaListQuery {
  pedidos: {
    totalCount: number;
    items: {
      pedidoId: string;
      tipoPessoa: string;
      clientePessoaFisicaNome?: string;
      clientePessoaFisicaCpf: string;
      clientePessoaJuridicaRazaoSocial?: string;
      clientePessoaJuridicaCnpj: string;
      allPedidosPlanos: { planoId: string; preco: number }[];
      vendedor: { nome: string };
      dataAgendamentoInstalacao?: string;
      tecnico?: { nome: string };
      statusId: string;
      dataInclusao: string;
    }[];
  };
  usuarios: { items: { usuarioId: string; nome: string; perfilId: string }[] };
}

const AUDITORIA_LIST_QUERY = gql`
  query ($pagination: Pagination, $filter: PedidoFilter, $sort: [Sort!]) {
    pedidos(pagination: $pagination, filter: $filter, sort: $sort) {
      totalCount
      items {
        pedidoId
        tipoPessoa
        clientePessoaFisicaNome
        clientePessoaFisicaCpf
        clientePessoaJuridicaRazaoSocial
        clientePessoaJuridicaCnpj
        allPedidosPlanos {
          planoId
          preco
        }
        vendedor {
          nome
        }
        dataAgendamentoInstalacao
        tecnico {
          nome
        }
        statusId
        dataInclusao
      }
    }
    usuarios(
      filter: {
        equals: { ativo: true }
        includes: { perfilId: [VENDEDOR, TECNICO] }
      }
    ) {
      items {
        usuarioId
        nome
        perfilId
      }
    }
  }
`;

export const AuditoriaView: React.FC = () => {
  const navigate = useNavigate();
  const { acessos, auth, setAuth } = useAuth();

  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [filterStatus, setFilterStatus] = useState([
    'PENDENTE_ANALISE_CREDITO',
    'PENDENTE_ATIVACAO',
    'PENDENTE_CADASTRO_RADIUS',
    'PENDENTE_CONFIRMACAO',
    'PENDENTE_CONSULTA_SPC_SERASA',
  ]);
  const [filterFields, setFilterFields] = useState<Filter | null>(null);
  const [sort, setSort] = useState<Sort | null>({
    field: 'dataInclusao',
    order: 'DESC',
  });

  const query = useQueryAuth<AuditoriaListQuery, AuditoriaListQueryVariables>({
    auth,
    setAuth,
    query: AUDITORIA_LIST_QUERY,
    variables: {
      filter: pedidoFilter(filterStatus, filterFields),
      pagination: paginate(pagination),
      sort: sort ? [sort] : undefined,
    },
  });

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  return (
    <>
      <PedidosFilterByStatus
        filterStatus={filterStatus}
        onFilterStatus={filterStatusProps => setFilterStatus(filterStatusProps)}
      />
      <Table
        title="Pedidos Auditoria"
        columns={[
          {
            key: 'opcoes',
            label: 'Opções',
            render: ({ pedidoId, tipoPessoa }) => (
              <FlexContainer>
                {acessos.includes('CADASTROS.PEDIDOS.AUDITAR') && (
                  <Button
                    type="button"
                    size="small"
                    style={{ width: 'max-content' }}
                    onClick={() =>
                      tipoPessoa === 'PESSOA_FISICA'
                        ? navigate(`/pedidos/pessoa-fisica/${pedidoId}/auditar`)
                        : navigate(
                            `/pedidos/pessoa-juridica/${pedidoId}/auditar`,
                          )
                    }
                  >
                    Auditar
                  </Button>
                )}
                {acessos.includes('CADASTROS.PEDIDOS.VISUALIZAR') && (
                  <>
                    <IconButton
                      title="Visualizar"
                      className="hover-green"
                      icon={() => <FiEye size={22} />}
                      disabled={
                        !acessos.includes('CADASTROS.PEDIDOS.VISUALIZAR')
                      }
                      toolTipDirection="right"
                      onClick={() =>
                        tipoPessoa === 'PESSOA_FISICA'
                          ? navigate(
                              `/pedidos/pessoa-fisica/${pedidoId}/detail`,
                            )
                          : navigate(
                              `/pedidos/pessoa-juridica/${pedidoId}/detail`,
                            )
                      }
                    />
                    <IconLink
                      openAnotherTab
                      title="Imprimir"
                      className="hover-orange"
                      icon={() => <FiPrinter size={22} />}
                      disabled={
                        !acessos.includes('CADASTROS.PEDIDOS.VISUALIZAR')
                      }
                      path={
                        tipoPessoa === 'PESSOA_FISICA'
                          ? `/pedidos/pessoa-fisica/${pedidoId}/imprimir`
                          : `/pedidos/pessoa-juridica/${pedidoId}/imprimir`
                      }
                    />
                  </>
                )}
              </FlexContainer>
            ),
          },
          {
            key: 'pedidoId',
            label: 'ID',
            sortable: true,
          },
          {
            key: 'tipoPessoa',
            label: 'Tipo Pessoa',
            sortable: true,
            render: ({ tipoPessoa }) => (
              <p>
                {tipoPessoa === 'PESSOA_FISICA'
                  ? 'Pessoa Física'
                  : 'Pessoa Jurídica'}
              </p>
            ),
          },
          {
            key: 'documento',
            label: 'Documento',
            render: ({
              tipoPessoa,
              clientePessoaFisicaCpf,
              clientePessoaJuridicaCnpj,
            }) => (
              <p>
                {tipoPessoa === 'PESSOA_FISICA'
                  ? cpfMask(clientePessoaFisicaCpf)
                  : cnpjMask(clientePessoaJuridicaCnpj)}
              </p>
            ),
          },
          {
            key: 'nome',
            label: 'Nome / Razão Social',
            render: ({
              tipoPessoa,
              clientePessoaFisicaNome,
              clientePessoaJuridicaRazaoSocial,
            }) => (
              <p>
                {tipoPessoa === 'PESSOA_FISICA'
                  ? clientePessoaFisicaNome
                  : clientePessoaJuridicaRazaoSocial}
              </p>
            ),
          },
          {
            key: 'total',
            label: 'Total (R$)',
            render: ({ allPedidosPlanos }) => (
              <p style={{ textAlign: 'right' }}>
                {currencyMask(
                  `${allPedidosPlanos
                    .reduce((preco, crr) => preco + crr.preco, 0)
                    .toFixed(2)}`,
                )}
              </p>
            ),
          },
          {
            key: 'vendedor',
            label: 'Vendedor',
            render: ({ vendedor }) => <p>{vendedor.nome}</p>,
          },
          {
            key: 'tecnico',
            label: 'Técnico',
            render: ({ tecnico }) => <p>{tecnico?.nome || '----'}</p>,
          },
          {
            key: 'dataAgendamentoInstalacao',
            label: 'Agend. Instalação',
            render: ({ dataAgendamentoInstalacao }) => (
              <p>
                {dataAgendamentoInstalacao
                  ? dateFormatWithHours(dataAgendamentoInstalacao)
                  : '----'}
              </p>
            ),
          },
          {
            key: 'statusId',
            label: 'Status',
            sortable: true,
            render: ({ statusId }) => (
              <Badge
                background={
                  statusId.includes('PENDENTE')
                    ? 'warning'
                    : statusId === 'INSTALADO'
                    ? 'success'
                    : 'error'
                }
              >
                {statusId.replace(/[_]/g, ' ')}
              </Badge>
            ),
          },
          {
            key: 'dataInclusao',
            label: 'Data Criação',
            render: ({ dataInclusao }) => (
              <p>{dateFormatWithHours(dataInclusao)}</p>
            ),
            sortable: true,
          },
        ]}
        items={query.data.pedidos.items}
        getRowId={({ pedidoId }) => pedidoId}
        totalCount={query.data.pedidos.totalCount}
        sort={sort}
        onSort={sortField => setSort(sortField)}
        pagination={pagination}
        onChangePage={page => setPagination({ ...pagination, page })}
        onChangePerPage={perPage =>
          setPagination({ ...pagination, perPage, page: 1 })
        }
        headerSideComponent={() => (
          <PedidosFilterFields
            usuarios={query.data.usuarios.items}
            filterFields={filterFields}
            onFilter={filterProps => {
              setFilterFields(filterProps);
              setPagination({ ...pagination, page: 1 });
            }}
            onClear={() => setFilterFields(null)}
          />
        )}
      />
    </>
  );
};

import { FiFileText, FiTrash2 } from 'react-icons/fi';

import styles from './PreviewFile.module.scss';

type PreviewFileProps = {
  files: { url: string; fileName: string; mimetype: string }[];
  removeFiles?: (index: number) => void;
};

export const PreviewFile: React.FC<PreviewFileProps> = ({
  files,
  removeFiles,
}) => (
  <section className={styles['preview-file-section']}>
    {files.map((file, index) => (
      <div key={index} className={styles['preview-file-item']}>
        {file.mimetype.includes('image') ? (
          <figure className={styles['preview-file-image']}>
            <img src={file.url} alt={file.fileName} title={file.fileName} />
          </figure>
        ) : (
          <div className={styles['preview-file-file']}>
            <FiFileText size={64} />
            <p className="body2">{file.mimetype.split('/')[1]}</p>
            <p className="body3">{file.fileName}</p>
          </div>
        )}

        {removeFiles && (
          <button
            type="button"
            aria-label="Clique para remover a imagem."
            title="Remover imagem"
            onClick={() => removeFiles(index)}
          >
            <FiTrash2 size={22} color="var(--error)" />
          </button>
        )}
      </div>
    ))}
  </section>
);

import { useError } from 'hooks/useError';
import { FormEvent, ReactNode, useEffect, useState } from 'react';

import styles from './Form.module.scss';

type FormProps = {
  id?: string;
  children: ReactNode;
  className?: string;
  columns?: number;
  onSubmit?: () => Promise<void>;
  footerComponent?: JSX.Element;
};

export interface FormErrorState {
  visible: boolean;
  error: Error | null;
  mostrarDetalhes: boolean;
}

export const Form: React.FC<FormProps> = ({
  id,
  children,
  className,
  columns = 1,
  onSubmit,
  footerComponent,
}) => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const { withErrorHandling } = useError();

  useEffect(() => {
    setIsLargeScreen(window.innerWidth > 1000);

    const handleResize = () => setIsLargeScreen(window.innerWidth > 1000);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmit = withErrorHandling(async (event: FormEvent) => {
    event.preventDefault();

    if (onSubmit) {
      await onSubmit();
    }
  });

  return (
    <>
      <form
        id={id}
        onSubmit={handleSubmit}
        className={[
          styles['form-container'],
          className ? `${className}` : '',
        ].join(' ')}
      >
        <div
          className={[
            styles['form-content'],
            `${isLargeScreen ? `col-${columns}` : ''}`,
          ].join(' ')}
        >
          {children}
        </div>

        {footerComponent && footerComponent}
      </form>
    </>
  );
};

import { Pedido } from '../list';
import { CardPedido } from './CardPedido';

import styles from './CardList.module.scss';

interface CardListProps {
  pedidos: Pedido[];
}

export const CardList: React.FC<CardListProps> = ({ pedidos }) => {
  return (
    <div className={styles['card-list-container']}>
      {pedidos.map(pedido => (
        <CardPedido key={pedido.pedidoId} pedido={pedido} />
      ))}
    </div>
  );
};

import { useForm } from 'hooks/useForm';
import { Telefone } from 'utils/types/Telefone';
import { currencyMask, phoneMask } from 'functions/mask';
import { convertEmptyKeysToNull } from 'functions/convertEmptyKeysToNull';
import { NewPedidoPessoaFisica } from 'views/pedidos/utils/NewPedidoPessoaFisica';
import { newPedidoDadosEmpregaticiosSchema } from '../../utils/newPedidoDadosEmpregaticiosSchema';

import { StepTitle } from '../../components/StepTitle';
import { Checkbox, Form, Input, Select } from 'components/Form';

import { NextStepFooter } from 'views/pedidos/components/NextStepFooter';
import { PedidoVinculoEmpregaticio } from 'utils/types/PedidoVinculoEmpregaticio';
import { PedidoOperadora } from 'utils/types/PedidoOperadora';

interface Fields {
  vinculoEmpregaticio: string;
  localTrabalho: string;
  telefoneTrabalho: Telefone;
  renda: string;
  atividadeExercida: string;
  tipoResidencia: string;
  quantidadeMoradores: string;
  tempoMoradia: string;
  possuiInternet: boolean;
  operadora: string;
  motivoCancelamento: string;
}

interface DadosEmpregaticiosProps {
  operadoras: PedidoOperadora[];
  vinculosEmpregaticios: PedidoVinculoEmpregaticio[];
  newPedido: NewPedidoPessoaFisica;
  setNewPedido: (values: NewPedidoPessoaFisica) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export const NewPedidoDadosEmpregaticios: React.FC<DadosEmpregaticiosProps> = ({
  operadoras,
  vinculosEmpregaticios,
  newPedido,
  setNewPedido,
  nextStep,
  prevStep,
}) => {
  const { fields, setFields, errors, submitHandler } = useForm<Fields>({
    vinculoEmpregaticio:
      newPedido.dadosEmpregaticios?.vinculoEmpregaticio || '',
    localTrabalho: newPedido.dadosEmpregaticios?.localTrabalho || '',
    telefoneTrabalho: {
      nome: 'Telefone Trabalho',
      telefone: newPedido.dadosEmpregaticios?.telefoneTrabalho?.telefone || '',
      whatsapp:
        newPedido.dadosEmpregaticios?.telefoneTrabalho?.whatsapp || false,
    },
    renda: newPedido.dadosEmpregaticios?.renda || '',
    atividadeExercida: newPedido.dadosEmpregaticios?.atividadeExercida || '',
    tipoResidencia: newPedido.dadosEmpregaticios?.tipoResidencia || '',
    quantidadeMoradores:
      newPedido.dadosEmpregaticios?.quantidadeMoradores || '',
    tempoMoradia: newPedido.dadosEmpregaticios?.tempoMoradia || '',
    possuiInternet: newPedido.dadosEmpregaticios?.possuiInternet || false,
    operadora: newPedido.dadosEmpregaticios?.operadora || '',
    motivoCancelamento: newPedido.dadosEmpregaticios?.motivoCancelamento || '',
  });

  const handleSubmit = submitHandler({
    validateSchema: newPedidoDadosEmpregaticiosSchema,
    callback: async values => {
      const parsedValues = convertEmptyKeysToNull(values);

      setNewPedido({ dadosEmpregaticios: parsedValues });
      nextStep();
    },
  });

  const handleChangeVinculoEmpregaticio = (value: string) => {
    switch (value) {
      case 'AUTONOMO':
        setFields({
          ...fields,
          vinculoEmpregaticio: value,
          localTrabalho: '',
          renda: '',
          telefoneTrabalho: {
            nome: 'Telefone Trabalho',
            telefone: '',
            whatsapp: false,
          },
        });
        break;
      case 'REGISTRADO':
        setFields({
          ...fields,
          vinculoEmpregaticio: value,
          atividadeExercida: '',
        });
        break;
      case 'OUTROS':
        setFields({
          ...fields,
          vinculoEmpregaticio: value,
          atividadeExercida: '',
          localTrabalho: '',
          renda: '',
          telefoneTrabalho: {
            nome: 'Telefone Trabalho',
            telefone: '',
            whatsapp: false,
          },
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <StepTitle title="Dados Empregatícios" onGoBack={prevStep} />
      <Form
        onSubmit={handleSubmit}
        columns={3}
        footerComponent={<NextStepFooter />}
      >
        <Select
          autoFocus
          name="vinculoEmpregaticio"
          label="Vínculo Empregatício"
          error={errors.vinculoEmpregaticio}
          value={fields.vinculoEmpregaticio}
          onChange={handleChangeVinculoEmpregaticio}
          options={vinculosEmpregaticios.map(
            ({ nome, vinculoEmpregaticioId }) => ({
              label: nome,
              value: vinculoEmpregaticioId,
            }),
          )}
        />

        {fields.vinculoEmpregaticio &&
          fields.vinculoEmpregaticio !== 'AUTONOMO' && (
            <>
              <h4 className="heading4 title span-3">Infomações do Trabalho.</h4>

              <Input
                name="localTrabalho"
                label="Local de Trabalho"
                error={errors.localTrabalho}
                value={fields.localTrabalho}
                onChange={value =>
                  setFields({ ...fields, localTrabalho: value })
                }
              />
              <Input
                inputMode="numeric"
                step="0.01"
                min="0.00"
                placeholder="R$ 0.00"
                name="renda"
                label="Renda"
                error={errors.renda}
                value={fields.renda}
                onChange={value =>
                  setFields({ ...fields, renda: currencyMask(value) })
                }
              />
              <Input
                type="tel"
                maxLength={15}
                name="telefoneTrabalho"
                label="Telefone do Trabalho"
                error={errors.telefoneTrabalho?.telefone}
                value={phoneMask(fields.telefoneTrabalho.telefone)}
                onChange={value =>
                  setFields({
                    ...fields,
                    telefoneTrabalho: {
                      ...fields.telefoneTrabalho,
                      telefone: value.replace(/\D+/g, ''),
                    },
                  })
                }
              />
            </>
          )}

        {fields.vinculoEmpregaticio &&
          fields.vinculoEmpregaticio !== 'REGISTRADO' && (
            <>
              <h4 className="heading4 title span-3">Autônomo</h4>

              <Input
                className="span-3"
                name="atividadeExercida"
                label="Atividade Exercída (Autônomo)"
                error={errors.atividadeExercida}
                value={fields.atividadeExercida}
                onChange={value =>
                  setFields({ ...fields, atividadeExercida: value })
                }
              />
            </>
          )}

        <h4 className="heading4 title span-3">Informações Residenciais</h4>

        <Select
          name="tipoResidencia"
          label="Tipo Residência"
          error={errors.tipoResidencia}
          value={fields.tipoResidencia}
          onChange={value => setFields({ ...fields, tipoResidencia: value })}
          options={[
            { label: 'Alugada', value: 'ALUGADA' },
            { label: 'Própria', value: 'PROPRIA' },
            { label: 'Outros', value: 'OUTROS' },
          ]}
        />
        <Input
          type="number"
          inputMode="numeric"
          min={1}
          name="quantidadeMoradores"
          label="Qtde. Moradores"
          error={errors.quantidadeMoradores}
          value={fields.quantidadeMoradores}
          onChange={value =>
            setFields({ ...fields, quantidadeMoradores: value })
          }
        />
        <Input
          name="tempoMoradia"
          label="Tempo de Moradia"
          error={errors.tempoMoradia}
          value={fields.tempoMoradia}
          onChange={value => setFields({ ...fields, tempoMoradia: value })}
        />
        <Checkbox
          className="column-start"
          name="possuiInternet"
          label="Já possui internet"
          checked={fields.possuiInternet}
          onChange={value => setFields({ ...fields, possuiInternet: value })}
        />
        {fields.possuiInternet && (
          <>
            <Select
              className="column-start"
              name="operadora"
              label="Operadora"
              error={errors.operadora}
              value={fields.operadora}
              onChange={value => setFields({ ...fields, operadora: value })}
              options={operadoras.map(({ nome, operadoraId }) => ({
                label: nome,
                value: operadoraId,
              }))}
            />
            <Input
              className="span-2"
              name="motivoCancelamento"
              label="Motivo Cancelamento Outra Operadora"
              error={errors.motivoCancelamento}
              value={fields.motivoCancelamento}
              onChange={value =>
                setFields({ ...fields, motivoCancelamento: value })
              }
            />
          </>
        )}
      </Form>
    </>
  );
};

import * as Yup from 'yup';
import { useEffect } from 'react';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { StatusEmpregaticioEnum } from 'utils/enums/StatusEmpregaticioEnum';
import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { Modal } from 'components/Modal/Modal';
import { Form, FormFooter, Input } from 'components/Form';

interface DesligarUsuarioQueryVariables {
  id: { usuarioId: string };
  input: {
    dataDesligamento?: string;
    statusEmpregaticio?: StatusEmpregaticioEnum;
  };
}

interface DesligarUsuarioQuery {
  usuarioUpdate: { usuarioId: string };
}

const DESLIGAR_USUARIO_QUERY = gql`
  mutation ($id: UsuarioID!, $input: UsuarioUpdateInput!) {
    usuarioUpdate(id: $id, input: $input) {
      usuarioId
    }
  }
`;

interface ModalDesligamentoProps {
  usuarioId: string;
  onClose: () => void;
  refetch: () => void;
}

export const ModalDesligamento: React.FC<ModalDesligamentoProps> = ({
  usuarioId,
  onClose,
  refetch,
}) => {
  const { auth, setAuth } = useAuth();
  const { fields, setFields, errors, submitHandler } = useForm({
    dataDesligamento: '',
  });

  useEffect(() => {
    const keyEvent = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', keyEvent);

    return () => window.removeEventListener('keydown', keyEvent);
  }, []);

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      dataDesligamento: Yup.string().required(
        'Data de desligamento obrigatório.',
      ),
    }),
    callback: async values => {
      const { errors } = await graphqlAuth<
        DesligarUsuarioQuery,
        DesligarUsuarioQueryVariables
      >({
        query: DESLIGAR_USUARIO_QUERY,
        variables: {
          id: { usuarioId },
          input: {
            dataDesligamento: values.dataDesligamento,
            statusEmpregaticio: 'DESLIGADO',
          },
        },
        auth,
        setAuth,
      });

      if (errors) {
        throw new GraphQLError('Falha ao tentar delisgar usuário.', errors);
      }

      successToast('Usuário desligado com sucesso!');

      onClose();

      refetch();
    },
  });

  return (
    <Modal title="Desligar Usuário" size="sm" onClose={onClose}>
      <Form
        onSubmit={handleSubmit}
        footerComponent={<FormFooter onCancel={onClose} />}
      >
        <Input
          type="date"
          name="dataDesligamento"
          label="Data de desligamento"
          error={errors?.dataDesligamento}
          value={fields.dataDesligamento}
          onChange={value => setFields({ ...fields, dataDesligamento: value })}
        />
      </Form>
    </Modal>
  );
};

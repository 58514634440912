import { useNavigate } from 'react-router';
import logo from 'assets/logo-dark.svg';
import styles from './Logo.module.scss';

export const Logo: React.FC = () => {
  const navigate = useNavigate();

  return (
    <figure
      className={styles['logo-container']}
      onClick={() => navigate('/')}
      title="Fibratech"
    >
      <img src={logo} alt="Logo Fibratech" />
    </figure>
  );
};

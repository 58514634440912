import { FormEvent, useState } from 'react';
import { FiSearch } from 'react-icons/fi';

import { useError } from 'hooks/useError';

import { Button, Input } from 'components/Form';

import styles from './SearchForm.module.scss';

interface Periodo {
  dataInicio: string;
  dataTermino: string;
}

interface SearchFormProps {
  periodo: Periodo;
  setPeriodo: (periodo: Periodo) => void;
}

export const SearchForm: React.FC<SearchFormProps> = ({
  periodo,
  setPeriodo,
}) => {
  const { withErrorHandling } = useError();

  const [fields, setFields] = useState({
    dataInicio: periodo.dataInicio,
    dataTermino: periodo.dataTermino,
  });

  const handleSubmit = withErrorHandling(async (event: FormEvent) => {
    event.preventDefault();

    setPeriodo({
      dataInicio: fields.dataInicio,
      dataTermino: fields.dataTermino,
    });
  });

  return (
    <section className={styles['search-section']}>
      <h5 className="heading5">Pesquise por um período:</h5>
      <form className={styles['form-container']} onSubmit={handleSubmit}>
        <Input
          type="date"
          label="Data Início"
          name="dataInicio"
          value={fields.dataInicio}
          onChange={value => setFields({ ...fields, dataInicio: value })}
        />
        <Input
          type="date"
          label="Data Término"
          name="dataTermino"
          value={fields.dataTermino}
          onChange={value => setFields({ ...fields, dataTermino: value })}
        />
        <Button
          type="submit"
          aria-label="Clique para pesquisar pelo período."
          title="Clique para pesquisar pelo período."
        >
          <FiSearch size={22} />
        </Button>
      </form>
    </section>
  );
};

import styles from './CardBody.module.scss';

interface CardBodyProps {
  description: string;
  quantity: string;
  icon: () => JSX.Element;
}

export const CardBody: React.FC<CardBodyProps> = ({
  description,
  quantity,
  icon,
}) => {
  return (
    <div className={styles['card-body']}>
      <div className={styles['card-content']}>
        <h6>{description}</h6>
        <h4>{quantity}</h4>
      </div>
      <i>{icon()}</i>
    </div>
  );
};

export interface PaginateProps {
  page: number;
  perPage: number;
}

export const paginate = ({ page, perPage }: PaginateProps) => {
  let limit = perPage;
  if (limit <= 0 || limit > 100) {
    limit = 10;
  }

  let offset = (page - 1) * limit;
  if (offset < 0) {
    offset = 0;
  }

  return { limit, offset };
};

import { useState } from 'react';
import { useNavigate } from 'react-router';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';

import { Email } from 'utils/types/Email';
import { successToast } from 'utils/toast';
import { SexoEnum } from 'utils/enums/SexoEnum';
import { Telefone } from 'utils/types/Telefone';
import { GraphQLError } from 'utils/GraphQLError';
import { EnderecoInput } from 'utils/types/Endereco';
import { EstadoCivilEnum } from 'utils/enums/EstadoCivilEnum';
import { PedidoStatusEnum } from 'utils/enums/PedidoStatusEnum';
import { TipoPessoaEnum } from 'utils/enums/TipoPessoaEnum';
import { NewPedidoPessoaJuridica } from 'views/pedidos/utils/NewPedidoPessoaJuridica';

import { gql } from 'functions/gql';
import { graphqlAuth, graphqlFormDataAuth } from 'functions/graphqlAuth';

import { StepTitle } from '../../components/StepTitle';
import { Form, InputMultiFile, PreviewFile } from 'components/Form';
import { FinishStepFooter } from 'views/pedidos/components/FinishStepFooter';

import ilustracao from 'assets/anexos-illustration.svg';
import styles from './NewPedidoAnexos.module.scss';
import { RedeSocial } from 'utils/types/RedeSocial';

interface ConsultaQueryVariables {
  input: {
    tipoPessoa: TipoPessoaEnum;
    clientePessoaJuridicaCnpj: string;
    clientePessoaJuridicaRazaoSocial: string;
    clientePessoaJuridicaNomeFantasia: string;
    clientePessoaJuridicaInscricaoEstadual?: string;
    clientePessoaJuridicaInscricaoMunicipal?: string;
    clientePessoaJuridicaDataAbertura: string;
    clientePessoaJuridicaTipoRetencaoNotaFiscal?: string;
    clientePessoaJuridicaRamoEmpresa?: string;
    telefones: Telefone[];
    emails?: Email[];
    clientePessoaJuridicaRepresentanteLegalNome: string;
    clientePessoaJuridicaRepresentanteLegalCpf: string;
    clientePessoaJuridicaRepresentanteLegalRg?: string;
    clientePessoaJuridicaRepresentanteLegalDataNascimento: string;
    clientePessoaJuridicaRepresentanteLegalSexo?: SexoEnum;
    clientePessoaJuridicaRepresentanteLegalEstadoCivilId?: string;
    documento: string;
    enderecoInstalacao: EnderecoInput;
    enderecoCobranca: EnderecoInput;
  };
}

interface ConsultaQuery {
  actions: {
    consultar: {
      consultaId: string;
      resultado: 'SEM_RESTRICAO' | 'REQUER_ANALISE';
    };
  };
}

const CONSULTA_QUERY = gql`
  mutation ($input: ConsultarInput!) {
    actions {
      consultar(input: $input) {
        consultaId
        resultado
      }
    }
  }
`;

interface PedidoCreateQueryVariables {
  input: {
    clientePessoaJuridicaCnpj: string;
    clientePessoaJuridicaRazaoSocial: string;
    clientePessoaJuridicaNomeFantasia: string;
    clientePessoaJuridicaInscricaoEstadual?: string;
    clientePessoaJuridicaInscricaoMunicipal?: string;
    clientePessoaJuridicaDataAbertura: string;
    clientePessoaJuridicaRamoEmpresa?: string;
    clientePessoaJuridicaTipoRetencaoNotaFiscal?: string;
    redesSociais?: RedeSocial[];
    telefones: Telefone[];
    emails?: Email[];
    clientePessoaJuridicaRepresentanteLegalNome: string;
    clientePessoaJuridicaRepresentanteLegalCpf: string;
    clientePessoaJuridicaRepresentanteLegalRg?: string;
    clientePessoaJuridicaRepresentanteLegalDataNascimento: string;
    clientePessoaJuridicaRepresentanteLegalSexo?: string;
    clientePessoaJuridicaRepresentanteLegalEstadoCivilId?: EstadoCivilEnum;
    enderecoInstalacao: EnderecoInput;
    enderecoCobranca: EnderecoInput;
    consultaId: string;
    planos: { planoId: string }[];
    diaVencimentoId: string;
    fidelidadeId: string;
    finalidades?: { finalidadeId: string }[];
    rede: string;
    senha: string;
    pontoAdicional: boolean;
    statusId: PedidoStatusEnum;
    tipoPessoa: 'PESSOA_JURIDICA';
    vendedorId: string;
    anexos: File[];
  };
}

interface PedidoCreateQuery {
  pedidoCreate: { pedidoId: string };
}

const PEDIDO_CREATE_QUERY = gql`
  mutation ($input: PedidoCreateInput!) {
    pedidoCreate(input: $input) {
      pedidoId
    }
  }
`;

interface AnexosProps {
  newPedido: NewPedidoPessoaJuridica;
  setNewPedido: (values: NewPedidoPessoaJuridica) => void;
  prevStep: () => void;
}

export const NewPedidoAnexos: React.FC<AnexosProps> = ({
  newPedido,
  setNewPedido,
  prevStep,
}) => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const { fields, setFields, submitHandler } = useForm<{ anexos: File[] }>({
    anexos: newPedido.anexos || [],
  });

  const handleSubmit = submitHandler({
    callback: async values => {
      try {
        setNewPedido({ anexos: values.anexos });

        const { data: consultaData, errors: consultaErrors } =
          await graphqlAuth<ConsultaQuery, ConsultaQueryVariables>({
            auth,
            setAuth,
            query: CONSULTA_QUERY,
            variables: {
              input: {
                tipoPessoa: 'PESSOA_JURIDICA',
                documento: newPedido.dados!.cnpj,
                clientePessoaJuridicaCnpj: newPedido.dados!.cnpj,
                clientePessoaJuridicaRazaoSocial: newPedido.dados!.razaoSocial,
                clientePessoaJuridicaNomeFantasia:
                  newPedido.dados!.nomeFantasia,
                clientePessoaJuridicaInscricaoEstadual:
                  newPedido.dados?.inscricaoEstadual,
                clientePessoaJuridicaInscricaoMunicipal:
                  newPedido.dados?.inscricaoMunicipal,
                clientePessoaJuridicaDataAbertura:
                  newPedido.dados!.dataAbertura,
                clientePessoaJuridicaTipoRetencaoNotaFiscal:
                  newPedido.dados?.tipoRetencaoNotaFiscal,
                clientePessoaJuridicaRamoEmpresa: newPedido.dados?.ramoEmpresa,
                emails: newPedido.dados?.emails.filter(
                  email => email.nome !== '',
                ),
                telefones:
                  newPedido.dados?.telefones[0].telefone !== ''
                    ? newPedido.dados!.telefones
                    : [],
                clientePessoaJuridicaRepresentanteLegalNome:
                  newPedido.dados!.representanteNome,
                clientePessoaJuridicaRepresentanteLegalCpf:
                  newPedido.dados!.representanteCpf,
                clientePessoaJuridicaRepresentanteLegalRg:
                  newPedido.dados?.representanteRg,
                clientePessoaJuridicaRepresentanteLegalDataNascimento:
                  newPedido.dados!.representanteDataNascimento,
                clientePessoaJuridicaRepresentanteLegalSexo: newPedido.dados
                  ?.representanteSexo as SexoEnum,
                clientePessoaJuridicaRepresentanteLegalEstadoCivilId: newPedido
                  .dados?.representanteEstadoCivil as EstadoCivilEnum,
                enderecoInstalacao: {
                  bairro: newPedido.enderecoInstalacao?.bairro,
                  cep: newPedido.enderecoInstalacao!.cep,
                  logradouro: newPedido.enderecoInstalacao?.logradouro,
                  nome: 'Endereço Instalação',
                  numero: newPedido.enderecoInstalacao!.numero,
                  cidadeId:
                    Number(newPedido.enderecoInstalacao?.ibge) || undefined,
                  complemento: newPedido.enderecoInstalacao?.complemento,
                  pontoReferencia:
                    newPedido.enderecoInstalacao?.pontoReferencia,
                },
                enderecoCobranca: {
                  bairro: newPedido.enderecoCobranca?.bairro,
                  cep: newPedido.enderecoCobranca!.cep,
                  logradouro: newPedido.enderecoCobranca?.logradouro,
                  nome: 'Endereço Cobrança',
                  numero: newPedido.enderecoCobranca!.numero,
                  cidadeId:
                    Number(newPedido.enderecoInstalacao?.ibge) || undefined,
                  complemento: newPedido.enderecoCobranca?.complemento,
                  pontoReferencia: newPedido.enderecoCobranca?.pontoReferencia,
                },
              },
            },
          });

        if (consultaErrors) {
          throw new GraphQLError('Erro ao realizar consulta.', consultaErrors);
        }

        const { errors } = await graphqlFormDataAuth<
          PedidoCreateQuery,
          PedidoCreateQueryVariables
        >({
          auth,
          setAuth,
          query: PEDIDO_CREATE_QUERY,
          variables: {
            input: {
              clientePessoaJuridicaCnpj: newPedido.dados!.cnpj,
              clientePessoaJuridicaRazaoSocial: newPedido.dados!.razaoSocial,
              clientePessoaJuridicaNomeFantasia: newPedido.dados!.nomeFantasia,
              clientePessoaJuridicaInscricaoEstadual:
                newPedido.dados?.inscricaoEstadual,
              clientePessoaJuridicaInscricaoMunicipal:
                newPedido.dados?.inscricaoMunicipal,
              clientePessoaJuridicaDataAbertura: newPedido.dados!.dataAbertura,
              clientePessoaJuridicaTipoRetencaoNotaFiscal:
                newPedido.dados?.tipoRetencaoNotaFiscal,
              clientePessoaJuridicaRamoEmpresa: newPedido.dados?.ramoEmpresa,
              redesSociais: newPedido.dados?.redesSociais.filter(
                redeSocial => redeSocial.link !== '',
              ),
              telefones:
                newPedido.dados?.telefones[0].telefone !== ''
                  ? newPedido.dados!.telefones
                  : [],
              emails: newPedido.dados?.emails.filter(
                email => email.nome !== '',
              ),
              clientePessoaJuridicaRepresentanteLegalCpf:
                newPedido.dados!.representanteCpf,
              clientePessoaJuridicaRepresentanteLegalNome:
                newPedido.dados!.representanteNome,
              clientePessoaJuridicaRepresentanteLegalRg:
                newPedido.dados?.representanteRg,
              clientePessoaJuridicaRepresentanteLegalDataNascimento:
                newPedido.dados!.representanteDataNascimento,
              clientePessoaJuridicaRepresentanteLegalSexo:
                newPedido.dados?.representanteSexo,
              clientePessoaJuridicaRepresentanteLegalEstadoCivilId: newPedido
                .dados?.representanteEstadoCivil as EstadoCivilEnum,
              enderecoInstalacao: {
                bairro: newPedido.enderecoInstalacao?.bairro,
                cep: newPedido.enderecoInstalacao!.cep,
                logradouro: newPedido.enderecoInstalacao?.logradouro,
                nome: 'Endereço Instalação',
                numero: newPedido.enderecoInstalacao!.numero,
                cidadeId:
                  Number(newPedido.enderecoInstalacao?.ibge) || undefined,
                complemento: newPedido.enderecoInstalacao?.complemento,
                pontoReferencia: newPedido.enderecoInstalacao?.pontoReferencia,
              },
              enderecoCobranca: {
                bairro: newPedido.enderecoCobranca?.bairro,
                cep: newPedido.enderecoCobranca!.cep,
                logradouro: newPedido.enderecoCobranca?.logradouro,
                nome: 'Endereço Cobrança',
                numero: newPedido.enderecoCobranca!.numero,
                cidadeId:
                  Number(newPedido.enderecoInstalacao?.ibge) || undefined,
                complemento: newPedido.enderecoCobranca?.complemento,
                pontoReferencia: newPedido.enderecoCobranca?.pontoReferencia,
              },
              consultaId: consultaData.actions.consultar.consultaId,
              planos: newPedido.planos!.map(({ planoId }) => ({ planoId })),
              diaVencimentoId: newPedido.informacoesServico!.diaVencimentoId,
              fidelidadeId: newPedido.informacoesServico!.fidelidadeId,
              finalidades: newPedido.informacoesServico?.finalidades.map(
                item => ({ finalidadeId: item }),
              ),
              rede: newPedido.informacoesServico!.rede,
              senha: newPedido.informacoesServico!.senha,
              pontoAdicional: false,
              statusId: 'PENDENTE_ANALISE_CREDITO',
              tipoPessoa: 'PESSOA_JURIDICA',
              vendedorId: auth!.usuario.usuarioId,
              anexos: [],
            },
          },
          files: fields.anexos.map((anexo, index) => [
            `variables.input.anexos.${index}`,
            anexo,
          ]),
        });

        if (errors) {
          throw new GraphQLError('Erro ao criar pedido.', errors);
        }

        successToast('Pedido criado com sucesso!');

        navigate('/pedidos');
      } finally {
        setDisableSubmitButton(false);
      }
    },
  });

  return (
    <>
      <StepTitle title="Anexos" onGoBack={prevStep} />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FinishStepFooter disableSubmitButton={disableSubmitButton} />
        }
      >
        <InputMultiFile
          className={styles['anexos-input-file']}
          background="secondary"
          name="anexos"
          label="Capturar / Adicionar Anexos"
          value={fields.anexos}
          onChange={files =>
            files &&
            setFields({
              ...fields,
              anexos: [...fields.anexos, ...Array.from(files)],
            })
          }
        />

        {fields.anexos.length > 0 ? (
          <PreviewFile
            files={fields.anexos.map(anexo => ({
              fileName: anexo.name,
              url: URL.createObjectURL(anexo),
              mimetype: anexo.type,
            }))}
            removeFiles={index =>
              setFields(state => ({
                ...state,
                anexos: state.anexos.filter((_, i) => i !== index),
              }))
            }
          />
        ) : (
          <figure className={styles['anexos-illustration']}>
            <img src={ilustracao} alt="Ilustração anexos" />
          </figure>
        )}
      </Form>
    </>
  );
};

import { Layout } from 'components/Layout';
import { NewPedidoPessoaJuridicaView } from 'views/pedidos/pessoa-juridica';

export const NewPedidoPessoaJuridicaPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [{ label: 'Pedidos', href: '/pedidos' }, { label: 'Novo' }],
      }}
    >
      <NewPedidoPessoaJuridicaView />
    </Layout>
  );
};

import { Pedido } from '../meus-pedidos';
import { Card } from './Card';

import styles from './CardList.module.scss';

interface CardListProps {
  pedidos: Pedido[];
}

export const CardList: React.FC<CardListProps> = ({ pedidos }) => {
  return (
    <div className={styles['card-list-container']}>
      {pedidos.map(pedido => (
        <Card key={pedido.pedidoId} pedido={pedido} />
      ))}
    </div>
  );
};

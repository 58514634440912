import { FiDownload, FiFileText } from 'react-icons/fi';

import styles from './AnexoPreview.module.scss';

interface AnexoPreviewListProps {
  anexos: { nome: string; url: string; mimetype: string }[];
}

export const AnexoPreviewList: React.FC<AnexoPreviewListProps> = ({
  anexos,
}) => {
  return (
    <section className={styles['anexo-preview-section']}>
      {anexos.length > 0 ? (
        anexos.map((anexo, index) => (
          <a
            key={index}
            href={`${anexo.url}?inline=false`}
            title="Clique para fazer download"
          >
            {anexo.mimetype.includes('image') ? (
              <figure className={styles['anexo-preview-image']}>
                <img src={anexo.url} alt={anexo.nome} />
              </figure>
            ) : (
              <div className={styles['anexo-preview-file']}>
                <FiFileText size={64} />
                <p className="body2">{anexo.mimetype.split('/')[1]}</p>
                <p className="body3">{anexo.nome}</p>
              </div>
            )}

            <div className={styles['anexo-preview-hover']}>
              <h6 className="heading6">Download</h6>
              <FiDownload size={32} />
            </div>
          </a>
        ))
      ) : (
        <p className="body2 text">Nenhum anexo adicionado.</p>
      )}
    </section>
  );
};

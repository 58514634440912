import { useState } from 'react';
import * as Yup from 'yup';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { PedidoTecnico } from 'utils/types/PedidoTecnico';
import { PedidoMaterial } from 'utils/types/PedidoMaterial';
import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';
import { fromDateTimeLocal, toDateTimeLocal } from 'functions/formatters';

import { FlexContainer } from 'components/FlexContainer';
import { MateriaisTable } from 'components/MateriaisTable';
import { Button, Fieldset, Form, Input, Select } from 'components/Form';

import styles from './PedidoInstalacao.module.scss';

interface PedidoUpdateMateriaisQueryAuditoriaVariables {
  id: { pedidoId: string };
  input: {
    dataAgendamentoInstalacao: string;
    tecnicoId: string;
    numeroSerieOnu: string;
    cto: string;
    ctoPorta: string;
    materiais: { materialId: string; quantidade: number }[];
  };
}

interface PedidoUpdateMateriaisAuditoriaQuery {
  pedidoUpdate: { pedidoId: string };
}

const PEDIDO_UPDATE_MATERIAIS_AUDITORIA_QUERY = gql`
  mutation ($id: PedidoID!, $input: PedidoAuditarInput!) {
    actions {
      pedidos {
        auditar(id: $id, input: $input) {
          pedidoId
        }
      }
    }
  }
`;

interface PedidoInstalacaoAuditoriaProps {
  pedidoId: string;
  dadosInstalacao: {
    tecnicoId: string;
    dataAgendamentoInstalacao: string;
    numeroSerieOnu: string;
    cto: string;
    ctoPorta: string;
    pedidoMateriais: { materialId: string; nome: string; quantidade: number }[];
  };
  allMateriais: PedidoMaterial[];
  allTecnicos: PedidoTecnico[];
  refetchQuery: () => void;
}

export const PedidoInstalacaoAuditoria: React.FC<
  PedidoInstalacaoAuditoriaProps
> = ({
  allMateriais,
  allTecnicos,
  dadosInstalacao,
  pedidoId,
  refetchQuery,
}) => {
  const { auth, setAuth } = useAuth();
  const [disableButtonSave, setDisableButtonSave] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    tecnico: dadosInstalacao.tecnicoId,
    dataAgendamento: new Date(dadosInstalacao.dataAgendamentoInstalacao),
    numeroSerieOnu: dadosInstalacao.numeroSerieOnu,
    cto: dadosInstalacao.cto,
    ctoPorta: dadosInstalacao.ctoPorta,
    materiaisSelecionados: dadosInstalacao.pedidoMateriais,
    material: '',
    quantidade: 0,
  });

  const addMaterial = async (
    material: PedidoMaterial | undefined,
    quantidade: number,
  ) => {
    const handleAddMaterial = submitHandler({
      validateSchema: Yup.object({
        material: Yup.string().required('Material obrigatório.'),
        quantidade: Yup.number()
          .min(1, 'Quantidade deve ser maior do que 0.')
          .required('Quantidade obrigatório.'),
      }),
      callback: async () => {
        setFields({
          ...fields,
          materiaisSelecionados: [
            ...fields.materiaisSelecionados,
            {
              materialId: material!.materialId,
              nome: material!.nome,
              quantidade,
            },
          ],
        });
      },
    });

    await handleAddMaterial();
  };

  const removeMaterial = (index: number) => {
    setFields({
      ...fields,
      materiaisSelecionados: fields.materiaisSelecionados.filter(
        (_, i) => index !== i,
      ),
    });
  };

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      tecnico: Yup.string().nullable().required('Técnico obrigatório.'),
      dataAgendamento: Yup.date()
        .nullable()
        .required('Data do agendamento obrigatório.'),
    }),
    callback: async values => {
      setDisableButtonSave(true);

      try {
        const { errors } = await graphqlAuth<
          PedidoUpdateMateriaisAuditoriaQuery,
          PedidoUpdateMateriaisQueryAuditoriaVariables
        >({
          auth,
          setAuth,
          query: PEDIDO_UPDATE_MATERIAIS_AUDITORIA_QUERY,
          variables: {
            id: { pedidoId },
            input: {
              dataAgendamentoInstalacao: values.dataAgendamento.toISOString(),
              numeroSerieOnu: values.numeroSerieOnu,
              cto: values.cto,
              ctoPorta: values.ctoPorta,
              tecnicoId: values.tecnico,
              materiais: values.materiaisSelecionados.map(item => ({
                materialId: item.materialId,
                quantidade: item.quantidade,
              })),
            },
          },
        });

        if (errors) {
          throw new GraphQLError(
            'Erro ao alterar instalação do pedido.',
            errors,
          );
        }

        successToast('Instalação alterada com sucesso!');

        refetchQuery();
      } finally {
        setDisableButtonSave(false);
      }
    },
  });

  return (
    <section>
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <Button
            type="submit"
            background="success"
            disabled={disableButtonSave}
            className={styles['save-button']}
          >
            Salvar
          </Button>
        }
      >
        <FlexContainer>
          <Input
            type="datetime-local"
            label="Data do Agendamento"
            name="dataAgendamento"
            error={errors?.dataAgendamento}
            value={toDateTimeLocal(fields.dataAgendamento)}
            onChange={value =>
              setFields({
                ...fields,
                dataAgendamento: fromDateTimeLocal(value)!,
              })
            }
          />
          <Select
            label="Técnico"
            name="tecnico"
            error={errors?.tecnico}
            value={fields.tecnico}
            onChange={value => setFields({ ...fields, tecnico: value })}
            options={allTecnicos.map(({ nome, usuarioId }) => ({
              label: nome,
              value: usuarioId,
            }))}
          />
        </FlexContainer>

        <Input
          label="Número de série ONU"
          name="numeroSerieOnu"
          error={errors?.numeroSerieOnu}
          value={fields.numeroSerieOnu}
          onChange={value => setFields({ ...fields, numeroSerieOnu: value })}
        />
        <FlexContainer>
          <Input
            type="number"
            label="CTO"
            name="cto"
            error={errors?.cto}
            value={fields.cto}
            onChange={value => setFields({ ...fields, cto: value })}
          />
          <Input
            type="number"
            label="CTO Porta"
            name="ctoPorta"
            error={errors?.ctoPorta}
            value={fields.ctoPorta}
            onChange={value => setFields({ ...fields, ctoPorta: value })}
          />
        </FlexContainer>

        <Fieldset legend="Materiais">
          <div className={styles['pedido-materiais-fields']}>
            <Select
              name="materiais"
              label="Material"
              error={errors?.material}
              value={fields.material}
              onChange={value => setFields({ ...fields, material: value })}
              options={allMateriais
                .map(({ materialId, nome }) => ({
                  label: nome,
                  value: materialId,
                }))
                .sort((a, b) => (a.label < b.label ? -1 : 1))}
            />
            <Input
              type="number"
              name="quantidade"
              label="Quantidade"
              error={errors?.quantidade}
              value={`${fields.quantidade}`}
              onChange={value =>
                setFields({ ...fields, quantidade: Number(value) })
              }
            />
            <Button
              type="button"
              background="success"
              className="column-start"
              onClick={() => {
                const material = allMateriais.find(
                  material => material.materialId === fields.material,
                );

                addMaterial(material, fields.quantidade);
              }}
            >
              Adicionar material
            </Button>
          </div>

          {fields.materiaisSelecionados.length > 0 ? (
            <MateriaisTable
              materiais={fields.materiaisSelecionados}
              onRemoveMaterial={removeMaterial}
            />
          ) : (
            <p className="body2 text" style={{ textAlign: 'center' }}>
              Nenhum material adicionado.
            </p>
          )}
        </Fieldset>
      </Form>
    </section>
  );
};

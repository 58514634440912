import { useState } from 'react';
import { FiCheckCircle, FiEye, FiEyeOff } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { PerfilEnum } from 'utils/enums/PerfilEnum';
import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { Button, Form, Input, InputWithIcon } from 'components/Form';
import { updateProfilePasswordSchema } from './updateProfilePasswordSchema';

interface MeUsuarioUpdateSenhaVariables {
  input: { senha: string; novaSenha: string };
}

interface MeUsuarioUpdateSenhaQuery {
  me: {
    usuarioUpdateSenha: {
      usuarioId: string;
      login: string;
      nome: string;
      resetar: boolean;
      perfilId: PerfilEnum;
      avatar: { url: string };
      grupoAcesso: {
        grupoAcessoId: string;
        nome: string;
        acessos: {
          totalCount: number;
          items: { acessoId: string; nome: string }[];
        };
      };
    };
  };
}

const ME_USUARIO_UPDATE_SENHA_QUERY = gql`
  mutation ($input: MeUsuarioUpdateSenhaInput!) {
    me {
      usuarioUpdateSenha(input: $input) {
        usuarioId
        login
        nome
        resetar
        perfilId
        avatar {
          url
        }
        grupoAcesso {
          grupoAcessoId
          nome
          acessos {
            totalCount
            items {
              acessoId
              nome
            }
          }
        }
      }
    }
  }
`;

export const ProfileFormSecurity: React.FC = () => {
  const { auth, setAuth } = useAuth();

  const [inputPasswordType, setInputPasswordType] = useState('password');
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    senhaAtual: '',
    novaSenha: '',
    confirmarSenha: '',
  });

  const submitSecurityForm = submitHandler({
    validateSchema: updateProfilePasswordSchema,
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { data, errors } = await graphqlAuth<
          MeUsuarioUpdateSenhaQuery,
          MeUsuarioUpdateSenhaVariables
        >({
          query: ME_USUARIO_UPDATE_SENHA_QUERY,
          variables: {
            input: {
              senha: values.senhaAtual,
              novaSenha: values.novaSenha,
            },
          },
          auth,
          setAuth,
        });

        if (errors || !data) {
          throw new GraphQLError('Falha ao alterar a senha.', errors);
        }

        setAuth({ ...auth!, usuario: data.me.usuarioUpdateSenha });

        successToast('Senha alterada com sucesso!');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <Form id="profileSecurityForm" onSubmit={submitSecurityForm}>
      <h4 className="heading4 title">Segurança da sua conta</h4>

      <InputWithIcon
        type={inputPasswordType}
        name="senhaAtual"
        label="Senha Atual"
        error={errors.senhaAtual}
        value={fields.senhaAtual}
        onChange={value => setFields({ ...fields, senhaAtual: value })}
        icon={() =>
          inputPasswordType === 'text' ? (
            <FiEyeOff
              size={22}
              onClick={() => setInputPasswordType('password')}
            />
          ) : (
            <FiEye size={22} onClick={() => setInputPasswordType('text')} />
          )
        }
      />
      <Input
        type="password"
        name="novaSenha"
        label="Nova Senha"
        error={errors.novaSenha}
        value={fields.novaSenha}
        onChange={value => setFields({ ...fields, novaSenha: value })}
      />
      <Input
        type="password"
        name="confirmarSenha"
        label="Confirmar Senha"
        error={errors.confirmarSenha}
        value={fields.confirmarSenha}
        onChange={value => setFields({ ...fields, confirmarSenha: value })}
      />
      <Button
        type="submit"
        background="success"
        form="profileSecurityForm"
        disabled={disableButtonSubmit}
      >
        <FiCheckCircle size={22} />
        Confirmar
      </Button>
    </Form>
  );
};

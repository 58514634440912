import { Navigate } from 'react-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';

import { Loading } from 'components/Loading';
import { WithAcesso } from 'components/WithAcesso';

import { AjudaPage } from 'pages/ajuda';
import { LoginPage } from './pages/login';
import { ProfilePage } from 'pages/profile';
import { NotFound } from './pages/NotFound';
import { DashboardPage } from './pages/dashboard';

import { NewDiaVencimentoPage } from 'pages/dia-vencimento/new';
import { ListDiaVencimentoPage } from 'pages/dia-vencimento/list';
import { EditDiaVencimentoPage } from 'pages/dia-vencimento/edit';
import { DetailDiaVencimentoPage } from 'pages/dia-vencimento/detail';
import { NewFidelidadePage } from 'pages/fidelidades/new';
import { ListFidelidadePage } from 'pages/fidelidades/list';
import { EditFidelidadePage } from 'pages/fidelidades/edit';
import { DetailFidelidadePage } from 'pages/fidelidades/detail';
import { NewFinalidadePage } from 'pages/finalidades/new';
import { ListFinalidadePage } from 'pages/finalidades/list';
import { EditFinalidadePage } from 'pages/finalidades/edit';
import { DetailFinalidadePage } from 'pages/finalidades/detail';
import { NewGrupoAcessoPage } from 'pages/grupo-acesso/new';
import { ListGrupoAcessoPage } from 'pages/grupo-acesso/list';
import { EditGrupoAcessoPage } from 'pages/grupo-acesso/edit';
import { DetailGrupoAcessoPage } from 'pages/grupo-acesso/detail';
import { NewOperadoraPage } from 'pages/operadoras/new';
import { ListOperadoraPage } from 'pages/operadoras/list';
import { EditOperadoraPage } from 'pages/operadoras/edit';
import { DetailOperadoraPage } from 'pages/operadoras/detail';
import { ListPedidoPage } from 'pages/pedidos/list';
import { NewPedidoPage } from 'pages/pedidos/new-pedido';
import { NewPedidoPessoaFisicaPage } from 'pages/pedidos/pessoa-fisica/new';
import { EditPedidoPessoaFisicaPage } from 'pages/pedidos/pessoa-fisica/edit';
import { DetailPedidoPessoaFisicaPage } from 'pages/pedidos/pessoa-fisica/detail';
import { PrintPedidoPessoaFisicaPage } from 'pages/pedidos/pessoa-fisica/imprimir';
import { AuditarPedidoPessoaFisicaPage } from 'pages/pedidos/pessoa-fisica/auditar';
import { NewPedidoPessoaJuridicaPage } from 'pages/pedidos/pessoa-juridica/new';
import { EditPedidoPessoaJuridicaPage } from 'pages/pedidos/pessoa-juridica/edit';
import { DetailPedidoPessoaJuridicaPage } from 'pages/pedidos/pessoa-juridica/detail';
import { PrintPedidoPessoaJuridicaPage } from 'pages/pedidos/pessoa-juridica/imprimir';
import { AuditarPedidoPessoaJuridicaPage } from 'pages/pedidos/pessoa-juridica/auditar';
import { NewPlanoPage } from 'pages/planos/new';
import { ListPlanoPage } from 'pages/planos/list';
import { EditPlanoPage } from 'pages/planos/edit';
import { DetailPlanoPage } from 'pages/planos/detail';
import { NewUsuarioPage } from 'pages/usuarios/new';
import { ListUsuarioPage } from 'pages/usuarios/list';
import { EditUsuarioPage } from 'pages/usuarios/edit';
import { DetailUsuarioPage } from 'pages/usuarios/detail';
import { ListConfiguracaoPage } from 'pages/configuracoes/list';
import { EditConfiguracaoPage } from 'pages/configuracoes/edit';
import { ResetarSenhaPage } from 'pages/resetar-senha';
import { AuditoriaPage } from 'pages/auditoria/list';
import { ListConsultaSophusPage } from 'pages/relatorios/consultas/sophus/list';
import { DetailConsultaSophusPage } from 'pages/relatorios/consultas/sophus/detail';
import { ListConsultaUsuariosPage } from 'pages/relatorios/consultas/usuarios/list';
import { DetailConsultaUsuariosPage } from 'pages/relatorios/consultas/usuarios/detail';
import { ListMeusPedidosPage } from 'pages/me/meus-pedidos';
import { TecnicoDashboardPage } from 'pages/tecnico/dashboard';
import { PedidosInstalacoesPage } from 'pages/tecnico/pedidos/list';
import { PedidoInstalacaoPage } from 'pages/tecnico/pedidos/instalar';
import { ListMateriaisPage } from 'pages/materiais/list';
import { NewMaterialPage } from 'pages/materiais/new';
import { EditMaterialPage } from 'pages/materiais/edit';
import { DetailMaterialPage } from 'pages/materiais/detail';
import { ListRelatorioDesempenhoVendasPage } from 'pages/relatorios/usuarios/desempenhoPedidos/list';

interface RouteProps {
  path: string;
  component: () => JSX.Element;
  acessoId?: string;
}

export const appRoutes: RouteProps[] = [
  {
    path: '/',
    component: DashboardPage,
  },
  {
    path: '/perfil',
    component: ProfilePage,
  },
  {
    path: '/ajuda',
    component: AjudaPage,
  },
  {
    path: '/resetar-senha',
    component: ResetarSenhaPage,
  },
  {
    path: '/dia-vencimento',
    component: ListDiaVencimentoPage,
    acessoId: 'CADASTROS.DIAS_VENCIMENTOS',
  },
  {
    path: '/dia-vencimento/new',
    component: NewDiaVencimentoPage,
    acessoId: 'CADASTROS.DIAS_VENCIMENTOS.INCLUIR',
  },
  {
    path: '/dia-vencimento/:diaVencimentoId/detalhes',
    component: DetailDiaVencimentoPage,
    acessoId: 'CADASTROS.DIAS_VENCIMENTOS.VISUALIZAR',
  },
  {
    path: '/dia-vencimento/:diaVencimentoId',
    component: EditDiaVencimentoPage,
    acessoId: 'CADASTROS.DIAS_VENCIMENTOS.ALTERAR',
  },
  {
    path: '/fidelidades',
    component: ListFidelidadePage,
    acessoId: 'CADASTROS.FIDELIDADES',
  },
  {
    path: '/fidelidades/new',
    component: NewFidelidadePage,
    acessoId: 'CADASTROS.FIDELIDADES.INCLUIR',
  },
  {
    path: '/fidelidades/:fidelidadeId/detalhes',
    component: DetailFidelidadePage,
    acessoId: 'CADASTROS.FIDELIDADES.VISUALIZAR',
  },
  {
    path: '/fidelidades/:fidelidadeId',
    component: EditFidelidadePage,
    acessoId: 'CADASTROS.FIDELIDADES.ALTERAR',
  },
  {
    path: '/finalidades',
    component: ListFinalidadePage,
    acessoId: 'CADASTROS.FINALIDADES',
  },
  {
    path: '/finalidades/new',
    component: NewFinalidadePage,
    acessoId: 'CADASTROS.FINALIDADES.INCLUIR',
  },
  {
    path: '/finalidades/:finalidadesId/detalhes',
    component: DetailFinalidadePage,
    acessoId: 'CADASTROS.FINALIDADES.VISUALIZAR',
  },
  {
    path: '/finalidades/:finalidadesId',
    component: EditFinalidadePage,
    acessoId: 'CADASTROS.FINALIDADES.ALTERAR',
  },
  {
    path: '/grupo-acesso',
    component: ListGrupoAcessoPage,
    acessoId: 'CADASTROS.GRUPOS_ACESSOS',
  },
  {
    path: '/grupo-acesso/new',
    component: NewGrupoAcessoPage,
    acessoId: 'CADASTROS.GRUPOS_ACESSOS.INCLUIR',
  },
  {
    path: '/grupo-acesso/:grupoAcessoId/detalhes',
    component: DetailGrupoAcessoPage,
    acessoId: 'CADASTROS.GRUPOS_ACESSOS.VISUALIZAR',
  },
  {
    path: '/grupo-acesso/:grupoAcessoId',
    component: EditGrupoAcessoPage,
    acessoId: 'CADASTROS.GRUPOS_ACESSOS.ALTERAR',
  },
  {
    path: '/materiais',
    component: ListMateriaisPage,
    acessoId: 'CADASTROS.MATERIAIS',
  },
  {
    path: '/materiais/new',
    component: NewMaterialPage,
    acessoId: 'CADASTROS.MATERIAIS.INCLUIR',
  },
  {
    path: '/materiais/:materialId',
    component: EditMaterialPage,
    acessoId: 'CADASTROS.MATERIAIS.ALTERAR',
  },
  {
    path: '/materiais/:materialId/detalhes',
    component: DetailMaterialPage,
    acessoId: 'CADASTROS.MATERIAIS.VISUALIZAR',
  },
  {
    path: '/operadoras',
    component: ListOperadoraPage,
    acessoId: 'CADASTROS.OPERADORAS',
  },
  {
    path: '/operadoras/new',
    component: NewOperadoraPage,
    acessoId: 'CADASTROS.OPERADORAS.INCLUIR',
  },
  {
    path: '/operadoras/:operadoraId/detalhes',
    component: DetailOperadoraPage,
    acessoId: 'CADASTROS.OPERADORAS.VISUALIZAR',
  },
  {
    path: '/operadoras/:operadoraId',
    component: EditOperadoraPage,
    acessoId: 'CADASTROS.OPERADORAS.ALTERAR',
  },
  {
    path: '/pedidos',
    component: ListPedidoPage,
    acessoId: 'CADASTROS.PEDIDOS.VISUALIZAR',
  },
  {
    path: '/pedidos/new-pedido',
    component: NewPedidoPage,
    acessoId: 'CADASTROS.PEDIDOS.INCLUIR',
  },
  {
    path: '/pedidos/pessoa-fisica/new',
    component: NewPedidoPessoaFisicaPage,
    acessoId: 'CADASTROS.PEDIDOS.INCLUIR',
  },
  {
    path: '/pedidos/pessoa-fisica/:pedidoId',
    component: EditPedidoPessoaFisicaPage,
    acessoId: 'CADASTROS.PEDIDOS.ALTERAR',
  },
  {
    path: '/pedidos/pessoa-fisica/:pedidoId/detail',
    component: DetailPedidoPessoaFisicaPage,
    acessoId: 'CADASTROS.PEDIDOS.VISUALIZAR',
  },
  {
    path: '/pedidos/pessoa-fisica/:pedidoId/imprimir',
    component: PrintPedidoPessoaFisicaPage,
    acessoId: 'CADASTROS.PEDIDOS.VISUALIZAR',
  },
  {
    path: '/pedidos/pessoa-fisica/:pedidoId/auditar',
    component: AuditarPedidoPessoaFisicaPage,
    acessoId: 'CADASTROS.PEDIDOS.AUDITAR',
  },
  {
    path: '/pedidos/pessoa-juridica/new',
    component: NewPedidoPessoaJuridicaPage,
    acessoId: 'CADASTROS.PEDIDOS.INCLUIR',
  },
  {
    path: '/pedidos/pessoa-juridica/:pedidoId',
    component: EditPedidoPessoaJuridicaPage,
    acessoId: 'CADASTROS.PEDIDOS.ALTERAR',
  },
  {
    path: '/pedidos/pessoa-juridica/:pedidoId/detail',
    component: DetailPedidoPessoaJuridicaPage,
    acessoId: 'CADASTROS.PEDIDOS.VISUALIZAR',
  },
  {
    path: '/pedidos/pessoa-juridica/:pedidoId/imprimir',
    component: PrintPedidoPessoaJuridicaPage,
    acessoId: 'CADASTROS.PEDIDOS.VISUALIZAR',
  },
  {
    path: '/pedidos/pessoa-juridica/:pedidoId/auditar',
    component: AuditarPedidoPessoaJuridicaPage,
    acessoId: 'CADASTROS.PEDIDOS.AUDITAR',
  },
  {
    path: '/auditoria',
    component: AuditoriaPage,
    acessoId: 'CADASTROS.PEDIDOS.AUDITAR',
  },
  {
    path: '/planos',
    component: ListPlanoPage,
    acessoId: 'CADASTROS.PLANOS',
  },
  {
    path: '/planos/new',
    component: NewPlanoPage,
    acessoId: 'CADASTROS.PLANOS.INCLUIR',
  },
  {
    path: '/planos/:planoId/detalhes',
    component: DetailPlanoPage,
    acessoId: 'CADASTROS.PLANOS.VISUALIZAR',
  },
  {
    path: '/planos/:planoId',
    component: EditPlanoPage,
    acessoId: 'CADASTROS.PLANOS.ALTERAR',
  },
  {
    path: '/usuarios',
    component: ListUsuarioPage,
    acessoId: 'CADASTROS.USUARIOS',
  },
  {
    path: '/usuarios/new',
    component: NewUsuarioPage,
    acessoId: 'CADASTROS.USUARIOS.INCLUIR',
  },
  {
    path: '/usuarios/:usuarioId/detalhes',
    component: DetailUsuarioPage,
    acessoId: 'CADASTROS.USUARIOS.VISUALIZAR',
  },
  {
    path: '/usuarios/:usuarioId',
    component: EditUsuarioPage,
    acessoId: 'CADASTROS.USUARIOS.ALTERAR',
  },
  {
    path: '/configuracoes',
    component: ListConfiguracaoPage,
    acessoId: 'CONFIGURACOES',
  },
  {
    path: '/configuracoes/:configuracaoId',
    component: EditConfiguracaoPage,
    acessoId: 'CONFIGURACOES.ALTERAR',
  },
  {
    path: '/relatorios/consultas-sophus',
    component: ListConsultaSophusPage,
    acessoId: 'RELATORIOS.CONSULTAS',
  },
  {
    path: '/relatorios/consultas-sophus/:consultaId',
    component: DetailConsultaSophusPage,
    acessoId: 'RELATORIOS.CONSULTAS',
  },
  {
    path: '/relatorios/consultas-usuarios',
    component: ListConsultaUsuariosPage,
    acessoId: 'RELATORIOS.CONSULTAS_USUARIOS',
  },
  {
    path: '/relatorios/consultas-usuarios/:consultaUsuarioId',
    component: DetailConsultaUsuariosPage,
    acessoId: 'RELATORIOS.CONSULTAS_USUARIOS',
  },
  {
    path: '/relatorios/usuarios/desempenho-vendas',
    component: ListRelatorioDesempenhoVendasPage,
    acessoId: 'RELATORIOS.USUARIOS_DESEMPENHO_PEDIDOS',
  },
  {
    path: '/me/meus-pedidos',
    component: ListMeusPedidosPage,
  },
];

export const tecnicoRoutes: RouteProps[] = [
  {
    path: '/',
    component: TecnicoDashboardPage,
  },
  {
    path: '/ajuda',
    component: AjudaPage,
  },
  {
    path: '/perfil',
    component: ProfilePage,
  },
  {
    path: '/pedidos/instalacoes',
    component: PedidosInstalacoesPage,
  },
  {
    path: '/pedidos/instalacoes/:pedidoId',
    component: PedidoInstalacaoPage,
  },
];

export const Router = () => {
  const { loading, auth } = useAuth();

  if (loading) {
    return <Loading />;
  }

  const routes =
    auth?.usuario.perfilId === 'TECNICO' ? tecnicoRoutes : appRoutes;

  return (
    <BrowserRouter>
      <Routes>
        {auth ? (
          auth.usuario.resetar ? (
            <>
              <Route path="/resetar-senha" element={<ResetarSenhaPage />} />
              <Route path="*" element={<Navigate to="/resetar-senha" />} />
            </>
          ) : (
            <>
              {routes.map(({ path, component: Component, acessoId }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    acessoId ? (
                      <WithAcesso acesso={acessoId}>
                        <Component />
                      </WithAcesso>
                    ) : (
                      <Component />
                    )
                  }
                />
              ))}
              <Route path="*" element={<NotFound />} />
            </>
          )
        ) : (
          <>
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

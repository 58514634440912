import { Layout } from 'components/Layout';
import { NewPedidoPessoaFisicaView } from 'views/pedidos/pessoa-fisica';

export const NewPedidoPessoaFisicaPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [{ label: 'Pedidos', href: '/pedidos' }, { label: 'Novo' }],
      }}
    >
      <NewPedidoPessoaFisicaView />
    </Layout>
  );
};

import { Sort } from 'utils/Sort';

export const sortArray = <T>(sort: Sort | null, items: T[]) => {
  if (!sort) {
    return items;
  }

  let oldItems = [...items];

  if (sort.order === 'DESC') {
    return oldItems.sort((a, b) =>
      a[sort.field as keyof T] > b[sort.field as keyof T] ? 1 : -1,
    );
  }

  return oldItems.sort((a, b) =>
    a[sort.field as keyof T] > b[sort.field as keyof T] ? -1 : 1,
  );
};

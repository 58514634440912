import { Layout } from 'components/Layout';
import { DetailFidelidadeView } from 'views/fidelidades/detail';

export const DetailFidelidadePage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Fidelidade', href: '/fidelidades' },
          { label: 'Detalhes' },
        ],
      }}
    >
      <DetailFidelidadeView />
    </Layout>
  );
};

const getFirstDayOfMonth = () => {
  const date = new Date();

  return new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
};

const getLastDayOfMonth = () => {
  const date = new Date();

  return new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
};

export { getFirstDayOfMonth, getLastDayOfMonth };

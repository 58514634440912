import _ from 'lodash';
import { useState } from 'react';

import { useForm } from 'hooks/useForm';
import { Filter } from 'utils/Filter';
import { cnpjMask, cpfMask } from 'functions/mask';
import { removeObjectKeys } from 'functions/removeObjectKeys';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'functions/dateUtils';

import { TableFilter } from 'components/TableFilter';
import { Form, FormFooter, Input, Select } from 'components/Form';
import { pedidoFilterSchema } from 'views/pedidos/utils/pedidoFilterSchema';

type FilterBy = 'DATA_INCLUSAO' | 'DATA_AGENDAMENTO_INSTALACAO';

interface DateFilter {
  high: string;
  low: string;
}

interface FilterFields {
  statusId: string[];
  tipoPessoa: string;
  clientePessoaFisicaCpf: string;
  clientePessoaFisicaNome: string;
  clientePessoaJuridicaCnpj: string;
  clientePessoaJuridicaNomeFantasia: string;
  clientePessoaJuridicaRazaoSocial: string;
  vendedorId: string;
  tecnicoId: string;
  dataInclusao: DateFilter;
  dataAgendamentoInstalacao: DateFilter;
}

interface PedidosFilterFieldsProps {
  usuarios: { usuarioId: string; nome: string; perfilId: string }[];
  filterFields: Filter<FilterFields> | null;
  onFilter: (values: Filter | null) => void;
  onClear: () => void;
}

export const PedidosFilterFields: React.FC<PedidosFilterFieldsProps> = ({
  usuarios,
  filterFields,
  onFilter,
  onClear,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const firstDayOfMonth = getFirstDayOfMonth().slice(0, 10);
  const lastDayOfMonth = getLastDayOfMonth().slice(0, 10);

  const { fields, setFields, errors, submitHandler } = useForm({
    filterBy: filterFields?.between?.dataAgendamentoInstalacao?.low
      ? 'DATA_AGENDAMENTO_INSTALACAO'
      : 'DATA_INCLUSAO',
    'equals.pedidoId': '',
    'includes.tipoPessoa': filterFields?.includes?.tipoPessoa || '',
    'includes.vendedorId': filterFields?.includes?.vendedorId || '',
    'includes.tecnicoId': filterFields?.includes?.tecnicoId || '',
    'likes.clientePessoaFisicaCpf':
      filterFields?.likes?.clientePessoaFisicaCpf || '',
    'likes.clientePessoaFisicaNome':
      filterFields?.likes?.clientePessoaFisicaNome || '',
    'likes.clientePessoaJuridicaCnpj':
      filterFields?.likes?.clientePessoaJuridicaCnpj || '',
    'likes.clientePessoaJuridicaNomeFantasia':
      filterFields?.likes?.clientePessoaJuridicaNomeFantasia || '',
    'likes.clientePessoaJuridicaRazaoSocial':
      filterFields?.likes?.clientePessoaJuridicaRazaoSocial || '',
    'between.dataInclusao.low':
      filterFields?.between?.dataInclusao?.low || firstDayOfMonth,
    'between.dataInclusao.high':
      filterFields?.between?.dataInclusao?.high || lastDayOfMonth,
    'between.dataAgendamentoInstalacao.low':
      filterFields?.between?.dataAgendamentoInstalacao?.low || '',
    'between.dataAgendamentoInstalacao.high':
      filterFields?.between?.dataAgendamentoInstalacao?.high || '',
  });

  const onChangeFilterBy = (value: FilterBy) => {
    if (!value) {
      setFields({
        ...fields,
        filterBy: value,
        'between.dataInclusao.low': '',
        'between.dataInclusao.high': '',
        'between.dataAgendamentoInstalacao.low': '',
        'between.dataAgendamentoInstalacao.high': '',
      });
      return;
    }

    if (value === 'DATA_INCLUSAO') {
      setFields({
        ...fields,
        filterBy: value,
        'between.dataInclusao.low': firstDayOfMonth,
        'between.dataInclusao.high': lastDayOfMonth,
        'between.dataAgendamentoInstalacao.low': '',
        'between.dataAgendamentoInstalacao.high': '',
      });
    }

    if (value === 'DATA_AGENDAMENTO_INSTALACAO') {
      setFields({
        ...fields,
        filterBy: value,
        'between.dataInclusao.low': '',
        'between.dataInclusao.high': '',
        'between.dataAgendamentoInstalacao.low': firstDayOfMonth,
        'between.dataAgendamentoInstalacao.high': lastDayOfMonth,
      });
    }
  };

  const handleSubmit = submitHandler({
    validateSchema: pedidoFilterSchema,
    callback: async values => {
      try {
        const parsed = Object.fromEntries(
          Object.entries(values)
            .map(entry => [entry[0], entry[1] || undefined])
            .filter(([_, value]) => value != null),
        );

        const parsedFilter: Record<string, any> = Object.keys(parsed).reduce(
          (result, key) => _.set(result, key, parsed[key]),
          {} as Record<string, any>,
        );

        if (Object.keys(parsedFilter).length === 0) {
          onFilter(null);
          return;
        }

        const filter = removeObjectKeys(parsedFilter, 'filterBy');

        onFilter(filter);
      } finally {
        setIsVisible(false);
      }
    },
  });

  return (
    <TableFilter
      title="Filtragem Pedidos"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isFiltering={filterFields !== null}
      onClear={onClear}
      onClose={() => setIsVisible(false)}
    >
      <Form
        columns={3}
        onSubmit={handleSubmit}
        footerComponent={<FormFooter onCancel={() => setIsVisible(false)} />}
      >
        <Input
          autoFocus
          type="number"
          min={1}
          name="equals.pedidoId"
          label="Cód. Pedido (ID)"
          value={fields['equals.pedidoId']}
          onChange={value => setFields({ ...fields, 'equals.pedidoId': value })}
        />
        <Select
          className="column-start"
          label="Filtrar por"
          name="filtrarPor"
          error={errors?.filterBy}
          value={fields.filterBy}
          onChange={value => onChangeFilterBy(value as FilterBy)}
          options={[
            { label: 'Data de inclusão', value: 'DATA_INCLUSAO' },
            {
              label: 'Data agendamento instalação',
              value: 'DATA_AGENDAMENTO_INSTALACAO',
            },
          ]}
        />

        {fields.filterBy && (
          <>
            {fields.filterBy === 'DATA_INCLUSAO' && (
              <>
                <Input
                  type="date"
                  name="between.dataInclusao.low"
                  label="Data de inclusão inicial"
                  error={errors?.between?.dataInclusao?.low}
                  value={fields['between.dataInclusao.low']}
                  onChange={value =>
                    setFields({ ...fields, 'between.dataInclusao.low': value })
                  }
                />
                <Input
                  type="date"
                  name="between.dataInclusao.high"
                  label="Data de inclusão final"
                  value={fields['between.dataInclusao.high']}
                  onChange={value =>
                    setFields({ ...fields, 'between.dataInclusao.high': value })
                  }
                />
              </>
            )}

            {fields.filterBy === 'DATA_AGENDAMENTO_INSTALACAO' && (
              <>
                <Input
                  type="date"
                  name="between.dataAgendamentoInstalacao.low"
                  label="Data agendamento instalação inicial"
                  error={errors?.between?.dataAgendamentoInstalacao?.low}
                  value={fields['between.dataAgendamentoInstalacao.low']}
                  onChange={value =>
                    setFields({
                      ...fields,
                      'between.dataAgendamentoInstalacao.low': value,
                    })
                  }
                />
                <Input
                  type="date"
                  name="between.dataAgendamentoInstalacao.high"
                  label="Data agendamento instalação final"
                  value={fields['between.dataAgendamentoInstalacao.high']}
                  onChange={value =>
                    setFields({
                      ...fields,
                      'between.dataAgendamentoInstalacao.high': value,
                    })
                  }
                />
              </>
            )}
          </>
        )}

        <Select
          className="column-start"
          label="Tipo Pessoa"
          name="includes.tipoPessoa"
          value={fields['includes.tipoPessoa'] as string}
          onChange={value =>
            setFields({ ...fields, 'includes.tipoPessoa': value })
          }
          options={[
            { label: 'Física', value: 'PESSOA_FISICA' },
            { label: 'Jurídica', value: 'PESSOA_JURIDICA' },
          ]}
        />

        {fields['includes.tipoPessoa'] === 'PESSOA_FISICA' && (
          <>
            <Input
              className="column-start"
              label="CPF"
              name="likes.clientePessoaFisicaCpf"
              value={cpfMask(fields['likes.clientePessoaFisicaCpf'] as string)}
              onChange={value =>
                setFields({
                  ...fields,
                  'likes.clientePessoaFisicaCpf': value.replace(/\D/g, ''),
                })
              }
            />
            <Input
              label="Nome"
              name="likes.clientePessoaFisicaNome"
              value={fields['likes.clientePessoaFisicaNome'] as string}
              onChange={value =>
                setFields({
                  ...fields,
                  'likes.clientePessoaFisicaNome': value,
                })
              }
            />
          </>
        )}

        {fields['includes.tipoPessoa'] === 'PESSOA_JURIDICA' && (
          <>
            <Input
              className="column-start"
              label="CNPJ"
              name="likes.clientePessoaJuridicaCnpj"
              value={cnpjMask(
                fields['likes.clientePessoaJuridicaCnpj'] as string,
              )}
              onChange={value =>
                setFields({
                  ...fields,
                  'likes.clientePessoaJuridicaCnpj': value.replace(/\D/g, ''),
                })
              }
            />
            <Input
              label="Razão Social"
              name="likes.clientePessoaJuridicaRazaoSocial"
              value={fields['likes.clientePessoaJuridicaRazaoSocial'] as string}
              onChange={value =>
                setFields({
                  ...fields,
                  'likes.clientePessoaJuridicaRazaoSocial': value,
                })
              }
            />
            <Input
              label="Nome Fantasia"
              name="likes.clientePessoaJuridicaNomeFantasia"
              value={
                fields['likes.clientePessoaJuridicaNomeFantasia'] as string
              }
              onChange={value =>
                setFields({
                  ...fields,
                  'likes.clientePessoaJuridicaNomeFantasia': value,
                })
              }
            />
          </>
        )}

        <Select
          label="Vendedor"
          name="includes.vendedorId"
          value={fields['includes.vendedorId'] as string}
          onChange={value =>
            setFields({ ...fields, 'includes.vendedorId': value })
          }
          options={usuarios
            .filter(usuario => usuario.perfilId === 'VENDEDOR')
            .map(({ nome, usuarioId }) => ({
              label: nome,
              value: usuarioId,
            }))}
        />
        <Select
          label="Técnico"
          name="includes.tecnicoId"
          value={fields['includes.tecnicoId'] as string}
          onChange={value =>
            setFields({ ...fields, 'includes.tecnicoId': value })
          }
          options={usuarios
            .filter(usuario => usuario.perfilId === 'TECNICO')
            .map(({ nome, usuarioId }) => ({
              label: nome,
              value: usuarioId,
            }))}
        />
      </Form>
    </TableFilter>
  );
};

import { CSSProperties } from 'react';
import styles from './FlexContainer.module.scss';

interface FlexContainerProps {
  className?: string;
  style?: CSSProperties;
}

export const FlexContainer: React.FC<FlexContainerProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <div
      className={`${styles['flex-container']} ${className ? className : ''}`}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';

import { Log } from 'utils/Log';
import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { EnderecoResult } from 'utils/types/Endereco';
import { RedeSocialEnum } from 'utils/enums/RedeSocialEnum';

import { TabList } from 'components/TabList';
import { Fallback } from 'components/Fallback';
import { LogsTable } from 'components/LogsTable';
import { PlanosTable } from 'components/PlanosTable';
import { AnexoPreviewList } from 'components/AnexoPreview';
import { ComunicacoesTable } from 'components/ComunicacoesTable';
import { DetailPedidoDados } from './components/DetailPedidoDados';
import { PedidoDetailInstalacao } from '../components/PedidoDetailInstalacao';
import { PedidoDetailConsulta } from 'views/pedidos/components/PedidoDetailConsulta';
import { PedidoDetailEnderecos } from 'views/pedidos/components/PedidoDetailEnderecos';
import { DetailPedidoDadosEmpregaticios } from './components/DetailPedidoDadosEmpregaticios';
import { PedidoDetailInformacoesServico } from 'views/pedidos/components/PedidoDetailInformacoesServico';

interface PedidoDetailPessoaFisicaQueryVariables {
  id: { pedidoId: string };
}

interface PedidoDetailPessoaFisicaQuery {
  pedido: {
    pedidoId: string;
    statusId: string;
    clientePessoaFisicaNome: string;
    clientePessoaFisicaCpf: string;
    clientePessoaFisicaRg: string;
    clientePessoaFisicaDataNascimento: string;
    clientePessoaFisicaSexo?: string;
    clientePessoaFisicaEstadoCivilId?: string;
    validacaoCliente?: number;
    allPedidosRedesSociais: {
      redeSocial: RedeSocialEnum;
      link: string;
    }[];
    allTelefones: Telefone[];
    allEmails: Email[];
    enderecoInstalacao: EnderecoResult;
    enderecoCobranca: EnderecoResult;
    consulta: { dataConsulta: string; resultado: string; url: string };
    allPedidosPlanos: { planoId: string; nome: string; preco: number }[];
    diaVencimentoId: string;
    fidelidadeId: string;
    allFinalidades: { finalidadeId: string; nome: string }[];
    rede: string;
    senha: string;
    clientePessoaFisicaVinculoEmpregaticioId: string;
    clientePessoaFisicaRegistradoLocalTrabalho?: string;
    clientePessoaFisicaRegistradoRenda?: number;
    clientePessoaFisicaRegistradoTelefoneTrabalho?: {
      telefoneId: string;
      nome: string;
      telefone: string;
      whatsapp: boolean;
    };
    clientePessoaFisicaAutonomoAtividadeExercida?: string;
    clientePessoaFisicaTipoResidencia?: string;
    clientePessoaFisicaQuantidadeMoradores?: number;
    clientePessoaFisicaTempoMoradia?: string;
    clientePessoaFisicaInternetAnterior?: boolean;
    clientePessoaFisicaInternetAnteriorOperadoraId?: string;
    clientePessoaFisicaInternetAnteriorMotivoCancelamento?: string;
    allComunicacoesInternas: {
      comunicacaoInternaId: string;
      descricao: string;
      createdAt: string;
      usuario: { usuarioId: string; nome: string };
    }[];
    dataInstalacao: string;
    numeroSerieOnu: string;
    cto: string;
    ctoPorta: string;
    tecnico: { usuarioId: string; nome: string };
    pedidosMateriais: {
      items: {
        pedidoMaterialId: string;
        material: { materialId: string; nome: string };
        quantidade: number;
      }[];
    };
    allAnexos: { nome: string; url: string; mimetype: string }[];
    logs: { items: Log[] };
  };
  diasVencimentos: { items: { diaVencimentoId: string; nome: string }[] };
  fidelidades: { items: { fidelidadeId: string; nome: string }[] };
  finalidades?: { items: { finalidadeId: string; nome: string }[] };
  vinculosEmpregaticios: {
    items: { vinculoEmpregaticioId: string; nome: string }[];
  };
  operadoras: { items: { operadoraId: string; nome: string }[] };
}

const PEDIDO_DETAIL_PESSOA_FISICA_QUERY = gql`
  query ($id: PedidoID!) {
    pedido(id: $id) {
      pedidoId
      statusId
      clientePessoaFisicaNome
      clientePessoaFisicaCpf
      clientePessoaFisicaRg
      clientePessoaFisicaDataNascimento
      clientePessoaFisicaSexo
      clientePessoaFisicaEstadoCivilId
      validacaoCliente
      allPedidosRedesSociais {
        redeSocial
        link
      }
      allTelefones {
        nome
        telefone
        whatsapp
      }
      allEmails {
        nome
        email
      }
      enderecoInstalacao {
        cep
        numero
        enderecoId
        logradouro
        bairro
        cidade {
          nome
          estado {
            nome
          }
        }
        complemento
        pontoReferencia
      }
      enderecoCobranca {
        cep
        numero
        enderecoId
        logradouro
        bairro
        cidade {
          nome
          estado {
            nome
          }
        }
        complemento
        pontoReferencia
      }
      consulta {
        dataConsulta
        resultado
        url
      }
      allPedidosPlanos {
        planoId
        nome
        preco
      }
      diaVencimentoId
      fidelidadeId
      allFinalidades {
        finalidadeId
        nome
      }
      rede
      senha
      clientePessoaFisicaVinculoEmpregaticioId
      clientePessoaFisicaRegistradoLocalTrabalho
      clientePessoaFisicaRegistradoRenda
      clientePessoaFisicaRegistradoTelefoneTrabalho {
        telefoneId
        nome
        telefone
        whatsapp
      }
      clientePessoaFisicaAutonomoAtividadeExercida
      clientePessoaFisicaTipoResidencia
      clientePessoaFisicaQuantidadeMoradores
      clientePessoaFisicaTempoMoradia
      clientePessoaFisicaInternetAnterior
      clientePessoaFisicaInternetAnteriorOperadoraId
      clientePessoaFisicaInternetAnteriorMotivoCancelamento
      allComunicacoesInternas {
        comunicacaoInternaId
        descricao
        createdAt
        usuario {
          usuarioId
          nome
        }
      }
      dataInstalacao
      numeroSerieOnu
      cto
      ctoPorta
      tecnico {
        usuarioId
        nome
      }
      pedidosMateriais {
        items {
          pedidoMaterialId
          material {
            materialId
            nome
          }
          quantidade
        }
      }
      allAnexos {
        nome
        url
        mimetype
      }
      logs {
        items {
          logId
          tipo
          descricao
          createdAt
          usuario {
            nome
            avatar {
              url
            }
          }
        }
      }
    }
    diasVencimentos {
      items {
        diaVencimentoId
        nome
      }
    }
    fidelidades {
      items {
        fidelidadeId
        nome
      }
    }
    finalidades {
      items {
        finalidadeId
        nome
      }
    }
    vinculosEmpregaticios {
      items {
        vinculoEmpregaticioId
        nome
      }
    }
    operadoras {
      items {
        operadoraId
        nome
      }
    }
  }
`;

export const DetailPedidoPessoaFisicaView: React.FC = () => {
  const params = useParams();
  const { auth, setAuth } = useAuth();

  const [fields, setFields] = useState({
    dados: {
      nome: '',
      cpf: '',
      rg: '',
      dataNascimento: '',
      sexo: '',
      estadoCivilId: '',
      redesSociais: [{ link: '', redeSocial: '' as RedeSocialEnum }],
      telefones: [{ nome: '', telefone: '', whatsapp: false }],
      emails: [{ nome: '', email: '' }],
      statusPedido: '',
      validacaoCliente: 0,
    },
    enderecoInstalacao: {
      enderecoId: '',
      cep: '',
      numero: '',
      logradouro: '',
      bairro: '',
      cidade: { nome: '', estado: { nome: '' } },
      complemento: '',
      pontoReferencia: '',
    },
    enderecoCobranca: {
      enderecoId: '',
      cep: '',
      numero: '',
      logradouro: '',
      bairro: '',
      cidade: { nome: '', estado: { nome: '' } },
      complemento: '',
      pontoReferencia: '',
    },
    consulta: { dataConsulta: '', resultado: '', url: '' },
    planos: [{ planoId: '', nome: '', preco: 0 }],
    informacoesServico: {
      diaVencimentoId: '',
      fidelidadeId: '',
      pedidoFinalidades: [{ finalidadeId: '', nome: '' }],
      rede: '',
      senha: '',
      diasVencimentos: [{ diaVencimentoId: '', nome: '' }],
      finalidades: [{ finalidadeId: '', nome: '' }],
      fidelidades: [{ fidelidadeId: '', nome: '' }],
    },
    dadosEmpregraticios: {
      vinculoEmpregaticioId: '',
      localTrabalho: '',
      telefoneTrabalho: { nome: '', telefone: '', whatsapp: false },
      renda: 0,
      atividadeExercida: '',
      tipoResidencia: '',
      quantidadeMoradores: 0,
      tempoMoradia: '',
      possuiInternet: false,
      operadoraId: '',
      motivoCancelamento: '',
      vinculosEmpregaticios: [{ vinculoEmpregaticioId: '', nome: '' }],
      operadoras: [{ operadoraId: '', nome: '' }],
    },
    instalacao: {
      dataInstalacao: '',
      numeroSerieOnu: '',
      cto: '',
      ctoPorta: '',
      tecnico: '',
      materiais: [{ materialId: '', nome: '', quantidade: 0 }],
    },
    anexos: [{ nome: '', url: '', mimetype: '' }],
  });

  const pedidoId = params.pedidoId || '';

  const query = useQueryAuth<
    PedidoDetailPessoaFisicaQuery,
    PedidoDetailPessoaFisicaQueryVariables
  >({
    auth,
    setAuth,
    query: PEDIDO_DETAIL_PESSOA_FISICA_QUERY,
    variables: { id: { pedidoId } },
  });

  useEffect(() => {
    if (query.data) {
      const { data } = query;

      setFields({
        dados: {
          nome: data.pedido.clientePessoaFisicaNome,
          cpf: data.pedido.clientePessoaFisicaCpf,
          rg: data.pedido.clientePessoaFisicaRg,
          dataNascimento: data.pedido.clientePessoaFisicaDataNascimento,
          sexo: data.pedido.clientePessoaFisicaSexo || '',
          estadoCivilId: data.pedido.clientePessoaFisicaEstadoCivilId || '',
          redesSociais: data.pedido.allPedidosRedesSociais,
          telefones: data.pedido.allTelefones,
          emails: data.pedido.allEmails,
          statusPedido: data.pedido.statusId,
          validacaoCliente: data.pedido.validacaoCliente || 0,
        },
        enderecoInstalacao: {
          enderecoId: data.pedido.enderecoInstalacao.enderecoId,
          cep: data.pedido.enderecoInstalacao.cep,
          numero: data.pedido.enderecoInstalacao.numero,
          logradouro: data.pedido.enderecoInstalacao.logradouro,
          bairro: data.pedido.enderecoInstalacao.bairro,
          cidade: data.pedido.enderecoInstalacao.cidade,
          complemento: data.pedido.enderecoInstalacao.complemento,
          pontoReferencia: data.pedido.enderecoInstalacao.pontoReferencia,
        },
        enderecoCobranca: {
          enderecoId: data.pedido.enderecoCobranca.enderecoId,
          cep: data.pedido.enderecoCobranca.cep,
          numero: data.pedido.enderecoCobranca.numero,
          logradouro: data.pedido.enderecoCobranca.logradouro,
          bairro: data.pedido.enderecoCobranca.bairro,
          cidade: data.pedido.enderecoCobranca.cidade,
          complemento: data.pedido.enderecoCobranca.complemento,
          pontoReferencia: data.pedido.enderecoCobranca.pontoReferencia,
        },
        consulta: data.pedido.consulta,
        planos: data.pedido.allPedidosPlanos,
        informacoesServico: {
          diaVencimentoId: data.pedido.diaVencimentoId,
          fidelidadeId: data.pedido.fidelidadeId,
          pedidoFinalidades: data.pedido.allFinalidades,
          rede: data.pedido.rede,
          senha: data.pedido.senha,
          diasVencimentos: data.diasVencimentos.items,
          fidelidades: data.fidelidades.items,
          finalidades: data.finalidades?.items || [
            { finalidadeId: '', nome: '' },
          ],
        },
        dadosEmpregraticios: {
          vinculoEmpregaticioId:
            data.pedido.clientePessoaFisicaVinculoEmpregaticioId,
          localTrabalho:
            data.pedido?.clientePessoaFisicaRegistradoLocalTrabalho || '',
          renda: data.pedido?.clientePessoaFisicaRegistradoRenda || 0,
          telefoneTrabalho: {
            nome:
              data.pedido.clientePessoaFisicaRegistradoTelefoneTrabalho?.nome ||
              '',
            telefone:
              data.pedido.clientePessoaFisicaRegistradoTelefoneTrabalho
                ?.telefone || '',
            whatsapp:
              data.pedido.clientePessoaFisicaRegistradoTelefoneTrabalho
                ?.whatsapp || false,
          },
          atividadeExercida:
            data.pedido?.clientePessoaFisicaAutonomoAtividadeExercida || '',
          tipoResidencia: data.pedido?.clientePessoaFisicaTipoResidencia || '',
          quantidadeMoradores:
            data.pedido?.clientePessoaFisicaQuantidadeMoradores || 0,
          tempoMoradia: data.pedido?.clientePessoaFisicaTempoMoradia || '',
          possuiInternet:
            data.pedido?.clientePessoaFisicaInternetAnterior || false,
          operadoraId:
            data.pedido?.clientePessoaFisicaInternetAnteriorOperadoraId || '',
          motivoCancelamento:
            data.pedido
              ?.clientePessoaFisicaInternetAnteriorMotivoCancelamento || '',
          vinculosEmpregaticios: data.vinculosEmpregaticios.items,
          operadoras: data.operadoras.items,
        },
        instalacao: {
          dataInstalacao: data.pedido?.dataInstalacao || '',
          numeroSerieOnu: data.pedido?.numeroSerieOnu || '',
          cto: data.pedido?.cto || '',
          ctoPorta: data.pedido?.ctoPorta || '',
          tecnico: data.pedido.tecnico?.nome || '',
          materiais: data.pedido.pedidosMateriais?.items?.map(item => ({
            materialId: item.material.materialId,
            nome: item.material.nome,
            quantidade: item.quantidade,
          })) || [{ materialId: '', nome: '', quantidade: '' }],
        },
        anexos: data.pedido.allAnexos,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  return (
    <TabList
      labels={[
        'Dados',
        'Endereços',
        'Consulta',
        'Planos',
        'Info. do Serviço',
        'Dados Empreg.',
        'Anexos',
        'Instalação',
        'Comunicações',
        'Logs',
      ]}
    >
      <DetailPedidoDados {...fields.dados} />
      <PedidoDetailEnderecos
        enderecoCobranca={fields.enderecoCobranca}
        enderecoInstalacao={fields.enderecoInstalacao}
      />
      <PedidoDetailConsulta {...fields.consulta} />
      <PlanosTable planos={fields.planos} />
      <PedidoDetailInformacoesServico {...fields.informacoesServico} />
      <DetailPedidoDadosEmpregaticios {...fields.dadosEmpregraticios} />
      <AnexoPreviewList anexos={fields.anexos} />
      <PedidoDetailInstalacao {...fields.instalacao} />
      <ComunicacoesTable
        comunicacoes={query.data.pedido.allComunicacoesInternas}
      />
      <LogsTable logs={query.data.pedido.logs.items} />
    </TabList>
  );
};

import { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useError } from 'hooks/useError';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { Sort } from 'utils/Sort';
import { Filter } from 'utils/Filter';
import { successToast } from 'utils/toast';
import { Pagination } from 'utils/Pagination';
import { GraphQLError } from 'utils/GraphQLError';

import { gql } from 'functions/gql';
import { paginate } from 'functions/paginate';
import { graphqlAuth } from 'functions/graphqlAuth';
import { dateFormatWithHours } from 'functions/formatters';

import { FidelidadeFilter } from './FidelidadeFilter';
import { Table } from 'components/Table';
import { Badge } from 'components/Badge';
import { Fallback } from 'components/Fallback';
import { EditOption } from 'components/EditOption';
import { IconButton } from 'components/IconButton';
import { FlexContainer } from 'components/FlexContainer';
import { ActivateOption } from 'components/ActivateOption';

interface FidelidadeListQueryVariables {
  filter?: Filter;
  pagination: Pagination;
  sort?: Sort[];
}

interface FidelidadeListQuery {
  fidelidades: {
    totalCount: number;
    items: {
      fidelidadeId: string;
      nome: string;
      ativo: boolean;
      createdAt: string;
    }[];
  };
}

const FIDELIDADE_LIST_QUERY = gql`
  query ($filter: FidelidadeFilter, $pagination: Pagination, $sort: [Sort!]) {
    fidelidades(filter: $filter, pagination: $pagination, sort: $sort) {
      totalCount
      items {
        fidelidadeId
        nome
        ativo
        createdAt
      }
    }
  }
`;

interface FidelidadeUpdateQuery {
  fidelidadeUpdate: {
    fidelidadeId: number;
    nome: string;
    ativo: string;
  };
}

interface FidelidadeUpdateVariables {
  id: { fidelidadeId: string };
  input: { ativo: boolean };
}

const FIDELIDADE_UPDATE_QUERY = gql`
  mutation ($id: FidelidadeID!, $input: FidelidadeUpdateInput!) {
    fidelidadeUpdate(id: $id, input: $input) {
      fidelidadeId
      nome
    }
  }
`;

export const ListFidelidadeView: React.FC = () => {
  const navigate = useNavigate();
  const { withErrorHandling } = useError();
  const { acessos, auth, setAuth } = useAuth();

  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [sort, setSort] = useState<Sort | null>(null);
  const [filter, setFilter] = useState<Filter | null>(null);

  const query = useQueryAuth<FidelidadeListQuery, FidelidadeListQueryVariables>(
    {
      auth,
      setAuth,
      query: FIDELIDADE_LIST_QUERY,
      variables: {
        filter: filter ? filter : undefined,
        pagination: paginate(pagination),
        sort: sort ? [sort] : undefined,
      },
    },
  );

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  const { data } = query;

  const handleActivate = withErrorHandling(
    async (fidelidadeId: string, ativo: boolean) => {
      const { errors } = await graphqlAuth<
        FidelidadeUpdateQuery,
        FidelidadeUpdateVariables
      >({
        auth,
        setAuth,
        query: FIDELIDADE_UPDATE_QUERY,
        variables: { id: { fidelidadeId }, input: { ativo: !ativo } },
      });

      if (errors) {
        throw new GraphQLError('Falha ao criar fidelidade', errors);
      }

      successToast('Fidelidade alterada com sucesso.');

      await query.refetch();
    },
  );

  return (
    <Table
      title="Fidelidades"
      columns={[
        {
          key: 'opcoes',
          label: 'Opções',
          render: ({ ativo, fidelidadeId }) => (
            <FlexContainer>
              <EditOption
                disabled={!acessos.includes('CADASTROS.FIDELIDADES.ALTERAR')}
                path={`/fidelidades/${fidelidadeId}`}
                toolTipDirection="right"
              />
              <ActivateOption
                isActive={ativo}
                onActivate={() => handleActivate(fidelidadeId, ativo)}
                disabled={!acessos.includes('CADASTROS.FIDELIDADES.ALTERAR')}
                toolTipDirection="right"
              />
              <IconButton
                title="Visualizar"
                className="hover-green"
                icon={() => <FiEye size={22} />}
                disabled={!acessos.includes('CADASTROS.FIDELIDADES.VISUALIZAR')}
                onClick={() =>
                  navigate(`/fidelidades/${fidelidadeId}/detalhes`)
                }
              />
            </FlexContainer>
          ),
        },
        {
          key: 'fidelidadeId',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'nome',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'ativo',
          label: 'Ativo',
          sortable: true,
          render: ({ ativo }) => (
            <Badge background={ativo ? 'success' : 'error'}>
              {ativo ? 'Sim' : 'Não'}
            </Badge>
          ),
        },
        {
          key: 'createdAt',
          label: 'Data Criação',
          render: ({ createdAt }) => <p>{dateFormatWithHours(createdAt)}</p>,
          sortable: true,
        },
      ]}
      items={data.fidelidades.items}
      getRowId={({ fidelidadeId }) => fidelidadeId}
      totalCount={data.fidelidades.totalCount}
      pagination={pagination}
      onChangePage={page => setPagination({ ...pagination, page })}
      onChangePerPage={perPage =>
        setPagination({ ...pagination, perPage, page: 1 })
      }
      sort={sort}
      onSort={sortField => setSort(sortField)}
      headerSideComponent={() => (
        <FidelidadeFilter
          isFiltering={filter !== null}
          onFilter={filterProps => {
            setFilter(filterProps);
            setPagination({ ...pagination, page: 1 });
          }}
          onClear={() => setFilter(null)}
        />
      )}
    />
  );
};

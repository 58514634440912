import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { RedeSocial } from 'utils/types/RedeSocial';

import { cpfMask } from 'functions/mask';

import { EmailList } from 'components/EmailList';
import { PhoneList } from 'components/PhoneList';
import { Fieldset, Input, Select } from 'components/Form';
import { RedeSocialList } from 'components/RedeSocialList';

interface Fields {
  nome: string;
  cpf: string;
  rg: string;
  dataNascimento: string;
  sexo: string;
  estadoCivilId: string;
  redesSociais: RedeSocial[];
  telefones: Telefone[];
  emails: Email[];
}

interface DadosProps {
  fields: Fields;
  setFields: (value: Fields) => void;
  errors: any;
}

export const FieldsetPedidoDados: React.FC<DadosProps> = ({
  fields,
  setFields,
  errors,
}) => {
  return (
    <Fieldset legend="Dados do cliente" columns={3}>
      <Input
        autoFocus
        label="Nome"
        name="name"
        error={errors?.nome}
        value={fields.nome}
        onChange={value => setFields({ ...fields, nome: value })}
      />
      <Input
        label="CPF"
        name="cpf"
        error={errors?.cpf}
        value={cpfMask(fields.cpf)}
        onChange={value => setFields({ ...fields, cpf: value })}
      />
      <Input
        label="RG"
        name="rg"
        error={errors?.rg}
        value={fields.rg}
        onChange={value => setFields({ ...fields, rg: value })}
      />
      <Input
        type="date"
        label="Data de Nascimento"
        name="dataNascimento"
        error={errors?.dataNascimento}
        value={fields.dataNascimento}
        onChange={value => setFields({ ...fields, dataNascimento: value })}
      />
      <Select
        label="Sexo"
        name="sexo"
        error={errors?.sexo}
        value={fields.sexo}
        onChange={value => setFields({ ...fields, sexo: value })}
        options={[
          { label: 'Masculino', value: 'MASCULINO' },
          { label: 'Feminino', value: 'FEMININO' },
          { label: 'Outros', value: 'OUTROS' },
        ]}
      />
      <Select
        label="Estado Cívil"
        name="estadoCivil"
        error={errors?.estadoCivilId}
        value={fields.estadoCivilId}
        onChange={value => setFields({ ...fields, estadoCivilId: value })}
        options={[
          { value: 'CASADO', label: 'Casado(a)' },
          { value: 'DIVORCIADO', label: 'Divorciado(a)' },
          { value: 'OUTROS', label: 'Outros' },
          { value: 'SEPARADO', label: 'Separado(a)' },
          { value: 'SOLTEIRO', label: 'Solteiro(a)' },
          { value: 'VIUVO', label: 'Viúvo(a)' },
        ]}
      />

      <h4 className="heading4 title span-3">Contato</h4>

      <RedeSocialList
        className="span-3"
        errors={errors?.redesSociais}
        redesSociais={fields.redesSociais}
        setNewRedesSociais={newRedesSocials =>
          setFields({ ...fields, redesSociais: newRedesSocials })
        }
      />

      <PhoneList
        className="span-3"
        errors={errors?.telefones}
        telefones={fields.telefones}
        setNewTelefones={newTelefones =>
          setFields({ ...fields, telefones: newTelefones })
        }
        onRemove={index =>
          fields.telefones.length > 2 &&
          setFields({
            ...fields,
            telefones: fields.telefones.filter((_, i) => index !== i),
          })
        }
      />

      <EmailList
        className="span-3"
        errors={errors?.emails}
        emails={fields.emails}
        setNewEmails={newEmails => setFields({ ...fields, emails: newEmails })}
      />
    </Fieldset>
  );
};

import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

import { useAuth } from 'hooks/useAuth';
import { useForm } from 'hooks/useForm';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { newGrupoAcessoSchema } from './utils/newGrupoAcessoSchema';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { Fallback } from 'components/Fallback';
import { TreeView } from 'components/TreeView';
import { Form, FormFooter, Input } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

interface Acesso {
  acessoId: string;
  nome: string;
  parentId: string;
}

interface TreeViewAcesso extends Acesso {
  checked: boolean;
  open: boolean;
}

interface QueryAcessos {
  acessos: { items: Acesso[] };
}

const QUERY_ACESSOS = gql`
  query {
    acessos {
      items {
        acessoId
        nome
        parentId
      }
    }
  }
`;

interface GrupoAcessoCreateQuery {
  grupoAcessoCreate: {
    grupoAcessoId: string;
    nome: string;
  };
}

interface GrupoAcessoCreateVariables {
  input: {
    nome: string;
    acessos: { acessoId: string }[];
  };
}

const GRUPO_ACESSO_CREATE_QUERY = gql`
  mutation ($input: GrupoAcessoCreateInput!) {
    grupoAcessoCreate(input: $input) {
      grupoAcessoId
      nome
      ativo
    }
  }
`;

export const NewGrupoAcessoView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [acessos, setAcessos] = useState<TreeViewAcesso[]>([]);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    nome: '',
  });

  const query = useQueryAuth<QueryAcessos>({
    auth,
    setAuth,
    query: QUERY_ACESSOS,
  });

  useEffect(() => {
    setAcessos(
      query.data
        ? query.data.acessos.items.map(acesso => ({
            ...acesso,
            checked: false,
            open: false,
          }))
        : [],
    );
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const handleSubmit = submitHandler({
    validateSchema: newGrupoAcessoSchema,
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const acessosSelecionados = acessos
          .filter(acesso => acesso.checked)
          .map(filtered => ({ acessoId: filtered.acessoId }));

        const { data, errors } = await graphqlAuth<
          GrupoAcessoCreateQuery,
          GrupoAcessoCreateVariables
        >({
          auth,
          setAuth,
          query: GRUPO_ACESSO_CREATE_QUERY,
          variables: {
            input: { nome: values.nome, acessos: acessosSelecionados },
          },
        });

        if (errors) {
          throw new GraphQLError('Falha ao criar grupo de acesso', errors);
        }

        successToast(
          `Grupo de acesso: ${data.grupoAcessoCreate.nome}`,
          'Criado com sucesso!',
        );

        navigate('/grupo-acesso');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />

        <h4 className="heading4 text" style={{ margin: '1rem 0' }}>
          Selecione os Acessos abaixo:
        </h4>

        <TreeView items={acessos} setItems={newItems => setAcessos(newItems)} />
      </Form>
    </>
  );
};

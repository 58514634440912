import { useState } from 'react';
import * as Yup from 'yup';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { PerfilEnum } from 'utils/enums/PerfilEnum';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { Button, Form, Input } from 'components/Form';

interface MeUsuarioUpdateNameQueryVariables {
  input: { nome: string };
}

export interface MeUsuarioUpdateNameQuery {
  me: {
    usuarioUpdate: {
      usuarioId: string;
      login: string;
      nome: string;
      resetar: boolean;
      perfilId: PerfilEnum;
      avatar: { url: string };
      grupoAcesso: {
        grupoAcessoId: string;
        nome: string;
        acessos: {
          totalCount: number;
          items: { acessoId: string; nome: string }[];
        };
      };
    };
  };
}

const ME_USUARIO_UPDATE_NAME_QUERY = gql`
  mutation ($input: MeUsuarioUpdateInput!) {
    me {
      usuarioUpdate(input: $input) {
        usuarioId
        login
        nome
        resetar
        perfilId
        avatar {
          url
        }
        grupoAcesso {
          grupoAcessoId
          nome
          acessos {
            totalCount
            items {
              acessoId
              nome
            }
          }
        }
      }
    }
  }
`;

export const ProfileForm: React.FC<{ nome: string }> = ({ nome }) => {
  const { auth, setAuth } = useAuth();

  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    nome: nome || '',
  });

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({ nome: Yup.string() }),
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { data, errors } = await graphqlAuth<
          MeUsuarioUpdateNameQuery,
          MeUsuarioUpdateNameQueryVariables
        >({
          query: ME_USUARIO_UPDATE_NAME_QUERY,
          variables: { input: { nome: values.nome } },
          auth,
          setAuth,
        });

        if (errors || !data) {
          throw new GraphQLError('Falha ao alterar nome.', errors);
        }

        setAuth({ ...auth!, usuario: data.me.usuarioUpdate });

        successToast('Nome alterado com sucesso!');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <Form id="profileDataForm" onSubmit={handleSubmit}>
      <h4 className="heading4 title">Seus dados</h4>

      <Input
        name="nome"
        label="Nome"
        error={errors.nome}
        value={fields.nome}
        onChange={value => setFields({ ...fields, nome: value })}
      />

      <Button
        type="submit"
        background="success"
        form="profileDataForm"
        title="Clique para salvar alterações"
        disabled={disableButtonSubmit}
      >
        Salvar Alterações
      </Button>
    </Form>
  );
};

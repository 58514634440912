import { useEffect, useState } from 'react';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { paginate } from 'functions/paginate';
import { Pagination } from 'utils/Pagination';
import { TipoPessoaEnum } from 'utils/enums/TipoPessoaEnum';
import { PedidoStatusEnum } from 'utils/enums/PedidoStatusEnum';

import { Fallback } from 'components/Fallback';
import { CardList } from './components/CardList';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { ExibirMaisButton } from './components/ExibirMaisButton';

import emptyData from 'assets/empty-data.svg';

export interface Pedido {
  pedidoId: string;
  tipoPessoa: TipoPessoaEnum;
  clientePessoaFisicaNome: string | null;
  clientePessoaFisicaCpf: string | null;
  clientePessoaJuridicaRazaoSocial: string | null;
  clientePessoaJuridicaCnpj: string | null;
  dataAgendamentoInstalacao: string;
  statusId: PedidoStatusEnum;
}

interface PedidosInstalacaoQueryVariables {
  pagination: Pagination;
}

interface PedidosInstalacaoQuery {
  pedidos: {
    totalCount: number;
    items: Pedido[];
  };
}

const PEDIDOS_INSTALACAO_QUERY = gql`
  query ($pagination: Pagination) {
    pedidos(
      pagination: $pagination
      filter: { equals: { statusId: PENDENTE_ATIVACAO } }
    ) {
      totalCount
      items {
        pedidoId
        tipoPessoa
        clientePessoaFisicaNome
        clientePessoaFisicaCpf
        clientePessoaJuridicaRazaoSocial
        clientePessoaJuridicaCnpj
        dataAgendamentoInstalacao
        statusId
      }
    }
  }
`;

export const PedidosInstalacoesView: React.FC = () => {
  const { auth, setAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [pedidos, setPedidos] = useState<Pedido[]>([]);
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });

  const query = useQueryAuth<
    PedidosInstalacaoQuery,
    PedidosInstalacaoQueryVariables
  >({
    query: PEDIDOS_INSTALACAO_QUERY,
    variables: { pagination: paginate(pagination) },
    auth,
    setAuth,
  });

  useEffect(() => {
    if (query.data) {
      try {
        const { data } = query;
        setPedidos(state => [...state].concat(data.pedidos.items));
      } finally {
        setIsLoading(false);
      }
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  const { data } = query;

  return (
    <section>
      {pedidos.length > 0 ? (
        <>
          <CardList pedidos={pedidos} />

          {isLoading ? (
            <div style={{ marginTop: '2rem' }}>
              <LoadingSpinner size="small" />
            </div>
          ) : (
            <ExibirMaisButton
              disabled={pedidos.length === data.pedidos.totalCount}
              onClick={() => {
                setIsLoading(true);
                setPagination({ ...pagination, page: pagination.page + 1 });
              }}
            />
          )}
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <p className="body2 text">Nenhum registro encontrado.</p>
          <figure style={{ marginTop: '2rem' }}>
            <img
              src={emptyData}
              alt="Ilustação - Sem dados."
              width={260}
              height={260}
            />
          </figure>
        </div>
      )}
    </section>
  );
};

import { Layout } from 'components/Layout';
import { EditFidelidadeView } from 'views/fidelidades/edit';

export const EditFidelidadePage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Fidelidade', href: '/fidelidades' },
          { label: 'Editar' },
        ],
      }}
    >
      <EditFidelidadeView />
    </Layout>
  );
};

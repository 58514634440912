import { useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { GraphQLError } from 'utils/GraphQLError';
import { successToast, warnToast } from 'utils/toast';
import { Pedido } from '../meus-pedidos';
import { gql } from 'functions/gql';
import { cnpjMask, cpfMask, currencyMask } from 'functions/mask';
import { dateFormat, dateFormatWithHours } from 'functions/formatters';
import { graphqlFormDataAuth } from 'functions/graphqlAuth';

import {
  Button,
  Form,
  FormFooter,
  InputMultiFile,
  PreviewFile,
} from 'components/Form';
import { Modal } from 'components/Modal/Modal';

import styles from './Card.module.scss';

interface PedidoAdicionarAnexoQueryVariables {
  id: { pedidoId: string };
  input: { anexos: File[] };
}

interface PedidoAdicionarAnexoQuery {
  actions: {
    pedidos: {
      adicionarAnexos: { pedidoId: string };
    };
  };
}

const PEDIDO_ADICIONAR_ANEXO_QUERY = gql`
  mutation ($id: PedidoID!, $input: PedidoAdicionarAnexosInput!) {
    actions {
      pedidos {
        adicionarAnexos(id: $id, input: $input) {
          pedidoId
        }
      }
    }
  }
`;

interface CardProps {
  pedido: Pedido;
}

export const Card: React.FC<CardProps> = ({ pedido }) => {
  const { auth, setAuth } = useAuth();
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const [showModalAnexos, setShowModalAnexos] = useState(false);
  const { fields, setFields, submitHandler, resetForm } = useForm({
    anexos: [] as File[],
  });
  const pedidoStatus = pedido.statusId.replace(/[_]/g, ' ');

  const handleSubmit = submitHandler({
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        if (values.anexos.length === 0) {
          warnToast('Nenhum anexo adicionado.', '', {
            position: 'top-right',
          });
          return;
        }

        const { errors } = await graphqlFormDataAuth<
          PedidoAdicionarAnexoQuery,
          PedidoAdicionarAnexoQueryVariables
        >({
          query: PEDIDO_ADICIONAR_ANEXO_QUERY,
          variables: {
            id: { pedidoId: pedido.pedidoId },
            input: { anexos: [] },
          },
          files: values.anexos.map((anexo, index) => [
            `variables.input.anexos.${index}`,
            anexo,
          ]),
          auth,
          setAuth,
        });

        if (errors) {
          throw new GraphQLError('Erro ao adicionar anexos no pedido.', errors);
        }

        successToast('Anexos inseridos com sucesso!', '', {
          position: 'top-right',
        });

        setShowModalAnexos(false);
        resetForm();
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      {showModalAnexos && (
        <Modal
          title="Adicionar anexos"
          onClose={() => setShowModalAnexos(false)}
        >
          <Form
            onSubmit={handleSubmit}
            footerComponent={
              <FormFooter
                disableButtonSubmit={disableButtonSubmit}
                onCancel={() => setShowModalAnexos(false)}
              />
            }
          >
            <InputMultiFile
              className={styles['input-file']}
              name="anexos"
              label="Capturar / Adicionar anexos"
              value={fields.anexos}
              onChange={files =>
                files &&
                setFields({
                  ...fields,
                  anexos: [...fields.anexos, ...Array.from(files)],
                })
              }
            />

            {fields.anexos.length > 0 ? (
              <PreviewFile
                files={fields.anexos.map(anexo => ({
                  fileName: anexo.name,
                  url: URL.createObjectURL(anexo),
                  mimetype: anexo.type,
                }))}
                removeFiles={index =>
                  setFields(state => ({
                    ...state,
                    anexos: state.anexos.filter((_, i) => i !== index),
                  }))
                }
              />
            ) : (
              <p
                className="body2 text"
                style={{ textAlign: 'center', margin: '1rem 0' }}
              >
                Nenhum anexo adicionado.
              </p>
            )}
          </Form>
        </Modal>
      )}

      <div className={styles['card-container']}>
        <div className={styles['card-header']}>
          <h5 className="heading5 title">
            {pedido.tipoPessoa === 'PESSOA_FISICA'
              ? 'Pessoa Física'
              : 'Pessoa Jurídica'}
          </h5>
        </div>

        <div className={styles['card-main']}>
          <p className="body2">
            STATUS:{' '}
            <span
              className={`${
                pedidoStatus.includes('INSTALADO')
                  ? 'green'
                  : pedidoStatus.includes('PENDENTE')
                  ? 'warning'
                  : pedidoStatus.includes('CANCELADO')
                  ? 'red'
                  : 'secondary'
              }`}
            >
              {pedidoStatus}
            </span>
          </p>

          <div className={styles['pedido-info']}>
            <p className={`${styles['text-row']} body2 primary`}>
              <FiCalendar color="var(--primary)" size={22} />
              {dateFormat(pedido.dataInclusao)}
            </p>
            <p className="heading6 green">
              {currencyMask(
                `${pedido.allPedidosPlanos
                  .reduce((preco, crr) => preco + crr.preco, 0)
                  .toFixed(2)}`,
              )}
            </p>
          </div>

          <div className={styles['pedido-details']}>
            <p className={`${styles['text-column']} body4 textLight`}>
              CÓD. PEDIDO:
              <span className="body2 text">{pedido.pedidoId}</span>
            </p>
            <p className={`${styles['text-column']} body4 textLight`}>
              DOCUMENTO:
              <span className="body2 text">
                {pedido.tipoPessoa === 'PESSOA_FISICA'
                  ? cpfMask(pedido.clientePessoaFisicaCpf)
                  : cnpjMask(pedido.clientePessoaJuridicaCnpj)}
              </span>
            </p>
            <p className={`${styles['text-column']} body4 textLight`}>
              NOME:
              <span className="body2 text">
                {pedido.tipoPessoa === 'PESSOA_FISICA'
                  ? pedido.clientePessoaFisicaNome
                  : pedido.clientePessoaJuridicaRazaoSocial}
              </span>
            </p>
            <p className={`${styles['text-column']} body4 textLight`}>
              TÉCNICO:{' '}
              <span className="body2 text">
                {pedido.tecnico?.nome || '----'}
              </span>
            </p>
            <p className={`${styles['text-column']} body4 textLight`}>
              AGEND. INSTALAÇÃO:
              <span className="body2 text">
                {pedido.dataAgendamentoInstalacao
                  ? dateFormatWithHours(pedido.dataAgendamentoInstalacao)
                  : '----'}
              </span>
            </p>
          </div>

          <Button
            type="button"
            size="small"
            className={styles['add-anexo-button']}
            onClick={() => setShowModalAnexos(true)}
          >
            Adicionar anexos
          </Button>
        </div>
      </div>
    </>
  );
};

import { Button } from 'components/Form';
import { FiCheck } from 'react-icons/fi';

import styles from './FinishStepFooter.module.scss';

interface FinishStepFooterProps {
  disableSubmitButton: boolean;
}

export const FinishStepFooter: React.FC<FinishStepFooterProps> = ({
  disableSubmitButton,
}) => {
  return (
    <div className={styles['finish-step-container']}>
      <Button type="submit" background="success" disabled={disableSubmitButton}>
        Finalizar
        <FiCheck size={22} />
      </Button>
    </div>
  );
};

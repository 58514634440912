import React, { createContext, useEffect, useState } from 'react';
import { PerfilEnum } from 'utils/enums/PerfilEnum';

export const LOCAL_STORAGE_TOKEN_KEY = 'TOKEN';

export type AuthProps = {
  refreshToken: string;
  accessToken: string;
  expiresAt: string;
  usuario: {
    usuarioId: string;
    login: string;
    nome: string;
    resetar: boolean;
    perfilId: PerfilEnum;
    avatar: {
      url: string;
    };
    grupoAcesso: {
      grupoAcessoId: string;
      nome: string;
      acessos: {
        totalCount: number;
        items: {
          acessoId: string;
          nome: string;
        }[];
      };
    };
  };
};

export type AuthContextProps = {
  loading: boolean;
  auth: AuthProps | null;
  setAuth: (auth: AuthProps | null) => void;
  acessos: string[];
};

export const AuthContext = createContext<AuthContextProps>({
  loading: true,
  auth: null,
  setAuth: () => {},
  acessos: [],
});

export const AuthContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [auth, _setAuth] = useState<AuthProps | null>(null);

  const acessos =
    auth?.usuario.grupoAcesso.acessos.items.map(item => item.acessoId) || [];

  const setAuth = (auth: AuthProps | null) => {
    if (!auth) {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    } else {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, JSON.stringify(auth));
    }
    _setAuth(auth);
  };

  const fetchStorage = () => {
    setLoading(true);

    try {
      const authStr = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

      if (!authStr) {
        setAuth(null);
        return;
      }

      try {
        const value = JSON.parse(authStr);

        if (value && value.accessToken && value.refreshToken && value.usuario) {
          setAuth({
            accessToken: value.accessToken,
            refreshToken: value.refreshToken,
            expiresAt: value.expiresAt,
            usuario: value.usuario,
          });
        } else {
          setAuth(null);
        }
      } catch (err) {
        console.error(err);
        setAuth(null);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStorage();
  }, []);

  return (
    <AuthContext.Provider value={{ loading, auth, setAuth, acessos }}>
      {children}
    </AuthContext.Provider>
  );
};

import styles from './PrintValidacao.module.scss';

export const PrintValidacao = () => {
  return (
    <section className={styles['print-validacao-section']}>
      <p>Nota de validação</p>
      <div className={styles['print-validacao-container']}>
        {Array(10)
          .fill(null)
          .map((_, index) => (
            <div
              key={`validacao-${index}`}
              className={styles['print-validacao-button']}
            >
              {index + 1}
            </div>
          ))}
      </div>
    </section>
  );
};

import { Fieldset, Select } from 'components/Form';

interface Fields {
  statusPedido: string;
}

interface PedidoEditarDadosPedidoProps {
  fields: Fields;
  setFields: (value: Fields) => void;
  errors: any;
}

export const PedidoEditarDadosPedido: React.FC<
  PedidoEditarDadosPedidoProps
> = ({ errors, fields, setFields }) => {
  return (
    <>
      <Fieldset legend="Dados do Pedido" columns={2}>
        <Select
          label="Status do Pedido"
          name="statusPedido"
          error={errors?.statusPedido}
          value={fields.statusPedido}
          onChange={value => setFields({ ...fields, statusPedido: value })}
          options={[
            { value: 'CANCELADO', label: 'Cancelado' },
            { value: 'INSTALADO', label: 'Instalado' },
            {
              value: 'PENDENTE_ANALISE_CREDITO',
              label: 'Pendente de Análise de Crédito',
            },
            { value: 'PENDENTE_ATIVACAO', label: 'Pendente de Ativação' },
            {
              value: 'PENDENTE_CADASTRO_RADIUS',
              label: 'Pendente de Cadastro Radius',
            },
            {
              value: 'PENDENTE_CONFIRMACAO',
              label: 'Pendente de Confirmação',
            },
            {
              value: 'PENDENTE_CONSULTA_SPC_SERASA',
              label: 'Pendente de Consulta SPC/Serasa',
            },
          ]}
        />
      </Fieldset>
    </>
  );
};

import { useParams } from 'react-router';
import { FiPrinter } from 'react-icons/fi';

import { useAuth } from 'hooks/useAuth';

import { Layout } from 'components/Layout';
import { LinkButton } from 'components/LinkButton';

import { EditPedidoPessoaJuridicaView } from 'views/pedidos/pessoa-juridica';

export const EditPedidoPessoaJuridicaPage = () => {
  const params = useParams();
  const { acessos } = useAuth();

  const pedidoId = params.pedidoId || '';

  return (
    <Layout
      breadcrumb={{
        items: [{ label: 'Pedidos', href: '/pedidos' }, { label: 'Editar' }],
      }}
      component={
        acessos.includes('CADASTROS.PEDIDOS.VISUALIZAR') ? (
          <LinkButton
            to={`/pedidos/pessoa-juridica/${pedidoId}/imprimir`}
            target="_blank"
            rel="noreferrer"
          >
            <FiPrinter size={22} /> Imprimir
          </LinkButton>
        ) : undefined
      }
    >
      <EditPedidoPessoaJuridicaView />
    </Layout>
  );
};

import { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useError } from 'hooks/useError';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { Sort } from 'utils/Sort';
import { Filter } from 'utils/Filter';
import { successToast } from 'utils/toast';
import { Pagination } from 'utils/Pagination';
import { GraphQLError } from 'utils/GraphQLError';

import { gql } from 'functions/gql';
import { paginate } from 'functions/paginate';
import { graphqlAuth } from 'functions/graphqlAuth';
import { dateFormatWithHours } from 'functions/formatters';

import { Table } from 'components/Table';
import { Badge } from 'components/Badge';
import { Fallback } from 'components/Fallback';
import { IconButton } from 'components/IconButton';
import { EditOption } from 'components/EditOption';
import { FlexContainer } from 'components/FlexContainer';
import { ActivateOption } from 'components/ActivateOption';
import { DiaVencimentoFilter } from './DiaVencimentoFilter';

interface DiaVencimentoListQueryVariables {
  filter?: Filter;
  pagination: Pagination;
  sort?: Sort[];
}

interface DiaVencimentoListQuery {
  diasVencimentos: {
    totalCount: number;
    items: {
      diaVencimentoId: string;
      nome: string;
      ativo: boolean;
      createdAt: string;
    }[];
  };
}

const DIA_VENCIMENTO_LIST_QUERY = gql`
  query (
    $filter: DiaVencimentoFilter
    $pagination: Pagination
    $sort: [Sort!]
  ) {
    diasVencimentos(filter: $filter, pagination: $pagination, sort: $sort) {
      totalCount
      items {
        diaVencimentoId
        nome
        ativo
        createdAt
      }
    }
  }
`;

interface DiaVencimentoUpdateQueryVariables {
  id: {
    diaVencimentoId: string;
  };
  input: {
    ativo?: boolean;
  };
}

interface DiaVencimentoUpdateQuery {
  diaVencimentoUpdate: {
    diaVencimentoId: string;
  };
}

const DIA_VENCIMENTO_UPDATE_QUERY = gql`
  mutation ($id: DiaVencimentoID!, $input: DiaVencimentoUpdateInput!) {
    diaVencimentoUpdate(id: $id, input: $input) {
      diaVencimentoId
    }
  }
`;

export const ListDiaVencimentoView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth, acessos } = useAuth();
  const { withErrorHandling } = useError();
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [sort, setSort] = useState<Sort | null>(null);
  const [filter, setFilter] = useState<Filter | null>(null);

  const query = useQueryAuth<
    DiaVencimentoListQuery,
    DiaVencimentoListQueryVariables
  >({
    auth,
    setAuth,
    query: DIA_VENCIMENTO_LIST_QUERY,
    variables: {
      filter: filter ? filter : undefined,
      pagination: paginate(pagination),
      sort: sort ? [sort] : undefined,
    },
  });

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  const { data } = query;

  const handleActivate = withErrorHandling(
    async (diaVencimentoId: string, ativo: boolean) => {
      const { errors } = await graphqlAuth<
        DiaVencimentoUpdateQuery,
        DiaVencimentoUpdateQueryVariables
      >({
        auth,
        setAuth,
        query: DIA_VENCIMENTO_UPDATE_QUERY,
        variables: {
          id: { diaVencimentoId },
          input: { ativo: !ativo },
        },
      });

      if (errors) {
        throw new GraphQLError('Falha ao alterar dia vencimento', errors);
      }

      successToast('Dia vencimento alterado com sucesso.');

      await query.refetch();
    },
  );

  return (
    <Table
      title="Dias Vencimentos"
      columns={[
        {
          key: 'opcoes',
          label: 'Opções',
          render: ({ ativo, diaVencimentoId }) => (
            <FlexContainer>
              <EditOption
                path={`/dia-vencimento/${diaVencimentoId}`}
                toolTipDirection="right"
                disabled={
                  !acessos.includes('CADASTROS.DIAS_VENCIMENTOS.ALTERAR')
                }
              />
              <ActivateOption
                isActive={ativo}
                onActivate={() => handleActivate(diaVencimentoId, ativo)}
                toolTipDirection="right"
                disabled={
                  !acessos.includes('CADASTROS.DIAS_VENCIMENTOS.ALTERAR')
                }
              />
              <IconButton
                title="Visualizar"
                className="hover-green"
                icon={() => <FiEye size={22} />}
                disabled={
                  !acessos.includes('CADASTROS.DIAS_VENCIMENTOS.VISUALIZAR')
                }
                onClick={() =>
                  navigate(`/dia-vencimento/${diaVencimentoId}/detalhes`)
                }
              />
            </FlexContainer>
          ),
        },
        {
          key: 'diaVencimentoId',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'nome',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'ativo',
          label: 'Ativo',
          sortable: true,
          render: ({ ativo }) => (
            <Badge background={ativo ? 'success' : 'error'}>
              {ativo ? 'Sim' : 'Não'}
            </Badge>
          ),
        },
        {
          key: 'createdAt',
          label: 'Data Criação',
          render: ({ createdAt }) => <p>{dateFormatWithHours(createdAt)}</p>,
          sortable: true,
        },
      ]}
      items={data.diasVencimentos.items}
      getRowId={({ diaVencimentoId }) => diaVencimentoId}
      totalCount={data.diasVencimentos.totalCount}
      pagination={pagination}
      onChangePage={page => setPagination({ ...pagination, page })}
      onChangePerPage={perPage =>
        setPagination({ ...pagination, perPage, page: 1 })
      }
      sort={sort}
      onSort={sortField => setSort(sortField)}
      headerSideComponent={() => (
        <DiaVencimentoFilter
          isFiltering={filter !== null}
          onFilter={filterProps => {
            setFilter(filterProps);
            setPagination({ ...pagination, page: 1 });
          }}
          onClear={() => setFilter(null)}
        />
      )}
    />
  );
};

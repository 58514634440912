import _ from 'lodash';
import * as Yup from 'yup';
import { useState } from 'react';

import { Filter } from 'utils/Filter';
import { useForm } from 'hooks/useForm';

import { TableFilter } from 'components/TableFilter';
import { Form, FormFooter, Input } from 'components/Form';

interface FinalidadeFilterProps {
  isFiltering: boolean;
  onFilter: (values: Filter<Record<string, any>> | null) => void;
  onClear: () => void;
}

export const FinalidadeFilter: React.FC<FinalidadeFilterProps> = ({
  isFiltering,
  onFilter,
  onClear,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { fields, setFields, submitHandler } = useForm({
    'equals.finalidadeId': '',
    'likes.nome': '',
  });

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      'equals.finalidadeId': Yup.string(),
      'likes.nome': Yup.string(),
    }),
    callback: async values => {
      try {
        const parsed = Object.fromEntries(
          Object.entries(values)
            .map(entry => [entry[0], entry[1] || undefined])
            .filter(([_, value]) => value != null),
        );

        const filter: Record<string, any> = Object.keys(parsed).reduce(
          (result, key) => _.set(result, key, parsed[key]),
          {} as Record<string, any>,
        );

        if (Object.keys(filter).length === 0) {
          onFilter(null);
          return;
        }

        onFilter(filter);
      } finally {
        setIsVisible(false);
      }
    },
  });

  return (
    <TableFilter
      title="Filtragem Fidelidade"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isFiltering={isFiltering}
      onClear={onClear}
      onClose={() => setIsVisible(false)}
    >
      <Form
        columns={2}
        onSubmit={handleSubmit}
        footerComponent={<FormFooter onCancel={() => setIsVisible(false)} />}
      >
        <Input
          autoFocus
          type="number"
          min={1}
          name="equals.finalidadeId"
          label="Cód. Finalidade (ID)"
          value={fields['equals.finalidadeId']}
          onChange={value =>
            setFields({ ...fields, 'equals.finalidadeId': value })
          }
        />
        <Input
          className="span-2"
          name="likes.nome"
          label="Nome"
          value={fields['likes.nome']}
          onChange={value => setFields({ ...fields, 'likes.nome': value })}
        />
      </Form>
    </TableFilter>
  );
};

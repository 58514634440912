import { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { phoneMask } from 'functions/mask';

import { Label } from './Label';
import { ErrorMessage } from './ErrorMessage';

import styles from './InputPhone.module.scss';

type InputPhoneProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'name' | 'label' | 'value' | 'onChange' | 'id'
> & {
  id: string;
  isWhatsapp: boolean;
  error?: string;
  value: string;
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
  toggleWhatsApp?: () => void;
};

export const InputPhone: React.FC<InputPhoneProps> = ({
  id,
  isWhatsapp,
  error,
  value,
  onChange,
  toggleWhatsApp,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <section className={`${styles['input-section']}`}>
      <Label htmlFor={id}>
        Telefone
        <div
          className={[
            styles['input-field-container'],
            isFocused ? styles['is-focused'] : '',
            !!error ? styles['is-errored'] : '',
          ].join(' ')}
        >
          <input
            type="tel"
            className={[
              styles['input-field'],
              !!error ? styles['is-errored'] : '',
            ].join(' ')}
            id={id}
            name="telefone"
            maxLength={15}
            value={phoneMask(value)}
            onChange={e =>
              onChange && onChange(e.target.value.replace(/\D+/g, ''), e)
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...rest}
          />
          <FaWhatsapp
            size={22}
            title={isWhatsapp ? 'Desmarcar whatsapp' : 'Marcar whatsapp'}
            className={isWhatsapp ? `${styles['is-whatsapp']}` : ''}
            onClick={toggleWhatsApp}
          />
        </div>
        {error && <ErrorMessage error={error} />}
      </Label>
    </section>
  );
};

import { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useError } from 'hooks/useError';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { paginate } from 'functions/paginate';
import { graphqlAuth } from 'functions/graphqlAuth';
import { dateFormatWithHours } from 'functions/formatters';

import { Sort } from 'utils/Sort';
import { Filter } from 'utils/Filter';
import { successToast } from 'utils/toast';
import { Pagination } from 'utils/Pagination';

import { GrupoAcessoFilter } from './GrupoAcessoFilter';
import { Table } from 'components/Table';
import { Badge } from 'components/Badge';
import { Fallback } from 'components/Fallback';
import { EditOption } from 'components/EditOption';
import { IconButton } from 'components/IconButton';
import { FlexContainer } from 'components/FlexContainer';
import { ActivateOption } from 'components/ActivateOption';

export interface QueryGruposAcessos {
  gruposAcessos: {
    totalCount: number;
    items: {
      grupoAcessoId: string;
      nome: string;
      ativo: boolean;
      createdAt: string;
      updatedAt: string;
    }[];
  };
}

export interface QueryGruposAcessosVariables {
  filter?: Filter;
  pagination: Pagination;
  sort?: Sort[];
}

const QUERY_GRUPOS_ACESSOS = gql`
  query ($filter: GrupoAcessoFilter, $pagination: Pagination, $sort: [Sort!]) {
    gruposAcessos(filter: $filter, pagination: $pagination, sort: $sort) {
      totalCount
      items {
        grupoAcessoId
        nome
        ativo
        createdAt
        updatedAt
      }
    }
  }
`;

interface GrupoAcessoUpdate {
  grupoAcessoUpdate: {
    grupoAcessoId: string;
    nome: string;
  };
}

interface GrupoAcessoUpdateVariables {
  id: { grupoAcessoId: string };
  input: {
    nome?: string;
    ativo?: boolean;
    acessos?: [{ acessoId: string }];
  };
}

const GRUPO_ACESSO_UPDATE = gql`
  mutation ($id: GrupoAcessoID!, $input: GrupoAcessoUpdateInput!) {
    grupoAcessoUpdate(id: $id, input: $input) {
      grupoAcessoId
      nome
    }
  }
`;

export const ListGrupoAcessoView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth, acessos } = useAuth();
  const { setError } = useError();
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [sort, setSort] = useState<Sort | null>(null);
  const [filter, setFilter] = useState<Filter | null>(null);

  const query = useQueryAuth<QueryGruposAcessos, QueryGruposAcessosVariables>({
    query: QUERY_GRUPOS_ACESSOS,
    auth,
    setAuth,
    variables: {
      filter: filter ? filter : undefined,
      pagination: paginate(pagination),
      sort: sort ? [sort] : undefined,
    },
  });

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  const { data } = query;

  const handleActivate = async (grupoAcessoId: string, ativo: boolean) => {
    try {
      await graphqlAuth<GrupoAcessoUpdate, GrupoAcessoUpdateVariables>({
        auth,
        setAuth,
        query: GRUPO_ACESSO_UPDATE,
        variables: {
          id: { grupoAcessoId },
          input: { ativo: !ativo },
        },
      });

      successToast('Grupo de acesso alterado com sucesso.');

      await query.refetch();
    } catch (err: any) {
      setError({
        isErrored: true,
        seeDetails: false,
        error: err,
      });
    }
  };

  return (
    <Table
      title="Grupos de Acessos"
      columns={[
        {
          key: 'opcoes',
          label: 'Opções',
          render: ({ grupoAcessoId, ativo }) => (
            <FlexContainer>
              <EditOption
                disabled={
                  grupoAcessoId === '1' ||
                  !acessos.includes('CADASTROS.GRUPOS_ACESSOS.ALTERAR')
                }
                path={`/grupo-acesso/${grupoAcessoId}`}
                toolTipDirection="right"
              />
              <ActivateOption
                isActive={ativo}
                onActivate={() => handleActivate(grupoAcessoId, ativo)}
                disabled={
                  grupoAcessoId === '1' ||
                  !acessos.includes('CADASTROS.GRUPOS_ACESSOS.ALTERAR')
                }
                toolTipDirection="right"
              />
              <IconButton
                title="Visualizar"
                className="hover-green"
                icon={() => <FiEye size={22} />}
                disabled={
                  !acessos.includes('CADASTROS.GRUPOS_ACESSOS.VISUALIZAR')
                }
                onClick={() =>
                  navigate(`/grupo-acesso/${grupoAcessoId}/detalhes`)
                }
              />
            </FlexContainer>
          ),
        },
        {
          key: 'grupoAcessoId',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'nome',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'ativo',
          label: 'Ativo',
          sortable: true,
          render: ({ ativo }) => (
            <Badge background={ativo ? 'success' : 'error'}>
              {ativo ? 'Sim' : 'Não'}
            </Badge>
          ),
        },
        {
          key: 'createdAt',
          label: 'Data Criação',
          render: ({ createdAt }) => <p>{dateFormatWithHours(createdAt)}</p>,
          sortable: true,
        },
        {
          key: 'updatedAt',
          label: 'Data Alteração',
          render: ({ updatedAt }) => <p>{dateFormatWithHours(updatedAt)}</p>,
          sortable: true,
        },
      ]}
      items={data.gruposAcessos.items}
      totalCount={data.gruposAcessos.totalCount}
      getRowId={({ grupoAcessoId }) => grupoAcessoId}
      pagination={pagination}
      onChangePage={page => setPagination({ ...pagination, page })}
      onChangePerPage={perPage =>
        setPagination({ ...pagination, perPage, page: 1 })
      }
      sort={sort}
      onSort={sortField => setSort(sortField)}
      headerSideComponent={() => (
        <GrupoAcessoFilter
          isFiltering={filter !== null}
          onFilter={filterProps => {
            setFilter(filterProps);
            setPagination({ ...pagination, page: 1 });
          }}
          onClear={() => setFilter(null)}
        />
      )}
    />
  );
};

import { Log } from 'utils/Log';

import styles from './LogsTable.module.scss';

interface LogsTableProps {
  logs: Log[];
}

export const LogsTable: React.FC<LogsTableProps> = ({ logs }) => {
  return (
    <div className={styles['logs-table-container-wrapper']}>
      <table className={styles['logs-table-container']}>
        <thead className={styles['logs-table-head']}>
          <tr>
            <th>Avatar</th>
            <th>Usuário</th>
            <th>Ação</th>
            <th>Descrição</th>
          </tr>
        </thead>
        <tbody className={styles['logs-table-body']}>
          {logs.length > 0 ? (
            logs.map(log => (
              <tr key={log.logId}>
                <td>
                  <figure className={styles['logs-table-user-avatar']}>
                    <img
                      src={log.usuario.avatar.url}
                      alt={log.usuario.nome}
                      width={42}
                      height={42}
                    />
                  </figure>
                </td>
                <td>{log.usuario.nome}</td>
                <td className={styles[log.tipo.toLowerCase()]}>{log.tipo}</td>
                <td>{log.descricao}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: 'center' }}>
                Nenhum registro encontrado.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

import { MenuMobile } from './MenuMobile';
import { MenuDesktop } from './MenuDesktop';
import { menuItems } from './menuItems';
import { useAuth } from 'hooks/useAuth';

export const AppMenu: React.FC = () => {
  const { acessos } = useAuth();

  const items = menuItems
    .filter(item => item.public || acessos.includes(item.acessoId))
    .map(item => ({
      ...item,
      ...(item.type === 'submenu'
        ? {
            children: item.children.filter(
              child => item.public || acessos.includes(child.acessoId),
            ),
          }
        : {}),
    }))
    .filter(
      item =>
        item.type === 'menuItem' ||
        (item.type === 'submenu' && item.children.length > 0),
    );

  return (
    <>
      <MenuDesktop menuItems={items} />
      <MenuMobile menuItems={items} />
    </>
  );
};

import { ToolTip } from 'components/ToolTip';

import styles from './IconButton.module.scss';

type IconButtonProps = {
  title: string;
  className?: string;
  disabled?: boolean;
  toolTipDirection?: 'top' | 'bottom' | 'left' | 'right';
  icon: () => JSX.Element;
  onClick: () => void;
};

export const IconButton: React.FC<IconButtonProps> = ({
  title,
  icon: Icon,
  className = '',
  disabled,
  toolTipDirection,
  onClick,
}) => (
  <button
    type="button"
    className={`${styles['icon-button-container']} ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {disabled ? (
      <Icon />
    ) : (
      <ToolTip title={title} direction={toolTipDirection}>
        <Icon />
      </ToolTip>
    )}
  </button>
);

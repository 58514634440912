import { Logo } from './Logo';
import { NavbarList } from './NavbarList';
import { UserProfile } from './UserProfile';
import { Menu } from './menuItems';

import styles from './MenuDesktop.module.scss';

type MenuDesktopProps = {
  menuItems: Menu[];
};

export const MenuDesktop: React.FC<MenuDesktopProps> = ({ menuItems }) => {
  return (
    <header className={styles['header-container']}>
      <div className={styles['header-navbar-container']}>
        <Logo />
        <nav className={styles['navbar-container']}>
          <NavbarList items={menuItems} />
          <UserProfile />
        </nav>
      </div>
    </header>
  );
};

import { Layout } from 'components/Layout';
import { EditGrupoAcessoView } from 'views/grupo-acesso/edit';

export const EditGrupoAcessoPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Grupos de Acessos', href: '/grupo-acesso' },
          { label: 'Editar' },
        ],
      }}
    >
      <EditGrupoAcessoView />
    </Layout>
  );
};

import { useState } from 'react';

import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';

import { Form, FormFooter, Input } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

interface FidelidadeCreateQuery {
  fidelidadeCreate: {
    fidelidadeId: number;
    nome: string;
  };
}

interface FidelidadeCreateVariables {
  input: { nome: string };
}

const FIDELIDADE_CREATE_QUERY = gql`
  mutation ($input: FidelidadeCreateInput!) {
    fidelidadeCreate(input: $input) {
      fidelidadeId
      nome
    }
  }
`;

export const NewFidelidadeView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({ nome: '' });

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      nome: Yup.string().required('Nome obrigatório.'),
    }),
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { data, errors } = await graphqlAuth<
          FidelidadeCreateQuery,
          FidelidadeCreateVariables
        >({
          auth,
          setAuth,
          query: FIDELIDADE_CREATE_QUERY,
          variables: { input: { nome: values.nome } },
        });

        if (errors) {
          throw new GraphQLError('Falha ao criar fidelidade', errors);
        }

        successToast(
          `Fidelidade: ${data.fidelidadeCreate.nome}`,
          'Criado com sucesso!',
        );

        navigate('/fidelidades');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
      </Form>
    </>
  );
};

import { Layout } from 'components/Layout';
import { DetailPlanoView } from 'views/planos/detail';

export const DetailPlanoPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [{ label: 'Planos', href: '/planos' }, { label: 'Detalhes' }],
      }}
    >
      <DetailPlanoView />
    </Layout>
  );
};

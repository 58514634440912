import styles from './LoadingSpinner.module.scss';

type LoadingSpinnerProps = {
  size?: 'large' | 'medium' | 'small';
};

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 'medium',
}) => (
  <div className={`${styles['loading-spinner']} ${styles[`size-${size}`]}`} />
);

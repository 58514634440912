import { Layout } from 'components/Layout';
import { AuditoriaView } from 'views/auditoria/list';

export const AuditoriaPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Auditoria' }] }}>
      <AuditoriaView />
    </Layout>
  );
};

import { useEffect } from 'react';
import { useParams } from 'react-router';
import logoImg from 'assets/logo-dark.svg';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import {
  currencyFormat,
  dateFormat,
  dateFormatWithHours,
} from 'functions/formatters';
import { gql } from 'functions/gql';
import { cnpjMask, cpfMask, phoneMask } from 'functions/mask';

import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { EnderecoResult } from 'utils/types/Endereco';
import { RedeSocialEnum } from 'utils/enums/RedeSocialEnum';

import { Fallback } from 'components/Fallback';
import { PrintText } from 'components/Print/PrintText';
import { PrintImage } from 'components/Print/PrintImage';
import { FlexContainer } from 'components/FlexContainer';
import { PrintSection } from 'components/Print/PrintSection';
import { PrintFieldset } from 'components/Print/PrintFieldset';
import { PrintValidacao } from 'components/Print/PrintValidacao';

interface PrintPedidoPessoaJuridicaQueryVariables {
  id: { pedidoId: string };
}

interface PrintPedidoPessoaJuridicaQuery {
  pedido: {
    pedidoId: string;
    clientePessoaJuridicaCnpj: string;
    clientePessoaJuridicaRazaoSocial: string;
    clientePessoaJuridicaNomeFantasia: string;
    clientePessoaJuridicaInscricaoEstadual: string;
    clientePessoaJuridicaInscricaoMunicipal: string;
    clientePessoaJuridicaDataAbertura: string;
    clientePessoaJuridicaTipoRetencaoNotaFiscal: string;
    clientePessoaJuridicaRamoEmpresa: string;
    allPedidosRedesSociais: {
      redeSocial: RedeSocialEnum;
      link: string;
    }[];
    allTelefones: Telefone[];
    allEmails: Email[];
    clientePessoaJuridicaRepresentanteLegalNome: string;
    clientePessoaJuridicaRepresentanteLegalCpf: string;
    clientePessoaJuridicaRepresentanteLegalRg: string;
    clientePessoaJuridicaRepresentanteLegalDataNascimento: string;
    clientePessoaJuridicaRepresentanteLegalSexo: string;
    clientePessoaJuridicaRepresentanteLegalEstadoCivilId: string;
    enderecoInstalacao: EnderecoResult;
    enderecoCobranca: EnderecoResult;
    consulta: { dataConsulta: string; resultado: string; url: string };
    allPedidosPlanos: { planoId: string; nome: string; preco: number }[];
    diaVencimento: { diaVencimentoId: string; nome: string };
    fidelidade: { fidelidadeId: string; nome: string };
    allFinalidades: { finalidadeId: string; nome: string }[];
    rede: string;
    senha: string;
    status: { statusId: string; nome: string };
    validacaoCliente: number;
    allAnexos: { nome: string; url: string; mimetype: string }[];
    vendedor: { usuarioId: string; nome: string };
  };
}

const PRINT_PEDIDO_PESSOA_JURIDICA_QUERY = gql`
  query ($id: PedidoID!) {
    pedido(id: $id) {
      pedidoId
      clientePessoaJuridicaCnpj
      clientePessoaJuridicaRazaoSocial
      clientePessoaJuridicaNomeFantasia
      clientePessoaJuridicaInscricaoEstadual
      clientePessoaJuridicaInscricaoMunicipal
      clientePessoaJuridicaDataAbertura
      clientePessoaJuridicaTipoRetencaoNotaFiscal
      clientePessoaJuridicaRamoEmpresa
      allPedidosRedesSociais {
        redeSocial
        link
      }
      allTelefones {
        nome
        telefone
        whatsapp
      }
      allEmails {
        nome
        email
      }
      clientePessoaJuridicaRepresentanteLegalNome
      clientePessoaJuridicaRepresentanteLegalCpf
      clientePessoaJuridicaRepresentanteLegalRg
      clientePessoaJuridicaRepresentanteLegalDataNascimento
      clientePessoaJuridicaRepresentanteLegalSexo
      clientePessoaJuridicaRepresentanteLegalEstadoCivilId
      enderecoInstalacao {
        cep
        numero
        enderecoId
        logradouro
        bairro
        cidade {
          nome
          estado {
            nome
          }
        }
        complemento
        pontoReferencia
      }
      enderecoCobranca {
        cep
        numero
        enderecoId
        logradouro
        bairro
        cidade {
          nome
          estado {
            nome
          }
        }
        complemento
        pontoReferencia
      }
      consulta {
        dataConsulta
        resultado
        url
      }
      allPedidosPlanos {
        planoId
        nome
        preco
      }
      diaVencimento {
        diaVencimentoId
        nome
      }
      fidelidade {
        fidelidadeId
        nome
      }
      allFinalidades {
        finalidadeId
        nome
      }
      rede
      senha
      validacaoCliente
      allAnexos {
        nome
        url
        mimetype
      }
      status {
        statusId
        nome
      }
      vendedor {
        usuarioId
        nome
      }
    }
  }
`;

export const PrintPedidoPessoaJuridicaView: React.FC = () => {
  const params = useParams();
  const { auth, setAuth } = useAuth();

  const pedidoId = params.pedidoId || '';

  const query = useQueryAuth<
    PrintPedidoPessoaJuridicaQuery,
    PrintPedidoPessoaJuridicaQueryVariables
  >({
    auth,
    setAuth,
    query: PRINT_PEDIDO_PESSOA_JURIDICA_QUERY,
    variables: { id: { pedidoId } },
  });

  useEffect(() => {
    if (query.data) {
      const timer = setTimeout(() => {
        window.print();
      }, 1200);

      return () => clearTimeout(timer);
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const {
    data: { pedido },
  } = query;

  const allPedidoAnexos = pedido.allAnexos.filter(anexo =>
    anexo.mimetype.includes('image'),
  );

  return (
    <PrintSection>
      <FlexContainer>
        <img src={logoImg} width={40} height={40} alt="Fibratech" />
        <h2>Fibratech</h2>
        <p className="body4" style={{ marginLeft: 'auto' }}>
          Código do pedido: {pedido.pedidoId}
        </p>
      </FlexContainer>

      <FlexContainer>
        <PrintFieldset legend="Pedido">
          <PrintText label="Status" text={pedido.status.nome} />
          <PrintText label="Vendedor" text={pedido.vendedor?.nome} />
          <PrintText
            label="Validação"
            text={
              pedido.validacaoCliente > 0
                ? `${pedido.validacaoCliente}`
                : 'Não informado'
            }
          />
          <PrintValidacao />
        </PrintFieldset>

        <PrintFieldset legend="Consulta">
          <PrintText
            label="Status"
            text={pedido.consulta.resultado.replace(/[_]/g, ' ')}
          />
          <PrintText
            label="Data da consulta"
            text={dateFormatWithHours(pedido.consulta.dataConsulta)}
          />
        </PrintFieldset>
      </FlexContainer>

      <PrintFieldset legend="Dados">
        <PrintText
          label="CNPJ"
          text={cnpjMask(pedido.clientePessoaJuridicaCnpj)}
        />
        <PrintText
          label="Razão social"
          text={cpfMask(pedido.clientePessoaJuridicaRazaoSocial)}
        />
        <PrintText
          label="Nome fantasia"
          text={pedido.clientePessoaJuridicaNomeFantasia}
        />
        <PrintText
          label="Inscrição estadual"
          text={pedido.clientePessoaJuridicaInscricaoEstadual}
        />
        <PrintText
          label="Inscrição municipal"
          text={pedido.clientePessoaJuridicaInscricaoMunicipal}
        />
        <PrintText
          label="Data abertura"
          text={dateFormat(pedido.clientePessoaJuridicaDataAbertura)}
        />
        <PrintText
          label="Tipo retenção nota fiscal"
          text={pedido.clientePessoaJuridicaTipoRetencaoNotaFiscal}
        />
        <PrintText
          label="Ramo empresa"
          text={pedido.clientePessoaJuridicaRamoEmpresa}
        />

        <h4 style={{ fontWeight: 500, width: '100%' }}>Redes Sociais:</h4>

        {pedido.allPedidosRedesSociais.length > 0 ? (
          pedido.allPedidosRedesSociais.map((redeSocial, index) => (
            <PrintText
              key={`rede-social-${index}`}
              label={redeSocial.redeSocial?.toLocaleLowerCase()}
              text={redeSocial.link}
            />
          ))
        ) : (
          <PrintText label="" text="Nenhum telefone adicionado." />
        )}

        <h4 style={{ fontWeight: 500, width: '100%' }}>Telefones:</h4>

        {pedido.allTelefones.length > 0 ? (
          pedido.allTelefones.map((telefone, index) => (
            <PrintText
              key={`telefone-${index}`}
              label={telefone.nome}
              text={phoneMask(telefone.telefone)}
            />
          ))
        ) : (
          <PrintText label="" text="Nenhum telefone adicionado." />
        )}

        <h4 style={{ fontWeight: 500, width: '100%' }}>E-mails:</h4>

        {pedido.allEmails.length > 0 ? (
          pedido.allEmails.map((email, index) => (
            <PrintText
              key={`email-${index}`}
              label={email.nome}
              text={email.email}
            />
          ))
        ) : (
          <PrintText label="" text="Nenhum e-mail adicionado." />
        )}

        <h4 style={{ fontWeight: 500, width: '100%' }}>Representante Legal:</h4>

        <PrintText
          label="Nome"
          text={pedido.clientePessoaJuridicaRepresentanteLegalNome}
        />
        <PrintText
          label="CPF"
          text={cpfMask(pedido.clientePessoaJuridicaRepresentanteLegalCpf)}
        />
        <PrintText
          label="RG"
          text={pedido.clientePessoaJuridicaRepresentanteLegalRg}
        />
        <PrintText
          label="Data Nasc."
          text={dateFormat(
            pedido.clientePessoaJuridicaRepresentanteLegalDataNascimento,
          )}
        />
        <PrintText
          label="Sexo"
          text={pedido.clientePessoaJuridicaRepresentanteLegalSexo}
        />
        <PrintText
          label="Estado Civil"
          text={pedido.clientePessoaJuridicaRepresentanteLegalEstadoCivilId}
        />
      </PrintFieldset>

      <PrintFieldset legend="Endereço Instalação">
        <PrintText label="CEP" text={pedido.enderecoInstalacao.cep} />
        <PrintText label="Número" text={pedido.enderecoInstalacao.numero} />
        <PrintText
          label="Cidade"
          text={pedido.enderecoInstalacao?.cidade?.nome}
        />
        <PrintText
          label="Estado"
          text={pedido.enderecoInstalacao?.cidade?.estado?.nome}
        />
        <PrintText
          label="Logradouro"
          text={pedido.enderecoInstalacao.logradouro}
        />
        <PrintText label="Bairro" text={pedido.enderecoInstalacao.bairro} />
        <PrintText
          label="Complemento"
          text={pedido.enderecoInstalacao.complemento}
        />
        <PrintText
          label="Ponto referência"
          text={pedido.enderecoInstalacao.pontoReferencia}
        />
      </PrintFieldset>

      <PrintFieldset legend="Endereço Cobrança">
        <PrintText label="CEP" text={pedido.enderecoCobranca.cep} />
        <PrintText label="Número" text={pedido.enderecoCobranca.numero} />
        <PrintText
          label="Cidade"
          text={pedido.enderecoCobranca?.cidade?.nome}
        />
        <PrintText
          label="Estado"
          text={pedido.enderecoCobranca?.cidade?.estado?.nome}
        />
        <PrintText
          label="Logradouro"
          text={pedido.enderecoCobranca.logradouro}
        />
        <PrintText label="Bairro" text={pedido.enderecoCobranca.bairro} />
        <PrintText
          label="Complemento"
          text={pedido.enderecoCobranca.complemento}
        />
        <PrintText
          label="Ponto referência"
          text={pedido.enderecoCobranca.pontoReferencia}
        />
      </PrintFieldset>

      <PrintFieldset legend="Planos">
        {pedido.allPedidosPlanos.length > 0 ? (
          pedido.allPedidosPlanos.map((plano, index) => (
            <div key={`plano-${index}`} style={{ width: '100%' }}>
              <PrintText label="Plano" text={plano.nome} />
              <PrintText label="Preço" text={currencyFormat(plano.preco)} />
            </div>
          ))
        ) : (
          <PrintText label="" text="Nenhum e-mail adicionado." />
        )}
      </PrintFieldset>

      <PrintFieldset legend="Informações do Serviço">
        <PrintText label="Dia vencimento" text={pedido.diaVencimento.nome} />
        <PrintText label="Fidelidade" text={pedido.fidelidade.nome} />

        <h4 style={{ fontWeight: 500, width: '100%' }}>Finalidades:</h4>

        <div style={{ width: '100%' }}>
          {pedido.allFinalidades.length > 0 ? (
            pedido.allFinalidades.map((finalidade, index) => (
              <PrintText
                key={`finalidade-${index}`}
                label=""
                text={finalidade.nome}
              />
            ))
          ) : (
            <PrintText label="" text="Nenhuma finalidade adicionada." />
          )}
        </div>

        <PrintText label="Nome do wi-fi" text={pedido.rede} />
        <PrintText label="Senha" text={pedido.senha} />
      </PrintFieldset>

      <PrintFieldset legend="Anexos" breakPage>
        {allPedidoAnexos.length > 0 ? (
          allPedidoAnexos.map((anexo, index) => (
            <PrintImage key={`anexo-${index}`} {...anexo} />
          ))
        ) : (
          <PrintText label="" text="Nenhum anexo adicionado." />
        )}
      </PrintFieldset>
    </PrintSection>
  );
};

import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { useAuth } from 'hooks/useAuth';
import { Button } from 'components/Form';
import { Layout } from 'components/Layout';
import { ListPlanoView } from 'views/planos/list';

export const ListPlanoPage = () => {
  const { acessos } = useAuth();
  const navigate = useNavigate();

  return (
    <Layout
      breadcrumb={{ items: [{ label: 'Planos' }] }}
      component={
        acessos.includes('CADASTROS.PLANOS.INCLUIR') ? (
          <Button type="button" onClick={() => navigate('/planos/new')}>
            <FiPlus size={20} /> Novo
          </Button>
        ) : undefined
      }
    >
      <ListPlanoView />
    </Layout>
  );
};

import * as Yup from 'yup';

export const newUsuarioSchema = Yup.object({
  nome: Yup.string().required('Nome obrigatório.'),
  login: Yup.string()
    .min(6, 'Mínimo de 6 caracteres.')
    .required('Login obrigatório.'),
  senha: Yup.string()
    .min(6, 'Mínimo de 6 caracteres.')
    .required('Senha obrigatório.'),
  confirmarSenha: Yup.string()
    .min(6, 'Mínimo de 6 caracteres.')
    .test('match', 'As senhas não conferem.', function (confirmarSenha) {
      return confirmarSenha === this.parent.senha;
    })
    .required('Confirmar senha obrigatório.'),
  grupoAcesso: Yup.string().required('Grupo de Acesso obrigatório.'),
  perfil: Yup.string().required('Perfil obrigatório.'),
});

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';

import { useAuth } from 'hooks/useAuth';
import { useForm } from 'hooks/useForm';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { editGrupoAcessoSchema } from './utils/editGrupoAcessoSchema';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { Fallback } from 'components/Fallback';
import { TreeView } from 'components/TreeView';
import { Form, FormFooter, Input } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

interface Acesso {
  acessoId: string;
  nome: string;
  parentId: string;
}

interface TreeViewAcesso extends Acesso {
  checked: boolean;
  open: boolean;
}

interface GrupoAcessoDataQuery {
  acessos: { items: Acesso[] };
  grupoAcesso: {
    grupoAcessoId: string;
    nome: string;
    acessos: { items: Acesso[] };
  };
}

interface GrupoAcessoDataQueryVariables {
  id: { grupoAcessoId: string };
}

const GRUPO_ACESSO_DATA_QUERY = gql`
  query ($id: GrupoAcessoID!) {
    acessos {
      items {
        acessoId
        nome
        parentId
      }
    }
    grupoAcesso(id: $id) {
      grupoAcessoId
      nome
      acessos {
        items {
          acessoId
          nome
          parentId
        }
      }
    }
  }
`;

interface GrupoAcessoUpdateQuery {
  grupoAcessoUpdate: {
    grupoAcessoId: string;
    nome: string;
  };
}

interface GrupoAcessoUpdateVariables {
  id: { grupoAcessoId: string };
  input: {
    nome?: string;
    acessos?: { acessoId: string }[];
  };
}

const GRUPO_ACESSO_UPDATE_QUERY = gql`
  mutation ($id: GrupoAcessoID!, $input: GrupoAcessoUpdateInput!) {
    grupoAcessoUpdate(id: $id, input: $input) {
      grupoAcessoId
      nome
    }
  }
`;

export const EditGrupoAcessoView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [acessos, setAcessos] = useState<TreeViewAcesso[]>([]);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    nome: '',
  });

  const grupoAcessoId = params.grupoAcessoId || '';

  const Q = useQueryAuth<GrupoAcessoDataQuery, GrupoAcessoDataQueryVariables>({
    auth,
    setAuth,
    query: GRUPO_ACESSO_DATA_QUERY,
    variables: {
      id: { grupoAcessoId },
    },
  });

  useEffect(() => {
    if (Q.data) {
      setAcessos(
        Q.data.acessos.items.map(acesso => ({
          ...acesso,
          checked: !!Q.data?.grupoAcesso.acessos.items.find(
            item => item.acessoId === acesso.acessoId,
          ),
          open: true,
        })),
      );
      setFields({ ...fields, nome: Q.data.grupoAcesso.nome });
    }
  }, [Q.data]);

  if (Q.fallback) {
    return <Fallback errors={Q.errors} loading={Q.loading} />;
  }

  const handleSubmit = submitHandler({
    validateSchema: editGrupoAcessoSchema,
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const acessosSelecionados = acessos
          .filter(acesso => acesso.checked)
          .map(filtered => ({ acessoId: filtered.acessoId }));

        const { errors } = await graphqlAuth<
          GrupoAcessoUpdateQuery,
          GrupoAcessoUpdateVariables
        >({
          auth,
          setAuth,
          query: GRUPO_ACESSO_UPDATE_QUERY,
          variables: {
            id: {
              grupoAcessoId,
            },
            input: { nome: values.nome, acessos: acessosSelecionados },
          },
        });

        if (errors) {
          throw new GraphQLError('Falha ao alterar grupo de acesso', errors);
        }

        successToast('Grupo de acesso alterado com sucesso!');

        navigate('/grupo-acesso');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />

        <h4 className="heading4 text" style={{ margin: '1rem 0' }}>
          Selecione os Acessos abaixo:
        </h4>

        <TreeView items={acessos} setItems={newItems => setAcessos(newItems)} />
      </Form>
    </>
  );
};

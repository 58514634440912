import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Log } from 'utils/Log';
import { gql } from 'functions/gql';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { TabList } from 'components/TabList';
import { Fallback } from 'components/Fallback';
import { LogsTable } from 'components/LogsTable';
import { Button, Form, Input } from 'components/Form';

import styles from './detail.module.scss';

interface Acesso {
  acessoId: string;
  nome: string;
  parentId: string;
}

interface GrupoAcessoDetailQuery {
  grupoAcesso: {
    grupoAcessoId: string;
    nome: string;
    acessos: { items: Acesso[] };
    logs: { items: Log[] };
  };
}

interface GrupoAcessoDetailQueryVariables {
  id: { grupoAcessoId: string };
}

const GRUPO_ACESSO_DETAIL_QUERY = gql`
  query ($id: GrupoAcessoID!) {
    grupoAcesso(id: $id) {
      grupoAcessoId
      nome
      acessos {
        items {
          acessoId
          nome
          parentId
        }
      }
      logs {
        items {
          logId
          tipo
          descricao
          createdAt
          usuario {
            nome
            avatar {
              url
            }
          }
        }
      }
    }
  }
`;

export const DetailGrupoAcessoView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [fields, setFields] = useState({
    nome: '',
    acessos: [] as Acesso[],
  });

  const grupoAcessoId = params.grupoAcessoId || '';

  const query = useQueryAuth<
    GrupoAcessoDetailQuery,
    GrupoAcessoDetailQueryVariables
  >({
    auth,
    setAuth,
    query: GRUPO_ACESSO_DETAIL_QUERY,
    variables: { id: { grupoAcessoId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({
        nome: query.data.grupoAcesso.nome,
        acessos: query.data.grupoAcesso.acessos.items,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  return (
    <TabList labels={['Detalhes', 'Logs']}>
      <Form
        footerComponent={
          <Button
            type="button"
            style={{ alignSelf: 'flex-end', width: '200px' }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        }
      >
        <Input readOnly name="nome" label="Nome" value={fields.nome} />

        <h4 className="heading4 text" style={{ margin: '1rem 0' }}>
          Acessos:
        </h4>

        <div className={styles['acessos-list-container']}>
          <ul>
            {fields.acessos.map((acesso, index) => {
              const isParentChild = acesso.acessoId.split('.').length === 3;

              return (
                <li
                  key={index}
                  className={`${
                    isParentChild
                      ? styles['is-parent-child']
                      : !!acesso.parentId
                      ? styles['has-parent']
                      : ''
                  }`}
                >
                  <p className="body2 text">{acesso.nome}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </Form>
      <LogsTable logs={query.data.grupoAcesso.logs.items} />
    </TabList>
  );
};

import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { diaVencimentoSelect } from './utils/diaVencimentoSelect';

import { Fallback } from 'components/Fallback';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { Form, FormFooter, Input, Select } from 'components/Form';

interface DiaVencimentoUpdateQuery {
  diaVencimentoUpdate: { diaVencimentoId: string };
}

interface DiaVencimentoUpdateVariables {
  id: { diaVencimentoId: string };
  input: {
    nome?: string;
    diaVencimentoId?: string;
  };
}

const DIA_VENCIMENTO_UPDATE_QUERY = gql`
  mutation ($id: DiaVencimentoID!, $input: DiaVencimentoUpdateInput!) {
    diaVencimentoUpdate(id: $id, input: $input) {
      diaVencimentoId
      nome
    }
  }
`;

interface DiaVencimentoDataQueryVariables {
  id: { diaVencimentoId: string };
}

interface DiaVencimentoDataQuery {
  diaVencimento: {
    diaVencimentoId: string;
    nome: string;
  };
}

const DIA_VENCIMENTO_DATA_QUERY = gql`
  query ($id: DiaVencimentoID!) {
    diaVencimento(id: $id) {
      diaVencimentoId
      nome
    }
  }
`;

export const EditDiaVencimentoView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    diaVencimento: '',
    descricao: '',
  });

  const diaVencimentoId = params.diaVencimentoId || '';

  const query = useQueryAuth<
    DiaVencimentoDataQuery,
    DiaVencimentoDataQueryVariables
  >({
    auth,
    setAuth,
    query: DIA_VENCIMENTO_DATA_QUERY,
    variables: { id: { diaVencimentoId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({
        ...fields,
        descricao: query.data.diaVencimento.nome,
        diaVencimento: query.data.diaVencimento.diaVencimentoId,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      diaVencimento: Yup.string().required('Dia Vencimento obrigatório.'),
      descricao: Yup.string().required('Descrição obrigatório.'),
    }),
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { errors } = await graphqlAuth<
          DiaVencimentoUpdateQuery,
          DiaVencimentoUpdateVariables
        >({
          auth,
          setAuth,
          query: DIA_VENCIMENTO_UPDATE_QUERY,
          variables: {
            id: { diaVencimentoId },
            input: {
              nome: values.descricao,
              diaVencimentoId: values.diaVencimento,
            },
          },
        });

        if (errors) {
          throw new GraphQLError('Falha ao alterar dia de vencimento', errors);
        }

        successToast('Dia vencimento alterado com sucesso!');

        navigate('/dia-vencimento');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
        columns={2}
      >
        <Select
          autoFocus
          label="Dia Vencimento"
          name="diaVencimento"
          onChange={value => setFields({ ...fields, diaVencimento: value })}
          value={fields.diaVencimento}
          error={errors.diaVencimento}
          options={diaVencimentoSelect}
        />

        <Input
          name="descricao"
          label="Descrição"
          error={errors.descricao}
          value={fields.descricao}
          onChange={value => setFields({ ...fields, descricao: value })}
        />
      </Form>
    </>
  );
};

import { cepMask } from 'functions/mask';
import { EnderecoResult } from 'utils/types/Endereco';

import { Fieldset, Form, Input } from 'components/Form';

interface EnderecosProps {
  enderecoInstalacao: EnderecoResult;
  enderecoCobranca: EnderecoResult;
}

export const Enderecos: React.FC<EnderecosProps> = props => {
  return (
    <Form>
      <Fieldset legend="Endereço Instalação" columns={3}>
        <Input
          readOnly
          className="column-start"
          name="cep"
          label="CEP"
          value={cepMask(props.enderecoInstalacao.cep)}
        />
        <Input
          readOnly
          name="numero"
          label="Número"
          value={props.enderecoInstalacao.numero}
        />
        <Input
          readOnly
          name="cidade"
          label="Cidade"
          value={props.enderecoInstalacao.cidade.nome}
        />
        <Input
          readOnly
          name="estado"
          label="Estado"
          value={props.enderecoInstalacao.cidade.estado.nome}
        />
        <Input
          readOnly
          name="logradouro"
          label="Logradouro"
          value={props.enderecoInstalacao.logradouro}
        />
        <Input
          readOnly
          name="bairro"
          label="Bairro"
          value={props.enderecoInstalacao.bairro}
        />
        <Input
          readOnly
          name="complemento"
          label="Complemento"
          value={props.enderecoInstalacao.complemento}
        />
        <Input
          readOnly
          name="pontoReferencia"
          label="Ponto de Referência"
          value={props.enderecoInstalacao.pontoReferencia}
        />
      </Fieldset>
      <Fieldset legend="Endereço Cobrança" columns={3}>
        <Input
          readOnly
          className="column-start"
          name="cep"
          label="CEP"
          value={cepMask(props.enderecoCobranca.cep)}
        />
        <Input
          readOnly
          name="numero"
          label="Número"
          value={props.enderecoCobranca.numero}
        />
        <Input
          readOnly
          name="cidade"
          label="Cidade"
          value={props.enderecoCobranca.cidade.nome}
        />
        <Input
          readOnly
          name="estado"
          label="Estado"
          value={props.enderecoCobranca.cidade.estado.nome}
        />
        <Input
          readOnly
          name="logradouro"
          label="Logradouro"
          value={props.enderecoCobranca.logradouro}
        />
        <Input
          readOnly
          name="bairro"
          label="Bairro"
          value={props.enderecoCobranca.bairro}
        />
        <Input
          readOnly
          name="complemento"
          label="Complemento"
          value={props.enderecoCobranca.complemento}
        />
        <Input
          readOnly
          name="pontoReferencia"
          label="Ponto de Referência"
          value={props.enderecoCobranca.pontoReferencia}
        />
      </Fieldset>
    </Form>
  );
};

import { dateFormatWithHours } from 'functions/formatters';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

import styles from './PedidoDetailConsulta.module.scss';

interface PedidoDetailConsultaProps {
  dataConsulta: string;
  resultado: string;
  url: string;
}

export const PedidoDetailConsulta: React.FC<
  PedidoDetailConsultaProps
> = props => {
  return (
    <section className={styles['consulta-section']}>
      {props.resultado === 'SEM_RESTRICAO' && (
        <div>
          <FiCheckCircle size={72} color="var(--success)" />
          <h2 className="success">Cliente Aprovado</h2>
        </div>
      )}

      {props.resultado === 'REQUER_ANALISE' && (
        <div>
          <FiXCircle size={72} color="var(--error)" />
          <h2 className="error">Cliente Para Análise</h2>
        </div>
      )}

      <p className="body2 text">
        Data da Consulta:
        <span> {dateFormatWithHours(props.dataConsulta)}</span>
      </p>

      <a
        href={props.url}
        target="_blank"
        rel="noreferrer"
        title="Clique para ver os detalhes da consulta."
      >
        Ver Detalhes
      </a>
    </section>
  );
};

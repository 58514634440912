import * as Yup from 'yup';

import { useAuth } from 'hooks/useAuth';
import { useForm } from 'hooks/useForm';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { Button, Form, Textarea } from 'components/Form';
import { ComunicacoesTable } from 'components/ComunicacoesTable';

interface ComunicacaoInternaCreateVariables {
  input: {
    pedidoId: string;
    descricao: string;
  };
}

interface ComunicacaoInternaCreateQuery {
  comunicacaoInternaCreate: {
    comunicacaoInternaId: string;
  };
}

const COMUNICACAO_INTERNA_CREATE_QUERY = gql`
  mutation ($input: ComunicacaoInternaCreateInput!) {
    comunicacaoInternaCreate(input: $input) {
      comunicacaoInternaId
    }
  }
`;

interface Comunicacao {
  comunicacaoInternaId: string;
  descricao: string;
  createdAt: string;
  usuario: {
    usuarioId: string;
    nome: string;
  };
}

interface PedidoComunicacoesProps {
  pedidoId: string;
  comunicacoes: Comunicacao[];
  refetchQuery: () => void;
}

export const PedidoComunicacoes: React.FC<PedidoComunicacoesProps> = ({
  pedidoId,
  comunicacoes,
  refetchQuery,
}) => {
  const { auth, setAuth } = useAuth();
  const { fields, setFields, submitHandler, errors, resetForm } = useForm({
    descricao: '',
  });

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      descricao: Yup.string().required('Descrição obrigatório.'),
    }),
    callback: async values => {
      const { errors } = await graphqlAuth<
        ComunicacaoInternaCreateQuery,
        ComunicacaoInternaCreateVariables
      >({
        query: COMUNICACAO_INTERNA_CREATE_QUERY,
        variables: {
          input: {
            pedidoId,
            descricao: values.descricao,
          },
        },
        auth,
        setAuth,
      });

      if (errors) {
        throw new GraphQLError(
          'Erro ao adicionar comunicação interna.',
          errors,
        );
      }

      successToast('Comunicação interna adicionada com sucesso!');
      resetForm();
      refetchQuery();
    },
  });

  return (
    <section>
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <Button
            type="submit"
            background="success"
            style={{ width: 'max-content', marginLeft: 'auto' }}
          >
            Incluir comunicação
          </Button>
        }
      >
        <h6 className="heading6 title">Incluir nova comunicação</h6>
        <Textarea
          label="Descrição"
          name="descricao"
          error={errors?.descricao}
          value={fields.descricao}
          onChange={value => setFields({ ...fields, descricao: value })}
        />
      </Form>
      <ComunicacoesTable comunicacoes={comunicacoes} />
    </section>
  );
};

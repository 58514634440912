import { useState } from 'react';
import _ from 'lodash';

import { useAuth } from 'hooks/useAuth';
import { useForm } from 'hooks/useForm';
import { useQueryAuth } from 'hooks/useQueryAuth';
import { Filter } from 'utils/Filter';
import { gql } from 'functions/gql';

import { Fallback } from 'components/Fallback';
import { TableFilter } from 'components/TableFilter';
import { Form, FormFooter, Input, Select } from 'components/Form';

interface MeusPedidosFilterQuery {
  usuarios: { items: { usuarioId: string; nome: string; perfilId: string }[] };
  statuses: { items: { statusId: string; nome: string }[] };
}

const MEUS_PEDIDOS_FILTER_QUERY = gql`
  query {
    usuarios(
      filter: {
        equals: { ativo: true }
        includes: { perfilId: [VENDEDOR, TECNICO] }
      }
    ) {
      items {
        usuarioId
        nome
        perfilId
      }
    }
    statuses {
      items {
        statusId
        nome
      }
    }
  }
`;

interface MeusPedidosFilterProps {
  isFiltering: boolean;
  onFilter: (values: Filter<Record<string, any>> | null) => void;
  onClear: () => void;
}

export const MeusPedidosFilter: React.FC<MeusPedidosFilterProps> = ({
  isFiltering,
  onFilter,
  onClear,
}) => {
  const { auth, setAuth } = useAuth();
  const [isVisible, setIsVisible] = useState(false);
  const { fields, setFields, submitHandler } = useForm({
    'equals.pedidoId': '',
    'includes.statusId': '',
    'includes.tipoPessoa': '',
    'includes.vendedorId': '',
    'includes.tecnicoId': '',
  });

  const query = useQueryAuth<MeusPedidosFilterQuery>({
    auth,
    setAuth,
    query: MEUS_PEDIDOS_FILTER_QUERY,
  });

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const { data } = query;

  const handleSubmit = submitHandler({
    callback: async values => {
      try {
        const parsed = Object.fromEntries(
          Object.entries(values)
            .map(entry => [entry[0], entry[1] || undefined])
            .filter(([_, value]) => value != null),
        );

        const filter: Record<string, any> = Object.keys(parsed).reduce(
          (result, key) => _.set(result, key, parsed[key]),
          {} as Record<string, any>,
        );

        if (Object.keys(filter).length === 0) {
          onFilter(null);
          return;
        }

        onFilter(filter);
      } finally {
        setIsVisible(false);
      }
    },
  });

  return (
    <TableFilter
      title="Filtragem Meus Pedidos"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isFiltering={isFiltering}
      onClear={onClear}
      onClose={() => setIsVisible(false)}
    >
      <Form
        columns={2}
        onSubmit={handleSubmit}
        footerComponent={<FormFooter onCancel={() => setIsVisible(false)} />}
      >
        <Input
          autoFocus
          name="equals.pedidoId"
          label="Cód. Pedido (ID)"
          value={fields['equals.pedidoId']}
          onChange={value => setFields({ ...fields, 'equals.pedidoId': value })}
        />
        <Select
          className="column-start"
          label="Tipo Pessoa"
          name="includes.tipoPessoa"
          value={fields['includes.tipoPessoa']}
          onChange={value =>
            setFields({ ...fields, 'includes.tipoPessoa': value })
          }
          options={[
            { label: 'Física', value: 'PESSOA_FISICA' },
            { label: 'Jurídica', value: 'PESSOA_JURIDICA' },
          ]}
        />
        <Select
          label="Status do Pedido"
          name="includes.statusId"
          value={fields['includes.statusId']}
          onChange={value =>
            setFields({ ...fields, 'includes.statusId': value })
          }
          options={data.statuses.items.map(({ nome, statusId }) => ({
            label: nome,
            value: statusId,
          }))}
        />
        <Select
          label="Vendedor"
          name="includes.vendedorId"
          value={fields['includes.vendedorId']}
          onChange={value =>
            setFields({ ...fields, 'includes.vendedorId': value })
          }
          options={data.usuarios.items
            .filter(usuario => usuario.perfilId === 'VENDEDOR')
            .map(({ nome, usuarioId }) => ({
              label: nome,
              value: usuarioId,
            }))}
        />
        <Select
          label="Técnico"
          name="includes.tecnicoId"
          value={fields['includes.tecnicoId']}
          onChange={value =>
            setFields({ ...fields, 'includes.tecnicoId': value })
          }
          options={data.usuarios.items
            .filter(usuario => usuario.perfilId === 'TECNICO')
            .map(({ nome, usuarioId }) => ({
              label: nome,
              value: usuarioId,
            }))}
        />
      </Form>
    </TableFilter>
  );
};

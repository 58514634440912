import styles from './PedidosFilterByStatus.module.scss';

interface PedidosFilterByStatusProps {
  filterStatus: string[];
  onFilterStatus: (filterStatusProps: string[]) => void;
}

export const PedidosFilterByStatus: React.FC<PedidosFilterByStatusProps> = ({
  filterStatus = [],
  onFilterStatus: onFilter,
}) => {
  const isAllStatus = filterStatus.includes('TODOS');

  const toggleStatus = (statusId: string) => {
    if (statusId === 'TODOS') {
      onFilter(['TODOS']);
      return;
    }

    if (filterStatus.includes(statusId)) {
      const items = filterStatus
        .filter(item => item !== statusId)
        .filter(item => item !== 'TODOS');

      onFilter(items.length > 0 ? items : ['TODOS']);
      return;
    }

    onFilter([...filterStatus.filter(item => item !== 'TODOS'), statusId]);
  };

  return (
    <div className={styles['filter-pedidos-container']}>
      <h5 className="heading5 title">Status do Pedido:</h5>

      <div className={styles['filter-button-container']}>
        <button
          className={isAllStatus ? styles['active-filter'] : ''}
          onClick={() => toggleStatus('TODOS')}
        >
          Todos
        </button>
        <button
          className={
            filterStatus.includes('PENDENTE_ANALISE_CREDITO') || isAllStatus
              ? styles['active-filter']
              : ''
          }
          onClick={() => toggleStatus('PENDENTE_ANALISE_CREDITO')}
        >
          Pendente Análise de Crédito
        </button>
        <button
          className={
            filterStatus.includes('PENDENTE_ATIVACAO') || isAllStatus
              ? styles['active-filter']
              : ''
          }
          onClick={() => toggleStatus('PENDENTE_ATIVACAO')}
        >
          Pendente Ativação
        </button>
        <button
          className={
            filterStatus.includes('PENDENTE_CADASTRO_RADIUS') || isAllStatus
              ? styles['active-filter']
              : ''
          }
          onClick={() => toggleStatus('PENDENTE_CADASTRO_RADIUS')}
        >
          Pendente Cadastro Radius
        </button>
        <button
          className={
            filterStatus.includes('PENDENTE_CONFIRMACAO') || isAllStatus
              ? styles['active-filter']
              : ''
          }
          onClick={() => toggleStatus('PENDENTE_CONFIRMACAO')}
        >
          Pendente Confirmação
        </button>
        <button
          className={
            filterStatus.includes('PENDENTE_CONSULTA_SPC_SERASA') || isAllStatus
              ? styles['active-filter']
              : ''
          }
          onClick={() => toggleStatus('PENDENTE_CONSULTA_SPC_SERASA')}
        >
          Pendente Consulta SPC/Serasa
        </button>
        <button
          className={
            filterStatus.includes('INSTALADO') || isAllStatus
              ? styles['active-filter']
              : ''
          }
          onClick={() => toggleStatus('INSTALADO')}
        >
          Instalado
        </button>
        <button
          className={
            filterStatus.includes('CANCELADO') || isAllStatus
              ? styles['active-filter']
              : ''
          }
          onClick={() => toggleStatus('CANCELADO')}
        >
          Cancelado
        </button>
      </div>
    </div>
  );
};

import { Layout } from 'components/Layout';
import { NewOperadoraView } from 'views/operadoras/new';

export const NewOperadoraPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Operadoras', href: '/operadoras' },
          { label: 'Novo' },
        ],
      }}
    >
      <NewOperadoraView />
    </Layout>
  );
};

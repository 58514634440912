import { useState } from 'react';

import _ from 'lodash';
import * as Yup from 'yup';
import dot from 'dot-object';

type SubmitHandlerProps<T> = {
  validateSchema?: Yup.AnyObjectSchema;
  callback: (formFields: T) => Promise<void>;
};

export const useForm = <T>(initialData: T) => {
  const [fields, setFields] = useState<T>(initialData);
  const [errors, setErrors] = useState<any>({});

  const validate = async (
    schema: Yup.AnyObjectSchema,
    fields: Record<string, any>,
  ) => {
    try {
      setErrors({});

      const validFields = await schema.validate(fields, { abortEarly: false });

      return validFields;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let validationErrors = {};

        err.inner.forEach(error => {
          if (error.path) {
            if (/^\[".+"\]$/.test(error.path)) {
              validationErrors = _.merge(
                validationErrors,
                dot.object({
                  [error.path.replace(/^\["(.+)"\]$/, '$1')]: error.message,
                }),
              );
            } else {
              validationErrors = _.merge(
                validationErrors,
                dot.object({ [error.path]: error.message }),
              );
            }
          }
        });

        console.log({ validationErrors });

        setErrors(validationErrors);
      }

      throw err;
    }
  };

  const submitHandler = ({
    validateSchema,
    callback,
  }: SubmitHandlerProps<T>) => {
    return async () => {
      try {
        let formFields = fields;

        if (validateSchema) {
          formFields = await validate(validateSchema, fields);
        }

        await callback(formFields);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          console.error(err);
          return;
        }

        throw err;
      }
    };
  };

  const resetForm = () => {
    setFields(initialData);
    setErrors({});
  };

  return {
    fields,
    setFields,
    resetForm,
    errors,
    submitHandler,
  };
};

import { Input } from './Form/Input';
import { Button } from './Form/Button';

import styles from './EmailList.module.scss';
import { FiX } from 'react-icons/fi';
import { FlexContainer } from './FlexContainer';

type Email = {
  email: string;
  nome: string;
};

type EmailListProps = {
  className?: string;
  errors?: any[];
  emails: Email[];
  setNewEmails: (newEmails: Email[]) => void;
};

export const EmailList: React.FC<EmailListProps> = ({
  className,
  errors,
  emails,
  setNewEmails,
}) => {
  const removeEmail = (index: number) => {
    if (emails.length > 1) {
      setNewEmails(emails.filter((_, i) => index !== i));
      return;
    }

    setNewEmails([{ nome: '', email: '' }]);
  };

  return (
    <>
      {emails.map((email, index) => (
        <section
          key={`email-${index}`}
          className={`${styles['email-section']} ${className ? className : ''}`}
        >
          <Input
            placeholder="Ex: E-mail pessoal"
            name={`email-nome-${index}`}
            label="Descrição E-mail"
            error={errors && errors[index]?.nome}
            value={email.nome}
            onChange={value => {
              const newEmails = [...emails];

              newEmails[index] = { ...newEmails[index], nome: value };

              setNewEmails(newEmails);
            }}
          />
          <FlexContainer className={styles['align-end']}>
            <Input
              type="email"
              name={`email-${index}`}
              label="E-mail"
              error={errors && errors[index]?.email}
              value={email.email}
              onChange={value => {
                const newEmails = [...emails];

                newEmails[index] = { ...newEmails[index], email: value };

                setNewEmails(newEmails);
              }}
            />
            <button
              type="button"
              style={errors ? { alignSelf: 'center' } : {}}
              onClick={() => removeEmail(index)}
            >
              <FiX size={22} />
            </button>
          </FlexContainer>
        </section>
      ))}

      <Button
        type="button"
        background="secondary"
        style={{ width: 'max-content' }}
        onClick={() => setNewEmails([...emails, { nome: '', email: '' }])}
      >
        Novo E-mail
      </Button>
    </>
  );
};

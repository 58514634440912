import { Layout } from 'components/Layout';
import { NewPlanoView } from 'views/planos/new';

export const NewPlanoPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [{ label: 'Planos', href: '/planos' }, { label: 'Novo' }],
      }}
    >
      <NewPlanoView />
    </Layout>
  );
};

import { Layout } from 'components/Layout';
import { PedidosInstalacoesView } from 'views/tecnico/pedidos/list';

export const PedidosInstalacoesPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Pedidos Instalações' }] }}>
      <PedidosInstalacoesView />
    </Layout>
  );
};

import { Fallback } from 'components/Fallback';
import { Textarea } from 'components/Form';
import { dateFormatWithHours } from 'functions/formatters';
import { gql } from 'functions/gql';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';
import { Modal } from './Modal';

import styles from './ModalDetalheComunicacao.module.scss';

interface ComunicacaoInternaDetailVariables {
  id: { comunicacaoInternaId: string };
}

interface ComunicacaoInternaDetailQuery {
  comunicacaoInterna: {
    comunicacaoInternaId: string;
    descricao: string;
    createdAt: string;
    usuario: {
      usuarioId: string;
      nome: string;
      perfilId: string;
      avatar: { url: string };
    };
  };
}

const COMUNICACAO_INTERNA_DETAIL_QUERY = gql`
  query ($id: ComunicacaoInternaID!) {
    comunicacaoInterna(id: $id) {
      comunicacaoInternaId
      descricao
      createdAt
      usuario {
        usuarioId
        nome
        perfilId
        avatar {
          url
        }
      }
    }
  }
`;

interface ModalDetalheComunicacaoProps {
  comunicacaoInternaId: string;
  onClose: () => void;
}

export const ModalDetalheComunicacao: React.FC<
  ModalDetalheComunicacaoProps
> = ({ comunicacaoInternaId, onClose }) => {
  const { auth, setAuth } = useAuth();

  const query = useQueryAuth<
    ComunicacaoInternaDetailQuery,
    ComunicacaoInternaDetailVariables
  >({
    query: COMUNICACAO_INTERNA_DETAIL_QUERY,
    variables: {
      id: { comunicacaoInternaId },
    },
    auth,
    setAuth,
  });

  return (
    <Modal title="Detalhes da comunicação" onClose={onClose} size="lg">
      {query.fallback && (
        <Fallback loading={query.loading} errors={query.errors} />
      )}

      {query.data && (
        <>
          <Textarea
            readOnly
            label="Descrição"
            name="descricao"
            value={query.data?.comunicacaoInterna.descricao || ''}
          />

          <article className={styles['info-container']}>
            <h5 className="heading5 title">Informações</h5>

            <p className="body2 text">
              Criado em:{' '}
              {dateFormatWithHours(query.data.comunicacaoInterna.createdAt)}
            </p>

            <p className="body2 text">Por:</p>

            <div className={styles['user']}>
              <figure>
                <img
                  src={query.data.comunicacaoInterna.usuario.avatar.url}
                  alt={query.data.comunicacaoInterna.usuario.nome}
                />
              </figure>
              <div>
                <p className="body2 text">
                  {query.data.comunicacaoInterna.usuario.nome}
                </p>
                <span className="caption2 primary">
                  {query.data.comunicacaoInterna.usuario.perfilId}
                </span>
              </div>
            </div>
          </article>
        </>
      )}
    </Modal>
  );
};

import { Layout } from 'components/Layout';
import { NewFidelidadeView } from 'views/fidelidades/new';

export const NewFidelidadePage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Fidelidade', href: '/fidelidades' },
          { label: 'Novo' },
        ],
      }}
    >
      <NewFidelidadeView />
    </Layout>
  );
};

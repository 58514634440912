import { AuthProps } from 'contexts/AuthContext';
import { PerfilEnum } from 'utils/enums/PerfilEnum';
import { gql } from './gql';
import {
  graphql,
  graphqlFormData,
  GraphQLFormDataProps,
  GraphQLProps,
  GraphQLResult,
} from './graphql';

export interface QueryRefreshToken {
  auth: {
    refreshToken: {
      refreshToken: string;
      accessToken: string;
      expiresAt: string;
      usuario: {
        usuarioId: string;
        login: string;
        nome: string;
        resetar: boolean;
        perfilId: PerfilEnum;
        avatar: {
          url: string;
        };
        grupoAcesso: {
          grupoAcessoId: string;
          nome: string;
          acessos: {
            totalCount: number;
            items: {
              acessoId: string;
              nome: string;
            }[];
          };
        };
      };
    };
  };
}

export interface QueryRefreshTokenVariables {
  input: {
    refreshToken: string;
  };
}

export const QUERY_REFRESH_TOKEN = gql`
  mutation ($input: RefreshTokenInput!) {
    auth {
      refreshToken(input: $input) {
        refreshToken
        accessToken
        expiresAt
        usuario {
          usuarioId
          login
          nome
          resetar
          perfilId
          avatar {
            url
          }
          grupoAcesso {
            grupoAcessoId
            nome
            acessos {
              totalCount
              items {
                acessoId
                nome
              }
            }
          }
        }
      }
    }
  }
`;

export interface GraphQLAuthProps<Variables> extends GraphQLProps<Variables> {
  auth: AuthProps | null;
  setAuth: (auth: AuthProps | null) => void;
}

export interface GraphQLFormDataAuthProps<Variables>
  extends GraphQLFormDataProps<Variables> {
  auth: AuthProps | null;
  setAuth: (auth: AuthProps | null) => void;
}

export const graphqlAuth = async <
  T = Record<string, any>,
  Variables = Record<string, any>,
>({
  query,
  variables,
  headers,
  auth,
  setAuth,
}: GraphQLAuthProps<Variables>): Promise<GraphQLResult<T>> => {
  if (!auth) {
    throw new Error(`GrahQLAuthError: auth is not set`);
  }

  let accessToken = auth.accessToken;

  if (Date.now() > new Date(auth.expiresAt).getTime()) {
    const { data, errors } = await graphql<
      QueryRefreshToken,
      QueryRefreshTokenVariables
    >({
      query: QUERY_REFRESH_TOKEN,
      variables: {
        input: {
          refreshToken: auth.refreshToken,
        },
      },
      headers,
    });

    if (!data || errors) {
      setAuth(null);

      throw new Error(`AuthenticationError: Failed to refresh token`);
    }

    setAuth(data.auth.refreshToken);

    accessToken = data.auth.refreshToken.accessToken;
  }

  return graphql({
    query,
    variables,
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const graphqlFormDataAuth = async <
  T = Record<string, any>,
  Variables = Record<string, any>,
>({
  query,
  variables,
  headers,
  files,
  auth,
  setAuth,
}: GraphQLFormDataAuthProps<Variables>): Promise<GraphQLResult<T>> => {
  if (!auth) {
    throw new Error(`GrahQLAuthError: auth is not set`);
  }

  let accessToken = auth.accessToken;

  if (Date.now() > new Date(auth.expiresAt).getTime()) {
    const { data, errors } = await graphql<
      QueryRefreshToken,
      QueryRefreshTokenVariables
    >({
      query: QUERY_REFRESH_TOKEN,
      variables: {
        input: {
          refreshToken: auth.refreshToken,
        },
      },
      headers,
    });

    if (!data || errors) {
      setAuth(null);

      throw new Error(`AuthenticationError: Failed to refresh token`);
    }

    setAuth(data.auth.refreshToken);

    accessToken = data.auth.refreshToken.accessToken;
  }

  return graphqlFormData({
    query,
    variables,
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
    files,
  });
};

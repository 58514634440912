import { useEffect, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { graphqlFormDataAuth } from 'functions/graphqlAuth';

import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { GraphQLError } from 'utils/GraphQLError';
import { RedeSocial } from 'utils/types/RedeSocial';
import { successToast, warnToast } from 'utils/toast';
import { PedidoAnexo } from 'utils/types/PedidoAnexo';
import { PedidoPlano } from 'utils/types/PedidoPlano';
import { PedidoTecnico } from 'utils/types/PedidoTecnico';
import { TipoPessoaEnum } from 'utils/enums/TipoPessoaEnum';
import { EstadoCivilEnum } from 'utils/enums/EstadoCivilEnum';
import { PedidoMaterial } from 'utils/types/PedidoMaterial';
import { RedeSocialEnum } from 'utils/enums/RedeSocialEnum';
import { PedidoStatusEnum } from 'utils/enums/PedidoStatusEnum';
import { PedidoFidelidade } from 'utils/types/PedidoFidelidade';
import { PedidoFinalidade } from 'utils/types/PedidoFinalidade';
import { EnderecoInput, EnderecoResult } from 'utils/types/Endereco';
import { PedidoDiaVencimento } from 'utils/types/PedidoDiaVencimento';
import { auditarPedidoPessoaJuridicaSchema } from '../utils/auditarPedidoPessoaJuridicaSchema';
import {
  parseFieldsToQueryVariables,
  parseQueryData,
} from '../utils/auditarPedidoPessoaJuridicaUtils';

import { TabList } from 'components/TabList';
import { Fallback } from 'components/Fallback';
import { Fieldset, Form, FormFooter } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { FieldsetPlanos } from '../components/FieldsetPlanos';
import { FieldsetAnexos } from '../components/FieldsetAnexos';
import { PedidoComunicacoes } from '../components/PedidoComunicacoes';
import { FieldsetPedidoDados } from './components/FieldsetPedidoDados';
import { FieldsetPedidoConsulta } from '../components/FieldsetPedidoConsulta';
import { FieldsetEnderecoCobranca } from '../components/FieldsetEnderecoCobranca';
import { PedidoInstalacaoAuditoria } from '../components/PedidoInstalacaoAuditoria';
import { FieldsetEnderecoInstalacao } from '../components/FieldsetEnderecoInstalacao';
import { FieldsetInformacoesServico } from '../components/FieldsetInformacoesServico';
import { FieldsetInformacoesTecnicas } from '../components/FieldsetInformacoesTecnicas';
import { FieldsetDadosPedido } from '../components/FieldsetDadosPedido';

export interface PedidoAuditarQueryVariables {
  id: { pedidoId: string };
  input: {
    clientePessoaJuridicaCnpj?: string;
    clientePessoaJuridicaRazaoSocial?: string;
    clientePessoaJuridicaNomeFantasia?: string;
    clientePessoaJuridicaInscricaoEstadual?: string;
    clientePessoaJuridicaInscricaoMunicipal?: string;
    clientePessoaJuridicaDataAbertura?: string;
    clientePessoaJuridicaTipoRetencaoNotaFiscal?: string;
    clientePessoaJuridicaRamoEmpresa?: string;
    redesSociais: RedeSocial[];
    telefones?: Telefone[];
    emails?: Email[];
    clientePessoaJuridicaRepresentanteLegalNome?: string;
    clientePessoaJuridicaRepresentanteLegalCpf?: string;
    clientePessoaJuridicaRepresentanteLegalRg?: string;
    clientePessoaJuridicaRepresentanteLegalDataNascimento?: string;
    clientePessoaJuridicaRepresentanteLegalSexo?: string;
    clientePessoaJuridicaRepresentanteLegalEstadoCivilId?: EstadoCivilEnum;
    enderecoInstalacao?: EnderecoInput;
    enderecoCobranca?: EnderecoInput;
    consultaId?: string;
    planos?: { planoId: string }[];
    diaVencimentoId?: string;
    fidelidadeId?: string;
    finalidades?: { finalidadeId: string }[];
    rede?: string;
    senha?: string;
    pontoAdicional: boolean;
    statusId?: PedidoStatusEnum;
    tipoPessoa?: TipoPessoaEnum;
    vendedorId?: string;
    validacaoCliente?: number;
    anexos?: File[];
    tecnicoId: string;
    dataAgendamentoInstalacao: string;
  };
}

interface PedidoAuditarQuery {
  actions: { pedidos: { auditar: { pedidoId: string } } };
}

const PEDIDO_AUDITAR_QUERY = gql`
  mutation ($id: PedidoID!, $input: PedidoAuditarInput!) {
    actions {
      pedidos {
        auditar(id: $id, input: $input) {
          pedidoId
        }
      }
    }
  }
`;

interface PedidoAuditarDataQueryVariables {
  id: { pedidoId: string };
}

export interface PedidoAuditarDataQuery {
  pedido: {
    pedidoId: string;
    tipoPessoa: string;
    clientePessoaJuridicaCnpj: string;
    clientePessoaJuridicaRazaoSocial: string;
    clientePessoaJuridicaNomeFantasia: string;
    clientePessoaJuridicaInscricaoEstadual?: string;
    clientePessoaJuridicaInscricaoMunicipal?: string;
    clientePessoaJuridicaDataAbertura: string;
    clientePessoaJuridicaTipoRetencaoNotaFiscal?: string;
    clientePessoaJuridicaRamoEmpresa?: string;
    allPedidosRedesSociais: {
      redeSocial: RedeSocialEnum;
      link: string;
    }[];
    allTelefones: Telefone[];
    allEmails: Email[];
    clientePessoaJuridicaRepresentanteLegalNome: string;
    clientePessoaJuridicaRepresentanteLegalCpf: string;
    clientePessoaJuridicaRepresentanteLegalRg?: string;
    clientePessoaJuridicaRepresentanteLegalDataNascimento: string;
    clientePessoaJuridicaRepresentanteLegalSexo?: string;
    clientePessoaJuridicaRepresentanteLegalEstadoCivilId?: string;
    enderecoInstalacao: EnderecoResult;
    enderecoCobranca: EnderecoResult;
    consulta: { dataConsulta: string; resultado: string; url: string };
    allPedidosPlanos: { planoId: string; nome: string; preco: number }[];
    diaVencimentoId: string;
    fidelidadeId: string;
    allFinalidades: { finalidadeId: string; nome: string }[];
    rede: string;
    senha: string;
    allComunicacoesInternas: {
      comunicacaoInternaId: string;
      descricao: string;
      createdAt: string;
      usuario: { usuarioId: string; nome: string };
    }[];
    tecnicoId: string;
    dataAgendamentoInstalacao: string;
    numeroSerieOnu: string;
    cto: string;
    ctoPorta: string;
    pedidosMateriais: {
      items: {
        quantidade: number;
        material: PedidoMaterial;
      }[];
    };
    validacaoCliente: number;
    allAnexos: { nome: string; url: string; mimetype: string }[];
    status: { statusId: string; nome: string };
  };
  planos: { items: PedidoPlano[] };
  diasVencimentos: { items: PedidoDiaVencimento[] };
  fidelidades: { items: PedidoFidelidade[] };
  finalidades: { items: PedidoFinalidade[] };
  usuarios: { items: PedidoTecnico[] };
  materiais: { items: PedidoMaterial[] };
}

const PEDIDO_AUDITAR_DATA_QUERY = gql`
  query ($id: PedidoID!) {
    pedido(id: $id) {
      pedidoId
      tipoPessoa
      clientePessoaJuridicaCnpj
      clientePessoaJuridicaRazaoSocial
      clientePessoaJuridicaNomeFantasia
      clientePessoaJuridicaInscricaoEstadual
      clientePessoaJuridicaInscricaoMunicipal
      clientePessoaJuridicaDataAbertura
      clientePessoaJuridicaTipoRetencaoNotaFiscal
      clientePessoaJuridicaRamoEmpresa
      allPedidosRedesSociais {
        redeSocial
        link
      }
      allTelefones {
        nome
        telefone
        whatsapp
      }
      allEmails {
        nome
        email
      }
      clientePessoaJuridicaRepresentanteLegalNome
      clientePessoaJuridicaRepresentanteLegalCpf
      clientePessoaJuridicaRepresentanteLegalRg
      clientePessoaJuridicaRepresentanteLegalDataNascimento
      clientePessoaJuridicaRepresentanteLegalSexo
      clientePessoaJuridicaRepresentanteLegalEstadoCivilId
      enderecoInstalacao {
        cep
        numero
        enderecoId
        cidadeId
        logradouro
        bairro
        cidadeId
        cidade {
          nome
          estado {
            nome
          }
        }
        complemento
        pontoReferencia
      }
      enderecoCobranca {
        cep
        numero
        enderecoId
        cidadeId
        logradouro
        bairro
        cidade {
          nome
          estado {
            nome
          }
        }
        complemento
        pontoReferencia
      }
      consulta {
        dataConsulta
        resultado
        url
      }
      allPedidosPlanos {
        planoId
        nome
        preco
      }
      diaVencimentoId
      fidelidadeId
      allFinalidades {
        finalidadeId
        nome
      }
      rede
      senha
      allComunicacoesInternas {
        comunicacaoInternaId
        descricao
        createdAt
        usuario {
          usuarioId
          nome
        }
      }
      pedidosMateriais {
        items {
          quantidade
          material {
            materialId
            nome
          }
        }
      }
      tecnicoId
      dataAgendamentoInstalacao
      numeroSerieOnu
      cto
      ctoPorta
      validacaoCliente
      allAnexos {
        nome
        url
        mimetype
      }
      status {
        statusId
        nome
      }
    }
    planos(filter: { equals: { ativo: true } }) {
      items {
        planoId
        nome
        preco
      }
    }
    diasVencimentos(filter: { equals: { ativo: true } }) {
      items {
        diaVencimentoId
        nome
      }
    }
    fidelidades(filter: { equals: { ativo: true } }) {
      items {
        fidelidadeId
        nome
      }
    }
    finalidades(filter: { equals: { ativo: true } }) {
      items {
        finalidadeId
        nome
      }
    }
    usuarios(filter: { equals: { perfilId: TECNICO, ativo: true } }) {
      items {
        usuarioId
        nome
      }
    }
    materiais {
      items {
        materialId
        nome
      }
    }
  }
`;

interface InitialDataProps {
  planos: PedidoPlano[];
  diasVencimentos: PedidoDiaVencimento[];
  fidelidades: PedidoFidelidade[];
  finalidades: PedidoFinalidade[];
  tecnicos: PedidoTecnico[];
  materiais: PedidoMaterial[];
}

export const AuditarPedidoPessoaJuridicaView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [initialData, setInitialData] = useState<InitialDataProps | null>(null);

  const pedidoId = params.pedidoId || '';

  const { errors, fields, setFields, submitHandler } = useForm({
    tipoPessoa: '',
    dados: {
      razaoSocial: '',
      nomeFantasia: '',
      cnpj: '',
      dataAbertura: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      tipoRetencaoNotaFiscal: '',
      ramoEmpresa: '',
      redesSociais: [{ link: '', redeSocial: '' as RedeSocialEnum }],
      telefones: [{ nome: '', telefone: '', whatsapp: false }],
      emails: [{ nome: '', email: '' }],
      representanteNome: '',
      representanteCpf: '',
      representanteRg: '',
      representanteSexo: '',
      representanteEstadoCivilId: '',
      representanteDataNascimento: '',
    },
    enderecoInstalacao: {
      cep: '',
      numero: '',
      estado: '',
      logradouro: '',
      bairro: '',
      cidade: '',
      complemento: '',
      pontoReferencia: '',
      ibge: '',
    },
    enderecoCobranca: {
      cep: '',
      numero: '',
      estado: '',
      logradouro: '',
      bairro: '',
      cidade: '',
      complemento: '',
      pontoReferencia: '',
      ibge: '',
    },
    consulta: { dataConsulta: '', resultado: '', url: '' },
    planos: { plano: '', planosSelecionados: [] as PedidoPlano[] },
    informacoesServico: {
      diaVencimentoId: '',
      fidelidadeId: '',
      pedidoFinalidades: [] as string[],
      rede: '',
      senha: '',
    },
    anexos: { anexos: [] as File[], pedidoAnexos: [] as PedidoAnexo[] },
    informacoesTecnicas: {
      dataAgendamento: null as Date | null,
      tecnico: '',
    },
    dadosPedido: {
      statusPedido: '',
      validacaoCliente: 0,
    },
    instalacao: {
      tecnicoId: '',
      dataAgendamentoInstalacao: '',
      numeroSerieOnu: '',
      cto: '',
      ctoPorta: '',
      pedidoMateriais: [{ materialId: '', nome: '', quantidade: 0 }],
    },
  });

  const query = useQueryAuth<
    PedidoAuditarDataQuery,
    PedidoAuditarDataQueryVariables
  >({
    auth,
    setAuth,
    query: PEDIDO_AUDITAR_DATA_QUERY,
    variables: { id: { pedidoId } },
  });

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      warnToast('Preencha os campos obrigatórios.');
    }
  }, [errors]);

  useEffect(() => {
    if (query.data) {
      const parsedData = parseQueryData(query.data);
      setFields(parsedData.pedidoFields);
      setInitialData(parsedData.parsedInitialData);
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const handleSubmit = submitHandler({
    validateSchema: auditarPedidoPessoaJuridicaSchema,
    callback: async values => {
      setDisableSubmitButton(true);

      try {
        if (values.planos.planosSelecionados.length === 0) {
          warnToast('No mínimo um plano deve ser selecionado.');
          return;
        }

        const variables = parseFieldsToQueryVariables(pedidoId, values);

        const { errors } = await graphqlFormDataAuth<
          PedidoAuditarQuery,
          PedidoAuditarQueryVariables
        >({
          auth,
          setAuth,
          query: PEDIDO_AUDITAR_QUERY,
          variables,
          files: values.anexos.anexos.map((anexo, index) => [
            `variables.input.anexos.${index}`,
            anexo,
          ]),
        });

        if (errors) {
          throw new GraphQLError('Erro ao auditar pedido.', errors);
        }

        successToast('Pedido auditado com sucesso!');

        navigate('/auditoria');
      } finally {
        setDisableSubmitButton(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <TabList labels={['Dados', 'Instalação', 'Comunicações']}>
        <Form
          onSubmit={handleSubmit}
          footerComponent={
            <FormFooter
              disableButtonSubmit={disableSubmitButton}
              onCancel={() => setIsModalVisible(true)}
            />
          }
        >
          <Fieldset legend="Status do pedido" columns={2}>
            <p className="body2">{query.data.pedido.status.nome}</p>
            <p className="body2">Cód. Pedido: {query.data.pedido.pedidoId}</p>
          </Fieldset>

          <FieldsetPedidoConsulta {...fields.consulta} />
          <FieldsetPedidoDados
            errors={errors?.dados}
            fields={fields.dados}
            setFields={values =>
              setFields({ ...fields, dados: { ...fields.dados, ...values } })
            }
          />

          <FieldsetEnderecoInstalacao
            errors={errors?.enderecoInstalacao}
            fields={fields.enderecoInstalacao}
            setFields={values =>
              setFields({
                ...fields,
                enderecoInstalacao: { ...fields.enderecoInstalacao, ...values },
              })
            }
          />

          <FieldsetEnderecoCobranca
            errors={errors?.enderecoCobranca}
            fields={fields.enderecoCobranca}
            setFields={values =>
              setFields({
                ...fields,
                enderecoCobranca: { ...fields.enderecoCobranca, ...values },
              })
            }
          />

          <FieldsetPlanos
            fields={{
              plano: fields.planos.plano,
              planosSelecionados: fields.planos.planosSelecionados,
            }}
            setFields={values =>
              setFields({ ...fields, planos: { ...fields.planos, ...values } })
            }
            allPlanos={initialData?.planos || []}
          />

          <FieldsetInformacoesServico
            errors={errors?.informacoesServico}
            fields={fields.informacoesServico}
            setFields={values =>
              setFields({
                ...fields,
                informacoesServico: { ...fields.informacoesServico, ...values },
              })
            }
            allDiasVencimentos={initialData?.diasVencimentos || []}
            allFidelidades={initialData?.fidelidades || []}
            allFinalidades={initialData?.finalidades || []}
          />

          <FieldsetAnexos
            fields={fields.anexos}
            setFields={values =>
              setFields({
                ...fields,
                anexos: { ...fields.anexos, anexos: [...values.anexos] },
              })
            }
          />

          <FieldsetInformacoesTecnicas
            errors={errors?.informacoesTecnicas}
            fields={fields.informacoesTecnicas}
            setFields={values =>
              setFields({
                ...fields,
                informacoesTecnicas: {
                  ...fields.informacoesTecnicas,
                  ...values,
                },
              })
            }
            allTecnicos={initialData?.tecnicos || []}
          />

          <FieldsetDadosPedido
            errors={errors?.dadosPedido}
            fields={fields.dadosPedido}
            setFields={values =>
              setFields({
                ...fields,
                dadosPedido: {
                  ...fields.dadosPedido,
                  ...values,
                },
              })
            }
          />
        </Form>

        <PedidoInstalacaoAuditoria
          pedidoId={pedidoId}
          dadosInstalacao={fields.instalacao}
          allMateriais={initialData?.materiais || []}
          allTecnicos={initialData?.tecnicos || []}
          refetchQuery={query.refetch}
        />

        <PedidoComunicacoes
          pedidoId={pedidoId}
          comunicacoes={query.data.pedido.allComunicacoesInternas}
          refetchQuery={query.refetch}
        />
      </TabList>
    </>
  );
};

import * as Yup from 'yup';

export const pedidoEnderecoSchema = Yup.object({
  cep: Yup.string().required('CEP obrigatório.'),
  numero: Yup.string().required('Número obrigatório.'),
  estado: Yup.string(),
  logradouro: Yup.string(),
  bairro: Yup.string(),
  cidade: Yup.string(),
  complemento: Yup.string(),
  pontoReferencia: Yup.string(),
});

import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { RedeSocial } from 'utils/types/RedeSocial';

import { cnpjMask, cpfMask } from 'functions/mask';

import { EmailList } from 'components/EmailList';
import { PhoneList } from 'components/PhoneList';
import { Fieldset, Input, Select } from 'components/Form';
import { RedeSocialList } from 'components/RedeSocialList';

interface Fields {
  razaoSocial: string;
  nomeFantasia: string;
  cnpj: string;
  dataAbertura: string;
  inscricaoEstadual?: string;
  inscricaoMunicipal?: string;
  tipoRetencaoNotaFiscal?: string;
  ramoEmpresa: string;
  redesSociais: RedeSocial[];
  telefones: Telefone[];
  emails: Email[];
  representanteNome: string;
  representanteCpf: string;
  representanteRg?: string;
  representanteSexo?: string;
  representanteEstadoCivilId?: string;
  representanteDataNascimento: string;
}

interface DadosProps {
  fields: Fields;
  setFields: (value: Fields) => void;
  errors: any;
}

export const FieldsetPedidoDados: React.FC<DadosProps> = ({
  fields,
  setFields,
  errors,
}) => {
  return (
    <Fieldset legend="Dados do cliente" columns={3}>
      <Input
        autoFocus
        maxLength={18}
        label="CNPJ"
        name="cnpj"
        error={errors?.cnpj}
        value={cnpjMask(fields.cnpj)}
        onChange={value => setFields({ ...fields, cnpj: value })}
      />
      <Input
        name="razaoSocial"
        label="Razão Social"
        error={errors?.razaoSocial}
        value={fields.razaoSocial}
        onChange={value => setFields({ ...fields, razaoSocial: value })}
      />
      <Input
        name="nomeFantasia"
        label="Nome Fantasia"
        error={errors?.nomeFantasia}
        value={fields.nomeFantasia}
        onChange={value => setFields({ ...fields, nomeFantasia: value })}
      />
      <Input
        name="inscricaoEstadual"
        label="Inscrição Estadual"
        error={errors?.inscricaoEstadual}
        value={fields.inscricaoEstadual || ''}
        onChange={value => setFields({ ...fields, inscricaoEstadual: value })}
      />
      <Input
        name="inscricaoMunicipal"
        label="Inscrição Municipal"
        error={errors?.inscricaoMunicipal}
        value={fields.inscricaoMunicipal || ''}
        onChange={value => setFields({ ...fields, inscricaoMunicipal: value })}
      />
      <Input
        type="date"
        name="dataAbertura"
        label="Data Abertura"
        error={errors?.dataAbertura}
        value={fields.dataAbertura}
        onChange={value => setFields({ ...fields, dataAbertura: value })}
      />
      <Input
        name="tipoRetencaoNotaFiscal"
        label="Tipo Retenção Nota Fiscal"
        error={errors?.tipoRetencaoNotaFiscal}
        value={fields.tipoRetencaoNotaFiscal || ''}
        onChange={value =>
          setFields({ ...fields, tipoRetencaoNotaFiscal: value })
        }
      />
      <Input
        name="ramoEmpresa"
        label="Ramo da Empresa"
        error={errors?.ramoEmpresa}
        value={fields.ramoEmpresa}
        onChange={value => setFields({ ...fields, ramoEmpresa: value })}
      />

      <h4 className="heading4 title span-3">Contato</h4>

      <RedeSocialList
        className="span-3"
        errors={errors?.redesSociais}
        redesSociais={fields.redesSociais}
        setNewRedesSociais={newRedesSociais =>
          setFields({ ...fields, redesSociais: newRedesSociais })
        }
      />

      <PhoneList
        className="span-3"
        errors={errors?.telefones}
        telefones={fields.telefones}
        setNewTelefones={newTelefones =>
          setFields({ ...fields, telefones: newTelefones })
        }
        onRemove={index =>
          fields.telefones.length > 2 &&
          setFields({
            ...fields,
            telefones: fields.telefones.filter((_, i) => index !== i),
          })
        }
      />

      <EmailList
        className="span-3"
        errors={errors?.emails}
        emails={fields.emails}
        setNewEmails={newEmails => setFields({ ...fields, emails: newEmails })}
      />

      <h4 className="heading4 title span-3">Representante Legal</h4>

      <Input
        name="representanteNome"
        label="Nome"
        error={errors?.representanteNome}
        value={fields.representanteNome}
        onChange={value => setFields({ ...fields, representanteNome: value })}
      />
      <Input
        maxLength={14}
        label="CPF"
        name="representanteCpf"
        error={errors?.representanteCpf}
        value={cpfMask(fields.representanteCpf)}
        onChange={value => setFields({ ...fields, representanteCpf: value })}
      />
      <Input
        name="representanteRg"
        label="RG"
        error={errors?.representanteRg}
        value={fields.representanteRg || ''}
        onChange={value => setFields({ ...fields, representanteRg: value })}
      />
      <Input
        type="date"
        name="representanteDataNascimento"
        label="Data Nascimento"
        error={errors?.representanteDataNascimento}
        value={fields.representanteDataNascimento}
        onChange={value =>
          setFields({ ...fields, representanteDataNascimento: value })
        }
      />
      <Select
        name="sexo"
        label="Sexo"
        error={errors?.representanteSexo}
        value={fields.representanteSexo || ''}
        onChange={value => setFields({ ...fields, representanteSexo: value })}
        options={[
          { label: 'Masculino', value: 'MASCULINO' },
          { label: 'Feminino', value: 'FEMININO' },
          { label: 'Outros', value: 'OUTROS' },
        ]}
      />
      <Select
        name="representanteEstadoCivil"
        label="Estado Civil"
        error={errors?.representanteEstadoCivilId}
        value={fields.representanteEstadoCivilId || ''}
        onChange={value =>
          setFields({ ...fields, representanteEstadoCivilId: value })
        }
        options={[
          { value: 'CASADO', label: 'Casado(a)' },
          { value: 'DIVORCIADO', label: 'Divorciado(a)' },
          { value: 'OUTROS', label: 'Outros' },
          { value: 'SEPARADO', label: 'Separado(a)' },
          { value: 'SOLTEIRO', label: 'Solteiro(a)' },
          { value: 'VIUVO', label: 'Viúvo(a)' },
        ]}
      />
    </Fieldset>
  );
};

import { Layout } from 'components/Layout';
import { ListRelatorioDesempenhoVendasView } from 'views/relatorios/usuarios/desempenhoPedidos/list';

export const ListRelatorioDesempenhoVendasPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Desempenho de Vendas' }] }}>
      <ListRelatorioDesempenhoVendasView />
    </Layout>
  );
};

import { useState } from 'react';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { Sort } from 'utils/Sort';
import { Filter } from 'utils/Filter';
import { Pagination } from 'utils/Pagination';

import { gql } from 'functions/gql';
import { paginate } from 'functions/paginate';

import { MeusPedidosFilter } from './MeusPedidosFilter';
import { Fallback } from 'components/Fallback';
import { CardList } from './components/CardList';
import { Pagination as TablePagination } from 'components/Table/Pagination';

import styles from './meus-pedidos.module.scss';

export interface Pedido {
  pedidoId: string;
  tipoPessoa: string;
  clientePessoaFisicaNome: string;
  clientePessoaFisicaCpf: string;
  clientePessoaJuridicaRazaoSocial: string;
  clientePessoaJuridicaCnpj: string;
  allPedidosPlanos: { planoId: string; preco: number }[];
  dataAgendamentoInstalacao: string;
  tecnico: { nome: string };
  statusId: string;
  dataInclusao: string;
}

interface MeusPedidosListQueryVariables {
  filter?: Filter;
  pagination: Pagination;
  sort?: Sort[];
}

interface MeusPedidosListQuery {
  me: { pedidos: { totalCount: number; items: Pedido[] } };
}

const MEUS_PEDIDOS_LIST_QUERY = gql`
  query ($pagination: Pagination, $filter: PedidoFilter, $sort: [Sort!]) {
    me {
      pedidos(pagination: $pagination, filter: $filter, sort: $sort) {
        totalCount
        items {
          pedidoId
          tipoPessoa
          clientePessoaFisicaNome
          clientePessoaFisicaCpf
          clientePessoaJuridicaRazaoSocial
          clientePessoaJuridicaCnpj
          allPedidosPlanos {
            planoId
            preco
          }
          dataAgendamentoInstalacao
          tecnico {
            nome
          }
          statusId
          dataInclusao
        }
      }
    }
  }
`;

export const ListMeusPedidosView: React.FC = () => {
  const { auth, setAuth } = useAuth();

  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [filter, setFilter] = useState<Filter | null>(null);

  const query = useQueryAuth<
    MeusPedidosListQuery,
    MeusPedidosListQueryVariables
  >({
    auth,
    setAuth,
    query: MEUS_PEDIDOS_LIST_QUERY,
    variables: {
      filter: filter ? filter : undefined,
      pagination: paginate(pagination),
    },
  });

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  const { data } = query;

  return (
    <section className={styles['meus-pedidos-section']}>
      <div className={styles['meus-pedidos-header']}>
        <h3 className="title">Pedidos ({query.data.me.pedidos.totalCount})</h3>
        <MeusPedidosFilter
          isFiltering={filter !== null}
          onFilter={filterProps => setFilter(filterProps)}
          onClear={() => setFilter(null)}
        />
      </div>

      {data.me.pedidos.items.length > 0 ? (
        <>
          <CardList pedidos={data.me.pedidos.items} />

          <TablePagination
            totalCount={data.me.pedidos.totalCount}
            page={pagination.page}
            onChangePage={page => setPagination({ ...pagination, page })}
            perPage={pagination.perPage}
            onChangePerPage={perPage =>
              setPagination({ ...pagination, perPage })
            }
          />
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <p className="body2 text">Nenhum registro encontrado.</p>
        </div>
      )}
    </section>
  );
};

import { useNavigate } from 'react-router';

import { useForm } from 'hooks/useForm';

import { cpfMask } from 'functions/mask';
import { convertEmptyKeysToNull } from 'functions/convertEmptyKeysToNull';

import { warnToast } from 'utils/toast';
import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { RedeSocial } from 'utils/types/RedeSocial';
import { PedidoEstadoCivil } from 'utils/types/PedidoEstadoCivil';
import { NewPedidoPessoaFisica } from 'views/pedidos/utils/NewPedidoPessoaFisica';
import { pedidoDadosPessoaFisicaSchema } from '../../utils/pedidoDadosPessoaFisicaSchema';

import { PhoneList } from 'components/PhoneList';
import { EmailList } from 'components/EmailList';
import { Form, Input, Select } from 'components/Form';
import { StepTitle } from '../../components/StepTitle';
import { NextStepFooter } from 'views/pedidos/components/NextStepFooter';
import { RedeSocialList } from 'components/RedeSocialList';
import { RedeSocialEnum } from 'utils/enums/RedeSocialEnum';

interface Fields {
  nome: string;
  cpf: string;
  rg: string;
  dataNascimento: string;
  sexo: string;
  estadoCivil: string;
  redesSociais: RedeSocial[];
  telefones: Telefone[];
  emails: Email[];
}

interface DadosProps {
  estadosCivis: PedidoEstadoCivil[];
  newPedido: NewPedidoPessoaFisica;
  setNewPedido: (values: NewPedidoPessoaFisica) => void;
  nextStep: () => void;
}

export const NewPedidoDados: React.FC<DadosProps> = ({
  estadosCivis,
  newPedido,
  setNewPedido,
  nextStep,
}) => {
  const navigate = useNavigate();
  const { fields, setFields, errors, submitHandler } = useForm<Fields>({
    nome: newPedido.dados?.nome || '',
    cpf: newPedido.dados?.cpf || '',
    rg: newPedido.dados?.rg || '',
    dataNascimento: newPedido.dados?.dataNascimento || '',
    sexo: newPedido.dados?.sexo || '',
    estadoCivil: newPedido.dados?.estadoCivil || '',
    redesSociais: newPedido.dados?.redesSociais || [
      { link: '', redeSocial: '' as RedeSocialEnum },
    ],
    telefones: newPedido.dados?.telefones || [
      { nome: '', telefone: '', whatsapp: false },
      { nome: '', telefone: '', whatsapp: false },
    ],
    emails: newPedido.dados?.emails || [{ nome: '', email: '' }],
  });

  const handleSubmit = submitHandler({
    validateSchema: pedidoDadosPessoaFisicaSchema,
    callback: async values => {
      const phones = values.telefones.map(item => item.telefone);
      const isDuplicate = phones.some(
        (item, index) => phones.indexOf(item) !== index,
      );

      if (isDuplicate) {
        warnToast('Telefone Duplicado', 'Informe telefones diferentes.');
        return;
      }

      const parsedValues = convertEmptyKeysToNull(values);

      setNewPedido({
        dados: { ...parsedValues, cpf: parsedValues.cpf.replace(/[-.]/g, '') },
      });
      nextStep();
    },
  });

  return (
    <>
      <StepTitle title="Dados Pessoais" onGoBack={() => navigate('/pedidos')} />
      <Form
        onSubmit={handleSubmit}
        columns={3}
        footerComponent={<NextStepFooter />}
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
        <Input
          maxLength={14}
          name="cpf"
          label="CPF"
          error={errors.cpf}
          value={cpfMask(fields.cpf)}
          onChange={value => setFields({ ...fields, cpf: value })}
        />
        <Input
          name="rg"
          label="RG"
          error={errors.rg}
          value={fields.rg}
          onChange={value => setFields({ ...fields, rg: value })}
        />
        <Input
          type="date"
          name="dataNascimento"
          label="Data Nascimento"
          error={errors.dataNascimento}
          value={fields.dataNascimento}
          onChange={value => setFields({ ...fields, dataNascimento: value })}
        />
        <Select
          name="sexo"
          label="Sexo"
          error={errors.sexo}
          value={fields.sexo}
          onChange={value => setFields({ ...fields, sexo: value })}
          options={[
            { label: 'Masculino', value: 'MASCULINO' },
            { label: 'Feminino', value: 'FEMININO' },
            { label: 'Outros', value: 'OUTROS' },
          ]}
        />
        <Select
          name="estadoCivil"
          label="Estado Civil"
          error={errors.estadoCivil}
          value={fields.estadoCivil || ''}
          onChange={value => setFields({ ...fields, estadoCivil: value })}
          options={estadosCivis
            .map(({ estadoCivilId, nome }) => ({
              label: nome,
              value: estadoCivilId,
            }))
            .sort((a, b) => (a.label > b.label ? 1 : -1))}
        />

        <h4 className="heading4 title span-3">Contato</h4>

        <RedeSocialList
          className="span-3"
          errors={errors.redesSociais}
          redesSociais={fields.redesSociais}
          setNewRedesSociais={newRedesSociais =>
            setFields({ ...fields, redesSociais: newRedesSociais })
          }
        />

        <PhoneList
          className="span-3"
          errors={errors.telefones}
          telefones={fields.telefones}
          setNewTelefones={newTelefones =>
            setFields({ ...fields, telefones: newTelefones })
          }
          onRemove={index =>
            fields.telefones.length > 2 &&
            setFields({
              ...fields,
              telefones: fields.telefones.filter((_, i) => index !== i),
            })
          }
        />
        <EmailList
          className="span-3"
          errors={errors.emails}
          emails={fields.emails}
          setNewEmails={newEmails =>
            setFields({ ...fields, emails: newEmails })
          }
        />
      </Form>
    </>
  );
};

import { PedidoAnexo } from 'utils/types/PedidoAnexo';
import styles from './PrintImage.module.scss';

export const PrintImage: React.FC<PedidoAnexo> = props => {
  if (props.mimetype.includes('image')) {
    return (
      <img
        className={styles['print-image-container']}
        src={props.url}
        alt={props.nome}
      />
    );
  }

  return null;
};

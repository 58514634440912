import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { FiAlertTriangle, FiLock } from 'react-icons/fi';

import { useAuth } from 'hooks/useAuth';
import { useForm } from 'hooks/useForm';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { graphqlFormDataAuth } from 'functions/graphqlAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { newUsuarioSchema } from './utils/newUsuarioSchema';

import {
  Form,
  FormFooter,
  Input,
  InputFile,
  InputWithIcon,
  PreviewFile,
  Select,
} from 'components/Form';
import { Fallback } from 'components/Fallback';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

interface GrupoAcesso {
  grupoAcessoId: string;
  nome: string;
}

interface Perfil {
  perfilId: string;
  nome: string;
}

interface UsuarioCreateDataQuery {
  gruposAcessos: { items: GrupoAcesso[] };
  perfis: { items: Perfil[] };
}

const USUARIO_CREATE_DATA_QUERY = gql`
  query {
    gruposAcessos(filter: { equals: { ativo: true } }) {
      items {
        grupoAcessoId
        nome
      }
    }
    perfis {
      items {
        perfilId
        nome
      }
    }
  }
`;

interface UsuarioCreateQueryVariables {
  input: {
    avatar?: File;
    grupoAcessoId: string;
    login: string;
    nome: string;
    perfilId: string;
    senha: string;
  };
}

interface UsuarioCreateQuery {
  usuarioCreate: { usuarioId: string; nome: string };
}

const USUARIO_CREATE_QUERY = gql`
  mutation ($input: UsuarioCreateInput!) {
    usuarioCreate(input: $input) {
      usuarioId
      nome
    }
  }
`;

export const NewUsuarioView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    nome: '',
    login: '',
    senha: '',
    confirmarSenha: '',
    grupoAcesso: '',
    perfil: '',
    avatar: null as File | null,
    perfis: [] as Perfil[],
    gruposAcessos: [] as GrupoAcesso[],
  });

  const query = useQueryAuth<UsuarioCreateDataQuery>({
    auth,
    setAuth,
    query: USUARIO_CREATE_DATA_QUERY,
  });

  useEffect(() => {
    if (query.data) {
      setFields({
        ...fields,
        gruposAcessos: query.data.gruposAcessos.items,
        perfis: query.data.perfis.items,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const handleSubmit = submitHandler({
    validateSchema: newUsuarioSchema,
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { data, errors } = await graphqlFormDataAuth<
          UsuarioCreateQuery,
          UsuarioCreateQueryVariables
        >({
          query: USUARIO_CREATE_QUERY,
          variables: {
            input: {
              grupoAcessoId: values.grupoAcesso,
              login: values.login,
              nome: values.nome,
              perfilId: values.perfil,
              senha: values.senha,
            },
          },
          files: values.avatar
            ? [['variables.input.avatar', values.avatar]]
            : undefined,
          auth,
          setAuth,
        });

        if (errors) {
          throw new GraphQLError('Falha ao criar usuário.', errors);
        }

        successToast(
          `Usuário: ${data.usuarioCreate.nome}`,
          'Criado com sucesso!',
        );

        navigate('/usuarios');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        columns={2}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
        <Input
          autoComplete="new-password"
          name="login"
          label="Login"
          error={errors.login}
          value={fields.login}
          onChange={value => setFields({ ...fields, login: value })}
        />
        <InputWithIcon
          autoComplete="new-password"
          type="password"
          name="senha"
          label="Senha"
          icon={() => <FiLock size={22} />}
          error={errors.senha}
          value={fields.senha}
          onChange={value => setFields({ ...fields, senha: value })}
        />
        <InputWithIcon
          autoComplete="new-password"
          type="password"
          name="confirmarSenha"
          label="Confirmar Senha"
          icon={() => <FiLock size={22} />}
          error={errors.confirmarSenha}
          value={fields.confirmarSenha}
          onChange={value => setFields({ ...fields, confirmarSenha: value })}
        />
        <Select
          name="grupoAcesso"
          label="Grupo de Acesso"
          error={errors.grupoAcesso}
          value={fields.grupoAcesso}
          onChange={value => setFields({ ...fields, grupoAcesso: value })}
          options={fields.gruposAcessos.map(({ grupoAcessoId, nome }) => ({
            label: nome,
            value: grupoAcessoId,
          }))}
        />
        <Select
          name="perfil"
          label="Perfil"
          error={errors.perfil}
          value={fields.perfil}
          onChange={value => setFields({ ...fields, perfil: value })}
          options={fields.perfis.map(({ nome, perfilId }) => ({
            label: nome,
            value: perfilId,
          }))}
        />
        <InputFile
          name="avatar"
          label="Adicionar Foto de Perfil"
          value={fields.avatar}
          onChange={files =>
            files && setFields({ ...fields, avatar: files[0] })
          }
        />
        <div style={{ gridColumnStart: 1 }}>
          {fields.avatar && (
            <PreviewFile
              files={[
                {
                  fileName: fields.avatar.name,
                  url: URL.createObjectURL(fields.avatar),
                  mimetype: fields.avatar.type,
                },
              ]}
              removeFiles={() => {
                setFields(state => ({ ...state, avatar: null }));
              }}
            />
          )}
        </div>
      </Form>
    </>
  );
};

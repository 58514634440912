import styles from './BarChartLegend.module.scss';

interface BarChartLegendProps {
  items: { label: string; color: string }[];
}

export const BarChartLegend: React.FC<BarChartLegendProps> = ({ items }) => {
  return (
    <div className={styles['legend-container']}>
      {items.map((item, index) => (
        <div key={index} className={styles['legend-item']}>
          <span
            style={{ background: item.color }}
            className={styles['circle']}
          />
          <p className="body4">{item.label}</p>
        </div>
      ))}
    </div>
  );
};

import { Telefone } from 'utils/types/Telefone';
import { currencyMask, phoneMask } from 'functions/mask';

import { Checkbox, Fieldset, Input, Select } from 'components/Form';
import { PedidoVinculoEmpregaticio } from 'utils/types/PedidoVinculoEmpregaticio';
import { PedidoOperadora } from 'utils/types/PedidoOperadora';

interface Fields {
  vinculoEmpregaticioId: string;
  localTrabalho: string;
  telefoneTrabalho: Telefone;
  renda: string;
  atividadeExercida: string;
  tipoResidencia: string;
  quantidadeMoradores: string;
  tempoMoradia: string;
  possuiInternet: boolean;
  operadoraId: string;
  motivoCancelamento: string;
}

interface DadosEmpregaticiosProps {
  allOperadoras: PedidoOperadora[];
  allVinculosEmpregaticios: PedidoVinculoEmpregaticio[];
  fields: Fields;
  setFields: (value: Fields) => void;
  errors: any;
}

export const FieldsetDadosEmpregaticios: React.FC<DadosEmpregaticiosProps> = ({
  allOperadoras,
  allVinculosEmpregaticios,
  fields,
  setFields,
  errors,
}) => {
  const handleChangeVinculoEmpregaticio = (value: string) => {
    switch (value) {
      case 'AUTONOMO':
        setFields({
          ...fields,
          vinculoEmpregaticioId: value,
          localTrabalho: fields.localTrabalho || '',
          renda: '',
          telefoneTrabalho: {
            nome: 'Telefone Trabalho',
            telefone: fields.telefoneTrabalho.telefone || '',
            whatsapp: false,
          },
        });
        break;
      case 'REGISTRADO':
        setFields({
          ...fields,
          vinculoEmpregaticioId: value,
          atividadeExercida: fields.atividadeExercida || '',
        });
        break;
      case 'OUTROS':
        setFields({
          ...fields,
          vinculoEmpregaticioId: value,
          atividadeExercida: fields.atividadeExercida || '',
          localTrabalho: fields.localTrabalho || '',
          renda: '',
          telefoneTrabalho: {
            nome: 'Telefone Trabalho',
            telefone: fields.telefoneTrabalho.telefone || '',
            whatsapp: false,
          },
        });
        break;

      default:
        break;
    }
  };

  return (
    <Fieldset legend="Dados Empregatícios" columns={3}>
      <Select
        name="vinculoEmpregaticio"
        label="Vínculo Empregatício"
        error={errors?.vinculoEmpregaticioId}
        value={fields.vinculoEmpregaticioId}
        onChange={handleChangeVinculoEmpregaticio}
        options={allVinculosEmpregaticios.map(
          ({ nome, vinculoEmpregaticioId }) => ({
            label: nome,
            value: vinculoEmpregaticioId,
          }),
        )}
      />

      {fields.vinculoEmpregaticioId &&
        fields.vinculoEmpregaticioId !== 'AUTONOMO' && (
          <>
            <h4 className="heading4 title span-3">Infomações do Trabalho.</h4>

            <Input
              name="localTrabalho"
              label="Local de Trabalho"
              error={errors?.localTrabalho}
              value={fields.localTrabalho}
              onChange={value => setFields({ ...fields, localTrabalho: value })}
            />
            <Input
              inputMode="numeric"
              placeholder="R$ 0.00"
              name="renda"
              label="Renda"
              error={errors?.renda}
              value={fields.renda}
              onChange={value =>
                setFields({ ...fields, renda: currencyMask(value) })
              }
            />
            <Input
              type="tel"
              maxLength={15}
              name="telefoneTrabalho"
              label="Telefone do Trabalho"
              error={errors?.telefoneTrabalho?.telefone}
              value={phoneMask(fields.telefoneTrabalho.telefone)}
              onChange={value =>
                setFields({
                  ...fields,
                  telefoneTrabalho: {
                    ...fields.telefoneTrabalho,
                    telefone: value.replace(/\D+/g, ''),
                  },
                })
              }
            />
          </>
        )}

      {fields.vinculoEmpregaticioId &&
        fields.vinculoEmpregaticioId !== 'REGISTRADO' && (
          <>
            <h4 className="heading4 title span-3">Autônomo</h4>

            <Input
              className="span-3"
              name="atividadeExercida"
              label="Atividade Exercída (Autônomo)"
              error={errors?.atividadeExercida}
              value={fields.atividadeExercida}
              onChange={value =>
                setFields({ ...fields, atividadeExercida: value })
              }
            />
          </>
        )}

      <h4 className="heading4 title span-3">Informações Residenciais</h4>

      <Select
        name="tipoResidencia"
        label="Tipo Residência"
        error={errors?.tipoResidencia}
        value={fields.tipoResidencia}
        onChange={value => setFields({ ...fields, tipoResidencia: value })}
        options={[
          { label: 'Alugada', value: 'ALUGADA' },
          { label: 'Própria', value: 'PROPRIA' },
          { label: 'Outros', value: 'OUTROS' },
        ]}
      />
      <Input
        type="number"
        inputMode="numeric"
        min={1}
        name="quantidadeMoradores"
        label="Qtde. Moradores"
        error={errors?.quantidadeMoradores}
        value={fields.quantidadeMoradores}
        onChange={value => setFields({ ...fields, quantidadeMoradores: value })}
      />
      <Input
        name="tempoMoradia"
        label="Tempo de Moradia"
        error={errors?.tempoMoradia}
        value={fields.tempoMoradia}
        onChange={value => setFields({ ...fields, tempoMoradia: value })}
      />
      <Checkbox
        className="column-start"
        name="possuiInternet"
        label="Já possui internet"
        checked={fields.possuiInternet}
        onChange={value => setFields({ ...fields, possuiInternet: value })}
      />
      {fields.possuiInternet && (
        <>
          <Select
            className="column-start"
            name="operadora"
            label="Operadora"
            error={errors?.operadoraId}
            value={fields.operadoraId}
            onChange={value => setFields({ ...fields, operadoraId: value })}
            options={allOperadoras.map(({ nome, operadoraId }) => ({
              label: nome,
              value: operadoraId,
            }))}
          />
          <Input
            className="span-2"
            name="motivoCancelamento"
            label="Motivo Cancelamento Outra Operadora"
            error={errors?.motivoCancelamento}
            value={fields.motivoCancelamento}
            onChange={value =>
              setFields({ ...fields, motivoCancelamento: value })
            }
          />
        </>
      )}
    </Fieldset>
  );
};

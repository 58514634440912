import * as Yup from 'yup';

export const editConfiguracoesSchema = Yup.object({
  serasaSophusCodigo: Yup.string().required('Código Sophus obrigatório.'),
  serasaSophusEntidade: Yup.string().required('Entidade Sophus obrigatório.'),
  serasaSophusSenha: Yup.string().required('Senha Sophus obrigatório.'),
  serasaSophusMaximoDiasReutilizarConsulta: Yup.number()
    .min(1, 'No mínimo 1 dia para reutilizar a consulta.')
    .required('Máx. Dias Reutilizar Consulta obrigatório.'),
});

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { FiHelpCircle, FiLogOut, FiUser } from 'react-icons/fi';
import { useAuth } from 'hooks/useAuth';

import styles from './UserProfile.module.scss';

export const UserProfile: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  return (
    <div className={styles['user-profile-container']}>
      <details>
        <summary title={auth?.usuario.nome || 'Perfil'}>
          <img
            src={auth?.usuario.avatar.url}
            width={36}
            height={36}
            alt={auth?.usuario.nome || 'Foto de Perfil'}
          />
          <span className={styles['dropdown-caret']} />
        </summary>

        <nav className={styles['user-profile-menu']}>
          <li title="Visualizar seu perfil">
            <Link to="/perfil" className="body2">
              <FiUser size={20} />
              Ver perfil
            </Link>
          </li>
          <li title="Clique para obter ajuda">
            <Link to="/ajuda" className="body2">
              <FiHelpCircle size={20} />
              Ajuda
            </Link>
          </li>

          <div className={styles['menu-divider']} />

          <li
            title="Clique para sair"
            className="body2"
            onClick={() => {
              setAuth(null);
              navigate('/');
            }}
          >
            <FiLogOut size={20} />
            Sair
          </li>
        </nav>
      </details>
    </div>
  );
};

import { Layout } from 'components/Layout';
import { DetailConsultaUsuarios } from 'views/relatorios/consultas/usuarios/detail';

export const DetailConsultaUsuariosPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          {
            label: 'Consultas Usuários',
            href: '/relatorios/consultas-usuarios',
          },
          { label: 'Detalhes' },
        ],
      }}
    >
      <DetailConsultaUsuarios />
    </Layout>
  );
};

import { useForm } from 'hooks/useForm';
import { convertEmptyKeysToNull } from 'functions/convertEmptyKeysToNull';
import { pedidoInformacoesServicosSchema } from '../utils/pedidoInformacoesServicoSchema';

import { StepTitle } from './StepTitle';
import { Form, Input, MultiSelect, Select } from 'components/Form';

import { NextStepFooter } from 'views/pedidos/components/NextStepFooter';

interface Fields {
  diaVencimentoId: string;
  fidelidadeId: string;
  finalidades: string[];
  rede: string;
  senha: string;
}

interface InformacoesServicoProps {
  diasVencimentos: { diaVencimentoId: string; nome: string }[];
  fidelidades: { fidelidadeId: string; nome: string }[];
  finalidades: { finalidadeId: string; nome: string }[];
  values: Fields;
  setValues: (values: Fields) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export const PedidoInformacoesServico: React.FC<InformacoesServicoProps> = ({
  diasVencimentos,
  fidelidades,
  finalidades,
  values,
  setValues,
  nextStep,
  prevStep,
}) => {
  const { fields, setFields, errors, submitHandler } = useForm<Fields>({
    diaVencimentoId: values?.diaVencimentoId || '',
    fidelidadeId: values?.fidelidadeId || '',
    finalidades: values?.finalidades || [],
    rede: values?.rede || '',
    senha: values?.senha || '',
  });

  const handleSubmit = submitHandler({
    validateSchema: pedidoInformacoesServicosSchema,
    callback: async values => {
      const parsedValues = convertEmptyKeysToNull(values);
      setValues(parsedValues);
      nextStep();
    },
  });

  return (
    <>
      <StepTitle title="Informações do Serviço" onGoBack={prevStep} />
      <Form
        onSubmit={handleSubmit}
        columns={2}
        footerComponent={<NextStepFooter />}
      >
        <Select
          name="diaVencimentoId"
          label="Dia Vencimento"
          error={errors.diaVencimentoId}
          value={fields.diaVencimentoId}
          onChange={value => setFields({ ...fields, diaVencimentoId: value })}
          options={diasVencimentos.map(({ diaVencimentoId, nome }) => ({
            label: nome,
            value: diaVencimentoId,
          }))}
        />
        <Select
          name="fidelidadeId"
          label="Fidelidade"
          error={errors.fidelidadeId}
          value={fields.fidelidadeId}
          onChange={value => setFields({ ...fields, fidelidadeId: value })}
          options={fidelidades.map(({ fidelidadeId, nome }) => ({
            label: nome,
            value: fidelidadeId,
          }))}
        />
        <MultiSelect
          name="finalidades"
          label="Finalidades"
          error={errors.finalidades}
          value={fields.finalidades}
          onChange={value => setFields({ ...fields, finalidades: value })}
          options={finalidades.map(({ finalidadeId, nome }) => ({
            label: nome,
            value: finalidadeId,
          }))}
        />
        <Input
          name="rede"
          label="Nome do wi-fi"
          error={errors.rede}
          value={fields.rede}
          onChange={value => setFields({ ...fields, rede: value })}
        />
        <Input
          name="senha"
          label="Senha"
          error={errors.senha}
          value={fields.senha}
          onChange={value => setFields({ ...fields, senha: value })}
        />
      </Form>
    </>
  );
};

import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';

import { useAuth } from 'hooks/useAuth';
import { useForm } from 'hooks/useForm';
import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';
import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';

import { Form, FormFooter, Input } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

interface MaterialCreateQueryVariables {
  input: { nome: string };
}

interface MaterialCreateQuery {
  materialCreate: {
    materialId: string;
    nome: string;
  };
}

const MATERIAL_CREATE_QUERY = gql`
  mutation ($input: MaterialCreateInput!) {
    materialCreate(input: $input) {
      materialId
      nome
    }
  }
`;

export const NewMaterialView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    nome: '',
  });

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      nome: Yup.string().required('Descrição obrigatório.'),
    }),
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { data, errors } = await graphqlAuth<
          MaterialCreateQuery,
          MaterialCreateQueryVariables
        >({
          auth,
          setAuth,
          query: MATERIAL_CREATE_QUERY,
          variables: {
            input: {
              nome: values.nome,
            },
          },
        });

        if (errors) {
          throw new GraphQLError('Falha ao criar material', errors);
        }

        successToast(
          `Material: ${data.materialCreate.nome}`,
          'Criado com sucesso!',
        );

        navigate('/materiais');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors?.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
      </Form>
    </>
  );
};

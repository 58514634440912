import { useState } from 'react';
import { FiDownload } from 'react-icons/fi';

import { useAuth } from 'hooks/useAuth';
import { useError } from 'hooks/useError';
import { Sort } from 'utils/Sort';
import { GraphQLError } from 'utils/GraphQLError';
import { PedidoStatusEnum } from 'utils/enums/PedidoStatusEnum';
import { PedidoTipoDataEnum } from 'utils/enums/PedidoTipoDataEnum';
import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';
import { exportToCsv } from 'functions/exportToCSV';
import { currencyFormat } from 'functions/formatters';

import { Button } from 'components/Form';
import { TableFooter } from 'components/Table/TableFooter';
import { RelatorioTable } from 'views/relatorios/components/RelatorioTable';
import {
  getTotaisDesempenhoVendas,
  TotalDesempenhoVenda,
} from 'views/relatorios/utils/getTotaisDesempenhoVendas';
import { DesempenhoVendasFilter } from './DesempenhoVendasFilter';

export interface RelatorioDesempenhoPedidosQueryVariables {
  input: {
    dataInicio: string;
    dataTermino: string;
    statuses: PedidoStatusEnum[];
    tipoData: PedidoTipoDataEnum;
  };
}

export interface DesempenhoPedidoItem {
  usuarioId: string;
  quantidadeDiasPeriodo: number;
  quantidadePedidos: number;
  precoTotalPlanosPedidos: number;
  quantidadePedidosPorQuantidadeDiasPeriodo: number;
  precoTotalPlanosPedidosPorQuantidadeDiasPeriodo: number;
  usuario: {
    usuarioId: string;
    nome: string;
  };
}

interface RelatorioDesempenhoPedidosQuery {
  relatorios: {
    usuarios: {
      desempenhoPedidos: {
        statuses: PedidoStatusEnum;
        tipoData: PedidoTipoDataEnum;
        dataInicio: string;
        dataTermino: string;
        items: DesempenhoPedidoItem[];
      };
    };
  };
  statuses: {
    items: {
      statusId: string;
      nome: string;
    }[];
  };
}

const RELATORIO_DESEMPENHO_PEDIDOS_QUERY = gql`
  query ($input: RelatoriosUsuariosDesempenhoPedidosInput!) {
    relatorios {
      usuarios {
        desempenhoPedidos(input: $input) {
          statuses
          tipoData
          dataInicio
          dataTermino
          items {
            usuarioId
            quantidadeDiasPeriodo
            quantidadePedidos
            precoTotalPlanosPedidos
            quantidadePedidosPorQuantidadeDiasPeriodo
            precoTotalPlanosPedidosPorQuantidadeDiasPeriodo
            usuario {
              usuarioId
              nome
            }
          }
        }
      }
    }
    statuses {
      items {
        statusId
        nome
      }
    }
  }
`;

export const ListRelatorioDesempenhoVendasView: React.FC = () => {
  const { auth, setAuth } = useAuth();
  const { withErrorHandling } = useError();

  const [sort, setSort] = useState<Sort | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totais, setTotais] = useState<TotalDesempenhoVenda | null>(null);
  const [desempenhoPedidos, setDesempenhoPedidos] = useState<
    DesempenhoPedidoItem[]
  >([]);

  const handleSearch = withErrorHandling(
    async (variables: RelatorioDesempenhoPedidosQueryVariables) => {
      setIsLoading(true);

      try {
        const { data, errors } = await graphqlAuth<
          RelatorioDesempenhoPedidosQuery,
          RelatorioDesempenhoPedidosQueryVariables
        >({
          auth,
          setAuth,
          query: RELATORIO_DESEMPENHO_PEDIDOS_QUERY,
          variables,
        });

        if (errors) {
          throw new GraphQLError('Erro ao pesquisar consultas sophus.', errors);
        }

        setTotais(
          getTotaisDesempenhoVendas(
            data.relatorios.usuarios.desempenhoPedidos.items,
          ),
        );
        setDesempenhoPedidos(data.relatorios.usuarios.desempenhoPedidos.items);
      } finally {
        setIsLoading(false);
      }
    },
  );

  const handleExportCSV = () => {
    const keys = [
      {
        label: 'Usuário',
        key: 'usuario',
        parser: (item: any) => item.usuario.nome,
      },
      {
        label: 'Qtd. Pedidos',
        key: 'quantidadePedidos',
        parser: (item: any) => `${item.quantidadePedidos}`,
      },
      {
        label: 'Média pedidos / dia',
        key: 'quantidadePedidosPorQuantidadeDiasPeriodo',
        parser: (item: any) =>
          `${item.quantidadePedidosPorQuantidadeDiasPeriodo
            .toFixed(2)
            .replace(/[.]/g, ',')}`,
      },
      {
        label: 'Valor total',
        key: 'precoTotalPlanosPedidos',
        parser: (item: any) =>
          item.precoTotalPlanosPedidos?.toFixed(2)?.replace(/[.]/g, ','),
      },
      {
        label: 'Média vendas / dia',
        key: 'precoTotalPlanosPedidosPorQuantidadeDiasPeriodo',
        parser: (item: any) =>
          item.precoTotalPlanosPedidosPorQuantidadeDiasPeriodo
            ?.toFixed(2)
            ?.replace(/[.]/g, ','),
      },
    ];

    const rows = [
      keys.map(key => key.label),
      ...desempenhoPedidos.map(consulta =>
        keys.map(({ parser, key }) =>
          parser ? parser(consulta) : consulta[key as keyof typeof consulta],
        ),
      ),
    ];

    exportToCsv('relatorio-desempenho-vendas.csv', rows);
  };

  return (
    <>
      <DesempenhoVendasFilter
        onSearch={newVariables => handleSearch(newVariables)}
      />
      <RelatorioTable
        title="Desempenho de vendas"
        sideComponent={() => (
          <Button
            type="button"
            disabled={desempenhoPedidos.length === 0}
            style={{ width: 'max-content' }}
            onClick={handleExportCSV}
          >
            <FiDownload size={20} /> Exportar CSV
          </Button>
        )}
        columns={[
          {
            key: 'usuario',
            label: 'Usuário',
            sortable: true,
            render: ({ usuario }) => <p>{usuario.nome}</p>,
          },
          {
            key: 'quantidadePedidos',
            label: 'Qtd. Pedidos',
            sortable: true,
            render: ({ quantidadePedidos }) => (
              <p className="text-align-center">{quantidadePedidos}</p>
            ),
            renderHeadColumn: ({ label }) => (
              <p className="text-align-center">{label}</p>
            ),
          },
          {
            key: 'quantidadePedidosPorQuantidadeDiasPeriodo',
            label: 'Média pedidos / dia',
            sortable: true,
            render: ({ quantidadePedidosPorQuantidadeDiasPeriodo }) => (
              <p className="text-align-center">
                {quantidadePedidosPorQuantidadeDiasPeriodo.toFixed(2)}
              </p>
            ),
            renderHeadColumn: ({ label }) => (
              <p className="text-align-center">{label}</p>
            ),
          },
          {
            key: 'precoTotalPlanosPedidos',
            label: 'Valor total',
            sortable: true,
            render: ({ precoTotalPlanosPedidos }) => (
              <p className="text-align-right">
                {currencyFormat(precoTotalPlanosPedidos)}
              </p>
            ),
            renderHeadColumn: ({ label }) => (
              <p className="text-align-right">{label}</p>
            ),
          },
          {
            key: 'precoTotalPlanosPedidosPorQuantidadeDiasPeriodo',
            label: 'Média vendas / dia',
            sortable: true,
            render: ({ precoTotalPlanosPedidosPorQuantidadeDiasPeriodo }) => (
              <p className="text-align-right">
                {currencyFormat(
                  precoTotalPlanosPedidosPorQuantidadeDiasPeriodo,
                )}
              </p>
            ),
            renderHeadColumn: ({ label }) => (
              <p className="text-align-right">{label}</p>
            ),
          },
        ]}
        items={desempenhoPedidos}
        isLoading={isLoading}
        getRowId={({ usuarioId }) => usuarioId}
        totalCount={desempenhoPedidos.length}
        sort={sort}
        onChangeSort={sortField => setSort(sortField)}
        tFootComponent={() => (
          <TableFooter
            columns={[
              { value: 'Totais:' },
              {
                value: totais?.sumQuantidadePedidos,
                textAlign: 'center',
              },
              {
                value:
                  totais?.sumQuantidadePedidosPorQuantidadeDiasPeriodo.toFixed(
                    2,
                  ),
                textAlign: 'center',
              },
              {
                value: currencyFormat(totais?.sumPrecoTotalPlanosPedidos),
                textAlign: 'right',
              },
              {
                value: currencyFormat(
                  totais?.sumPrecoTotalPlanosPedidosPorQuantidadeDiasPeriodo,
                ),
                textAlign: 'right',
              },
            ]}
          />
        )}
      />
    </>
  );
};

import { FiArrowLeft } from 'react-icons/fi';

import styles from './StepTitle.module.scss';

interface StepTitleProps {
  title: string;
  onGoBack: () => void;
}

export const StepTitle: React.FC<StepTitleProps> = ({ title, onGoBack }) => {
  return (
    <div className={styles['step-title-container']}>
      <button
        type="button"
        className={styles['go-back-button']}
        onClick={onGoBack}
      >
        <FiArrowLeft size={28} />
      </button>
      <h4 className="heading4 title">{title}</h4>
    </div>
  );
};

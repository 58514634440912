import { useState } from 'react';
import { FiShield, FiShieldOff } from 'react-icons/fi';

import { IconButton } from 'components/IconButton';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

type ActivateOptionProps = {
  disabled?: boolean;
  title?: string;
  isActive: boolean;
  toolTipDirection?: 'top' | 'bottom' | 'left' | 'right';
  onActivate: () => void;
};

export const ActivateOption: React.FC<ActivateOptionProps> = ({
  disabled,
  title,
  isActive,
  toolTipDirection,
  onActivate,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleActivate = async (isConfirmed: boolean) => {
    setIsModalVisible(false);

    if (isConfirmed) {
      onActivate();
    }
  };

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={toggleActivate}
        icon={isActive ? FiShieldOff : FiShield}
        title={isActive ? 'Inativar' : 'Ativar'}
        description={`Tem certeza que você deseja ${
          isActive ? 'inativar' : 'ativar'
        }  este registro?`}
      />
      <IconButton
        disabled={disabled}
        icon={() =>
          isActive ? <FiShield size={22} /> : <FiShieldOff size={22} />
        }
        title={title ? title : 'Ativar / Inativar'}
        className={!disabled ? 'hover-orange' : ''}
        onClick={() => setIsModalVisible(true)}
        toolTipDirection={toolTipDirection}
      />
    </>
  );
};

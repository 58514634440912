import { FcCalendar } from 'react-icons/fc';
import { Log } from 'utils/Log';
import { Card } from './Card';

import styles from './UltimasAtividades.module.scss';

interface UltimasAtividadesProps {
  items: Log[];
}

export const UltimasAtividades: React.FC<UltimasAtividadesProps> = ({
  items,
}) => {
  return (
    <Card
      title="Últimas atividades"
      headerIcon={() => <FcCalendar size={26} />}
    >
      <div className={styles['ultimas-atividades-container']}>
        <div className={styles['legend-container']}>
          <p className={styles['legend-item']}>
            <span className={`${styles['circle']} ${styles['incluir']}`} />
            Incluir
          </p>
          <p className={styles['legend-item']}>
            <span className={`${styles['circle']} ${styles['alterar']}`} />
            Alterar
          </p>
          <p className={styles['legend-item']}>
            <span className={`${styles['circle']} ${styles['visualizar']}`} />
            Visualizar
          </p>
          <p className={styles['legend-item']}>
            <span className={`${styles['circle']} ${styles['outros']}`} />
            Outros
          </p>
        </div>

        <ul className={styles['ultimas-atividades-list']}>
          {items.map(item => (
            <li>
              <p className={`${styles['legend-item']} body2 title`}>
                <span
                  className={`${styles['circle']} ${
                    styles[`${item.tipo.toLocaleLowerCase()}`]
                  }`}
                />
                {item.usuario.nome}
              </p>
              <p className="body3 textLight">{item.descricao}</p>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

import { useState } from 'react';

import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';

import { Form, FormFooter, Input } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { useAuth } from 'hooks/useAuth';

interface OperadoraCreateQuery {
  operadoraCreate: {
    operadoraId: number;
    nome: string;
  };
}

interface OperadoraCreateVariables {
  input: { nome: string };
}

const OPERADORA_CREATE_QUERY = gql`
  mutation ($input: OperadoraCreateInput!) {
    operadoraCreate(input: $input) {
      operadoraId
      nome
    }
  }
`;

export const NewOperadoraView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({ nome: '' });

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      nome: Yup.string().required('Nome obrigatório.'),
    }),
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { data, errors } = await graphqlAuth<
          OperadoraCreateQuery,
          OperadoraCreateVariables
        >({
          auth,
          setAuth,
          query: OPERADORA_CREATE_QUERY,
          variables: { input: { nome: values.nome } },
        });

        if (errors) {
          throw new GraphQLError('Falha ao criar operadora', errors);
        }

        successToast(
          `Operadora: ${data.operadoraCreate.nome}`,
          'Criada com sucesso!',
        );

        navigate('/operadoras');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
      </Form>
    </>
  );
};

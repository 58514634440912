export const makeDashboardQuery = (acessos: string[]) => {
  let inputs = '';
  let queries = '';

  if (acessos.includes('DASHBOARDS.CONSULTAS')) {
    inputs += `
        $consultasAggregationInput: QueryDashboardsConsultasAggregationInput!
        $consultasRankingUsuariosInput: QueryDashboardsConsultasRankingsUsuariosInput!
      `;
    queries += `
        dashboards {
          consultas {
            aggregation(input: $consultasAggregationInput) {
              quantidade
            }
            rankings {
              usuarios(input: $consultasRankingUsuariosInput) {
                posicao
                quantidade
                usuario {
                  usuarioId
                  nome
                }
              }
            }
          }
        }
      `;
  }

  if (acessos.includes('DASHBOARDS.PEDIDOS')) {
    inputs += `
        $pedidosAggregationInput: QueryDashboardsPedidosAggregationInput!
        $pedidosRankingUsuariosInput: QueryDashboardsPedidosRankingsUsuariosInput!
        $pedidosStatusesInput: QueryDashboardsPedidosStatusesInput!
      `;
    queries += `
        dashboards {
          pedidos {
            aggregation(input: $pedidosAggregationInput) {
              quantidade
              total
            }
            rankings {
              usuarios(input: $pedidosRankingUsuariosInput) {
                posicao
                quantidade
                usuario {
                  usuarioId
                  nome
                }
              }
            }
            statuses(input: $pedidosStatusesInput) {
              quantidade
              status {
                statusId
                nome
              }
            }
          }
        }
      `;
  }

  if (acessos.includes('DASHBOARDS.ULTIMAS_ATIVIDADES')) {
    queries += `
      dashboards {
        ultimasAtividades {
          logId
          tipo
          descricao
          createdAt
          usuario {
            nome
            avatar {
              url
            }
          }
        }
      }
    `;
  }

  return `
    query ${
      inputs &&
      `(
      ${inputs}
      )`
    } {
      me {
        usuario {
          usuarioId
        }
      }
      ${queries}
    }
  `;
};

import { Layout } from 'components/Layout';
import { DetailConsultaSophusView } from 'views/relatorios/consultas/sophus/detail';

export const DetailConsultaSophusPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Consultas Sophus', href: '/relatorios/consultas-sophus' },
          { label: 'Detalhes' },
        ],
      }}
    >
      <DetailConsultaSophusView />
    </Layout>
  );
};

import { cnpjValidator, cpfValidator } from 'functions/validators';
import * as Yup from 'yup';

export const pedidoDadosPessoaJuridicaSchema = Yup.object({
  razaoSocial: Yup.string().required('Razão Social obrigatório.'),
  nomeFantasia: Yup.string().required('Nome Fantasia obrigatório.'),
  cnpj: Yup.string()
    .test({
      test: value => cnpjValidator.isValid(`${value}`),
      message: 'CNPJ Inválido.',
    })
    .required('CNPJ obrigatório.'),
  dataAbertura: Yup.string().required('Data Abertura obrigatório.'),
  inscricaoEstadual: Yup.string(),
  inscricaoMunicipal: Yup.string(),
  tipoRetencaoNotaFiscal: Yup.string(),
  ramoEmpresa: Yup.string(),
  representanteNome: Yup.string().required('Nome obrigatório.'),
  representanteCpf: Yup.string()
    .test({
      test: value => cpfValidator.isValid(`${value}`),
      message: 'CNPJ Inválido.',
    })
    .required('CPF obrigatório'),
  representanteRg: Yup.string(),
  representanteEstadoCivil: Yup.string(),
  representanteDataNascimento: Yup.string().required(
    'Data Nascimento obrigatório.',
  ),
  redesSociais: Yup.array().of(
    Yup.object({
      redeSocial: Yup.string(),
      link: Yup.string().when('redeSocial', {
        is: (value: string) => value !== '',
        then: Yup.string().required('Link obrigatório.'),
      }),
    }),
  ),
  telefones: Yup.array()
    .of(
      Yup.object({
        nome: Yup.string().required('Descrição do Telefone obrigatório.'),
        telefone: Yup.string().required('Telefone obrigatório.'),
        whatsapp: Yup.boolean(),
      }).required(),
    )
    .min(2, 'No mínimo ${min} telefones devem ser cadastrados.')
    .required(),
  emails: Yup.array().of(
    Yup.object({
      nome: Yup.string(),
      email: Yup.string().when('nome', {
        is: (value: string) => value !== '',
        then: Yup.string()
          .email('E-mail inválido')
          .required('E-mail obrigatório.'),
      }),
    }),
  ),
});

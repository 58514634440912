import { Fieldset } from 'components/Form';
import { dateFormatWithHours } from 'functions/formatters';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

import styles from './FieldsetPedidoConsulta.module.scss';

interface ConsultaProps {
  dataConsulta: string;
  resultado: string;
  url: string;
}

export const FieldsetPedidoConsulta: React.FC<ConsultaProps> = props => {
  return (
    <Fieldset legend="Resultado da consulta">
      <section className={styles['consulta-section']}>
        <article className={styles['consulta-result-container']}>
          {props.resultado === 'SEM_RESTRICAO' && (
            <div className={styles['consulta-result']}>
              <FiCheckCircle size={22} color="var(--success)" />
              <h3 className="success">Cliente Aprovado</h3>
            </div>
          )}

          {props.resultado === 'REQUER_ANALISE' && (
            <div className={styles['consulta-result']}>
              <FiXCircle size={22} color="var(--error)" />
              <h3 className="error">Cliente Para Análise</h3>
            </div>
          )}

          <p className="body2 text">
            Data da Consulta:
            <span> {dateFormatWithHours(props.dataConsulta)}</span>
          </p>
        </article>

        <a
          href={props.url}
          target="_blank"
          rel="noreferrer"
          title="Clique para ver os detalhes da consulta."
        >
          Ver Detalhes
        </a>
      </section>
    </Fieldset>
  );
};

import { API_URL } from 'utils/constants';
import { createFetchError } from './createFetchError';

export type GraphQLFormDataFile = [string, File];

export interface GraphQLProps<Variables> {
  query: string;
  variables?: Variables;
  headers?: Record<string, string>;
}

export interface GraphQLFormDataProps<Variables>
  extends GraphQLProps<Variables> {
  files?: GraphQLFormDataFile[];
}

export interface GraphQLResult<T> {
  data: T;
  errors: Error[];
}

export const graphql = async <
  T = Record<string, any>,
  Variables = Record<string, any>,
>({
  query,
  variables,
  headers,
}: GraphQLProps<Variables>): Promise<GraphQLResult<T>> => {
  const url = new URL(API_URL());

  url.pathname = '/graphql';

  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  if (response.status !== 200) {
    const error = await createFetchError(response);

    throw error;
  }

  const { data, errors } = await response.json();

  return {
    data,
    errors,
  };
};

export const graphqlFormData = async <
  T = Record<string, any>,
  Variables = Record<string, any>,
>({
  query,
  variables,
  headers,
  files = [],
}: GraphQLFormDataProps<Variables>): Promise<GraphQLResult<T>> => {
  const url = new URL(API_URL());

  url.pathname = '/graphql';

  const formData = new FormData();

  formData.append('operations', JSON.stringify({ query, variables }));

  formData.append(
    'map',
    JSON.stringify(
      files.reduce(
        (prev, curr, index) => ({
          ...prev,
          [`${index}`]: [curr[0]],
        }),
        {} as Record<string, any>,
      ),
    ),
  );

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i][1];

    formData.append(`${i}`, file);
  }

  const response = await fetch(url.toString(), {
    method: 'POST',
    headers,
    body: formData,
  });

  if (response.status !== 200) {
    const error = await createFetchError(response);

    throw error;
  }

  const { data, errors } = await response.json();

  return {
    data,
    errors,
  };
};

import { Pedido } from '../list';
import { cnpjMask, cpfMask } from 'functions/mask';
import { dateFormatWithHours } from 'functions/formatters';
import { Button } from 'components/Form';

import styles from './CardPedido.module.scss';
import { useNavigate } from 'react-router';

interface CardPedidoProps {
  pedido: Pedido;
}

export const CardPedido: React.FC<CardPedidoProps> = ({ pedido }) => {
  const navigate = useNavigate();
  const pedidoStatus = pedido.statusId.replace(/[_]/g, ' ');

  return (
    <div className={styles['card-container']}>
      <div className={styles['card-header']}>
        <h5 className="heading5 title">
          {pedido.tipoPessoa === 'PESSOA_FISICA'
            ? 'Pessoa Física'
            : 'Pessoa Jurídica'}
        </h5>
      </div>

      <div className={styles['card-main']}>
        <p className="body2">
          STATUS: <span className="warning">{pedidoStatus}</span>
        </p>

        <div className={styles['pedido-details']}>
          <p className={`${styles['text-column']} body4 textLight`}>
            CÓD. PEDIDO:
            <span className="body2 text">{pedido.pedidoId}</span>
          </p>
          <p className={`${styles['text-column']} body4 textLight`}>
            DOCUMENTO:
            <span className="body2 text">
              {pedido.tipoPessoa === 'PESSOA_FISICA'
                ? cpfMask(pedido.clientePessoaFisicaCpf)
                : cnpjMask(pedido.clientePessoaJuridicaCnpj)}
            </span>
          </p>
          <p className={`${styles['text-column']} body4 textLight`}>
            NOME:
            <span className="body2 text">
              {pedido.tipoPessoa === 'PESSOA_FISICA'
                ? pedido.clientePessoaFisicaNome
                : pedido.clientePessoaJuridicaRazaoSocial}
            </span>
          </p>
          <p className={`${styles['text-column']} body4 textLight`}>
            AGEND. INSTALAÇÃO:
            <span className="body2 text">
              {pedido.dataAgendamentoInstalacao
                ? dateFormatWithHours(pedido.dataAgendamentoInstalacao)
                : '----'}
            </span>
          </p>
        </div>

        <Button
          type="button"
          onClick={() => navigate(`/pedidos/instalacoes/${pedido.pedidoId}`)}
        >
          Instalar pedido
        </Button>
      </div>
    </div>
  );
};

import { Link } from 'react-router-dom';
import { ToolTip } from 'components/ToolTip';

import styles from './IconLink.module.scss';

type IconLinkProps = {
  title: string;
  className?: string;
  disabled?: boolean;
  openAnotherTab?: boolean;
  toolTipDirection?: 'top' | 'bottom' | 'left' | 'right';
  path: string;
  icon: () => JSX.Element;
};

export const IconLink: React.FC<IconLinkProps> = ({
  title,
  icon: Icon,
  className = '',
  disabled,
  openAnotherTab,
  toolTipDirection,
  path,
}) => (
  <div className={`${styles['icon-link-container']}`}>
    {disabled ? (
      <i className={disabled ? styles['is-disabled'] : ''}>
        <Icon />
      </i>
    ) : openAnotherTab ? (
      <Link to={path} className={className} target="_blank" rel="noreferrer">
        <ToolTip title={title} direction={toolTipDirection}>
          <Icon />
        </ToolTip>
      </Link>
    ) : (
      <Link to={path} className={className}>
        <ToolTip title={title} direction={toolTipDirection}>
          <Icon />
        </ToolTip>
      </Link>
    )}
  </div>
);

import { useState } from 'react';
import { BiError } from 'react-icons/bi';
import { FiChevronDown, FiChevronUp, FiCopy } from 'react-icons/fi';
import { parseError } from 'functions/parseError';
import { copyTextToClipboard } from 'functions/copyTextToClipboard';
import { Button } from './Form';

import styles from './ErrorDetails.module.scss';

interface ErrorDetailsProps {
  error: {
    isErrored: boolean;
    error: Error | null;
    seeDetails: boolean;
  };
  onShowDetails: () => void;
}

export const ErrorDetails: React.FC<ErrorDetailsProps> = ({
  error,
  onShowDetails,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async (text: string) => {
    try {
      setIsCopied(true);
      await copyTextToClipboard(text);
    } finally {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  return (
    <div className={styles['error-details-container']}>
      <i>
        <BiError size={72} className="warning" />
      </i>

      <p className="body2 text">{error && error?.error?.message}</p>

      {error ? (
        <>
          <Button
            type="button"
            isOutlined
            background="secondary"
            onClick={onShowDetails}
            title="Clique para visualizar os detalhes do erro."
          >
            {error.seeDetails ? 'Esconder' : 'Ver Detalhes'}
            {error.seeDetails ? (
              <FiChevronUp size={22} />
            ) : (
              <FiChevronDown size={22} />
            )}
          </Button>

          {error.seeDetails && (
            <>
              <button
                type="button"
                className={[
                  styles['copy-button'],
                  isCopied ? styles['is-copied'] : '',
                ].join(' ')}
                onClick={() =>
                  handleCopyClick(
                    JSON.stringify(parseError(error.error), null, 2),
                  )
                }
              >
                <FiCopy size={18} />
                {isCopied ? 'Texto copiado' : 'Copiar'}
              </button>
              <code>
                <pre>{JSON.stringify(parseError(error.error), null, 2)}</pre>
              </code>
            </>
          )}
        </>
      ) : (
        <p>Nenhum conteúdo recebido pela API.</p>
      )}
    </div>
  );
};

import { Layout } from 'components/Layout';
import { ListConfiguracaoView } from 'views/configuracoes/list';

export const ListConfiguracaoPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Configurações' }] }}>
      <ListConfiguracaoView />
    </Layout>
  );
};

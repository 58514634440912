import { useEffect } from 'react';
import { RiFilterFill, RiFilterLine } from 'react-icons/ri';
import { ModalFilter } from './ModalFilter';
import { Button } from 'components/Form';

import styles from './styles.module.scss';

type TableFilterProps = {
  title: string;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  isFiltering: boolean;
  onClose: () => void;
  onClear: () => void;
};

export const TableFilter: React.FC<TableFilterProps> = ({
  title,
  isVisible,
  setIsVisible,
  children,
  isFiltering,
  onClose,
  onClear,
}) => {
  useEffect(() => {
    const keyEvent = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', keyEvent);

    return () => window.removeEventListener('keydown', keyEvent);
  }, []);

  return (
    <>
      <div className={styles['filter-button-container']}>
        <Button
          size="small"
          background="secondary"
          type="button"
          onClick={() => setIsVisible(true)}
        >
          {isFiltering ? (
            <RiFilterFill size={20} />
          ) : (
            <RiFilterLine size={20} />
          )}
          Filtrar
        </Button>

        {isFiltering && (
          <button
            type="button"
            className={styles['remove-filter-button']}
            title="Clique para limpar os filtros."
            onClick={onClear}
          >
            LIMPAR
          </button>
        )}
      </div>

      {isVisible && (
        <ModalFilter title={title} onClose={onClose}>
          {children}
        </ModalFilter>
      )}
    </>
  );
};

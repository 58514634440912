import { Layout } from 'components/Layout';
import { TecnicoDashboardView } from 'views/tecnico/dashboard/dashboard';

export const TecnicoDashboardPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Dashboard' }] }}>
      <TecnicoDashboardView />
    </Layout>
  );
};

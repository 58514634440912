import { useEffect, useState } from 'react';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { PedidoPlano } from 'utils/types/PedidoPlano';
import { PedidoFidelidade } from 'utils/types/PedidoFidelidade';
import { PedidoFinalidade } from 'utils/types/PedidoFinalidade';
import { PedidoEstadoCivil } from 'utils/types/PedidoEstadoCivil';
import { StepsPessoaJuridica } from '../utils/StepsPessoaJuridica';
import { PedidoDiaVencimento } from 'utils/types/PedidoDiaVencimento';

import { Fallback } from 'components/Fallback';
import { PedidoPlanos } from '../components/PedidoPlanos';
import { NewPedidoDados } from './components/NewPedidoDados';
import { NewPedidoAnexos } from './components/NewPedidoAnexos';
import { PedidoEnderecoCobranca } from '../components/PedidoEnderecoCobranca';
import { PedidoInformacoesServico } from '../components/PedidoInformacoesServico';
import { PedidoEnderecoInstalacao } from '../components/PedidoEnderecoInstalacao';

interface PedidoCreateData {
  planos: { items: PedidoPlano[] };
  fidelidades: { items: PedidoFidelidade[] };
  finalidades: { items: PedidoFinalidade[] };
  estadosCivis: { items: PedidoEstadoCivil[] };
  diasVencimentos: { items: PedidoDiaVencimento[] };
}

const PEDIDO_CREATE_DATA = gql`
  query {
    estadosCivis {
      items {
        estadoCivilId
        nome
      }
    }
    planos(filter: { equals: { ativo: true } }) {
      items {
        planoId
        nome
        preco
      }
    }
    diasVencimentos(filter: { equals: { ativo: true } }) {
      items {
        diaVencimentoId
        nome
      }
    }
    fidelidades(filter: { equals: { ativo: true } }) {
      items {
        fidelidadeId
        nome
      }
    }
    finalidades(filter: { equals: { ativo: true } }) {
      items {
        finalidadeId
        nome
      }
    }
  }
`;

interface InitialDataProps {
  estadosCivis: PedidoEstadoCivil[];
  planos: PedidoPlano[];
  diasVencimentos: PedidoDiaVencimento[];
  fidelidades: PedidoFidelidade[];
  finalidades: PedidoFinalidade[];
}

const STEPS: StepsPessoaJuridica[] = [
  'DADOS',
  'ENDERECO_INSTALACAO',
  'ENDERECO_COBRANCA',
  'PLANOS',
  'INFORMACOES_SERVICOS',
  'ANEXOS',
];

export const NewPedidoPessoaJuridicaView: React.FC = () => {
  const { auth, setAuth } = useAuth();
  const [pageStep, setPageStep] = useState<StepsPessoaJuridica>('DADOS');
  const [initialData, setInitialData] = useState<InitialDataProps | null>(null);

  const [newPedido, setNewPedido] = useState<Record<string, any>>({
    tipoPessoa: 'PESSOA_JURIDICA',
  });

  const query = useQueryAuth<PedidoCreateData>({
    auth,
    setAuth,
    query: PEDIDO_CREATE_DATA,
  });

  useEffect(() => {
    if (query.data) {
      setInitialData({
        estadosCivis: query.data.estadosCivis.items,
        planos: query.data.planos.items,
        diasVencimentos: query.data.diasVencimentos.items,
        fidelidades: query.data.fidelidades.items,
        finalidades: query.data.finalidades.items,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const prevStep = () => {
    const index = STEPS.findIndex(x => x === pageStep);
    setPageStep(STEPS[index - 1]);
  };

  const nextStep = () => {
    const index = STEPS.findIndex(x => x === pageStep);
    setPageStep(STEPS[index + 1]);
  };

  switch (pageStep) {
    case 'DADOS':
      return (
        <NewPedidoDados
          nextStep={nextStep}
          estadosCivis={initialData?.estadosCivis || []}
          newPedido={newPedido}
          setNewPedido={values => setNewPedido({ ...newPedido, ...values })}
        />
      );
    case 'ENDERECO_INSTALACAO':
      return (
        <PedidoEnderecoInstalacao
          prevStep={prevStep}
          nextStep={nextStep}
          values={newPedido.enderecoInstalacao}
          setValues={values =>
            setNewPedido({
              ...newPedido,
              enderecoInstalacao: {
                ...newPedido.enderecoInstalacao,
                ...values,
              },
            })
          }
        />
      );
    case 'ENDERECO_COBRANCA':
      return (
        <PedidoEnderecoCobranca
          prevStep={prevStep}
          nextStep={nextStep}
          values={{
            enderecoInstalacao: newPedido.enderecoInstalacao,
            enderecoCobranca: newPedido.enderecoCobranca,
          }}
          setValues={values =>
            setNewPedido({
              ...newPedido,
              enderecoCobranca: { ...newPedido.enderecoCobranca, ...values },
            })
          }
        />
      );
    case 'PLANOS':
      return (
        <PedidoPlanos
          prevStep={prevStep}
          nextStep={nextStep}
          planos={newPedido.planos}
          setPlanos={newPlanos =>
            setNewPedido({ ...newPedido, planos: newPlanos })
          }
          allPlanos={initialData?.planos || []}
        />
      );
    case 'INFORMACOES_SERVICOS':
      return (
        <PedidoInformacoesServico
          prevStep={prevStep}
          nextStep={nextStep}
          values={newPedido.informacoesServico}
          setValues={values =>
            setNewPedido({
              ...newPedido,
              informacoesServico: {
                ...newPedido.informacoesServico,
                ...values,
              },
            })
          }
          diasVencimentos={initialData?.diasVencimentos || []}
          fidelidades={initialData?.fidelidades || []}
          finalidades={initialData?.finalidades || []}
        />
      );
    case 'ANEXOS':
      return (
        <NewPedidoAnexos
          prevStep={prevStep}
          newPedido={newPedido}
          setNewPedido={values => setNewPedido({ ...newPedido, ...values })}
        />
      );

    default:
      return <></>;
  }
};

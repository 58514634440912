import { useForm } from 'hooks/useForm';
import { warnToast } from 'utils/toast';
import { Endereco } from 'utils/types/Endereco';
import { pedidoEnderecoSchema } from '../utils/pedidoEnderecoSchema';
import { cepMask } from 'functions/mask';
import { getAddress } from 'functions/getAddress';
import { convertEmptyKeysToNull } from 'functions/convertEmptyKeysToNull';

import { StepTitle } from './StepTitle';
import { NextStepFooter } from './NextStepFooter';
import { Button, Form, Input } from 'components/Form';

interface EnderecoCobrancaProps {
  values: {
    enderecoCobranca: Endereco;
    enderecoInstalacao: Endereco;
  };
  setValues: (values: Endereco) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export const PedidoEnderecoCobranca: React.FC<EnderecoCobrancaProps> = ({
  values,
  setValues,
  prevStep,
  nextStep,
}) => {
  const { fields, setFields, errors, submitHandler } = useForm<Endereco>({
    cep: values?.enderecoCobranca?.cep || '',
    numero: values?.enderecoCobranca?.numero || '',
    estado: values?.enderecoCobranca?.estado || '',
    logradouro: values?.enderecoCobranca?.logradouro || '',
    bairro: values?.enderecoCobranca?.bairro || '',
    cidade: values?.enderecoCobranca?.cidade || '',
    complemento: values?.enderecoCobranca?.complemento || '',
    pontoReferencia: values?.enderecoCobranca?.pontoReferencia || '',
    ibge: values?.enderecoCobranca?.ibge || '',
  });

  const searchCep = async (cep: string) => {
    try {
      if (cep === '') return;

      const address = await getAddress(cep);

      setFields({
        ...fields,
        bairro: address.bairro,
        cidade: address.localidade,
        complemento: address.complemento,
        logradouro: address.logradouro,
        estado: address.uf,
        ibge: address.ibge,
      });
    } catch (err: any) {
      warnToast('Não foi possível encontrar o CEP.', err.message);
    }
  };

  const handleClickMesmoEnderecoInstalacao = () => {
    setFields({
      ...fields,
      cep: values.enderecoInstalacao.cep || '',
      numero: values.enderecoInstalacao.numero || '',
      estado: values.enderecoInstalacao.estado || '',
      logradouro: values.enderecoInstalacao.logradouro || '',
      bairro: values.enderecoInstalacao.bairro || '',
      cidade: values.enderecoInstalacao.cidade || '',
      complemento: values.enderecoInstalacao.complemento || '',
      pontoReferencia: values.enderecoInstalacao.pontoReferencia || '',
      ibge: values.enderecoInstalacao.ibge || '',
    });
  };

  const handleSubmit = submitHandler({
    validateSchema: pedidoEnderecoSchema,
    callback: async values => {
      const parsedValues = convertEmptyKeysToNull(values);
      setValues({
        ...parsedValues,
        cep: parsedValues.cep.replace(/\D+/g, ''),
      });
      nextStep();
    },
  });

  return (
    <>
      <StepTitle title="Endereço Cobrança" onGoBack={prevStep} />
      <Form
        onSubmit={handleSubmit}
        columns={3}
        footerComponent={<NextStepFooter />}
      >
        <Button
          autoFocus
          type="button"
          title="Clique para utilizar o mesmo endereço da instalação"
          onClick={() => handleClickMesmoEnderecoInstalacao()}
        >
          Mesmo endereço da instalação
        </Button>
        <Input
          className="column-start"
          maxLength={9}
          inputMode="numeric"
          name="cep"
          label="CEP"
          error={errors.cep}
          value={cepMask(fields.cep)}
          onChange={value => setFields({ ...fields, cep: value })}
          onBlur={e => searchCep(e.target.value)}
        />
        <Input
          type="number"
          inputMode="numeric"
          min="0"
          name="numero"
          label="Número"
          error={errors.numero}
          value={fields.numero}
          onChange={value => setFields({ ...fields, numero: value })}
        />
        <Input
          name="cidade"
          label="Cidade"
          error={errors.cidade}
          value={fields.cidade}
          onChange={value => setFields({ ...fields, cidade: value })}
        />
        <Input
          name="estado"
          label="Estado"
          error={errors.estado}
          value={fields.estado}
          onChange={value => setFields({ ...fields, estado: value })}
        />
        <Input
          name="logradouro"
          label="Logradouro"
          error={errors.logradouro}
          value={fields.logradouro}
          onChange={value => setFields({ ...fields, logradouro: value })}
        />
        <Input
          name="bairro"
          label="Bairro"
          error={errors.bairro}
          value={fields.bairro}
          onChange={value => setFields({ ...fields, bairro: value })}
        />
        <Input
          name="complemento"
          label="Complemento"
          error={errors.complemento}
          value={fields.complemento}
          onChange={value => setFields({ ...fields, complemento: value })}
        />
        <Input
          name="pontoReferencia"
          label="Ponto de Referência"
          error={errors.pontoReferencia}
          value={fields.pontoReferencia}
          onChange={value => setFields({ ...fields, pontoReferencia: value })}
        />
      </Form>
    </>
  );
};

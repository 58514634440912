import { Layout } from 'components/Layout';
import { DetailOperadoraView } from 'views/operadoras/detail';

export const DetailOperadoraPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Operadoras', href: '/operadoras' },
          { label: 'Detalhes' },
        ],
      }}
    >
      <DetailOperadoraView />
    </Layout>
  );
};

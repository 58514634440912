import styles from './TableFooter.module.scss';

interface TableFooterProps {
  columns: {
    value: string | number | undefined;
    textAlign?: 'center' | 'left' | 'right';
  }[];
}

export const TableFooter: React.FC<TableFooterProps> = ({ columns }) => {
  return (
    <tfoot className={styles['table-footer']}>
      <tr>
        {columns.map((column, index) => (
          <td
            key={`tfoot-col-${index}`}
            className={column.textAlign ? `text-align-${column.textAlign}` : ''}
          >
            {column.value || '---'}
          </td>
        ))}
      </tr>
    </tfoot>
  );
};

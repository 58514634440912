import { Label } from './Label';
import styles from './Validacao.module.scss';

type ValidacaoProps = {
  className?: string;
  value: number;
  onChange?: (value: number) => void;
};

export const Validacao: React.FC<ValidacaoProps> = ({
  className,
  value,
  onChange,
}) => {
  return (
    <section
      className={`${styles['validacao-section']} ${className ? className : ''}`}
    >
      <Label htmlFor="validacao">Validação</Label>
      <div className={styles['validacao-button-list']}>
        {Array(10)
          .fill(null)
          .map((_, index) => (
            <button
              key={`button-${index}`}
              type="button"
              className={`${styles['validacao-button']} ${
                index + 1 === value ? styles['button-active'] : ''
              }`}
              onClick={() => onChange && onChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
      </div>
    </section>
  );
};

import type { Menu } from './menuItems';
import { useEffect, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { MenuItem } from './MenuItem';

import styles from './SubMenu.module.scss';

type SubMenuProps = {
  label: string;
  icon?: () => JSX.Element;
  items: Menu[];
};

export const SubMenu: React.FC<SubMenuProps> = ({ label, icon, items }) => {
  const [isDrop, setIsDrop] = useState(false);

  // Fecha um submenu que esteja aberto, ao clicar em outro submenu.
  useEffect(() => {
    if (!isDrop) {
      return;
    }

    const handleCloseDropdown = () => setIsDrop(false);

    window.addEventListener('click', handleCloseDropdown);

    return () => window.removeEventListener('click', handleCloseDropdown);
  }, [isDrop]);

  return (
    <li
      className={styles['submenu-container']}
      onClick={() => setIsDrop(!isDrop)}
    >
      <div className={`${styles['submenu-item']}`} title={label}>
        {icon && <i>{icon()}</i>}
        <p className="body2">{label}</p>
        <i className={`${isDrop ? styles['rotate-icon'] : ''}`}>
          <FiChevronRight size={18} />
        </i>
      </div>

      <div
        className={`${styles['submenu-list-container']} ${
          isDrop ? styles['drop-submenu'] : ''
        }`}
      >
        <ul className={styles['submenu-list']}>
          {items.map(menu => {
            if (menu.type === 'submenu') {
              return (
                <SubMenu
                  key={menu.label}
                  items={menu.children}
                  label={menu.label}
                />
              );
            }

            return <MenuItem key={menu.label} menu={menu} />;
          })}
        </ul>
      </div>
    </li>
  );
};

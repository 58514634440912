import * as Yup from 'yup';

export const editUsuarioSchema = Yup.object({
  nome: Yup.string().required('Nome obrigatório.'),
  login: Yup.string().required('Login obrigatório.'),
  senha: Yup.string().when({
    is: (value: any) => value !== null && value !== '',
    then: Yup.string().min(6, 'Mínimo de 6 caracteres.'),
  }),
  confirmarSenha: Yup.string().when('senha', {
    is: (value: any) => value !== null && value !== '',
    then: Yup.string()
      .min(6, 'Mínimo de 6 caracteres.')
      .test('match', 'As senhas não conferem.', function (confirmarSenha) {
        return confirmarSenha === this.parent.senha;
      })
      .required('Confirmar senha obrigatório.'),
  }),
  grupoAcesso: Yup.string().required('Grupo de Acesso obrigatório.'),
  perfil: Yup.string().required('Perfil obrigatório.'),
  statusEmpregaticio: Yup.string()
    .nullable()
    .required('Status empregatício obrigatório.'),
  dataDesligamento: Yup.string().when('statusEmpregaticio', {
    is: (value: any) => value === 'DESLIGADO',
    then: Yup.string().required('Data de desligamento obrigatório.'),
  }),
});

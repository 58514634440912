import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { Log } from 'utils/Log';
import { gql } from 'functions/gql';

import { TabList } from 'components/TabList';
import { Fallback } from 'components/Fallback';
import { LogsTable } from 'components/LogsTable';
import { Button, Form, Input } from 'components/Form';

interface FinalidadeDetailQuery {
  finalidade: {
    finalidadeId: string;
    nome: string;
    logs: { items: Log[] };
  };
}

interface FinalidadeDetailQueryVariables {
  id: { finalidadeId: string };
}

const FINALIDADE_DETAIL_QUERY = gql`
  query ($id: FinalidadeID!) {
    finalidade(id: $id) {
      finalidadeId
      nome
      logs {
        items {
          logId
          tipo
          descricao
          createdAt
          usuario {
            nome
            avatar {
              url
            }
          }
        }
      }
    }
  }
`;

export const DetailFinalidadeView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [fields, setFields] = useState({ nome: '' });

  const finalidadeId = params.finalidadesId || '';

  const query = useQueryAuth<
    FinalidadeDetailQuery,
    FinalidadeDetailQueryVariables
  >({
    auth,
    setAuth,
    query: FINALIDADE_DETAIL_QUERY,
    variables: { id: { finalidadeId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({ ...fields, nome: query.data.finalidade.nome });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  return (
    <TabList labels={['Detalhes', 'Logs']}>
      <Form
        footerComponent={
          <Button
            type="button"
            style={{ alignSelf: 'flex-end', width: '200px' }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        }
      >
        <Input readOnly name="nome" label="Nome" value={fields.nome} />
      </Form>
      <LogsTable logs={query.data?.finalidade.logs.items} />
    </TabList>
  );
};

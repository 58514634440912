import { useAuth } from 'hooks/useAuth';
import { Navigate } from 'react-router';

export interface WithAcessoProps {
  acesso: string;
}

export const WithAcesso: React.FC<WithAcessoProps> = ({ acesso, children }) => {
  const { acessos } = useAuth();

  if (!acessos.includes(acesso)) {
    console.error(
      `Acesso negado, usuário não possui acesso "${acesso}". Redirecionando para /`,
    );

    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

import { useEffect } from 'react';
import { IconBaseProps } from 'react-icons/lib';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

import { Button } from 'components/Form';
import { ModalOverlay } from './ModalOverlay';

import styles from './ModalConfirm.module.scss';

type ModalConfirmProps = {
  isVisible: boolean;
  onClose: (isConfirmed: boolean) => void;
  title: string;
  description: string;
  icon: React.ComponentType<IconBaseProps>;
};

export const ModalConfirm: React.FC<ModalConfirmProps> = ({
  isVisible,
  title,
  description,
  icon: Icon,
  onClose,
}) => {
  useEffect(() => {
    const keyEvent = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose(false);
      }
    };

    window.addEventListener('keydown', keyEvent);

    return () => window.removeEventListener('keydown', keyEvent);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <ModalOverlay onClick={() => onClose(false)}>
      <div
        className={styles['modal-confirm-frame-container']}
        onClick={e => e.stopPropagation()}
      >
        <Icon size={52} color="var(--error)" />
        <h3 className="heading3 title">{title}</h3>
        <p className="body1 text">{description}</p>

        <div className={styles['modal-confirm-frame-buttons']}>
          <Button
            autoFocus
            type="button"
            background="success"
            onClick={() => onClose(true)}
          >
            <FiCheckCircle size={22} />
            Sim
          </Button>
          <Button
            type="button"
            background="error"
            isOutlined
            onClick={() => onClose(false)}
          >
            <FiXCircle size={22} />
            Não
          </Button>
        </div>
      </div>
    </ModalOverlay>
  );
};

import { useState } from 'react';
import { FiEye, FiUserX } from 'react-icons/fi';
import { useNavigate } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useError } from 'hooks/useError';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { Sort } from 'utils/Sort';
import { Filter } from 'utils/Filter';
import { successToast } from 'utils/toast';
import { Pagination } from 'utils/Pagination';
import { GraphQLError } from 'utils/GraphQLError';
import { StatusEmpregaticioEnum } from 'utils/enums/StatusEmpregaticioEnum';

import { gql } from 'functions/gql';
import { paginate } from 'functions/paginate';
import { graphqlAuth } from 'functions/graphqlAuth';
import { dateFormat, dateFormatWithHours } from 'functions/formatters';

import { UserAvatar } from './UserAvatar';
import { UsuarioFilter } from './UsuarioFilter';

import { Table } from 'components/Table';
import { Badge } from 'components/Badge';
import { Fallback } from 'components/Fallback';
import { EditOption } from 'components/EditOption';
import { IconButton } from 'components/IconButton';
import { FlexContainer } from 'components/FlexContainer';
import { ActivateOption } from 'components/ActivateOption';
import { ModalDesligamento } from './ModalDesligamento';

interface UsuarioUpdateQueryVariables {
  id: { usuarioId: string };
  input: {
    ativo?: boolean;
    dataDesligamento?: string | null;
    statusEmpregaticio?: StatusEmpregaticioEnum;
  };
}

interface UsuarioUpdateQuery {
  usuarioUpdate: { usuarioId: string };
}

const USUARIO_UPDATE_QUERY = gql`
  mutation ($id: UsuarioID!, $input: UsuarioUpdateInput!) {
    usuarioUpdate(id: $id, input: $input) {
      usuarioId
    }
  }
`;

interface UsuarioListQueryVariables {
  filter?: Filter;
  pagination: Pagination;
  sort?: Sort[];
}

interface UsuarioListQuery {
  usuarios: {
    totalCount: number;
    items: {
      usuarioId: string;
      ativo: boolean;
      avatar: { url: string };
      createdAt: string;
      login: string;
      nome: string;
      isRoot: boolean;
      perfil: { perfilId: string; nome: string };
      dataAdmissao: string;
      dataDesligamento: string;
      statusEmpregaticio: StatusEmpregaticioEnum;
      grupoAcesso: { grupoAcessoId: string; nome: string };
    }[];
  };
}

const USUARIO_LIST_QUERY = gql`
  query ($filter: UsuarioFilter, $pagination: Pagination, $sort: [Sort!]) {
    usuarios(filter: $filter, pagination: $pagination, sort: $sort) {
      totalCount
      items {
        usuarioId
        ativo
        avatar {
          url
        }
        createdAt
        login
        nome
        isRoot
        perfil {
          perfilId
          nome
        }
        dataAdmissao
        dataDesligamento
        statusEmpregaticio
        grupoAcesso {
          grupoAcessoId
          nome
        }
      }
    }
  }
`;

export const ListUsuarioView: React.FC = () => {
  const navigate = useNavigate();
  const { withErrorHandling } = useError();
  const { acessos, auth, setAuth } = useAuth();
  const [showModalDesligamento, setShowModalDesligamento] = useState({
    isVisible: false,
    usuarioId: null as string | null,
  });
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [sort, setSort] = useState<Sort | null>(null);
  const [filter, setFilter] = useState<Filter | null>(null);

  const query = useQueryAuth<UsuarioListQuery, UsuarioListQueryVariables>({
    auth,
    setAuth,
    query: USUARIO_LIST_QUERY,
    variables: {
      filter: filter ? filter : undefined,
      pagination: paginate(pagination),
      sort: sort ? [sort] : undefined,
    },
  });

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  const { data } = query;

  const handleActivate = withErrorHandling(
    async (
      usuarioId: string,
      ativo: boolean,
      statusEmpregaticio: StatusEmpregaticioEnum,
    ) => {
      const { errors } = await graphqlAuth<
        UsuarioUpdateQuery,
        UsuarioUpdateQueryVariables
      >({
        query: USUARIO_UPDATE_QUERY,
        variables: {
          id: { usuarioId },
          input: {
            ativo: !ativo,
            ...(statusEmpregaticio === 'DESLIGADO'
              ? {
                  statusEmpregaticio: 'ADMITIDO',
                  dataDesligamento: null,
                }
              : {}),
          },
        },
        auth,
        setAuth,
      });

      if (errors) {
        throw new GraphQLError('Falha ao ativar/inativar usuário.', errors);
      }

      successToast('Usuário alterado com sucesso.');

      await query.refetch();
    },
  );

  return (
    <>
      {showModalDesligamento.isVisible && (
        <ModalDesligamento
          usuarioId={showModalDesligamento.usuarioId!}
          refetch={query.refetch}
          onClose={() =>
            setShowModalDesligamento({ isVisible: false, usuarioId: null })
          }
        />
      )}
      <Table
        title="Usuários"
        columns={[
          {
            key: 'opcoes',
            label: 'Opções',
            render: ({ ativo, usuarioId, statusEmpregaticio, isRoot }) => (
              <FlexContainer>
                <EditOption
                  disabled={
                    !acessos.includes('CADASTROS.USUARIOS.ALTERAR') || isRoot
                  }
                  path={`/usuarios/${usuarioId}`}
                  toolTipDirection="right"
                />
                <ActivateOption
                  isActive={ativo}
                  onActivate={() =>
                    handleActivate(usuarioId, ativo, statusEmpregaticio)
                  }
                  disabled={
                    !acessos.includes('CADASTROS.USUARIOS.ALTERAR') || isRoot
                  }
                  toolTipDirection="right"
                />
                <IconButton
                  title="Visualizar"
                  className="hover-green"
                  icon={() => <FiEye size={22} />}
                  disabled={!acessos.includes('CADASTROS.USUARIOS.VISUALIZAR')}
                  onClick={() => navigate(`/usuarios/${usuarioId}/detalhes`)}
                />
                <IconButton
                  title="Desligar usuário"
                  className="hover-red"
                  icon={() => <FiUserX size={22} />}
                  disabled={statusEmpregaticio === 'DESLIGADO' || isRoot}
                  onClick={() =>
                    setShowModalDesligamento({ isVisible: true, usuarioId })
                  }
                />
              </FlexContainer>
            ),
          },
          {
            key: 'usuarioId',
            label: 'ID',
            sortable: true,
          },
          {
            key: 'avatar',
            label: 'Avatar',
            render: ({ avatar }) => <UserAvatar url={avatar.url} />,
          },
          {
            key: 'nome',
            label: 'Nome',
            sortable: true,
          },
          {
            key: 'login',
            label: 'Login',
            sortable: true,
          },
          {
            key: 'grupoAcesso',
            label: 'Grupo de Acesso',
            render: ({ grupoAcesso }) => <p>{grupoAcesso.nome}</p>,
          },
          {
            key: 'perfil',
            label: 'Perfil',
            render: ({ perfil }) => <p>{perfil.nome}</p>,
          },
          {
            key: 'ativo',
            label: 'Ativo',
            sortable: true,
            render: ({ ativo }) => (
              <Badge background={ativo ? 'success' : 'error'}>
                {ativo ? 'Sim' : 'Não'}
              </Badge>
            ),
          },
          {
            key: 'statusEmpregaticio',
            label: 'Status Empregatício',
            render: ({ statusEmpregaticio }) => (
              <p>{statusEmpregaticio || '---'}</p>
            ),
          },
          {
            key: 'dataAdmissao',
            label: 'Data Admissão',
            render: ({ dataAdmissao }) => <p>{dateFormat(dataAdmissao)}</p>,
          },
          {
            key: 'dataDesligamento',
            label: 'Data Desligamento',
            render: ({ dataDesligamento }) => (
              <p>{dataDesligamento ? dateFormat(dataDesligamento) : '---'}</p>
            ),
          },
          {
            key: 'createdAt',
            label: 'Data Criação',
            render: ({ createdAt }) => <p>{dateFormatWithHours(createdAt)}</p>,
            sortable: true,
          },
        ]}
        items={data.usuarios.items}
        getRowId={({ usuarioId }) => usuarioId}
        totalCount={data.usuarios.totalCount}
        pagination={pagination}
        onChangePage={page => setPagination({ ...pagination, page })}
        onChangePerPage={perPage =>
          setPagination({ ...pagination, perPage, page: 1 })
        }
        sort={sort}
        onSort={sortField => setSort(sortField)}
        headerSideComponent={() => (
          <UsuarioFilter
            isFiltering={filter !== null}
            onFilter={filterProps => {
              setFilter(filterProps);
              setPagination({ ...pagination, page: 1 });
            }}
            onClear={() => setFilter(null)}
          />
        )}
      />
    </>
  );
};

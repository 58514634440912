import { Button } from 'components/Form';

import ilustration from 'assets/page-not-found.svg';
import styles from './NotFoundView.module.scss';
import { useNavigate } from 'react-router';

export const NotFoundView: React.FC = () => {
  const navigate = useNavigate();

  return (
    <section className={styles['not-found-section']}>
      <figure>
        <img
          src={ilustration}
          alt="Ilustração de um computador em manutenção"
        />
      </figure>
      <article className={styles['not-found-description']}>
        <h1>404</h1>
        <p>Página não encontrada.</p>

        <Button
          type="button"
          background="primary"
          onClick={() => navigate('/')}
        >
          Voltar ao Início
        </Button>
      </article>
    </section>
  );
};

import { useEffect, useState } from 'react';
import styles from './Fieldset.module.scss';

type FieldSetProps = {
  legend: string;
  className?: string;
  columns?: number;
};

export const Fieldset: React.FC<FieldSetProps> = ({
  children,
  legend,
  className,
  columns = 1,
}) => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    setIsLargeScreen(window.innerWidth > 1000);

    const handleResize = () => setIsLargeScreen(window.innerWidth > 1000);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <fieldset
      className={`${styles['fieldset-container']} ${
        className ? className : ''
      }`}
    >
      <legend>{legend}</legend>

      <div
        className={`${styles['fieldset-content']} ${
          isLargeScreen ? `col-${columns}` : ''
        }`}
      >
        {children}
      </div>
    </fieldset>
  );
};

import { Button } from 'components/Form';
import { ModalOverlay } from './ModalOverlay';
import { ErrorDetails } from 'components/ErrorDetails';

import styles from './ModalError.module.scss';

type ModalErrorProps = {
  error: {
    isErrored: boolean;
    error: Error | null;
    seeDetails: boolean;
  };
  onClose: () => void;
  onShowDetails: () => void;
};

export const ModalError: React.FC<ModalErrorProps> = ({
  error,
  onClose,
  onShowDetails,
}) => {
  return (
    <ModalOverlay>
      <div className={styles['modal-frame']}>
        <ErrorDetails error={error} onShowDetails={onShowDetails} />
        <Button background="success" onClick={onClose}>
          OK
        </Button>
      </div>
    </ModalOverlay>
  );
};

import { Layout } from 'components/Layout';
import { DetailUsuarioView } from 'views/usuarios/detail';

export const DetailUsuarioPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Usuários', href: '/usuarios' },
          { label: 'Detalhes' },
        ],
      }}
    >
      <DetailUsuarioView />
    </Layout>
  );
};

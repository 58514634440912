import { Layout } from 'components/Layout';
import { DetailMaterialView } from 'views/materiais/detail';

export const DetailMaterialPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Materiais', href: '/materiais' },
          { label: 'Detalhes' },
        ],
      }}
    >
      <DetailMaterialView />
    </Layout>
  );
};

export type Address = {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
};

export const getAddress = async (cepToSearch: string) => {
  const cepValue = cepToSearch.replace(/[^\d]/g, '');

  if (cepValue.length !== 8) {
    throw new Error('CEP Invalido.');
  }

  const response = await fetch(
    `https://viacep.com.br/ws/${cepToSearch || ''}/json`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    },
  );

  if (response.status !== 200) {
    throw new Error('Erro ao requisitar o ViaCep.');
  }

  const data = await response.json();

  if (data.erro) {
    throw new Error('CEP não encontrado.');
  }

  return data as Address;
};

import iconv from 'iconv-lite';

export const exportToCsv = (fileName: string, rows: string[][]) => {
  let processRow = (row: any) => {
    let finalVal = '';

    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j].toString();

      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }

      let result = innerValue.replace(/"/g, '""');

      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';

      if (j > 0) finalVal += ';';

      finalVal += result;
    }

    return finalVal + '\n';
  };

  let csvFile = '';

  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  let blob = new Blob([iconv.encode(csvFile, 'win1252')], {
    type: 'text/csv;charset=win1252;',
  });

  let link = document.createElement('a');

  if (link.download !== undefined) {
    let url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

import { useNavigate } from 'react-router';

import { useForm } from 'hooks/useForm';
import { cnpjMask, cpfMask } from 'functions/mask';
import { convertEmptyKeysToNull } from 'functions/convertEmptyKeysToNull';

import { warnToast } from 'utils/toast';
import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { RedeSocial } from 'utils/types/RedeSocial';
import { PedidoEstadoCivil } from 'utils/types/PedidoEstadoCivil';
import { NewPedidoPessoaJuridica } from 'views/pedidos/utils/NewPedidoPessoaJuridica';
import { pedidoDadosPessoaJuridicaSchema } from 'views/pedidos/utils/pedidoDadosPessoaJuridicaSchema';

import { PhoneList } from 'components/PhoneList';
import { EmailList } from 'components/EmailList';
import { Form, Input, Select } from 'components/Form';
import { StepTitle } from '../../components/StepTitle';
import { NextStepFooter } from 'views/pedidos/components/NextStepFooter';
import { RedeSocialEnum } from 'utils/enums/RedeSocialEnum';
import { RedeSocialList } from 'components/RedeSocialList';

interface Fields {
  razaoSocial: string;
  nomeFantasia: string;
  cnpj: string;
  dataAbertura: string;
  inscricaoEstadual: string;
  inscricaoMunicipal: string;
  tipoRetencaoNotaFiscal: string;
  ramoEmpresa: string;
  redesSociais: RedeSocial[];
  telefones: Telefone[];
  emails: Email[];
  representanteNome: string;
  representanteCpf: string;
  representanteRg: string;
  representanteSexo: string;
  representanteEstadoCivil: string;
  representanteDataNascimento: string;
}

interface DadosProps {
  estadosCivis: PedidoEstadoCivil[];
  newPedido: NewPedidoPessoaJuridica;
  setNewPedido: (values: NewPedidoPessoaJuridica) => void;
  nextStep: () => void;
}

export const NewPedidoDados: React.FC<DadosProps> = ({
  estadosCivis,
  newPedido,
  setNewPedido,
  nextStep,
}) => {
  const navigate = useNavigate();
  const { fields, setFields, errors, submitHandler } = useForm<Fields>({
    cnpj: newPedido.dados?.cnpj || '',
    razaoSocial: newPedido.dados?.razaoSocial || '',
    nomeFantasia: newPedido.dados?.nomeFantasia || '',
    dataAbertura: newPedido.dados?.dataAbertura || '',
    inscricaoEstadual: newPedido.dados?.inscricaoEstadual || '',
    inscricaoMunicipal: newPedido.dados?.inscricaoMunicipal || '',
    tipoRetencaoNotaFiscal: newPedido.dados?.tipoRetencaoNotaFiscal || '',
    ramoEmpresa: newPedido.dados?.ramoEmpresa || '',
    redesSociais: newPedido.dados?.redesSociais || [
      { link: '', redeSocial: '' as RedeSocialEnum },
    ],
    telefones: newPedido.dados?.telefones || [
      { nome: '', telefone: '', whatsapp: false },
      { nome: '', telefone: '', whatsapp: false },
    ],
    emails: newPedido.dados?.emails || [{ nome: '', email: '' }],
    representanteNome: newPedido.dados?.representanteNome || '',
    representanteCpf: newPedido.dados?.representanteCpf || '',
    representanteRg: newPedido.dados?.representanteRg || '',
    representanteSexo: newPedido.dados?.representanteSexo || '',
    representanteEstadoCivil: newPedido.dados?.representanteEstadoCivil || '',
    representanteDataNascimento:
      newPedido.dados?.representanteDataNascimento || '',
  });

  const handleSubmit = submitHandler({
    validateSchema: pedidoDadosPessoaJuridicaSchema,
    callback: async values => {
      const phones = values.telefones.map(item => item.telefone);
      const isDuplicate = phones.some(
        (item, index) => phones.indexOf(item) !== index,
      );

      if (isDuplicate) {
        warnToast('Telefone Duplicado', 'Informe telefones diferentes.');
        return;
      }

      const parsedValues = convertEmptyKeysToNull(values);

      setNewPedido({
        dados: {
          ...parsedValues,
          cnpj: parsedValues.cnpj.replace(/\D+/g, ''),
          representanteCpf: parsedValues.representanteCpf.replace(/\D+/g, ''),
        },
      });
      nextStep();
    },
  });

  return (
    <>
      <StepTitle
        title="Dados do Cliente"
        onGoBack={() => navigate('/pedidos')}
      />
      <Form
        onSubmit={handleSubmit}
        columns={3}
        footerComponent={<NextStepFooter />}
      >
        <Input
          autoFocus
          maxLength={18}
          name="cnpj"
          label="CNPJ"
          error={errors.cnpj}
          value={cnpjMask(fields.cnpj)}
          onChange={value => setFields({ ...fields, cnpj: value })}
        />
        <Input
          name="razaoSocial"
          label="Razão Social"
          error={errors.razaoSocial}
          value={fields.razaoSocial}
          onChange={value => setFields({ ...fields, razaoSocial: value })}
        />
        <Input
          name="nomeFantasia"
          label="Nome Fantasia"
          error={errors.nomeFantasia}
          value={fields.nomeFantasia}
          onChange={value => setFields({ ...fields, nomeFantasia: value })}
        />
        <Input
          name="inscricaoEstadual"
          label="Inscrição Estadual"
          error={errors.inscricaoEstadual}
          value={fields.inscricaoEstadual}
          onChange={value => setFields({ ...fields, inscricaoEstadual: value })}
        />
        <Input
          name="inscricaoMunicipal"
          label="Inscrição Municipal"
          error={errors.inscricaoMunicipal}
          value={fields.inscricaoMunicipal}
          onChange={value =>
            setFields({ ...fields, inscricaoMunicipal: value })
          }
        />
        <Input
          type="date"
          name="dataAbertura"
          label="Data Abertura"
          error={errors.dataAbertura}
          value={fields.dataAbertura}
          onChange={value => setFields({ ...fields, dataAbertura: value })}
        />
        <Input
          name="tipoRetencaoNotaFiscal"
          label="Tipo Retenção Nota Fiscal"
          error={errors.tipoRetencaoNotaFiscal}
          value={fields.tipoRetencaoNotaFiscal}
          onChange={value =>
            setFields({ ...fields, tipoRetencaoNotaFiscal: value })
          }
        />
        <Input
          name="ramoEmpresa"
          label="Ramo da Empresa"
          error={errors.ramoEmpresa}
          value={fields.ramoEmpresa}
          onChange={value => setFields({ ...fields, ramoEmpresa: value })}
        />

        <h4 className="heading4 title span-3">Contato</h4>

        <RedeSocialList
          className="span-3"
          errors={errors.redesSociais}
          redesSociais={fields.redesSociais}
          setNewRedesSociais={newRedesSociais =>
            setFields({ ...fields, redesSociais: newRedesSociais })
          }
        />

        <PhoneList
          className="span-3"
          errors={errors.telefones}
          telefones={fields.telefones}
          setNewTelefones={newTelefones =>
            setFields({ ...fields, telefones: newTelefones })
          }
          onRemove={index =>
            fields.telefones.length > 2 &&
            setFields({
              ...fields,
              telefones: fields.telefones.filter((_, i) => index !== i),
            })
          }
        />

        <EmailList
          className="span-3"
          errors={errors.emails}
          emails={fields.emails}
          setNewEmails={newEmails =>
            setFields({ ...fields, emails: newEmails })
          }
        />

        <h4 className="heading4 title span-3">Representante Legal</h4>

        <Input
          name="representanteNome"
          label="Nome"
          error={errors.representanteNome}
          value={fields.representanteNome}
          onChange={value => setFields({ ...fields, representanteNome: value })}
        />
        <Input
          maxLength={14}
          name="representanteCpf"
          label="CPF"
          error={errors.representanteCpf}
          value={cpfMask(fields.representanteCpf)}
          onChange={value => setFields({ ...fields, representanteCpf: value })}
        />
        <Input
          name="representanteRg"
          label="RG"
          error={errors.representanteRg}
          value={fields.representanteRg}
          onChange={value => setFields({ ...fields, representanteRg: value })}
        />
        <Input
          type="date"
          name="representanteDataNascimento"
          label="Data Nascimento"
          error={errors.representanteDataNascimento}
          value={fields.representanteDataNascimento}
          onChange={value =>
            setFields({ ...fields, representanteDataNascimento: value })
          }
        />
        <Select
          name="sexo"
          label="Sexo"
          error={errors.representanteSexo}
          value={fields.representanteSexo}
          onChange={value => setFields({ ...fields, representanteSexo: value })}
          options={[
            { label: 'Masculino', value: 'MASCULINO' },
            { label: 'Feminino', value: 'FEMININO' },
            { label: 'Outros', value: 'OUTROS' },
          ]}
        />
        <Select
          name="representanteEstadoCivil"
          label="Estado Civil"
          error={errors.representanteEstadoCivil}
          value={fields.representanteEstadoCivil}
          onChange={value =>
            setFields({ ...fields, representanteEstadoCivil: value })
          }
          options={estadosCivis.map(({ estadoCivilId, nome }) => ({
            label: nome,
            value: estadoCivilId,
          }))}
        />
      </Form>
    </>
  );
};

import { Layout } from 'components/Layout';
import { PedidoInstalacaoView } from 'views/tecnico/pedidos/instalar';

export const PedidoInstalacaoPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Pedido Instalação' }] }}>
      <PedidoInstalacaoView />
    </Layout>
  );
};

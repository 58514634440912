import { Layout } from 'components/Layout';
import { EditMaterialView } from 'views/materiais/edit';

export const EditMaterialPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Materiais', href: '/materiais' },
          { label: 'Editar' },
        ],
      }}
    >
      <EditMaterialView />
    </Layout>
  );
};

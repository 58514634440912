import { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { diaVencimentoSelect } from './utils/diaVencimentoSelect';

import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { Form, FormFooter, Input, Select } from 'components/Form';

interface DiaVencimentoCreateQuery {
  diaVencimentoCreate: {
    diaVencimentoId: string;
    nome: string;
  };
}

interface DiaVencimentoCreateVariables {
  input: {
    diaVencimentoId: string;
    nome: string;
  };
}

const DIA_VENCIMENTO_CREATE_QUERY = gql`
  mutation ($input: DiaVencimentoCreateInput!) {
    diaVencimentoCreate(input: $input) {
      diaVencimentoId
      nome
    }
  }
`;

export const NewDiaVencimentoView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    diaVencimento: '',
    descricao: '',
  });

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      diaVencimento: Yup.string().required('Dia Vencimento obrigatório.'),
      descricao: Yup.string().required('Descrição obrigatório.'),
    }),
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { data, errors } = await graphqlAuth<
          DiaVencimentoCreateQuery,
          DiaVencimentoCreateVariables
        >({
          auth,
          setAuth,
          query: DIA_VENCIMENTO_CREATE_QUERY,
          variables: {
            input: {
              nome: values.descricao,
              diaVencimentoId: values.diaVencimento,
            },
          },
        });

        if (errors) {
          throw new GraphQLError('Falha ao criar dia de vencimento', errors);
        }

        successToast(
          `Dia de Vencimento: ${data.diaVencimentoCreate.nome}`,
          'Criado com sucesso!',
        );

        navigate('/dia-vencimento');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
        columns={2}
      >
        <Select
          autoFocus
          label="Dia Vencimento"
          name="diaVencimento"
          onChange={value => setFields({ ...fields, diaVencimento: value })}
          value={fields.diaVencimento}
          error={errors.diaVencimento}
          options={diaVencimentoSelect}
        />

        <Input
          name="descricao"
          label="Descrição"
          error={errors.descricao}
          value={fields.descricao}
          onChange={value => setFields({ ...fields, descricao: value })}
        />
      </Form>
    </>
  );
};

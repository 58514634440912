import { useEffect } from 'react';

import { FiX } from 'react-icons/fi';

import { ModalOverlay } from './ModalOverlay';

import styles from './Modal.module.scss';

type ModalProps = {
  onClose: () => void;
  size?: 'sm' | 'lg' | 'xl';
  title: string;
};

export const Modal: React.FC<ModalProps> = ({
  onClose,
  size = 'xl',
  title,
  children,
}) => {
  useEffect(() => {
    const keyEvent = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', keyEvent);

    return () => window.removeEventListener('keydown', keyEvent);
  }, []);

  return (
    <ModalOverlay>
      <div
        className={`${styles['modal-frame-container']} ${
          styles[`modal-${size}`]
        }`}
        onClick={e => e.stopPropagation()}
      >
        <header className={styles['modal-frame-header']}>
          <h3 className="heading3 title">{title}</h3>
          <button
            aria-label="Clique para fechar a janela"
            onClick={() => onClose()}
            title="Fechar janela"
          >
            <FiX size={22} />
          </button>
        </header>

        <main className={styles['modal-frame-main-container']}>{children}</main>
      </div>
    </ModalOverlay>
  );
};

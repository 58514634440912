import { currencyMask } from 'functions/mask';
import styles from './ConsultaItensTable.module.scss';

interface CosultaItensTableProps {
  consultaItens: {
    consultaItemId: string;
    tipo: string;
    quantidade: number;
    valor: number;
  }[];
}

export const ConsultaItensTable: React.FC<CosultaItensTableProps> = ({
  consultaItens,
}) => {
  return (
    <table className={styles['consulta-itens-table-container']}>
      <thead className={styles['consulta-itens-table-head']}>
        <tr>
          <th>ID</th>
          <th>Tipo</th>
          <th>Quantidade</th>
          <th style={{ textAlign: 'right' }}>Valor</th>
        </tr>
      </thead>
      <tbody className={styles['consulta-itens-table-body']}>
        {consultaItens.length > 0 ? (
          consultaItens.map(consultaItem => (
            <tr key={consultaItem.consultaItemId}>
              <td>{consultaItem.consultaItemId}</td>
              <td>{consultaItem.tipo.replace(/[_]/g, ' ')}</td>
              <td>{consultaItem.quantidade}</td>
              <td style={{ textAlign: 'right' }}>
                {currencyMask(`${consultaItem.valor}`)}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={4} style={{ textAlign: 'center' }}>
              Nenhum registro encontrado.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

import { Layout } from 'components/Layout';
import { ListMeusPedidosView } from 'views/me/meus-pedidos';

export const ListMeusPedidosPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Meus Pedidos' }] }}>
      <ListMeusPedidosView />
    </Layout>
  );
};

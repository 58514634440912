import styles from './Badge.module.scss';

interface BadgeProps {
  background?:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
  className?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  background = 'primary',
  className,
  children,
}) => {
  return (
    <div
      className={[
        styles['badge-container'],
        `${
          background ? styles[`badge-${background}`] : styles[`badge-primary`]
        }`,
        `${className ? className : ''}`,
      ].join(' ')}
    >
      <p>{children}</p>
    </div>
  );
};

import { Link, LinkProps } from 'react-router-dom';
import styles from './LinkButton.module.scss';

interface LinkButtonProps extends LinkProps {
  className?: string;
  background?: 'primary' | 'secondary' | 'success' | 'error';
  isOutlined?: boolean;
  size?: 'large' | 'normal' | 'small';
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  background,
  className,
  isOutlined,
  size,
  children,
  ...rest
}) => {
  return (
    <Link
      className={[
        styles['link-container'],
        `${background ? styles[`btn-${background}`] : styles[`btn-primary`]}`,
        `${isOutlined ? styles[`btn-outlined-${background}`] : ''}`,
        `${size ? styles[size] : styles['normal']}`,
        `${className ? className : ''}`,
      ].join(' ')}
      {...rest}
    >
      {children}
    </Link>
  );
};

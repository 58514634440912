import { useState } from 'react';

import { gql } from 'functions/gql';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { Table } from 'components/Table';
import { Fallback } from 'components/Fallback';
import { EditOption } from 'components/EditOption';
import { FlexContainer } from 'components/FlexContainer';
import { dateFormatWithHours } from 'functions/formatters';

interface ConfiguracaoListQuery {
  configuracoes: {
    totalCount: number;
    items: {
      configuracaoId: string;
      serasaSophusEntidade: string;
      serasaSophusMaximoDiasReutilizarConsulta: string;
      createdAt: string;
    }[];
  };
}

const CONFIGURACAO_LIST_QUERY = gql`
  query {
    configuracoes {
      totalCount
      items {
        configuracaoId
        serasaSophusEntidade
        serasaSophusMaximoDiasReutilizarConsulta
        createdAt
      }
    }
  }
`;

export const ListConfiguracaoView: React.FC = () => {
  const { auth, setAuth, acessos } = useAuth();
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });

  const query = useQueryAuth<ConfiguracaoListQuery>({
    auth,
    setAuth,
    query: CONFIGURACAO_LIST_QUERY,
  });

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  const { data } = query;

  return (
    <Table
      title="Configurações"
      columns={[
        {
          key: 'opcoes',
          label: 'Opções',
          render: ({ configuracaoId }) => (
            <FlexContainer>
              <EditOption
                path={`/configuracoes/${configuracaoId}`}
                toolTipDirection="right"
                disabled={!acessos.includes('CONFIGURACOES.ALTERAR')}
              />
            </FlexContainer>
          ),
        },
        {
          key: 'configuracaoId',
          label: 'ID',
        },
        {
          key: 'serasaSophusEntidade',
          label: 'Sophus Entidade',
        },
        {
          key: 'serasaSophusMaximoDiasReutilizarConsulta',
          label: 'Máx. Dias Reutilizar Consulta',
        },
        {
          key: 'createdAt',
          label: 'Data Criação',
          render: ({ createdAt }) => <p>{dateFormatWithHours(createdAt)}</p>,
          sortable: true,
        },
      ]}
      items={data.configuracoes.items}
      getRowId={({ configuracaoId }) => configuracaoId}
      totalCount={data.configuracoes.totalCount}
      pagination={pagination}
      onChangePage={page => setPagination({ ...pagination, page })}
      onChangePerPage={perPage =>
        setPagination({ ...pagination, perPage, page: 1 })
      }
    />
  );
};

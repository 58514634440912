import {
  PedidoEditarDataQuery,
  PedidoUpdateQueryVariables,
} from '../pessoa-juridica/edit';

import { Email } from 'utils/types/Email';
import { Endereco } from 'utils/types/Endereco';
import { Telefone } from 'utils/types/Telefone';
import { RedeSocial } from 'utils/types/RedeSocial';
import { PedidoAnexo } from 'utils/types/PedidoAnexo';
import { PedidoPlano } from 'utils/types/PedidoPlano';
import { EstadoCivilEnum } from 'utils/enums/EstadoCivilEnum';
import { PedidoStatusEnum } from 'utils/enums/PedidoStatusEnum';
import { RedeSocialEnum } from 'utils/enums/RedeSocialEnum';

interface ValuesFields {
  tipoPessoa: string;
  dados: {
    razaoSocial: string;
    nomeFantasia: string;
    cnpj: string;
    dataAbertura: string;
    inscricaoEstadual: string;
    inscricaoMunicipal: string;
    tipoRetencaoNotaFiscal: string;
    ramoEmpresa: string;
    redesSociais: RedeSocial[];
    telefones: Telefone[];
    emails: Email[];
    representanteNome: string;
    representanteCpf: string;
    representanteRg: string;
    representanteSexo: string;
    representanteEstadoCivilId: string;
    representanteDataNascimento: string;
  };
  enderecoInstalacao: Endereco;
  enderecoCobranca: Endereco;
  consulta: {
    consultaId: string;
    dataConsulta: string;
    resultado: string;
    url: string;
  };
  planos: { plano: string; planosSelecionados: PedidoPlano[] };
  informacoesServico: {
    diaVencimentoId: string;
    fidelidadeId: string;
    pedidoFinalidades: string[];
    rede: string;
    senha: string;
  };
  anexos: { anexos: File[]; pedidoAnexos: PedidoAnexo[] };
  dadosPedido: { statusPedido: string };
  instalacao: {
    tecnicoId: string;
    dataAgendamentoInstalacao: string;
    numeroSerieOnu: string;
    cto: string;
    ctoPorta: string;
    pedidoMateriais: { materialId: string; nome: string; quantidade: number }[];
  };
}

export const parseQueryData = (data: PedidoEditarDataQuery) => {
  const pedidoFields = {
    tipoPessoa: data.pedido.tipoPessoa,
    dados: {
      cnpj: data.pedido.clientePessoaJuridicaCnpj,
      razaoSocial: data.pedido.clientePessoaJuridicaRazaoSocial,
      nomeFantasia: data.pedido.clientePessoaJuridicaNomeFantasia,
      inscricaoEstadual:
        data.pedido.clientePessoaJuridicaInscricaoEstadual || '',
      inscricaoMunicipal:
        data.pedido.clientePessoaJuridicaInscricaoMunicipal || '',
      dataAbertura: data.pedido.clientePessoaJuridicaDataAbertura,
      tipoRetencaoNotaFiscal:
        data.pedido.clientePessoaJuridicaTipoRetencaoNotaFiscal || '',
      ramoEmpresa: data.pedido.clientePessoaJuridicaRamoEmpresa || '',
      redesSociais:
        data.pedido.allPedidosRedesSociais.length > 0
          ? data.pedido.allPedidosRedesSociais
          : [{ link: '', redeSocial: '' as RedeSocialEnum }],
      telefones: data.pedido.allTelefones,
      emails:
        data.pedido.allEmails.length > 0
          ? data.pedido.allEmails
          : [{ nome: '', email: '' }],
      representanteNome:
        data.pedido.clientePessoaJuridicaRepresentanteLegalNome,
      representanteCpf: data.pedido.clientePessoaJuridicaRepresentanteLegalCpf,
      representanteRg:
        data.pedido.clientePessoaJuridicaRepresentanteLegalRg || '',
      representanteDataNascimento:
        data.pedido.clientePessoaJuridicaRepresentanteLegalDataNascimento,
      representanteSexo:
        data.pedido.clientePessoaJuridicaRepresentanteLegalSexo || '',
      representanteEstadoCivilId:
        data.pedido.clientePessoaJuridicaRepresentanteLegalEstadoCivilId || '',
    },
    enderecoInstalacao: {
      cep: data.pedido.enderecoInstalacao.cep,
      numero: data.pedido.enderecoInstalacao.numero,
      logradouro: data.pedido.enderecoInstalacao.logradouro,
      bairro: data.pedido.enderecoInstalacao.bairro,
      cidade: data.pedido.enderecoInstalacao.cidade.nome,
      estado: data.pedido.enderecoInstalacao.cidade.estado.nome,
      complemento: data.pedido.enderecoInstalacao.complemento || '',
      pontoReferencia: data.pedido.enderecoInstalacao.pontoReferencia || '',
      ibge: `${data.pedido.enderecoInstalacao.cidadeId}`,
    },
    enderecoCobranca: {
      cep: data.pedido.enderecoCobranca.cep,
      numero: data.pedido.enderecoCobranca.numero,
      logradouro: data.pedido.enderecoCobranca.logradouro,
      bairro: data.pedido.enderecoCobranca.bairro,
      cidade: data.pedido.enderecoCobranca.cidade.nome,
      estado: data.pedido.enderecoCobranca.cidade.estado.nome,
      complemento: data.pedido.enderecoCobranca.complemento || '',
      pontoReferencia: data.pedido.enderecoCobranca.pontoReferencia || '',
      ibge: `${data.pedido.enderecoCobranca.cidadeId}`,
    },
    consulta: {
      consultaId: data.pedido.consulta.consultaId,
      dataConsulta: data.pedido.consulta.dataConsulta,
      resultado: data.pedido.consulta.resultado,
      url: data.pedido.consulta.url,
    },
    planos: {
      plano: '',
      planosSelecionados: data.pedido.allPedidosPlanos,
    },
    informacoesServico: {
      diaVencimentoId: data.pedido.diaVencimentoId,
      fidelidadeId: data.pedido.fidelidadeId,
      pedidoFinalidades: data.pedido.allFinalidades.map(
        ({ finalidadeId }) => finalidadeId,
      ),
      rede: data.pedido.rede,
      senha: data.pedido.senha,
    },
    anexos: { anexos: [], pedidoAnexos: data.pedido.allAnexos },
    dadosPedido: {
      statusPedido: data.pedido.status?.statusId || '',
    },
    instalacao: {
      tecnicoId: data.pedido.tecnicoId,
      dataAgendamentoInstalacao: data.pedido.dataAgendamentoInstalacao,
      numeroSerieOnu: data.pedido?.numeroSerieOnu || '',
      cto: data.pedido?.cto || '',
      ctoPorta: data.pedido?.ctoPorta || '',
      pedidoMateriais: data.pedido.pedidosMateriais.items.map(item => ({
        materialId: item.material.materialId,
        nome: item.material.nome,
        quantidade: item.quantidade,
      })),
    },
  };

  const parsedInitialData = {
    diasVencimentos: data.diasVencimentos.items,
    fidelidades: data.fidelidades.items,
    finalidades: data.finalidades.items,
    planos: data.planos.items,
    tecnicos: data.usuarios.items,
    materiais: data.materiais.items,
  };

  return { pedidoFields, parsedInitialData };
};

export const parseFieldsToQueryVariables = (
  pedidoId: string,
  values: ValuesFields,
): PedidoUpdateQueryVariables => {
  return {
    id: { pedidoId },
    input: {
      clientePessoaJuridicaCnpj: values.dados.cnpj,
      clientePessoaJuridicaRazaoSocial: values.dados.razaoSocial,
      clientePessoaJuridicaNomeFantasia: values.dados.nomeFantasia,
      clientePessoaJuridicaInscricaoEstadual:
        values.dados.inscricaoEstadual || undefined,
      clientePessoaJuridicaInscricaoMunicipal:
        values.dados.inscricaoMunicipal || undefined,
      clientePessoaJuridicaDataAbertura: values.dados.dataAbertura,
      clientePessoaJuridicaTipoRetencaoNotaFiscal:
        values.dados.tipoRetencaoNotaFiscal || undefined,
      clientePessoaJuridicaRamoEmpresa: values.dados.ramoEmpresa || undefined,
      redesSociais: values.dados?.redesSociais
        .filter(redeSocial => redeSocial.link !== '')
        .map(({ link, redeSocial }) => ({ link, redeSocial })),
      telefones: values.dados.telefones,
      emails: values.dados?.emails.filter(email => email.nome !== ''),
      clientePessoaJuridicaRepresentanteLegalNome:
        values.dados.representanteNome,
      clientePessoaJuridicaRepresentanteLegalCpf: values.dados.representanteCpf,
      clientePessoaJuridicaRepresentanteLegalRg:
        values.dados.representanteRg || undefined,
      clientePessoaJuridicaRepresentanteLegalDataNascimento:
        values.dados.representanteDataNascimento,
      clientePessoaJuridicaRepresentanteLegalSexo:
        values.dados.representanteSexo || undefined,
      clientePessoaJuridicaRepresentanteLegalEstadoCivilId:
        (values.dados.representanteEstadoCivilId as EstadoCivilEnum) ||
        undefined,
      enderecoInstalacao: {
        bairro: values.enderecoInstalacao.bairro,
        cep: values.enderecoInstalacao.cep,
        logradouro: values.enderecoInstalacao.logradouro,
        nome: 'Endereço Instalação',
        numero: values.enderecoInstalacao.numero,
        cidadeId: Number(values.enderecoInstalacao.ibge) || undefined,
        complemento: values.enderecoInstalacao.complemento || undefined,
        pontoReferencia: values.enderecoInstalacao.pontoReferencia || undefined,
      },
      enderecoCobranca: {
        bairro: values.enderecoCobranca.bairro,
        cep: values.enderecoCobranca!.cep,
        logradouro: values.enderecoCobranca.logradouro,
        nome: 'Endereço Cobrança',
        numero: values.enderecoCobranca!.numero,
        cidadeId: Number(values.enderecoInstalacao.ibge) || undefined,
        complemento: values.enderecoCobranca.complemento || undefined,
        pontoReferencia: values.enderecoCobranca.pontoReferencia || undefined,
      },
      consultaId: values.consulta.consultaId,
      planos: values.planos.planosSelecionados.map(({ planoId }) => ({
        planoId,
      })),
      diaVencimentoId: values.informacoesServico.diaVencimentoId,
      fidelidadeId: values.informacoesServico.fidelidadeId,
      finalidades: values.informacoesServico.pedidoFinalidades.map(
        finalidadeId => ({ finalidadeId }),
      ),
      rede: values.informacoesServico.rede,
      senha: values.informacoesServico.senha,
      pontoAdicional: false,
      statusId: values.dadosPedido.statusPedido as PedidoStatusEnum,
      tecnicoId: values.instalacao.tecnicoId || undefined,
      dataAgendamentoInstalacao:
        values.instalacao.dataAgendamentoInstalacao || undefined,
      numeroSerieOnu: values.instalacao.numeroSerieOnu || undefined,
      cto: values.instalacao.cto || undefined,
      ctoPorta: values.instalacao.ctoPorta || undefined,
      tipoPessoa: 'PESSOA_JURIDICA',
      anexos: [],
    },
  };
};

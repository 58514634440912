import { FallbackError } from './FallbackError';
import { Loading } from './Loading';

export interface FallbackProps {
  loading: boolean;
  errors: Error[] | null;
  goBackURL?: string;
}

export const Fallback: React.FC<FallbackProps> = ({
  loading,
  goBackURL,
  errors,
}) => {
  if (loading) {
    return <Loading />;
  }

  return <FallbackError errors={errors} goBackURL={goBackURL} />;
};

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';

import { Log } from 'utils/Log';
import { diaVencimentoSelect } from './utils/diaVencimentoSelect';

import { TabList } from 'components/TabList';
import { Fallback } from 'components/Fallback';
import { LogsTable } from 'components/LogsTable';
import { Button, Form, Input, Select } from 'components/Form';

interface DiaVencimentoDetailQueryVariables {
  id: { diaVencimentoId: string };
}

interface DiaVencimentoDetailQuery {
  diaVencimento: {
    diaVencimentoId: string;
    nome: string;
    logs: { items: Log[] };
  };
}

const DIA_VENCIMENTO_DETAIL_QUERY = gql`
  query ($id: DiaVencimentoID!) {
    diaVencimento(id: $id) {
      diaVencimentoId
      nome
      logs {
        items {
          logId
          tipo
          descricao
          createdAt
          usuario {
            nome
            avatar {
              url
            }
          }
        }
      }
    }
  }
`;

export const DetailDiaVencimentoView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [fields, setFields] = useState({
    diaVencimento: '',
    descricao: '',
  });

  const diaVencimentoId = params.diaVencimentoId || '';

  const query = useQueryAuth<
    DiaVencimentoDetailQuery,
    DiaVencimentoDetailQueryVariables
  >({
    auth,
    setAuth,
    query: DIA_VENCIMENTO_DETAIL_QUERY,
    variables: { id: { diaVencimentoId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({
        descricao: query.data.diaVencimento.nome,
        diaVencimento: query.data.diaVencimento.diaVencimentoId,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  return (
    <TabList labels={['Detalhes', 'Logs']}>
      <Form
        columns={2}
        footerComponent={
          <Button
            type="button"
            style={{ alignSelf: 'flex-end', width: '200px' }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        }
      >
        <Select
          autoFocus
          label="Dia Vencimento"
          name="diaVencimento"
          readOnly
          value={fields.diaVencimento}
          options={diaVencimentoSelect}
        />

        <Input
          name="descricao"
          label="Descrição"
          readOnly
          value={fields.descricao}
        />
      </Form>
      <LogsTable logs={query.data.diaVencimento.logs.items} />
    </TabList>
  );
};

import {
  FiAward,
  FiBookmark,
  FiBriefcase,
  FiCalendar,
  FiEdit3,
  FiFileText,
  FiGrid,
  FiHome,
  FiLayers,
  FiList,
  FiPhoneCall,
  FiSettings,
  FiTool,
  FiTrendingUp,
  FiUserPlus,
  FiUsers,
} from 'react-icons/fi';

export type Menu = SubMenu | MenuLinkItem;

export type MenuLinkItem = {
  acessoId: string;
  type: 'menuItem';
  label: string;
  path: string;
  icon?: () => JSX.Element;
  public?: boolean;
};

export type SubMenu = {
  acessoId: string;
  type: 'submenu';
  label: string;
  icon?: () => JSX.Element;
  children: Menu[];
  public?: boolean;
};

export const menuItems: Menu[] = [
  {
    acessoId: 'DASHBOARD',
    type: 'menuItem',
    label: 'Início',
    path: '/',
    icon: () => <FiHome size={20} />,
    public: true,
  },
  {
    acessoId: 'CADASTROS',
    type: 'submenu',
    label: 'Cadastros',
    icon: () => <FiEdit3 size={20} />,
    children: [
      {
        acessoId: 'CADASTROS.DIAS_VENCIMENTOS',
        type: 'menuItem',
        path: '/dia-vencimento',
        label: 'Dias Vencimentos',
        icon: () => <FiCalendar size={20} />,
      },
      {
        acessoId: 'CADASTROS.FIDELIDADES',
        type: 'menuItem',
        path: '/fidelidades',
        label: 'Fidelidades',
        icon: () => <FiBookmark size={20} />,
      },
      {
        acessoId: 'CADASTROS.FINALIDADES',
        type: 'menuItem',
        path: '/finalidades',
        label: 'Finalidades',
        icon: () => <FiBriefcase size={20} />,
      },
      {
        acessoId: 'CADASTROS.GRUPOS_ACESSOS',
        type: 'menuItem',
        path: '/grupo-acesso',
        label: 'Grupo de Acesso',
        icon: () => <FiGrid size={20} />,
      },
      {
        acessoId: 'CADASTROS.MATERIAIS',
        type: 'menuItem',
        path: '/materiais',
        label: 'Materiais',
        icon: () => <FiTool size={20} />,
      },
      {
        acessoId: 'CADASTROS.OPERADORAS',
        type: 'menuItem',
        path: '/operadoras',
        label: 'Operadoras',
        icon: () => <FiPhoneCall size={20} />,
      },
      {
        acessoId: 'CADASTROS.PEDIDOS.VISUALIZAR',
        type: 'menuItem',
        path: '/pedidos',
        label: 'Pedidos',
        icon: () => <FiFileText size={20} />,
      },
      {
        acessoId: 'CADASTROS.PLANOS',
        type: 'menuItem',
        path: '/planos',
        label: 'Planos',
        icon: () => <FiLayers size={20} />,
      },
      {
        acessoId: 'CADASTROS.USUARIOS',
        type: 'menuItem',
        path: '/usuarios',
        label: 'Usuários',
        icon: () => <FiUserPlus size={20} />,
      },
    ],
  },
  {
    acessoId: 'CADASTROS.PEDIDOS.VISUALIZAR',
    type: 'menuItem',
    path: '/pedidos',
    label: 'Pedidos',
    icon: () => <FiFileText size={20} />,
  },
  {
    acessoId: 'MEUS_PEDIDOS',
    type: 'menuItem',
    path: '/me/meus-pedidos',
    label: 'Meus Pedidos',
    icon: () => <FiTrendingUp size={20} />,
  },
  {
    acessoId: 'CADASTROS.PEDIDOS.AUDITAR',
    type: 'menuItem',
    path: '/auditoria',
    label: 'Auditoria',
    icon: () => <FiAward size={20} />,
  },
  {
    acessoId: 'RELATORIOS',
    type: 'submenu',
    label: 'Relatorios',
    icon: () => <FiList size={20} />,
    children: [
      {
        acessoId: 'RELATORIOS.CONSULTAS',
        type: 'menuItem',
        path: '/relatorios/consultas-sophus',
        label: 'Consultas Sophus',
        icon: () => <FiFileText size={20} />,
      },
      {
        acessoId: 'RELATORIOS.CONSULTAS_USUARIOS',
        type: 'menuItem',
        path: '/relatorios/consultas-usuarios',
        label: 'Consultas Usuários',
        icon: () => <FiUsers size={20} />,
      },
      {
        acessoId: 'RELATORIOS.USUARIOS_DESEMPENHO_PEDIDOS',
        type: 'menuItem',
        path: '/relatorios/usuarios/desempenho-vendas',
        label: 'Desempenho de Vendas',
        icon: () => <FiFileText size={20} />,
      },
    ],
  },
  {
    acessoId: 'CONFIGURACOES',
    type: 'menuItem',
    path: '/configuracoes',
    label: 'Configurações',
    icon: () => <FiSettings size={20} />,
  },
];

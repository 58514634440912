import { useState } from 'react';
import { useNavigate } from 'react-router';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';

import { Email } from 'utils/types/Email';
import { successToast } from 'utils/toast';
import { Telefone } from 'utils/types/Telefone';
import { SexoEnum } from 'utils/enums/SexoEnum';
import { GraphQLError } from 'utils/GraphQLError';
import { EnderecoInput } from 'utils/types/Endereco';
import { TipoPessoaEnum } from 'utils/enums/TipoPessoaEnum';
import { EstadoCivilEnum } from 'utils/enums/EstadoCivilEnum';
import { PedidoStatusEnum } from 'utils/enums/PedidoStatusEnum';
import { NewPedidoPessoaFisica } from 'views/pedidos/utils/NewPedidoPessoaFisica';

import { gql } from 'functions/gql';
import { graphqlAuth, graphqlFormDataAuth } from 'functions/graphqlAuth';

import { StepTitle } from '../../components/StepTitle';
import { Form, InputMultiFile, PreviewFile } from 'components/Form';
import { FinishStepFooter } from 'views/pedidos/components/FinishStepFooter';

import ilustracao from 'assets/anexos-illustration.svg';
import styles from './NewPedidoAnexos.module.scss';
import { RedeSocial } from 'utils/types/RedeSocial';

interface ConsultaQueryVariables {
  input: {
    tipoPessoa: TipoPessoaEnum;
    clientePessoaFisicaNome: string;
    clientePessoaFisicaCpf: string;
    clientePessoaFisicaRg?: string;
    clientePessoaFisicaDataNascimento: string;
    clientePessoaFisicaSexo?: SexoEnum;
    clientePessoaFisicaEstadoCivilId?: EstadoCivilEnum;
    documento: string;
    telefones: Telefone[];
    emails?: Email[];
    enderecoInstalacao: EnderecoInput;
    enderecoCobranca: EnderecoInput;
  };
}

interface ConsultaQuery {
  actions: { consultar: { consultaId: string; resultado: string } };
}

const CONSULTA_QUERY = gql`
  mutation ($input: ConsultarInput!) {
    actions {
      consultar(input: $input) {
        consultaId
        resultado
      }
    }
  }
`;

interface PedidoCreateQueryVariables {
  input: {
    clientePessoaFisicaNome: string;
    clientePessoaFisicaCpf: string;
    clientePessoaFisicaRg?: string;
    clientePessoaFisicaDataNascimento: string;
    clientePessoaFisicaSexo?: string;
    clientePessoaFisicaEstadoCivilId?: EstadoCivilEnum;
    telefones: Telefone[];
    emails?: Email[];
    enderecoInstalacao: EnderecoInput;
    enderecoCobranca: EnderecoInput;
    consultaId: string;
    planos: { planoId: string }[];
    diaVencimentoId: string;
    fidelidadeId: string;
    finalidades?: { finalidadeId: string }[];
    rede: string;
    senha: string;
    clientePessoaFisicaVinculoEmpregaticioId: string;
    clientePessoaFisicaAutonomoAtividadeExercida?: string;
    clientePessoaFisicaRegistradoRenda?: number;
    clientePessoaFisicaRegistradoLocalTrabalho?: string;
    clientePessoaFisicaRegistradoTelefoneTrabalho?: Partial<Telefone>;
    clientePessoaFisicaInternetAnterior: boolean;
    clientePessoaFisicaInternetAnteriorOperadoraId?: string;
    clientePessoaFisicaInternetAnteriorMotivoCancelamento?: string;
    clientePessoaFisicaTipoResidencia?: string;
    clientePessoaFisicaQuantidadeMoradores?: number;
    pontoAdicional: boolean;
    redesSociais?: RedeSocial[];
    statusId: PedidoStatusEnum;
    tipoPessoa: 'PESSOA_FISICA';
    vendedorId: string;
    anexos: File[];
  };
}

interface PedidoCreateQuery {
  pedidoCreate: { pedidoId: string };
}

const PEDIDO_CREATE_QUERY = gql`
  mutation ($input: PedidoCreateInput!) {
    pedidoCreate(input: $input) {
      pedidoId
    }
  }
`;

interface AnexosProps {
  newPedido: NewPedidoPessoaFisica;
  setNewPedido: (values: NewPedidoPessoaFisica) => void;
  prevStep: () => void;
}

export const NewPedidoAnexos: React.FC<AnexosProps> = ({
  newPedido,
  setNewPedido,
  prevStep,
}) => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const { fields, setFields, submitHandler } = useForm<{ anexos: File[] }>({
    anexos: newPedido.anexos || [],
  });

  const handleSubmit = submitHandler({
    callback: async values => {
      setDisableSubmitButton(true);

      try {
        setNewPedido({ anexos: values.anexos });

        const { data: consultaData, errors: consultaErrors } =
          await graphqlAuth<ConsultaQuery, ConsultaQueryVariables>({
            auth,
            setAuth,
            query: CONSULTA_QUERY,
            variables: {
              input: {
                tipoPessoa: 'PESSOA_FISICA',
                documento: newPedido.dados!.cpf,
                clientePessoaFisicaNome: newPedido.dados!.nome,
                clientePessoaFisicaCpf: newPedido.dados!.cpf,
                clientePessoaFisicaRg: newPedido.dados?.rg,
                clientePessoaFisicaDataNascimento:
                  newPedido.dados!.dataNascimento,
                clientePessoaFisicaSexo: newPedido.dados?.sexo as SexoEnum,
                clientePessoaFisicaEstadoCivilId: newPedido.dados
                  ?.estadoCivil as EstadoCivilEnum,
                emails: newPedido.dados?.emails.filter(
                  email => email.nome !== '',
                ),
                telefones: newPedido.dados!.telefones,
                enderecoInstalacao: {
                  bairro: newPedido.enderecoInstalacao?.bairro,
                  cep: newPedido.enderecoInstalacao!.cep,
                  logradouro: newPedido.enderecoInstalacao?.logradouro,
                  nome: 'Endereço Instalação',
                  numero: newPedido.enderecoInstalacao!.numero,
                  cidadeId:
                    Number(newPedido.enderecoInstalacao?.ibge) || undefined,
                  complemento: newPedido.enderecoInstalacao?.complemento,
                  pontoReferencia:
                    newPedido.enderecoInstalacao?.pontoReferencia,
                },
                enderecoCobranca: {
                  bairro: newPedido.enderecoCobranca?.bairro,
                  cep: newPedido.enderecoCobranca!.cep,
                  logradouro: newPedido.enderecoCobranca?.logradouro,
                  nome: 'Endereço Cobrança',
                  numero: newPedido.enderecoCobranca!.numero,
                  cidadeId:
                    Number(newPedido.enderecoInstalacao?.ibge) || undefined,
                  complemento: newPedido.enderecoCobranca?.complemento,
                  pontoReferencia: newPedido.enderecoCobranca?.pontoReferencia,
                },
              },
            },
          });

        if (consultaErrors) {
          throw new GraphQLError('Erro ao realizar consulta.', consultaErrors);
        }

        const { errors } = await graphqlFormDataAuth<
          PedidoCreateQuery,
          PedidoCreateQueryVariables
        >({
          auth,
          setAuth,
          query: PEDIDO_CREATE_QUERY,
          variables: {
            input: {
              clientePessoaFisicaNome: newPedido.dados!.nome,
              clientePessoaFisicaCpf: newPedido.dados!.cpf,
              clientePessoaFisicaRg: newPedido.dados?.rg,
              clientePessoaFisicaDataNascimento:
                newPedido.dados!.dataNascimento,
              clientePessoaFisicaSexo: newPedido.dados?.sexo,
              clientePessoaFisicaEstadoCivilId: newPedido.dados
                ?.estadoCivil as EstadoCivilEnum,
              redesSociais: newPedido.dados?.redesSociais.filter(
                redeSocial => redeSocial.link !== '',
              ),
              telefones: newPedido.dados!.telefones,
              emails: newPedido.dados?.emails.filter(
                email => email.nome !== '',
              ),
              enderecoInstalacao: {
                bairro: newPedido.enderecoInstalacao?.bairro,
                cep: newPedido.enderecoInstalacao!.cep,
                logradouro: newPedido.enderecoInstalacao?.logradouro,
                nome: 'Endereço Instalação',
                numero: newPedido.enderecoInstalacao!.numero,
                cidadeId:
                  Number(newPedido.enderecoInstalacao?.ibge) || undefined,
                complemento: newPedido.enderecoInstalacao?.complemento,
                pontoReferencia: newPedido.enderecoInstalacao?.pontoReferencia,
              },
              enderecoCobranca: {
                bairro: newPedido.enderecoCobranca?.bairro,
                cep: newPedido.enderecoCobranca!.cep,
                logradouro: newPedido.enderecoCobranca?.logradouro,
                nome: 'Endereço Cobrança',
                numero: newPedido.enderecoCobranca!.numero,
                cidadeId:
                  Number(newPedido.enderecoInstalacao?.ibge) || undefined,
                complemento: newPedido.enderecoCobranca?.complemento,
                pontoReferencia: newPedido.enderecoCobranca?.pontoReferencia,
              },
              consultaId: consultaData.actions.consultar.consultaId,
              planos: newPedido.planos!.map(({ planoId }) => ({ planoId })),
              diaVencimentoId: newPedido.informacoesServico!.diaVencimentoId,
              fidelidadeId: newPedido.informacoesServico!.fidelidadeId,
              finalidades: newPedido.informacoesServico?.finalidades.map(
                item => ({ finalidadeId: item }),
              ),
              rede: newPedido.informacoesServico!.rede,
              senha: newPedido.informacoesServico!.senha,
              pontoAdicional: false,
              clientePessoaFisicaVinculoEmpregaticioId:
                newPedido.dadosEmpregaticios!.vinculoEmpregaticio,
              clientePessoaFisicaAutonomoAtividadeExercida:
                newPedido.dadosEmpregaticios?.atividadeExercida,
              clientePessoaFisicaRegistradoRenda: newPedido.dadosEmpregaticios
                ?.renda
                ? Number(
                    newPedido.dadosEmpregaticios.renda
                      .replace(/[R$.\s]/g, '')
                      .replace(',', '.'),
                  )
                : undefined,
              clientePessoaFisicaRegistradoLocalTrabalho:
                newPedido.dadosEmpregaticios?.localTrabalho,
              clientePessoaFisicaRegistradoTelefoneTrabalho: newPedido
                .dadosEmpregaticios?.telefoneTrabalho.telefone
                ? {
                    nome: newPedido.dadosEmpregaticios?.telefoneTrabalho.nome,
                    telefone:
                      newPedido.dadosEmpregaticios?.telefoneTrabalho.telefone,
                    whatsapp:
                      newPedido.dadosEmpregaticios?.telefoneTrabalho.whatsapp,
                  }
                : undefined,
              clientePessoaFisicaInternetAnterior:
                newPedido.dadosEmpregaticios?.possuiInternet || false,
              clientePessoaFisicaInternetAnteriorOperadoraId:
                newPedido.dadosEmpregaticios?.operadora,
              clientePessoaFisicaInternetAnteriorMotivoCancelamento:
                newPedido.dadosEmpregaticios?.motivoCancelamento,
              clientePessoaFisicaTipoResidencia:
                newPedido.dadosEmpregaticios?.tipoResidencia,
              clientePessoaFisicaQuantidadeMoradores:
                Number(newPedido.dadosEmpregaticios?.quantidadeMoradores) ||
                undefined,
              statusId: 'PENDENTE_ANALISE_CREDITO',
              tipoPessoa: 'PESSOA_FISICA',
              vendedorId: auth!.usuario.usuarioId,
              anexos: [],
            },
          },
          files: fields.anexos.map((anexo, index) => [
            `variables.input.anexos.${index}`,
            anexo,
          ]),
        });

        if (errors) {
          throw new GraphQLError('Erro ao criar pedido.', errors);
        }

        successToast('Pedido criado com sucesso!');

        navigate('/pedidos');
      } finally {
        setDisableSubmitButton(false);
      }
    },
  });

  return (
    <>
      <StepTitle title="Anexos" onGoBack={prevStep} />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FinishStepFooter disableSubmitButton={disableSubmitButton} />
        }
      >
        <InputMultiFile
          className={styles['anexos-input-file']}
          background="secondary"
          accept="*"
          name="anexos"
          label="Capturar / Adicionar Anexos"
          value={fields.anexos}
          onChange={files =>
            files &&
            setFields({
              ...fields,
              anexos: [...fields.anexos, ...Array.from(files)],
            })
          }
        />

        {fields.anexos.length > 0 ? (
          <PreviewFile
            files={fields.anexos.map(anexo => ({
              fileName: anexo.name,
              url: URL.createObjectURL(anexo),
              mimetype: anexo.type,
            }))}
            removeFiles={index =>
              setFields(state => ({
                ...state,
                anexos: state.anexos.filter((_, i) => i !== index),
              }))
            }
          />
        ) : (
          <figure className={styles['anexos-illustration']}>
            <img src={ilustracao} alt="Ilustração anexos" />
          </figure>
        )}
      </Form>
    </>
  );
};

import { Layout } from 'components/Layout';
import { EditOperadoraView } from 'views/operadoras/edit';

export const EditOperadoraPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Operadoras', href: '/operadoras' },
          { label: 'Editar' },
        ],
      }}
    >
      <EditOperadoraView />
    </Layout>
  );
};

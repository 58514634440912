import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useForm } from 'hooks/useForm';
import { useQueryAuth } from 'hooks/useQueryAuth';
import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { Fallback } from 'components/Fallback';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { Form, FormFooter, Input } from 'components/Form';

interface MaterialUpdateQueryVariables {
  id: { materialId: string };
  input: { nome: string };
}

interface MaterialUpdateQuery {
  materialUpdate: {
    materialId: string;
  };
}

const MATERIAL_UPDATE_QUERY = gql`
  mutation ($id: MaterialID!, $input: MaterialUpdateInput!) {
    materialUpdate(id: $id, input: $input) {
      materialId
    }
  }
`;

interface MaterialDataQueryVariables {
  id: { materialId: string };
}

interface MaterialDataQuery {
  material: {
    materialId: string;
    nome: string;
  };
}

const MATERIAL_DATA_QUERY = gql`
  query ($id: MaterialID!) {
    material(id: $id) {
      materialId
      nome
    }
  }
`;

export const EditMaterialView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    nome: '',
  });

  const materialId = params.materialId || '';

  const query = useQueryAuth<MaterialDataQuery, MaterialDataQueryVariables>({
    auth,
    setAuth,
    query: MATERIAL_DATA_QUERY,
    variables: { id: { materialId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({
        ...fields,
        nome: query.data.material.nome,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      nome: Yup.string().required('Nome obrigatório.'),
    }),
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { errors } = await graphqlAuth<
          MaterialUpdateQuery,
          MaterialUpdateQueryVariables
        >({
          auth,
          setAuth,
          query: MATERIAL_UPDATE_QUERY,
          variables: {
            id: { materialId },
            input: {
              nome: values.nome,
            },
          },
        });

        if (errors) {
          throw new GraphQLError('Falha ao alterar material', errors);
        }

        successToast('Material alterado com sucesso!');

        navigate('/materiais');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors?.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
      </Form>
    </>
  );
};

import { phoneMask } from 'functions/mask';

import { Telefone } from 'utils/types/Telefone';

import { FlexContainer } from 'components/FlexContainer';
import { Checkbox, Form, Input, Select } from 'components/Form';
import { currencyFormat } from 'functions/formatters';

interface DadosEmpregaticiosProps {
  vinculoEmpregaticioId: string;
  localTrabalho: string;
  telefoneTrabalho: Telefone;
  renda: number;
  atividadeExercida: string;
  tipoResidencia: string;
  quantidadeMoradores: number;
  tempoMoradia: string;
  possuiInternet: boolean;
  operadoraId: string;
  motivoCancelamento: string;
  vinculosEmpregaticios: { vinculoEmpregaticioId: string; nome: string }[];
  operadoras: { operadoraId: string; nome: string }[];
}

export const DetailPedidoDadosEmpregaticios: React.FC<
  DadosEmpregaticiosProps
> = props => {
  return (
    <Form columns={3}>
      <Select
        readOnly
        name="vinculoEmpregaticio"
        label="Vínculo Empregatício"
        value={props.vinculoEmpregaticioId}
        options={props.vinculosEmpregaticios.map(
          ({ nome, vinculoEmpregaticioId }) => ({
            label: nome,
            value: vinculoEmpregaticioId,
          }),
        )}
      />

      {props.vinculoEmpregaticioId &&
        props.vinculoEmpregaticioId !== 'AUTONOMO' && (
          <>
            <h4 className="heading4 title span-3">Infomações do Trabalho.</h4>

            <FlexContainer className="span-3">
              <Input
                readOnly
                name="localTrabalho"
                label="Local de Trabalho"
                value={props.localTrabalho}
              />
              <Input
                readOnly
                name="renda"
                label="Renda"
                value={currencyFormat(props.renda)}
              />
              <Input
                readOnly
                name="telefoneTrabalho"
                label="Telefone do Trabalho"
                value={phoneMask(props.telefoneTrabalho.telefone)}
              />
            </FlexContainer>
          </>
        )}

      {props.vinculoEmpregaticioId &&
        props.vinculoEmpregaticioId !== 'REGISTRADO' && (
          <>
            <h4 className="heading4 title span-3">Autônomo</h4>

            <Input
              readOnly
              className="span-3"
              name="atividadeExercida"
              label="Atividade Exercída (Autônomo)"
              value={props.atividadeExercida}
            />
          </>
        )}

      <h4 className="heading4 title span-3">Informações Residenciais</h4>

      <Select
        readOnly
        name="tipoResidencia"
        label="Tipo Residência"
        value={props.tipoResidencia}
        options={[
          { label: 'Alugada', value: 'ALUGADA' },
          { label: 'Própria', value: 'PROPRIA' },
          { label: 'Outros', value: 'OUTROS' },
        ]}
      />
      <Input
        readOnly
        name="quantidadeMoradores"
        label="Qtde. Moradores"
        value={`${props.quantidadeMoradores}`}
      />
      <Input
        readOnly
        name="tempoMoradia"
        label="Tempo de Moradia"
        value={props.tempoMoradia}
      />
      <Checkbox
        readOnly
        className="column-start"
        name="possuiInternet"
        label="Já possui internet"
        checked={props.possuiInternet}
      />
      {props.possuiInternet && (
        <>
          <Select
            readOnly
            className="column-start"
            name="operadora"
            label="Operadora"
            value={props.operadoraId}
            options={props.operadoras.map(({ nome, operadoraId }) => ({
              label: nome,
              value: operadoraId,
            }))}
          />
          <Input
            readOnly
            className="span-2"
            name="motivoCancelamento"
            label="Motivo Cancelamento Outra Operadora"
            value={props.motivoCancelamento}
          />
        </>
      )}
    </Form>
  );
};

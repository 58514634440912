import { Layout } from 'components/Layout';
import { EditDiaVencimentoView } from 'views/dia-vencimento/edit';

export const EditDiaVencimentoPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Dias Vencimentos', href: '/dia-vencimento' },
          { label: 'Editar' },
        ],
      }}
    >
      <EditDiaVencimentoView />
    </Layout>
  );
};

import { dateFormatWithHours } from 'functions/formatters';
import { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import styles from './ComunicacoesTable.module.scss';
import { IconButton } from './IconButton';
import { ModalDetalheComunicacao } from './Modal/ModalDetalheComunicacao';

interface Comunicacao {
  comunicacaoInternaId: string;
  descricao: string;
  createdAt: string;
  usuario: {
    usuarioId: string;
    nome: string;
  };
}

interface ComunicacoesTableProps {
  comunicacoes: Comunicacao[];
}

export const ComunicacoesTable: React.FC<ComunicacoesTableProps> = ({
  comunicacoes,
}) => {
  const [showModalDetail, setShowModalDetail] = useState<{
    isVisible: boolean;
    comunicacaoInternaId: string | null;
  }>({
    isVisible: false,
    comunicacaoInternaId: null,
  });

  return (
    <>
      {showModalDetail.isVisible && (
        <ModalDetalheComunicacao
          comunicacaoInternaId={showModalDetail.comunicacaoInternaId!}
          onClose={() =>
            setShowModalDetail({ isVisible: false, comunicacaoInternaId: null })
          }
        />
      )}
      <div className={styles['comunicacoes-table-container-wrapper']}>
        <h6 className="heading6 title">Comunicações ({comunicacoes.length})</h6>
        <table className={styles['comunicacoes-table-container']}>
          <thead className={styles['comunicacoes-table-head']}>
            <tr>
              <th>Opções</th>
              <th>ID</th>
              <th>Usuário</th>
              <th>Descrição</th>
              <th>Data Criação</th>
            </tr>
          </thead>
          <tbody className={styles['comunicacoes-table-body']}>
            {comunicacoes.length > 0 ? (
              comunicacoes.map(comunicacao => (
                <tr key={comunicacao.comunicacaoInternaId}>
                  <td>
                    <IconButton
                      title="Visualizar"
                      className="hover-green"
                      icon={() => <FiEye size={22} />}
                      toolTipDirection="right"
                      onClick={() =>
                        setShowModalDetail({
                          isVisible: true,
                          comunicacaoInternaId:
                            comunicacao.comunicacaoInternaId,
                        })
                      }
                    />
                  </td>
                  <td>{comunicacao.comunicacaoInternaId}</td>
                  <td>{comunicacao.usuario.nome}</td>
                  <td className={styles['elipsed-table-row']}>
                    {comunicacao.descricao}
                  </td>
                  <td>{dateFormatWithHours(comunicacao.createdAt)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: 'center' }}>
                  Nenhum registro encontrado.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

import { Statuses } from '../dashboard';

export const parseBarChartData = (statuses: Statuses[]) => {
  return statuses.map(item => {
    switch (item.status.statusId) {
      case 'CANCELADO':
        return {
          id: item.status.statusId,
          label: item.status.nome,
          value: item.quantidade,
          color: 'var(--red)',
        };
      case 'INSTALADO':
        return {
          id: item.status.statusId,
          label: item.status.nome,
          value: item.quantidade,
          color: 'var(--green)',
        };
      case 'PENDENTE_ANALISE_CREDITO':
        return {
          id: item.status.statusId,
          label: 'Pend. Análise Cred.',
          value: item.quantidade,
          color: 'var(--info)',
        };
      case 'PENDENTE_ATIVACAO':
        return {
          id: item.status.statusId,
          label: 'Pend. Ativação',
          value: item.quantidade,
          color: 'var(--warning)',
        };
      case 'PENDENTE_CADASTRO_RADIUS':
        return {
          id: item.status.statusId,
          label: 'Pend. Cad. Radius',
          value: item.quantidade,
          color: '#8E30FF',
        };
      case 'PENDENTE_CONFIRMACAO':
        return {
          id: item.status.statusId,
          label: 'Pend. Confirmação',
          value: item.quantidade,
          color: '#EF18D7',
        };
      case 'PENDENTE_CONSULTA_SPC_SERASA':
        return {
          id: item.status.statusId,
          label: 'Pend. Consulta',
          value: item.quantidade,
          color: '#00D8CB',
        };
      default: {
        return {
          id: item.status.statusId,
          label: item.status.nome,
          value: item.quantidade,
          color: 'var(--primary)',
        };
      }
    }
  });
};

import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';

import { Button, Form, Input, InputWithIcon } from 'components/Form';

import logo from 'assets/logo-dark.svg';
import styles from './styles.module.scss';
import { resetarSenhaSchema } from './utils/resetarSenhaSchema';
import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';
import { useAuth } from 'hooks/useAuth';
import { GraphQLError } from 'utils/GraphQLError';

interface MeUsuarioUpdateSenhaQueryVariables {
  input: { novaSenha: string; senha: string };
}

interface MeUsuarioUpdateSenhaQuery {
  me: { usuarioUpdateSenha: { usuarioId: string; nome: string } };
}

const ME_USUARIO_UPDATE_SENHA_QUERY = gql`
  mutation ($input: MeUsuarioUpdateSenhaInput!) {
    me {
      usuarioUpdateSenha(input: $input) {
        usuarioId
        nome
      }
    }
  }
`;

export const ResetarSenhaView: React.FC = () => {
  const { auth, setAuth } = useAuth();
  const [inputPasswordType, setInputPasswordType] = useState('password');

  const { fields, setFields, errors, submitHandler } = useForm({
    senhaAtual: '',
    novaSenha: '',
    confirmarSenha: '',
  });

  const handleSubmit = submitHandler({
    validateSchema: resetarSenhaSchema,
    callback: async values => {
      const { errors } = await graphqlAuth<
        MeUsuarioUpdateSenhaQuery,
        MeUsuarioUpdateSenhaQueryVariables
      >({
        auth,
        setAuth,
        query: ME_USUARIO_UPDATE_SENHA_QUERY,
        variables: {
          input: { senha: values.senhaAtual, novaSenha: values.novaSenha },
        },
      });

      if (errors) {
        throw new GraphQLError(`Falha ao criar nova senha. \n`, errors);
      }

      setAuth(null);
    },
  });

  return (
    <section className={styles['resetar-senha-section']}>
      <div className={styles['content']}>
        <figure className={styles['img-container']}>
          <img src={logo} alt="Fibratech Logo" />
        </figure>

        <h1 className="title">Crie sua nova senha.</h1>

        <Form onSubmit={handleSubmit} columns={1}>
          <InputWithIcon
            autoFocus
            type={inputPasswordType}
            name="senhaAtual"
            label="Senha atual"
            error={errors.senhaAtual}
            value={fields.senhaAtual}
            onChange={value => setFields({ ...fields, senhaAtual: value })}
            icon={() =>
              inputPasswordType === 'text' ? (
                <FiEyeOff
                  size={22}
                  onClick={() => setInputPasswordType('password')}
                />
              ) : (
                <FiEye size={22} onClick={() => setInputPasswordType('text')} />
              )
            }
          />
          <Input
            type="password"
            name="novaSenha"
            label="Nova senha"
            error={errors.novaSenha}
            value={fields.novaSenha}
            onChange={value => setFields({ ...fields, novaSenha: value })}
          />
          <Input
            type="password"
            name="confirmarSenha"
            label="Confirmar Senha"
            error={errors.confirmarSenha}
            value={fields.confirmarSenha}
            onChange={value => setFields({ ...fields, confirmarSenha: value })}
          />
          <Button background="primary" type="submit">
            Confirmar
          </Button>
        </Form>
      </div>

      <div className={styles['square']} />
    </section>
  );
};

import { useNavigate } from 'react-router';

import pessoaFisica from 'assets/pessoa-fisica.svg';
import pessoaJuridica from 'assets/pessoa-juridica.svg';

import styles from './new-pedido.module.scss';

export const NewPedidoView: React.FC = () => {
  const navigate = useNavigate();

  return (
    <section className={styles['novo-pedido-section']}>
      <h4 className="heading4 title">Selecione o tipo de pessoa abaixo:</h4>

      <div className={styles['tipo-pessoa-container']}>
        <button
          type="button"
          onClick={() => navigate('/pedidos/pessoa-fisica/new')}
          title="Pessoa Física"
        >
          Pessoa Física
          <figure>
            <img src={pessoaFisica} alt="Ilustração Pessoa Física" />
          </figure>
        </button>
        <button
          type="button"
          onClick={() => navigate('/pedidos/pessoa-juridica/new')}
          title="Pessoa Jurídica"
        >
          Pessoa Jurídica
          <figure>
            <img src={pessoaJuridica} alt="Ilustração Pessoa Jurídica" />
          </figure>
        </button>
      </div>
    </section>
  );
};

import { cloneElement, useEffect, useState } from 'react';

import { TabButtons } from './TabButton';

import styles from './styles.module.scss';

interface TabListProps {
  labels: string[];
  children: JSX.Element[];
  defaultTab?: number;
}

export const TabList: React.FC<TabListProps> = ({
  children,
  labels,
  defaultTab,
}) => {
  const [activeTab, setActiveTab] = useState(labels[defaultTab || 0]);
  const [applyAnimation, setApplyAnimation] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setApplyAnimation(false);
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [applyAnimation]);

  const handleChangeTab = (label: string) => {
    setApplyAnimation(true);
    setActiveTab(label);
  };

  return (
    <div className={styles['tab-list-container']}>
      <TabButtons
        labels={labels}
        activeTab={activeTab}
        onTabClick={handleChangeTab}
      />

      <div
        className={`${styles['tab-content']} ${
          applyAnimation ? styles.animate : ''
        }`}
      >
        {cloneElement(children[labels.findIndex(value => value === activeTab)])}
      </div>
    </div>
  );
};

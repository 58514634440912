export const cnpjValidator = {
  isValid: (cnpj: string | undefined) => {
    if (!cnpj) return false;

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const validTypes =
      typeof cnpj === 'string' || Number.isInteger(cnpj) || Array.isArray(cnpj);

    // Elimina valor em formato inválido
    if (!validTypes) return false;

    // Guarda um array com todos os dígitos do valor
    const match = (cnpj.toString().match(/\d/g) || []).map(Number);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) return false;

    // Elimina inválidos com todos os dígitos iguais
    const items = Array.from(new Set(numbers));
    if (items.length === 1) return false;

    // Cálculo validador
    const calc = (x: any) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12);

    // Valida 1o. dígito verificador
    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    // Valida 2o. dígito verificador
    const digit1 = calc(13);
    return digit1 === digits[1];
  },
};

export const cpfValidator = {
  isValid: (value: string | undefined) => {
    if (!value) return false;

    let soma = 0;
    let resto = 0;

    const REGEX = /^(?:(\d)\1{10})$|(\D)|^(\d{12,})$|^(\d{0,10})$/g;

    const cpf = value.replaceAll('.', '').replaceAll('-', '');

    if (cpf.match(REGEX)) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;
    }

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;
    }

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true;
  },
};

export const phoneValidator = {
  isValid: (value: string | undefined) => {
    if (!value) return false;

    //retira todos os caracteres menos os numeros
    const telefone = value.replace(/\D/g, '');

    //verifica se tem a qtde de numero correto
    if (!(telefone.length >= 10 && telefone.length <= 11)) return false;

    //Se tiver 11 caracteres, verificar se começa com 9 o celular
    if (telefone.length === 11 && parseInt(telefone.substring(2, 3)) !== 9)
      return false;

    //verifica se não é nenhum numero digitado errado (propositalmente)
    for (let i = 0; i < 10; i++) {
      //um for de 0 a 9.
      //estou utilizando o metodo Array(q+1).join(n) onde "q" é a quantidade e n é o
      //caractere a ser repetido
      if (
        telefone === new Array(11).join(`${i}`) ||
        telefone === new Array(12).join(`${i}`)
      )
        return false;
    }

    //DDDs validos
    const codigosDDD = [
      11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34,
      35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62,
      64, 63, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85,
      86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    ];

    //verifica se o DDD é valido
    if (codigosDDD.indexOf(parseInt(telefone.substring(0, 2))) === -1)
      return false;

    //se passar por todas as validações acima, então está tudo certo
    return true;
  },
};

import { Card } from './Card';
import { RankingTopPosition } from './RankingTopPosition';

import styles from './Ranking.module.scss';

interface RankingProps {
  title: string;
  headerIcon?: () => JSX.Element;
  items: {
    posicao: number;
    quantidade: number;
    usuario: { usuarioId: string; nome: string };
  }[];
}

export const Ranking: React.FC<RankingProps> = ({
  title,
  headerIcon,
  items,
}) => {
  return (
    <Card title={title} headerIcon={headerIcon}>
      <div className={styles['ranking-list']}>
        {items.length > 0 ? (
          items.map((item, index) => {
            switch (item.posicao) {
              case 1:
                return (
                  <RankingTopPosition
                    key={index}
                    item={item}
                    trophyColor="gold"
                  />
                );
              case 2:
                return (
                  <RankingTopPosition
                    key={index}
                    item={item}
                    trophyColor="silver"
                  />
                );
              case 3:
                return (
                  <RankingTopPosition
                    key={index}
                    item={item}
                    trophyColor="bronze"
                  />
                );
              default:
                return (
                  <div key={index} className={styles['ranking-row']}>
                    <p className="body2 text">{item.posicao}º</p>
                    <p className="body1 text">
                      {item.usuario.nome} -{' '}
                      <span className="textLight">{item.quantidade}</span>
                    </p>
                  </div>
                );
            }
          })
        ) : (
          <div className={styles['ranking-empty']}>
            <p>Nenhum ranking disponivel.</p>
          </div>
        )}
      </div>
    </Card>
  );
};

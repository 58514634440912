import { useState } from 'react';
import { FiDownload, FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useError } from 'hooks/useError';
import { Sort } from 'utils/Sort';
import { Filter } from 'utils/Filter';
import { GraphQLError } from 'utils/GraphQLError';
import { gql } from 'functions/gql';
import { cnpjMask, cpfMask } from 'functions/mask';
import { graphqlAuth } from 'functions/graphqlAuth';
import { exportToCsv } from 'functions/exportToCSV';
import { dateFormatWithHours } from 'functions/formatters';

import { Button } from 'components/Form';
import { IconButton } from 'components/IconButton';
import { ConsultaSophusFilter } from './ConsultaSophusFilter';
import { KeyCSV } from 'views/relatorios/utils/KeyCSV';
import { sortArray } from 'views/relatorios/utils/sortArray';
import { RelatorioTable } from 'views/relatorios/components/RelatorioTable';

export interface FilterFields {
  between: {
    dataConsulta: {
      low: string;
      high: string;
    };
  };
  likes?: {
    documento?: string;
  };
}

interface ConsultaSophus {
  consultaId: string;
  documento: string;
  resultado: string;
  dataConsulta: string;
}

interface ConsultaSophusListQueryVariables {
  filter?: Filter;
}

interface ConsultaSophusListQuery {
  relatorios: {
    consultas: {
      consultas: ConsultaSophus[];
    };
  };
}

const CONSULTA_SOPHUS_LIST_QUERY = gql`
  query ($filter: ConsultaFilter) {
    relatorios {
      consultas {
        consultas(filter: $filter) {
          consultaId
          documento
          resultado
          dataConsulta
        }
      }
    }
  }
`;

export const ListConsultaSophusView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const { withErrorHandling } = useError();

  const [sort, setSort] = useState<Sort | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [consultasSophus, setConsultasSophus] = useState<ConsultaSophus[]>([]);

  const handleSearch = withErrorHandling(
    async (filterFelds: FilterFields | null) => {
      setIsLoading(true);

      try {
        const { data, errors } = await graphqlAuth<
          ConsultaSophusListQuery,
          ConsultaSophusListQueryVariables
        >({
          auth,
          setAuth,
          query: CONSULTA_SOPHUS_LIST_QUERY,
          variables: {
            filter: filterFelds ? filterFelds : undefined,
          },
        });

        if (errors) {
          throw new GraphQLError('Erro ao pesquisar consultas sophus.', errors);
        }

        setConsultasSophus(data.relatorios.consultas.consultas);
      } finally {
        setIsLoading(false);
      }
    },
  );

  const handleExportCSV = () => {
    const keys: KeyCSV<ConsultaSophus>[] = [
      {
        label: 'ID',
        key: 'consultaId',
      },
      {
        label: 'Documento',
        key: 'documento',
        parser: item =>
          item.documento.length > 11
            ? cnpjMask(item.documento)
            : cpfMask(item.documento),
      },
      {
        label: 'Data Consulta',
        key: 'dataConsulta',
        parser: item => new Date(item.dataConsulta).toLocaleDateString(),
      },
      {
        label: 'Resultado',
        key: 'resultado',
        parser: item => item.resultado.replace(/_/g, ' '),
      },
    ];

    const rows = [
      keys.map(key => key.label),
      ...consultasSophus.map(consulta =>
        keys.map(({ parser, key }) =>
          parser ? parser(consulta) : consulta[key as keyof typeof consulta],
        ),
      ),
    ];

    exportToCsv('relatorio-consultas-sophus.csv', rows);
  };

  return (
    <>
      <ConsultaSophusFilter onSearch={handleSearch} />
      <RelatorioTable
        title="Consultas"
        sideComponent={() => (
          <Button
            type="button"
            disabled={consultasSophus.length === 0}
            style={{ width: 'max-content' }}
            onClick={handleExportCSV}
          >
            <FiDownload size={20} /> Exportar CSV
          </Button>
        )}
        columns={[
          {
            key: 'opcoes',
            label: 'Opções',
            render: ({ consultaId }) => (
              <IconButton
                title="Visualizar"
                className="hover-green"
                toolTipDirection="right"
                icon={() => <FiEye size={22} />}
                onClick={() =>
                  navigate(`/relatorios/consultas-sophus/${consultaId}`)
                }
              />
            ),
          },
          {
            key: 'consultaId',
            label: 'ID',
            sortable: true,
          },
          {
            key: 'documento',
            label: 'Documento',
            sortable: true,
            render: ({ documento }) => (
              <p>
                {documento.length > 11
                  ? cnpjMask(documento)
                  : cpfMask(documento)}
              </p>
            ),
          },
          {
            key: 'resultado',
            label: 'Resultado',
            sortable: true,
            render: ({ resultado }) => <p>{resultado.replace(/[_]/g, ' ')}</p>,
          },
          {
            key: 'dataConsulta',
            label: 'Data Consulta',
            sortable: true,
            render: ({ dataConsulta }) => (
              <p>{dateFormatWithHours(dataConsulta)}</p>
            ),
          },
        ]}
        items={sortArray(sort, consultasSophus)}
        isLoading={isLoading}
        getRowId={({ consultaId }) => consultaId}
        totalCount={consultasSophus.length}
        sort={sort}
        onChangeSort={sortField => setSort(sortField)}
      />
    </>
  );
};

import styles from './TableHeader.module.scss';

type TableHeaderProps = {
  title: string;
  sideComponent?: () => JSX.Element;
};

export const TableHeader: React.FC<TableHeaderProps> = ({
  title,
  sideComponent: SideComponent,
}) => {
  return (
    <div className={styles['table-header-container']}>
      <h3 className="title">{title}</h3>
      {SideComponent && <SideComponent />}
    </div>
  );
};

import { ChangeEvent, useEffect, useRef } from 'react';

import { Label } from './Label';
import { ErrorMessage } from './ErrorMessage';

import styles from './InputFile.module.scss';

type InputMultiFileProps = {
  background?: 'primary' | 'secondary';
  className?: string;
  isOutlined?: boolean;
  accept?: string;
  name: string;
  label: string;
  error?: string;
  value: File[];
  onChange: (
    value: FileList | null,
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
};

export const InputMultiFile: React.FC<InputMultiFileProps> = ({
  background,
  className,
  isOutlined = false,
  accept = 'image/*',
  name,
  label,
  error = '',
  value,
  onChange,
}) => {
  const inputMultiFileRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputMultiFileRef.current) {
      if (!value.length) {
        inputMultiFileRef.current.value = '';
      }
    }
  }, [value]);

  return (
    <section
      className={[
        styles['input-file-section'],
        `${background ? styles[`btn-${background}`] : styles[`btn-primary`]}`,
        `${isOutlined ? styles[`btn-outlined-${background}`] : ''}`,
        `${className ? className : ''}`,
      ].join(' ')}
    >
      <Label htmlFor={name}>
        {label}
        <input
          ref={inputMultiFileRef}
          type="file"
          accept={accept}
          className={styles['input-field']}
          multiple
          id={name}
          name={name}
          onChange={e => onChange(e.target.files, e)}
        />
        {error && <ErrorMessage error={error} />}
      </Label>
    </section>
  );
};

import * as Yup from 'yup';
import { FiSearch } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { useQueryAuth } from 'hooks/useQueryAuth';
import { gql } from 'functions/gql';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'functions/dateUtils';
import { PedidoStatusEnum } from 'utils/enums/PedidoStatusEnum';
import { PedidoTipoDataEnum } from 'utils/enums/PedidoTipoDataEnum';
import { RelatorioDesempenhoPedidosQueryVariables } from './list';

import {
  Button,
  Fieldset,
  Form,
  Input,
  MultiSelect,
  Select,
} from 'components/Form';
import { Fallback } from 'components/Fallback';
import { useAuth } from 'hooks/useAuth';

interface AllStatusesQuery {
  statuses: {
    items: {
      statusId: string;
      nome: string;
    }[];
  };
}

const ALL_STATUSES_QUERY = gql`
  query {
    statuses {
      items {
        statusId
        nome
      }
    }
  }
`;

interface DesempenhoVendasFilterProps {
  onSearch: (variables: RelatorioDesempenhoPedidosQueryVariables) => void;
}

export const DesempenhoVendasFilter: React.FC<DesempenhoVendasFilterProps> = ({
  onSearch,
}) => {
  const { auth, setAuth } = useAuth();
  const { fields, setFields, errors, submitHandler } = useForm({
    input: {
      dataInicio: getFirstDayOfMonth().slice(0, 10),
      dataTermino: getLastDayOfMonth().slice(0, 10),
      statuses: [] as PedidoStatusEnum[],
      tipoData: 'DATA_INCLUSAO' as PedidoTipoDataEnum,
    },
  });

  const query = useQueryAuth<AllStatusesQuery>({
    auth,
    setAuth,
    query: ALL_STATUSES_QUERY,
  });

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      input: Yup.object({
        dataInicio: Yup.string().required('Data início obrigatório.'),
        dataTermino: Yup.string().required('Data término obrigatório.'),
        tipoData: Yup.string().required('Tipo de data obrigatório'),
      }),
    }),
    callback: async values => {
      const newVariables = {
        input: {
          dataInicio: values.input.dataInicio,
          dataTermino: values.input.dataTermino,
          statuses: values.input.statuses,
          tipoData: values.input.tipoData,
        },
      };

      onSearch(newVariables);
    },
  });

  return (
    <Fieldset legend="Filtros">
      <Form onSubmit={handleSubmit} columns={3}>
        <Input
          type="date"
          name="dataInicio"
          label="Data início"
          error={errors?.input?.dataInicio}
          value={fields.input.dataInicio}
          onChange={value =>
            setFields({
              ...fields,
              input: { ...fields.input, dataInicio: value },
            })
          }
        />
        <Input
          type="date"
          name="dataTermino"
          label="Data término"
          error={errors?.input?.dataTermino}
          value={fields.input.dataTermino}
          onChange={value =>
            setFields({
              ...fields,
              input: { ...fields.input, dataTermino: value },
            })
          }
        />
        <Select
          name="tipoData"
          label="Tipo de data"
          error={errors?.input?.tipoData}
          value={fields.input.tipoData}
          onChange={value =>
            setFields({
              ...fields,
              input: { ...fields.input, tipoData: value as PedidoTipoDataEnum },
            })
          }
          options={[
            { label: 'Data Inclusão', value: 'DATA_INCLUSAO' },
            { label: 'Data Instalação', value: 'DATA_INSTALACAO' },
            {
              label: 'Data Agendamento Instalação',
              value: 'DATA_AGENDAMENTO_INSTALACAO',
            },
          ]}
        />
        <div className="span-3">
          <MultiSelect
            name="statuses"
            label="Pedido status"
            value={fields.input.statuses}
            onChange={value =>
              setFields({
                ...fields,
                input: {
                  ...fields.input,
                  statuses: value as PedidoStatusEnum[],
                },
              })
            }
            options={query.data.statuses.items.map(status => ({
              label: status.nome,
              value: status.statusId,
            }))}
          />
        </div>

        <Button type="submit">
          <FiSearch size={20} />
          Pesquisar
        </Button>
      </Form>
    </Fieldset>
  );
};

import * as Yup from 'yup';

export const pedidoFilterSchema = Yup.object({
  filterBy: Yup.string().nullable(),
  'between.dataInclusao.low': Yup.string().when('filterBy', {
    is: 'DATA_INCLUSAO',
    then: Yup.string().required('Data de inclusão inicial obrigatório.'),
  }),
  'between.dataInclusao.high': Yup.string().when('filterBy', {
    is: 'DATA_INCLUSAO',
    then: Yup.string(),
  }),
  'between.dataAgendamentoInstalacao.low': Yup.string().when('filterBy', {
    is: 'DATA_AGENDAMENTO_INSTALACAO',
    then: Yup.string().required(
      'Data agendamento instalação inicial obrigatório.',
    ),
  }),
  'between.dataAgendamentoInstalacao.high': Yup.string().when('filterBy', {
    is: 'DATA_AGENDAMENTO_INSTALACAO',
    then: Yup.string(),
  }),
});

export const convertEmptyKeysToNull = <T>(item: T): T => {
  const keys = Object.keys(item) as (keyof T)[];

  const newer = keys.reduce(
    (newer, key) => ({
      ...newer,
      [key]: (item[key] as any) === '' ? null : item[key],
    }),
    {} as T,
  );

  return newer;
};

import * as Yup from 'yup';
import { cpfValidator } from 'functions/validators';

export const editPedidoPessoaFisicaSchema = Yup.object({
  dados: Yup.object({
    nome: Yup.string().required('Nome obrigatório.'),
    cpf: Yup.string()
      .test({
        test: value => cpfValidator.isValid(`${value}`),
        message: 'CPF Inválido.',
      })
      .required('CPF obrigatório.'),
    rg: Yup.string().nullable(),
    dataNascimento: Yup.string().required('Data Nascimento obrigatório.'),
    sexo: Yup.string(),
    estadoCivil: Yup.string(),
    redesSociais: Yup.array().of(
      Yup.object({
        redeSocial: Yup.string(),
        link: Yup.string().when('redeSocial', {
          is: (value: string) => value !== '',
          then: Yup.string().required('Link obrigatório.'),
        }),
      }),
    ),
    telefones: Yup.array()
      .of(
        Yup.object({
          nome: Yup.string().required('Descrição do Telefone obrigatório.'),
          telefone: Yup.string().required('Telefone obrigatório.'),
          whatsapp: Yup.boolean(),
        }).required(),
      )
      .min(2, 'No mínimo ${min} telefones devem ser cadastrados.')
      .required(),
    emails: Yup.array().of(
      Yup.object({
        nome: Yup.string(),
        email: Yup.string().when('nome', {
          is: (value: string) => value !== '',
          then: Yup.string()
            .email('E-mail inválido')
            .required('E-mail obrigatório.'),
        }),
      }),
    ),
  }),
  enderecoInstalacao: Yup.object({
    cep: Yup.string().required('CEP obrigatório.'),
    numero: Yup.string().required('Número obrigatório.'),
    estado: Yup.string(),
    logradouro: Yup.string(),
    bairro: Yup.string(),
    cidade: Yup.string().required('Cidade obrigatório.'),
    complemento: Yup.string().nullable(),
    pontoReferencia: Yup.string().nullable(),
  }),
  enderecoCobranca: Yup.object({
    cep: Yup.string().required('CEP obrigatório.'),
    numero: Yup.string().required('Número obrigatório.'),
    estado: Yup.string(),
    logradouro: Yup.string(),
    bairro: Yup.string(),
    cidade: Yup.string().required('Cidade obrigatório.'),
    complemento: Yup.string().nullable(),
    pontoReferencia: Yup.string().nullable(),
  }),
  informacoesServico: Yup.object({
    diaVencimentoId: Yup.string().required('Dia Vencimento obrigatório.'),
    fidelidadeId: Yup.string().required('Fidelidade obrigatório.'),
    finalidades: Yup.array().of(Yup.string()),
    rede: Yup.string().required('Nome do wi-fi obrigatório.'),
    senha: Yup.string()
      .min(8, 'Mínimo de ${min} caracteres.')
      .required('Senha obrigatório.'),
  }),
  dadosEmpregaticios: Yup.object({
    vinculoEmpregaticioId: Yup.string().required(
      'Vínculo Empregatício obrigatório.',
    ),
    localTrabalho: Yup.string().when('vinculoEmpregaticioId', {
      is: (value: any) => value !== 'AUTONOMO',
      then: Yup.string().required('Local de Trabalho obrigatório.'),
    }),
    renda: Yup.string().when('vinculoEmpregaticioId', {
      is: (value: any) => value !== 'AUTONOMO',
      then: Yup.string()
        .test({
          test: value =>
            Number(value?.replace(/[R$.\s]/g, '').replace(',', '.')) >= 0.0,
          message: 'Renda deve ser maior ou igual à R$ 0.00.',
        })
        .required('Renda obrigatório.'),
    }),
    telefoneTrabalho: Yup.object({
      nome: Yup.string(),
      numero: Yup.string(),
      whatsapp: Yup.boolean(),
    }),
    atividadeExercida: Yup.string().when('vinculoEmpregaticioId', {
      is: (value: any) => value === 'AUTONOMO',
      then: Yup.string().required('Atividade Exercída obrigatório.'),
    }),
    tipoResidencia: Yup.string(),
    quantidadeMoradores: Yup.string(),
    tempoMoradia: Yup.string(),
    possuiInternet: Yup.boolean(),
    operadoraId: Yup.string().when('possuiInternet', {
      is: true,
      then: Yup.string().required('Operadora obrigatório.'),
    }),
    motivoCancelamento: Yup.string().when('possuiInternet', {
      is: true,
      then: Yup.string().required('Motivo Cancelamento obrigatório.'),
    }),
  }),
  dadosPedido: Yup.object({
    statusPedido: Yup.string().required('Status do Pedido obrigatório.'),
  }),
});

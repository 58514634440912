import { Button } from 'components/Form';
import { Layout } from 'components/Layout';
import { useAuth } from 'hooks/useAuth';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { ListPedidoView } from 'views/pedidos/list';

export const ListPedidoPage = () => {
  const { acessos } = useAuth();
  const navigate = useNavigate();

  return (
    <Layout
      breadcrumb={{ items: [{ label: 'Pedidos' }] }}
      component={
        acessos.includes('CADASTROS.PEDIDOS.INCLUIR') ? (
          <Button type="button" onClick={() => navigate('/pedidos/new-pedido')}>
            <FiPlus size={20} /> Novo
          </Button>
        ) : undefined
      }
    >
      <ListPedidoView />
    </Layout>
  );
};

import type { Menu } from './menuItems';
import { MenuItem } from './MenuItem';
import { SubMenu } from './SubMenu';

import styles from './NavbarList.module.scss';

type NavbarListProps = {
  items: Menu[];
};

export const NavbarList: React.FC<NavbarListProps> = ({ items }) => {
  return (
    <ul className={styles['navbar-list-container']}>
      {items.map(menu => {
        if (menu.type === 'submenu') {
          return (
            <SubMenu
              key={menu.label}
              items={menu.children}
              label={menu.label}
              icon={menu.icon}
            />
          );
        }

        return <MenuItem key={menu.label} menu={menu} />;
      })}
    </ul>
  );
};

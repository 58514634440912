import { cpfMask } from 'functions/mask';

import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { RedeSocial } from 'utils/types/RedeSocial';

import { FlexContainer } from 'components/FlexContainer';
import { Form, Input, InputPhone, Select } from 'components/Form';
import { Validacao } from 'components/Form/Validacao';

interface DadosProps {
  nome: string;
  cpf: string;
  rg: string;
  dataNascimento: string;
  sexo: string;
  estadoCivilId: string;
  redesSociais: RedeSocial[];
  telefones: Telefone[];
  emails: Email[];
  statusPedido: string;
  validacaoCliente: number;
}

export const DetailPedidoDados: React.FC<DadosProps> = props => {
  return (
    <>
      <Form columns={3}>
        <Input readOnly name="nome" label="Nome" value={props.nome} />
        <Input readOnly name="cpf" label="CPF" value={cpfMask(props.cpf)} />
        <Input readOnly name="rg" label="RG" value={props.rg} />
        <Input
          readOnly
          name="dataNascimento"
          type="date"
          label="Data Nascimento"
          value={props.dataNascimento}
        />
        <Select
          readOnly
          name="sexo"
          label="Sexo"
          value={props.sexo}
          options={[
            { label: 'Masculino', value: 'MASCULINO' },
            { label: 'Feminino', value: 'FEMININO' },
            { label: 'Outros', value: 'OUTROS' },
          ]}
        />
        <Select
          readOnly
          name="estadoCivil"
          label="Estado Civil"
          value={props.estadoCivilId}
          options={[
            { value: 'CASADO', label: 'Casado(a)' },
            { value: 'DIVORCIADO', label: 'Divorciado(a)' },
            { value: 'OUTROS', label: 'Outros' },
            { value: 'SEPARADO', label: 'Separado(a)' },
            { value: 'SOLTEIRO', label: 'Solteiro(a)' },
            { value: 'VIUVO', label: 'Viúvo(a)' },
          ]}
        />

        <h5 className="heading5 title span-3">Redes sociais</h5>

        {props.redesSociais.length ? (
          props.redesSociais.map((redeSocial, index) => (
            <FlexContainer key={index} className="span-2">
              <Select
                readOnly
                name="redeSocial"
                label="Rede Social"
                value={redeSocial.redeSocial}
                options={[
                  { label: 'E-mail', value: 'EMAIL' },
                  { label: 'Facebook', value: 'FACEBOOK' },
                  { label: 'Instagram', value: 'INSTAGRAM' },
                  { label: 'Linkedin', value: 'LINKEDIN' },
                  { label: 'TikTok', value: 'TIKTOK' },
                  { label: 'Twitter', value: 'TWITTER' },
                  { label: 'Website', value: 'WEBSITE' },
                  { label: 'Whatsapp', value: 'WHATSAPP' },
                  { label: 'Outro', value: 'OUTRO' },
                ]}
              />
              <Input
                readOnly
                name={`link-${index}`}
                label="Link"
                value={redeSocial.link}
              />
            </FlexContainer>
          ))
        ) : (
          <p className="body2 text">Nenhuma rede social adicionada.</p>
        )}

        <h5 className="heading5 title span-3">Telefones</h5>

        {props.telefones.length ? (
          props.telefones.map((telefone, index) => (
            <FlexContainer key={index} className="span-2">
              <Input
                readOnly
                name="descricaoTelefone"
                label="Descrição Telefone"
                value={telefone.nome}
              />
              <InputPhone
                readOnly
                id={`telefone-${index}`}
                isWhatsapp={telefone.whatsapp}
                value={telefone.telefone}
              />
            </FlexContainer>
          ))
        ) : (
          <p className="body2 text">Nenhum telefone adicionado.</p>
        )}

        <h5 className="heading5 title span-3">E-mails</h5>

        {props.emails.length ? (
          props.emails.map((email, index) => (
            <FlexContainer key={index} className="span-2">
              <Input
                readOnly
                name="descricaoEmail"
                label="Descrição E-mail"
                value={email.nome}
              />
              <Input
                readOnly
                name={`email-${index}`}
                label={`E-mail ${index + 1}`}
                value={email.email}
              />
            </FlexContainer>
          ))
        ) : (
          <p className="body2 text">Nenhum e-mail adicionado.</p>
        )}

        <h5 className="heading5 title span-3">Dados do Pedido</h5>

        <Select
          readOnly
          label="Status do Pedido"
          name="statusPedido"
          value={props.statusPedido}
          options={[
            { value: 'CANCELADO', label: 'Cancelado' },
            { value: 'INSTALADO', label: 'Instalado' },
            {
              value: 'PENDENTE_ANALISE_CREDITO',
              label: 'Pendente de Análise de Crédito',
            },
            { value: 'PENDENTE_ATIVACAO', label: 'Pendente de Ativação' },
            {
              value: 'PENDENTE_CADASTRO_RADIUS',
              label: 'Pendente de Cadastro Radius',
            },
            {
              value: 'PENDENTE_CONFIRMACAO',
              label: 'Pendente de Confirmação',
            },
            {
              value: 'PENDENTE_CONSULTA_SPC_SERASA',
              label: 'Pendente de Consulta SPC/Serasa',
            },
          ]}
        />
        <Validacao value={props.validacaoCliente} />
      </Form>
    </>
  );
};

import { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useError } from 'hooks/useError';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { Sort } from 'utils/Sort';
import { Filter } from 'utils/Filter';
import { successToast } from 'utils/toast';
import { Pagination } from 'utils/Pagination';
import { GraphQLError } from 'utils/GraphQLError';

import { gql } from 'functions/gql';
import { paginate } from 'functions/paginate';
import { graphqlAuth } from 'functions/graphqlAuth';
import { dateFormatWithHours } from 'functions/formatters';

import { OperadoraFilter } from './OperadoraFilter';
import { Table } from 'components/Table';
import { Badge } from 'components/Badge';
import { Fallback } from 'components/Fallback';
import { EditOption } from 'components/EditOption';
import { IconButton } from 'components/IconButton';
import { FlexContainer } from 'components/FlexContainer';
import { ActivateOption } from 'components/ActivateOption';

interface OperadoraListQueryVariables {
  filter?: Filter;
  pagination: Pagination;
  sort?: Sort[];
}

interface OperadoraListQuery {
  operadoras: {
    totalCount: number;
    items: {
      operadoraId: string;
      nome: string;
      ativo: boolean;
      createdAt: string;
    }[];
  };
}

const OPERADORA_LIST_QUERY = gql`
  query ($filter: OperadoraFilter, $pagination: Pagination, $sort: [Sort!]) {
    operadoras(filter: $filter, pagination: $pagination, sort: $sort) {
      totalCount
      items {
        operadoraId
        nome
        ativo
        createdAt
      }
    }
  }
`;

interface OperadoraUpdateQuery {
  operadoraUpdate: {
    operadoraId: number;
    ativo: boolean;
  };
}

interface OperadoraUpdateVariables {
  id: { operadoraId: string };
  input: { ativo: boolean };
}

const OPERADORA_UPDATE_QUERY = gql`
  mutation ($input: OperadoraUpdateInput!, $id: OperadoraID!) {
    operadoraUpdate(input: $input, id: $id) {
      operadoraId
      ativo
    }
  }
`;

export const ListOperadoraView: React.FC = () => {
  const navigate = useNavigate();
  const { withErrorHandling } = useError();
  const { acessos, auth, setAuth } = useAuth();

  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [sort, setSort] = useState<Sort | null>(null);
  const [filter, setFilter] = useState<Filter | null>(null);

  const query = useQueryAuth<OperadoraListQuery, OperadoraListQueryVariables>({
    auth,
    setAuth,
    query: OPERADORA_LIST_QUERY,
    variables: {
      filter: filter ? filter : undefined,
      pagination: paginate(pagination),
      sort: sort ? [sort] : undefined,
    },
  });

  if (query.fallback) {
    return <Fallback loading={query.loading} errors={query.errors} />;
  }

  const { data } = query;

  const handleActivate = withErrorHandling(
    async (operadoraId: string, ativo: boolean) => {
      const { errors } = await graphqlAuth<
        OperadoraUpdateQuery,
        OperadoraUpdateVariables
      >({
        auth,
        setAuth,
        query: OPERADORA_UPDATE_QUERY,
        variables: { id: { operadoraId }, input: { ativo: !ativo } },
      });

      if (errors) {
        throw new GraphQLError('Falha ao editar operadora', errors);
      }

      successToast('Operadora alterada com sucesso.');

      await query.refetch();
    },
  );

  return (
    <Table
      title="Operadoras"
      columns={[
        {
          key: 'opcoes',
          label: 'Opções',
          render: ({ ativo, operadoraId }) => (
            <FlexContainer>
              <EditOption
                disabled={!acessos.includes('CADASTROS.OPERADORAS.ALTERAR')}
                path={`/operadoras/${operadoraId}`}
                toolTipDirection="right"
              />
              <ActivateOption
                isActive={ativo}
                onActivate={() => handleActivate(operadoraId, ativo)}
                disabled={!acessos.includes('CADASTROS.OPERADORAS.ALTERAR')}
                toolTipDirection="right"
              />
              <IconButton
                title="Visualizar"
                className="hover-green"
                icon={() => <FiEye size={22} />}
                onClick={() => navigate(`/operadoras/${operadoraId}/detalhes`)}
              />
            </FlexContainer>
          ),
        },
        {
          key: 'operadoraId',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'nome',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'ativo',
          label: 'Ativo',
          sortable: true,
          render: ({ ativo }) => (
            <Badge background={ativo ? 'success' : 'error'}>
              {ativo ? 'Sim' : 'Não'}
            </Badge>
          ),
        },
        {
          key: 'createdAt',
          label: 'Data Criação',
          render: ({ createdAt }) => <p>{dateFormatWithHours(createdAt)}</p>,
          sortable: true,
        },
      ]}
      items={data.operadoras.items}
      getRowId={({ operadoraId }) => operadoraId}
      totalCount={data.operadoras.totalCount}
      pagination={pagination}
      onChangePage={page => setPagination({ ...pagination, page })}
      onChangePerPage={perPage =>
        setPagination({ ...pagination, perPage, page: 1 })
      }
      sort={sort}
      onSort={sortField => setSort(sortField)}
      headerSideComponent={() => (
        <OperadoraFilter
          isFiltering={filter !== null}
          onFilter={filterProps => {
            setFilter(filterProps);
            setPagination({ ...pagination, page: 1 });
          }}
          onClear={() => setFilter(null)}
        />
      )}
    />
  );
};

import { gql } from 'functions/gql';
import { graphqlFormDataAuth } from 'functions/graphqlAuth';
import { useAuth } from 'hooks/useAuth';
import { useError } from 'hooks/useError';
import { GraphQLError } from 'utils/GraphQLError';
import { PerfilEnum } from 'utils/enums/PerfilEnum';

import styles from './ProfileAvatar.module.scss';

export interface QueryMeUsuarioUpdateProfilePicture {
  me: {
    usuarioUpdate: {
      usuarioId: string;
      login: string;
      nome: string;
      resetar: boolean;
      perfilId: PerfilEnum;
      avatar: {
        url: string;
      };
      grupoAcesso: {
        grupoAcessoId: string;
        nome: string;
        acessos: {
          totalCount: number;
          items: {
            acessoId: string;
            nome: string;
          }[];
        };
      };
    };
  };
}

export const QUERY_USUARIO_UPDATE_PROFILE_PICTURE = gql`
  mutation ($input: MeUsuarioUpdateInput!) {
    me {
      usuarioUpdate(input: $input) {
        usuarioId
        login
        nome
        resetar
        perfilId
        avatar {
          url
        }
        grupoAcesso {
          grupoAcessoId
          nome
          acessos {
            totalCount
            items {
              acessoId
              nome
            }
          }
        }
      }
    }
  }
`;

interface ProfileAvatarProps {
  profile: {
    nome: string;
    avatar: {
      url: string;
    };
  };
}

export const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ profile }) => {
  const { auth, setAuth } = useAuth();
  const { withErrorHandling } = useError();

  const handleChange = withErrorHandling(async (file: File) => {
    const { data, errors } = await graphqlFormDataAuth<
      QueryMeUsuarioUpdateProfilePicture,
      Record<string, never>
    >({
      query: QUERY_USUARIO_UPDATE_PROFILE_PICTURE,
      files: [['variables.input.avatar', file]],
      auth,
      setAuth,
    });

    if (errors || !data) {
      throw new GraphQLError('Falha ao alterar foto de perfil', errors);
    }

    setAuth({ ...auth!, usuario: data.me.usuarioUpdate });
  });

  return (
    <section className={styles['profile-avatar-container']}>
      <h4 className="heading4 title">Imagem de perfil</h4>
      <label htmlFor="inputFile">
        <input
          type="file"
          id="inputFile"
          accept="image/png, image/gif, image/jpeg, image/webp"
          onChange={e => e.target.files && handleChange(e.target.files[0])}
        />
        <figure>
          <img
            src={profile.avatar.url || '/default-avatar.png'}
            alt={profile.nome || 'Avatar'}
          />
        </figure>
        <span className="body1">Mudar foto de perfil</span>
      </label>
    </section>
  );
};

import { Layout } from 'components/Layout';
import { EditUsuarioView } from 'views/usuarios/edit';

export const EditUsuarioPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [{ label: 'Usuários', href: '/usuarios' }, { label: 'Editar' }],
      }}
    >
      <EditUsuarioView />
    </Layout>
  );
};

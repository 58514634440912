import { Sort } from 'utils/Sort';
import { Pagination } from './Pagination';
import { TableHeader } from './TableHeader';
import { TableWrapper } from './TableWrapper';

export interface Column<T> {
  key: string;
  label: string;
  sortable?: boolean;
  render?: (item: T, index: number) => JSX.Element;
  renderHeadColumn?: (
    column: Omit<Column<T>, 'render' | 'renderHeadColumn'>,
  ) => JSX.Element;
}

export interface TableProps<T> {
  title: string;
  columns: Column<T>[];
  items: T[];
  totalCount: number;
  getRowId: (item: T) => string;
  pagination: { page: number; perPage: number };
  onChangePage: (page: number) => void;
  onChangePerPage: (perPage: number) => void;
  sort?: Sort | null;
  onSort?: (sort: Sort | null) => void;
  headerSideComponent?: () => JSX.Element;
  tFootComponent?: () => JSX.Element;
}

export const Table = <T,>({
  title,
  columns,
  items,
  totalCount,
  getRowId,
  pagination,
  onChangePage,
  onChangePerPage,
  sort,
  onSort: onChangeSort,
  headerSideComponent,
  tFootComponent,
}: TableProps<T>) => {
  return (
    <section>
      <TableHeader
        title={`${title} (${totalCount})`}
        sideComponent={headerSideComponent}
      />
      <TableWrapper
        columns={columns}
        items={items}
        getRowId={getRowId}
        sort={sort}
        onSort={onChangeSort}
        tFootComponent={tFootComponent}
      />
      <Pagination
        totalCount={totalCount}
        page={pagination.page}
        onChangePage={onChangePage}
        perPage={pagination.perPage}
        onChangePerPage={onChangePerPage}
      />
    </section>
  );
};

import { useAuth } from 'hooks/useAuth';
import { FcInspection } from 'react-icons/fc';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import ilustration from 'assets/default-dashboard.svg';
import styles from './dashboard.module.scss';

export const TecnicoDashboardView: React.FC = () => {
  const { auth } = useAuth();

  return (
    <section className={styles['dashboard-section']}>
      <div className={styles['shortcut-card']}>
        <Link to="/pedidos/instalacoes">
          <figure>
            <FcInspection size={32} />
          </figure>
          <h6 className="body2">INSTALAÇÕES</h6>
        </Link>
      </div>
      <h4 className="heading4 title">Bem vindo ao Fibratech.</h4>
      <article className={styles['dashboard-container']}>
        <h5 className="heading5 title">Logado como:</h5>
        <figure>
          <img
            src={auth?.usuario.avatar.url}
            alt="Foto de perfil"
            title="Foto de perfil"
          />
          <p className="body2 text">{auth?.usuario.nome}</p>
          <Link to="/perfil" title="Ver perfil">
            <FiExternalLink size={22} />
          </Link>
        </figure>
      </article>
      <figure className={styles['illustration-container']}>
        <img
          src={ilustration}
          alt="Ilustração de dashboard"
          title="Imagem ilustrativa"
        />
      </figure>
    </section>
  );
};

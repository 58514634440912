import * as Yup from 'yup';
import { FiSearch } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';
import { gql } from 'functions/gql';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'functions/dateUtils';

import { Fallback } from 'components/Fallback';
import { Button, Fieldset, Form, Input, Select } from 'components/Form';
import { FilterFields } from './list';

interface ConsultaUsuarioFilterDataQuery {
  usuarios: { items: { usuarioId: string; nome: string }[] };
}

const CONSULTA_USUARIO_FILTER_DATA_QUERY = gql`
  query {
    usuarios(filter: { equals: { ativo: true } }) {
      items {
        usuarioId
        nome
      }
    }
  }
`;

interface ConsultaUsuariosFilterProps {
  onSearch: (filterFields: FilterFields | null) => void;
}

export const ConsultaUsuariosFilter: React.FC<ConsultaUsuariosFilterProps> = ({
  onSearch,
}) => {
  const { auth, setAuth } = useAuth();
  const { fields, setFields, errors, submitHandler } = useForm({
    dataInicio: getFirstDayOfMonth().slice(0, 10),
    dataFim: getLastDayOfMonth().slice(0, 10),
    usuario: '',
    tipo: '',
  });

  const query = useQueryAuth<ConsultaUsuarioFilterDataQuery>({
    auth,
    setAuth,
    query: CONSULTA_USUARIO_FILTER_DATA_QUERY,
  });

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      dataInicio: Yup.string().required('Data início obrigatório.'),
      dataFim: Yup.string().required('Data fim obrigatório.'),
    }),
    callback: async values => {
      const filterFields = {
        between: {
          createdAt: {
            low: values.dataInicio,
            high: values.dataFim,
          },
        },
        equals: {
          ...(values.usuario && { usuarioId: values.usuario }),
          ...(values.tipo && { tipo: values.tipo }),
        },
      };

      onSearch(filterFields);
    },
  });

  const { data } = query;

  return (
    <Fieldset legend="Filtros">
      <Form onSubmit={handleSubmit} columns={3}>
        <Input
          type="date"
          name="dataInicio"
          label="Data início"
          error={errors?.dataInicio}
          value={fields.dataInicio}
          onChange={value => setFields({ ...fields, dataInicio: value })}
        />
        <Input
          type="date"
          name="dataFim"
          label="Data fim"
          error={errors?.dataFim}
          value={fields.dataFim}
          onChange={value => setFields({ ...fields, dataFim: value })}
        />
        <Select
          className="column-start"
          name="usuario"
          label="Usuário"
          value={fields.usuario}
          onChange={value => setFields({ ...fields, usuario: value })}
          options={data.usuarios.items.map(({ nome, usuarioId }) => ({
            label: nome,
            value: usuarioId,
          }))}
        />
        <Select
          name="tipo"
          label="Tipo Consulta"
          value={fields.tipo}
          onChange={value => setFields({ ...fields, tipo: value })}
          options={[
            { label: 'Nova', value: 'NOVA' },
            { label: 'Reaproveitada', value: 'REAPROVEITADA' },
          ]}
        />

        <Button type="submit" style={{ marginTop: '1rem' }}>
          <FiSearch size={20} />
          Pesquisar
        </Button>
      </Form>
    </Fieldset>
  );
};

import * as Yup from 'yup';
import { FiSearch } from 'react-icons/fi';
import { useForm } from 'hooks/useForm';
import { FilterFields } from './list';
import { Button, Fieldset, Form, Input } from 'components/Form';
import { cnpjValidator, cpfValidator } from 'functions/validators';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'functions/dateUtils';

interface ConsultaSophusFilterProps {
  onSearch: (filterFields: FilterFields | null) => void;
}

export const ConsultaSophusFilter: React.FC<ConsultaSophusFilterProps> = ({
  onSearch,
}) => {
  const { fields, setFields, errors, submitHandler } = useForm({
    dataInicio: getFirstDayOfMonth().slice(0, 10),
    dataFim: getLastDayOfMonth().slice(0, 10),
    documento: '',
  });

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      dataInicio: Yup.string().required('Data início obrigatório.'),
      dataFim: Yup.string().required('Data fim obrigatório.'),
      documento: Yup.string().test({
        test: value => {
          if (!value) {
            return true;
          }

          const parsedValue = value.replace(/\D/g, '');

          const isValidDocument =
            parsedValue.length > 11
              ? cnpjValidator.isValid(parsedValue)
              : cpfValidator.isValid(parsedValue);

          return isValidDocument;
        },
        message: 'Documento inválido.',
      }),
    }),
    callback: async values => {
      const filterFields = {
        between: {
          dataConsulta: {
            low: values.dataInicio,
            high: values.dataFim,
          },
        },
        ...(values.documento && {
          likes: {
            documento: values.documento,
          },
        }),
      };

      onSearch(filterFields);
    },
  });

  return (
    <Fieldset legend="Filtros">
      <Form onSubmit={handleSubmit} columns={3}>
        <Input
          type="date"
          name="dataInicio"
          label="Data início"
          error={errors?.dataInicio}
          value={fields.dataInicio}
          onChange={value => setFields({ ...fields, dataInicio: value })}
        />
        <Input
          type="date"
          name="dataFim"
          label="Data fim"
          error={errors?.dataFim}
          value={fields.dataFim}
          onChange={value => setFields({ ...fields, dataFim: value })}
        />
        <Input
          name="documento"
          label="Documento"
          error={errors?.documento}
          value={fields.documento}
          onChange={value => setFields({ ...fields, documento: value })}
        />

        <Button type="submit" style={{ marginTop: '1rem' }}>
          <FiSearch size={20} />
          Pesquisar
        </Button>
      </Form>
    </Fieldset>
  );
};

import styles from './TabButton.module.scss';

interface TabButtonsProps {
  labels: string[];
  activeTab: string;
  onTabClick: (label: string) => void;
}

export const TabButtons: React.FC<TabButtonsProps> = ({
  activeTab,
  labels,
  onTabClick,
}) => (
  <div className={styles['tab-buttons-container']}>
    {labels.map(label => (
      <button
        key={label}
        className={`${styles['tab-button']} ${
          activeTab === label ? styles['active'] : ''
        }`}
        onClick={() => onTabClick(label)}
        title={`Visualizar ${label}`}
      >
        {label}
      </button>
    ))}
  </div>
);

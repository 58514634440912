import { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from './Button';

import styles from './Signature.module.scss';

interface SignatureProps {
  label?: string;
  className?: string;
  onChange: (value: File | null) => void;
}

type CanvasSize = 'small' | 'medium' | 'large';

export const Signature: React.FC<SignatureProps> = ({
  label,
  className,
  onChange,
}) => {
  const canvasRef = useRef<SignatureCanvas | null>(null);
  const [canvasSize, setCanvasSize] = useState<CanvasSize>('small');

  const canvasWidth =
    canvasSize === 'large' ? 1000 : canvasSize === 'medium' ? 600 : 250;

  const resizeCanvas = () => {
    const width = window.innerWidth;

    const newCanvasSize =
      width > 1024 ? 'large' : width > 767 ? 'medium' : 'small';

    if (newCanvasSize !== canvasSize) {
      setCanvasSize(newCanvasSize);
    }
  };

  useEffect(() => {
    resizeCanvas();

    window.addEventListener('resize', resizeCanvas);

    return () => window.removeEventListener('resize', resizeCanvas);
  }, [resizeCanvas]);

  const clearCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.clear();
      onChange(null);
    }
  };

  return (
    <section
      className={`${styles['signature-section']}  ${
        className ? className : ''
      }`}
    >
      {label && <p className="text body2">{label}</p>}

      <SignatureCanvas
        ref={canvasRef}
        onEnd={() => {
          if (canvasRef.current) {
            canvasRef.current.getTrimmedCanvas().toBlob(blob => {
              if (blob) {
                const file = new File([blob], 'assinatura.png', {
                  type: 'image/png',
                  lastModified: Date.now(),
                });

                onChange(file);
              }
            }, 'image/png');
          }
        }}
        canvasProps={{
          width: canvasWidth,
          height: 250,
          id: 'signature',
          style: {
            border: '1px solid var(--gray400)',
            borderRadius: '0.25rem',
          },
        }}
      />

      <Button type="button" onClick={clearCanvas}>
        Corrigir assinatura
      </Button>
    </section>
  );
};

import { Layout } from 'components/Layout';
import { EditConfiguracaoView } from 'views/configuracoes/edit';

export const EditConfiguracaoPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Configurações', href: '/configuracoes' },
          { label: 'Editar' },
        ],
      }}
    >
      <EditConfiguracaoView />
    </Layout>
  );
};

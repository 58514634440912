import { TabList } from 'components/TabList';
import { useAuth } from 'hooks/useAuth';
import { ProfileAvatar } from './ProfileAvatar';
import { ProfileForm } from './ProfileForm';
import { ProfileFormSecurity } from './ProfileFormSecurity';

import styles from './styles.module.scss';

export const ProfileView: React.FC = () => {
  const { auth } = useAuth();

  return (
    <div className={styles['profile-container']}>
      <ProfileAvatar
        profile={{
          avatar: {
            url: auth?.usuario.avatar.url || '',
          },
          nome: auth?.usuario.nome || '',
        }}
      />
      <TabList labels={['Dados do perfil', 'Segurança da conta']}>
        <ProfileForm nome={auth?.usuario.nome || ''} />
        <ProfileFormSecurity />
      </TabList>
    </div>
  );
};

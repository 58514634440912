import { AuthProps } from 'contexts/AuthContext';
import { graphqlAuth } from 'functions/graphqlAuth';
import { useEffect, useState } from 'react';

export interface UseQueryAuthProps<Variables> {
  query: string;
  variables?: Variables;
  headers?: Record<string, string>;
  auth: AuthProps | null;
  setAuth: (auth: AuthProps | null) => void;
}

export interface UseQueryAuth<T> {
  data: T | null;
  errors: Error[] | null;
}

export type UseQueryAuthResult<T> =
  | UseQueryAuthResultLoading
  | UseQueryAuthResultError
  | UseQueryAuthResultEmpty
  | UseQueryAuthResultSuccess<T>;

export type UseQueryAuthResultLoading = {
  state: 'loading';
  fallback: true;
  loading: true;
  errors: null;
  data: null;
  refetch: () => Promise<void>;
};

export type UseQueryAuthResultError = {
  state: 'error';
  fallback: true;
  loading: false;
  errors: Error[];
  data: null;
  refetch: () => Promise<void>;
};

export interface UseQueryAuthResultEmpty {
  state: 'empty';
  fallback: true;
  loading: false;
  errors: null;
  data: null;
  refetch: () => Promise<void>;
}

export interface UseQueryAuthResultSuccess<T> {
  state: 'fullfilled';
  fallback: false;
  loading: false;
  errors: null;
  data: T;
  refetch: () => Promise<void>;
}

export const useQueryAuth = <
  T = Record<string, any>,
  Variables = Record<string, any>,
>({
  query,
  variables,
  headers,
  auth,
  setAuth,
}: UseQueryAuthProps<Variables>): UseQueryAuthResult<T> => {
  const [queryState, setQueryState] = useState<UseQueryAuth<T>>({
    data: null,
    errors: null,
  });
  const [loading, setLoading] = useState(true);

  const fetchQuery = async () => {
    setLoading(true);

    try {
      const { data, errors } = await graphqlAuth<T, Variables>({
        query,
        variables,
        headers,
        auth,
        setAuth,
      });

      setQueryState({ data, errors });
    } catch (err) {
      setQueryState({ data: null, errors: [err as Error] });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuery();
  }, [query, JSON.stringify({ variables, headers })]);

  const refetch = fetchQuery;

  if (loading) {
    return {
      state: 'loading',
      fallback: true,
      loading: true,
      errors: null,
      data: null,
      refetch,
    };
  }

  if (queryState.errors && queryState.errors.length > 0) {
    return {
      state: 'error',
      fallback: true,
      loading: false,
      errors: queryState.errors,
      data: null,
      refetch,
    };
  }

  if (!queryState.data) {
    return {
      state: 'empty',
      fallback: true,
      loading: false,
      errors: null,
      data: null,
      refetch,
    };
  }

  return {
    state: 'fullfilled',
    fallback: false,
    loading: false,
    errors: null,
    data: queryState.data,
    refetch,
  };
};

import { AnexoPreviewList } from 'components/AnexoPreview';
import { Fieldset, InputMultiFile, PreviewFile } from 'components/Form';
import { PedidoAnexo } from 'utils/types/PedidoAnexo';

import styles from './FieldsetAnexos.module.scss';

interface Fields {
  anexos: File[];
  pedidoAnexos: PedidoAnexo[];
}

interface AnexosProps {
  fields: Fields;
  setFields: (value: Fields) => void;
}

export const FieldsetAnexos: React.FC<AnexosProps> = ({
  fields,
  setFields,
}) => {
  return (
    <Fieldset legend="Anexos">
      <AnexoPreviewList anexos={fields.pedidoAnexos} />

      <InputMultiFile
        className={styles['anexos-input-file']}
        background="secondary"
        name="anexos"
        label="Adicionar Novos Anexos"
        value={fields.anexos}
        onChange={files =>
          files &&
          setFields({
            ...fields,
            anexos: [...fields.anexos, ...Array.from(files)],
          })
        }
      />

      {fields.anexos.length > 0 && (
        <PreviewFile
          files={fields.anexos.map(anexo => ({
            fileName: anexo.name,
            url: URL.createObjectURL(anexo),
            mimetype: anexo.type,
          }))}
          removeFiles={index =>
            setFields({
              ...fields,
              anexos: fields.anexos.filter((_, i) => i !== index),
            })
          }
        />
      )}
    </Fieldset>
  );
};

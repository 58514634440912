import { FiX } from 'react-icons/fi';
import { RedeSocialEnum } from 'utils/enums/RedeSocialEnum';
import { RedeSocial } from 'utils/types/RedeSocial';
import { FlexContainer } from './FlexContainer';
import { Button, Input, Select } from './Form';
import styles from './RedeSocialList.module.scss';

type RedeSocialListProps = {
  className?: string;
  errors?: any[];
  redesSociais: RedeSocial[];
  setNewRedesSociais: (redesSociais: RedeSocial[]) => void;
};

export const RedeSocialList = ({
  className,
  errors,
  redesSociais,
  setNewRedesSociais,
}: RedeSocialListProps) => {
  const removeRedeSocial = (index: number) => {
    if (redesSociais.length > 1) {
      setNewRedesSociais(redesSociais.filter((_, i) => index !== i));
      return;
    }

    setNewRedesSociais([{ link: '', redeSocial: '' as RedeSocialEnum }]);
  };

  return (
    <>
      {redesSociais.map((redeSocial, index) => (
        <section
          key={`rede-social-${index}`}
          className={`${styles['rede-social-section']} ${
            className ? className : ''
          }`}
        >
          <Select
            name={`rede-social-nome-${index}`}
            label="Rede Social"
            error={errors && errors[index]?.nome}
            value={redeSocial.redeSocial}
            onChange={value => {
              const newRedesSociais = [...redesSociais];

              newRedesSociais[index] = {
                ...newRedesSociais[index],
                redeSocial: value as RedeSocialEnum,
              };

              setNewRedesSociais(newRedesSociais);
            }}
            options={[
              { label: 'E-mail', value: 'EMAIL' },
              { label: 'Facebook', value: 'FACEBOOK' },
              { label: 'Instagram', value: 'INSTAGRAM' },
              { label: 'Linkedin', value: 'LINKEDIN' },
              { label: 'TikTok', value: 'TIKTOK' },
              { label: 'Twitter', value: 'TWITTER' },
              { label: 'Website', value: 'WEBSITE' },
              { label: 'Whatsapp', value: 'WHATSAPP' },
              { label: 'Outro', value: 'OUTRO' },
            ]}
          />
          <FlexContainer className={styles['align-end']}>
            <Input
              name={`link-${index}`}
              label="Link"
              error={errors && errors[index]?.link}
              value={redeSocial.link}
              onChange={value => {
                const newRedesSociais = [...redesSociais];

                newRedesSociais[index] = {
                  ...newRedesSociais[index],
                  link: value,
                };

                setNewRedesSociais(newRedesSociais);
              }}
            />
            <button
              type="button"
              style={errors ? { alignSelf: 'center' } : {}}
              onClick={() => removeRedeSocial(index)}
            >
              <FiX size={22} />
            </button>
          </FlexContainer>
        </section>
      ))}

      <Button
        type="button"
        background="secondary"
        style={{ width: 'max-content' }}
        onClick={() =>
          setNewRedesSociais([
            ...redesSociais,
            { redeSocial: '' as RedeSocialEnum, link: '' },
          ])
        }
      >
        Nova Rede Social
      </Button>
    </>
  );
};

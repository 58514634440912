import { fromDateTimeLocal, toDateTimeLocal } from 'functions/formatters';

import { Fieldset, Input, Select } from 'components/Form';

interface Fields {
  dataAgendamento: Date | null;
  tecnico: string;
}

interface FieldsetInformacoesTecnicasProps {
  allTecnicos: { usuarioId: string; nome: string }[];
  fields: Fields;
  setFields: (value: Fields) => void;
  errors: any;
}

export const FieldsetInformacoesTecnicas: React.FC<
  FieldsetInformacoesTecnicasProps
> = ({ allTecnicos, errors, fields, setFields }) => {
  return (
    <Fieldset legend="Informações Tecnicas" columns={2}>
      <Select
        label="Técnico"
        name="tecnico"
        error={errors?.tecnico}
        value={fields.tecnico}
        onChange={value => setFields({ ...fields, tecnico: value })}
        options={allTecnicos.map(({ nome, usuarioId }) => ({
          label: nome,
          value: usuarioId,
        }))}
      />
      <Input
        type="datetime-local"
        label="Data do Agendamento"
        name="dataAgendamento"
        error={errors?.dataAgendamento}
        value={toDateTimeLocal(fields.dataAgendamento)}
        onChange={value =>
          setFields({ ...fields, dataAgendamento: fromDateTimeLocal(value) })
        }
      />
    </Fieldset>
  );
};

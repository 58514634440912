import { useParams } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';
import { Log } from 'utils/Log';
import { gql } from 'functions/gql';

import { TabList } from 'components/TabList';
import { Fallback } from 'components/Fallback';
import { LogsTable } from 'components/LogsTable';
import { ConsultaDetail } from './ConsultaDetail';
import { ConsultaItensTable } from './ConsultaItensTable';

interface ConsultaSophusDetailQueryVariables {
  id: { consultaId: string };
}

interface ConsultaSophusDetailQuery {
  consulta: {
    consultaId: string;
    documento: string;
    resultado: string;
    dataConsulta: string;
    url: string;
    logs: { items: Log[] };
    allConsultasItens: {
      consultaItemId: string;
      tipo: string;
      quantidade: number;
      valor: number;
    }[];
  };
}

const CONSULTA_SOPHUS_DETAIL_QUERY = gql`
  query ($id: ConsultaID!) {
    consulta(id: $id) {
      consultaId
      documento
      resultado
      dataConsulta
      url
      logs {
        items {
          logId
          tipo
          descricao
          createdAt
          usuario {
            nome
            avatar {
              url
            }
          }
        }
      }
      allConsultasItens {
        consultaItemId
        tipo
        quantidade
        valor
      }
    }
  }
`;

export const DetailConsultaSophusView: React.FC = () => {
  const params = useParams();
  const { auth, setAuth } = useAuth();

  const consultaId = params.consultaId || '';

  const query = useQueryAuth<
    ConsultaSophusDetailQuery,
    ConsultaSophusDetailQueryVariables
  >({
    auth,
    setAuth,
    query: CONSULTA_SOPHUS_DETAIL_QUERY,
    variables: { id: { consultaId } },
  });

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const { data } = query;

  return (
    <TabList labels={['Detalhes', 'Consulta Itens', 'Logs']}>
      <ConsultaDetail
        dataConsulta={data.consulta.dataConsulta}
        resultado={data.consulta.resultado}
        url={data.consulta.url}
      />
      <ConsultaItensTable consultaItens={data.consulta.allConsultasItens} />
      <LogsTable logs={data.consulta.logs.items} />
    </TabList>
  );
};

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { parseError } from 'functions/parseError';
import { Button } from './Form';

import styles from './FallbackError.module.scss';

export interface FallbackErrorProps {
  errors: Error[] | null;
  goBackURL?: string;
}

export const FallbackError: React.FC<FallbackErrorProps> = ({
  errors,
  goBackURL,
}) => {
  const [seeDetails, setSeeDetails] = useState(false);

  useEffect(() => {
    if (errors) {
      for (const error of errors) {
        console.error(error);
      }
    }
  }, [errors]);

  return (
    <section className={styles['fallback-error-section']}>
      <h1>Ooops...</h1>
      <p className="body2 text">A página que você procura encontrou um erro.</p>

      {errors ? (
        <>
          <Button
            type="button"
            isOutlined
            background="secondary"
            onClick={() => setSeeDetails(!seeDetails)}
            title="Clique para visualizar os detalhes do erro."
          >
            {seeDetails ? 'Esconder' : 'Ver Detalhes'}
            {seeDetails ? (
              <FiChevronUp size={22} />
            ) : (
              <FiChevronDown size={22} />
            )}
          </Button>

          {seeDetails && (
            <code>
              <pre>
                {JSON.stringify(
                  errors.map(err => parseError(err)),
                  null,
                  2,
                )}
              </pre>
            </code>
          )}
        </>
      ) : (
        <p>Nenhum conteúdo recebido pela API.</p>
      )}

      <Link to={goBackURL || '/'} title="Clique para voltar ao início.">
        <FiArrowLeft size={22} />
        Voltar ao Início
      </Link>
    </section>
  );
};

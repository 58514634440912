import * as Yup from 'yup';

export const resetarSenhaSchema = Yup.object().shape({
  senhaAtual: Yup.string()
    .min(6, 'Mínimo de 6 caracteres.')
    .required('Senha atual obrigatório.'),
  novaSenha: Yup.string()
    .min(6, 'Mínimo de 6 caracteres.')
    .required('Nova senha obrigatório.'),
  confirmarSenha: Yup.string()
    .min(6, 'Mínimo de 6 caracteres.')
    .test('match', 'As senhas não conferem.', function (confirmarSenha) {
      return confirmarSenha === this.parent.novaSenha;
    })
    .required('Confirmar senha obrigatório.'),
});

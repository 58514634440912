import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { EnderecoResult } from 'utils/types/Endereco';

import { Enderecos } from './Enderecos';
import { ConsultaDetail } from './ConsultaDetail';
import { DadosPessoaFisica } from './DadosPessoaFisica';
import { DadosPessoaJuridica } from './DadosPessoaJuridica';
import { TabList } from 'components/TabList';
import { Fallback } from 'components/Fallback';

interface ConsultaUsuariosDetailQueryVariables {
  id: { consultaUsuarioId: string };
}

interface ConsultaUsuariosDetailQuery {
  consultaUsuario: {
    consultaUsuarioId: string;
    tipo: string;
    consulta: {
      consultaId: string;
      dataConsulta: string;
      resultado: string;
      url: string;
    };
    usuario: { usuarioId: string; nome: string };
    tipoPessoa: string;
    clientePessoaFisicaNome: string;
    clientePessoaFisicaCpf: string;
    clientePessoaFisicaRg: string;
    clientePessoaFisicaSexo: string;
    clientePessoaFisicaEstadoCivilId: string;
    clientePessoaFisicaDataNascimento: string;
    clientePessoaJuridicaCnpj: string;
    clientePessoaJuridicaRazaoSocial: string;
    clientePessoaJuridicaRamoEmpresa: string;
    clientePessoaJuridicaNomeFantasia: string;
    clientePessoaJuridicaDataAbertura: string;
    clientePessoaJuridicaInscricaoEstadual: string;
    clientePessoaJuridicaInscricaoMunicipal: string;
    clientePessoaJuridicaTipoRetencaoNotaFiscal: string;
    clientePessoaJuridicaRepresentanteLegalRg: string;
    clientePessoaJuridicaRepresentanteLegalCpf: string;
    clientePessoaJuridicaRepresentanteLegalNome: string;
    clientePessoaJuridicaRepresentanteLegalSexo: string;
    clientePessoaJuridicaRepresentanteLegalEstadoCivilId: string;
    clientePessoaJuridicaRepresentanteLegalDataNascimento: string;
    allTelefones: Telefone[];
    allEmails: Email[];
    enderecoInstalacao: EnderecoResult;
    enderecoCobranca: EnderecoResult;
  };
}

const CONSULTA_USUARIOS_DETAIL_QUERY = gql`
  query ($id: ConsultaUsuarioID!) {
    consultaUsuario(id: $id) {
      consultaUsuarioId
      tipo
      consulta {
        consultaId
        dataConsulta
        resultado
        url
      }
      usuario {
        usuarioId
        nome
      }
      tipoPessoa
      clientePessoaFisicaNome
      clientePessoaFisicaCpf
      clientePessoaFisicaRg
      clientePessoaFisicaSexo
      clientePessoaFisicaEstadoCivilId
      clientePessoaFisicaDataNascimento
      clientePessoaJuridicaCnpj
      clientePessoaJuridicaRazaoSocial
      clientePessoaJuridicaRamoEmpresa
      clientePessoaJuridicaNomeFantasia
      clientePessoaJuridicaDataAbertura
      clientePessoaJuridicaInscricaoEstadual
      clientePessoaJuridicaInscricaoMunicipal
      clientePessoaJuridicaTipoRetencaoNotaFiscal
      clientePessoaJuridicaRepresentanteLegalRg
      clientePessoaJuridicaRepresentanteLegalCpf
      clientePessoaJuridicaRepresentanteLegalNome
      clientePessoaJuridicaRepresentanteLegalSexo
      clientePessoaJuridicaRepresentanteLegalEstadoCivilId
      clientePessoaJuridicaRepresentanteLegalDataNascimento
      allTelefones {
        telefoneId
        nome
        telefone
        whatsapp
      }
      allEmails {
        emailId
        nome
        email
      }
      enderecoInstalacao {
        cep
        numero
        enderecoId
        logradouro
        bairro
        cidade {
          nome
          estado {
            nome
          }
        }
        complemento
        pontoReferencia
      }
      enderecoCobranca {
        cep
        numero
        enderecoId
        logradouro
        bairro
        cidade {
          nome
          estado {
            nome
          }
        }
        complemento
        pontoReferencia
      }
    }
  }
`;

export const DetailConsultaUsuarios: React.FC = () => {
  const params = useParams();
  const { auth, setAuth } = useAuth();

  const [fields, setFields] = useState({
    tipo: '',
    consulta: { consultaId: '', dataConsulta: '', resultado: '', url: '' },
    usuario: { usuarioId: '', nome: '' },
    tipoPessoa: '',
    dadosPessoaFisica: {
      nome: '',
      cpf: '',
      rg: '',
      dataNascimento: '',
      sexo: '',
      estadoCivilId: '',
      telefones: [{ nome: '', telefone: '', whatsapp: false }],
      emails: [{ nome: '', email: '' }],
    },
    dadosPessoaJuridica: {
      cnpj: '',
      razaoSocial: '',
      nomeFantasia: '',
      dataAbertura: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      tipoRetencaoNotaFiscal: '',
      ramoEmpresa: '',
      telefones: [{ nome: '', telefone: '', whatsapp: false }],
      emails: [{ nome: '', email: '' }],
      representanteNome: '',
      representanteCpf: '',
      representanteRg: '',
      representanteSexo: '',
      representanteEstadoCivilId: '',
      representanteDataNascimento: '',
    },
    enderecoInstalacao: {
      enderecoId: '',
      cep: '',
      numero: '',
      logradouro: '',
      bairro: '',
      cidade: { nome: '', estado: { nome: '' } },
      complemento: '',
      pontoReferencia: '',
    },
    enderecoCobranca: {
      enderecoId: '',
      cep: '',
      numero: '',
      logradouro: '',
      bairro: '',
      cidade: { nome: '', estado: { nome: '' } },
      complemento: '',
      pontoReferencia: '',
    },
  });

  const consultaUsuarioId = params.consultaUsuarioId || '';

  const query = useQueryAuth<
    ConsultaUsuariosDetailQuery,
    ConsultaUsuariosDetailQueryVariables
  >({
    auth,
    setAuth,
    query: CONSULTA_USUARIOS_DETAIL_QUERY,
    variables: { id: { consultaUsuarioId } },
  });

  useEffect(() => {
    if (query.data) {
      const { data } = query;

      setFields({
        tipo: data.consultaUsuario.tipo,
        consulta: data.consultaUsuario.consulta,
        usuario: data.consultaUsuario.usuario,
        tipoPessoa: data.consultaUsuario.tipoPessoa,
        dadosPessoaFisica: {
          nome: data.consultaUsuario.clientePessoaFisicaNome,
          cpf: data.consultaUsuario.clientePessoaFisicaCpf,
          rg: data.consultaUsuario.clientePessoaFisicaRg,
          dataNascimento:
            data.consultaUsuario.clientePessoaFisicaDataNascimento,
          sexo: data.consultaUsuario.clientePessoaFisicaSexo || '',
          estadoCivilId:
            data.consultaUsuario.clientePessoaFisicaEstadoCivilId || '',
          telefones: data.consultaUsuario.allTelefones,
          emails: data.consultaUsuario.allEmails,
        },
        dadosPessoaJuridica: {
          cnpj: data.consultaUsuario.clientePessoaJuridicaCnpj,
          razaoSocial: data.consultaUsuario.clientePessoaJuridicaRazaoSocial,
          nomeFantasia: data.consultaUsuario.clientePessoaJuridicaNomeFantasia,
          dataAbertura: data.consultaUsuario.clientePessoaJuridicaDataAbertura,
          inscricaoEstadual:
            data.consultaUsuario.clientePessoaJuridicaInscricaoEstadual,
          inscricaoMunicipal:
            data.consultaUsuario.clientePessoaJuridicaInscricaoMunicipal,
          tipoRetencaoNotaFiscal:
            data.consultaUsuario.clientePessoaJuridicaTipoRetencaoNotaFiscal,
          ramoEmpresa: data.consultaUsuario.clientePessoaJuridicaRamoEmpresa,
          telefones: data.consultaUsuario.allTelefones,
          emails: data.consultaUsuario.allEmails,
          representanteNome:
            data.consultaUsuario.clientePessoaJuridicaRepresentanteLegalNome,
          representanteCpf:
            data.consultaUsuario.clientePessoaJuridicaRepresentanteLegalCpf,
          representanteRg:
            data.consultaUsuario.clientePessoaJuridicaRepresentanteLegalRg,
          representanteSexo:
            data.consultaUsuario.clientePessoaJuridicaRepresentanteLegalSexo,
          representanteEstadoCivilId:
            data.consultaUsuario
              .clientePessoaJuridicaRepresentanteLegalEstadoCivilId,
          representanteDataNascimento:
            data.consultaUsuario
              .clientePessoaJuridicaRepresentanteLegalDataNascimento,
        },
        enderecoInstalacao: {
          enderecoId: data.consultaUsuario.enderecoInstalacao.enderecoId,
          cep: data.consultaUsuario.enderecoInstalacao.cep,
          numero: data.consultaUsuario.enderecoInstalacao.numero,
          logradouro: data.consultaUsuario.enderecoInstalacao.logradouro,
          bairro: data.consultaUsuario.enderecoInstalacao.bairro,
          cidade: data.consultaUsuario.enderecoInstalacao.cidade,
          complemento: data.consultaUsuario.enderecoInstalacao.complemento,
          pontoReferencia:
            data.consultaUsuario.enderecoInstalacao.pontoReferencia,
        },
        enderecoCobranca: {
          enderecoId: data.consultaUsuario.enderecoCobranca.enderecoId,
          cep: data.consultaUsuario.enderecoCobranca.cep,
          numero: data.consultaUsuario.enderecoCobranca.numero,
          logradouro: data.consultaUsuario.enderecoCobranca.logradouro,
          bairro: data.consultaUsuario.enderecoCobranca.bairro,
          cidade: data.consultaUsuario.enderecoCobranca.cidade,
          complemento: data.consultaUsuario.enderecoCobranca.complemento,
          pontoReferencia:
            data.consultaUsuario.enderecoCobranca.pontoReferencia,
        },
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  return (
    <TabList labels={['Detalhes', 'Dados', 'Endereços']}>
      <ConsultaDetail
        dataConsulta={fields.consulta.dataConsulta}
        resultado={fields.consulta.resultado}
        url={fields.consulta.url}
      />

      {fields.tipoPessoa === 'PESSOA_FISICA' ? (
        <DadosPessoaFisica {...fields.dadosPessoaFisica} />
      ) : (
        <DadosPessoaJuridica {...fields.dadosPessoaJuridica} />
      )}

      <Enderecos
        enderecoInstalacao={fields.enderecoInstalacao}
        enderecoCobranca={fields.enderecoCobranca}
      />
    </TabList>
  );
};

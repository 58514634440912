import { Layout } from 'components/Layout';
import { ListConsultaSophusView } from 'views/relatorios/consultas/sophus/list';

export const ListConsultaSophusPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Consultas Sophus' }] }}>
      <ListConsultaSophusView />
    </Layout>
  );
};

import { ChangeEvent, InputHTMLAttributes, useState } from 'react';

import { Label } from './Label';
import { ErrorMessage } from './ErrorMessage';

import styles from './InputWithIcon.module.scss';

type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'name' | 'label' | 'value' | 'onChange'
> & {
  name: string;
  label: string;
  error?: string;
  icon: () => JSX.Element;
  value: string;
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
};

export const InputWithIcon: React.FC<InputProps> = ({
  className,
  name,
  label,
  error = '',
  icon: Icon,
  value,
  onChange,
  ...rest
}) => {
  const [inputFocus, setInputFocus] = useState(false);

  return (
    <section
      className={`${styles['input-section']}  ${className ? className : ''}`}
    >
      <Label htmlFor={name}>
        {label}
        <div
          className={[
            styles['input-field-container'],
            inputFocus ? styles['is-focused'] : '',
            !!error ? styles['is-errored'] : '',
          ].join(' ')}
        >
          <input
            type="text"
            className={styles['input-field']}
            id={name}
            name={name}
            value={value}
            onFocus={() => setInputFocus(true)}
            onChange={e => onChange && onChange(e.target.value, e)}
            onBlur={() => setInputFocus(false)}
            {...rest}
          />
          <Icon />
        </div>
        {error && <ErrorMessage error={error} />}
      </Label>
    </section>
  );
};

import { FiX } from 'react-icons/fi';
import { FlexContainer } from './FlexContainer';

import { Button, Input, InputPhone } from './Form';

import styles from './PhoneList.module.scss';

type Telefone = {
  nome: string;
  telefone: string;
  whatsapp: boolean;
};

type PhoneListProps = {
  className?: string;
  errors?: any[];
  telefones: Telefone[];
  setNewTelefones: (newTelefones: Telefone[]) => void;
  onRemove: (index: number) => void;
};

export const PhoneList: React.FC<PhoneListProps> = ({
  className,
  errors,
  telefones,
  setNewTelefones,
  onRemove,
}) => {
  const toggleWhatsApp = (index: number) => {
    const items = [...telefones];

    items.splice(index, 1, {
      ...telefones[index],
      whatsapp: !telefones[index].whatsapp,
    });

    setNewTelefones(items);
  };

  return (
    <>
      {telefones.map((telefone, index) => (
        <section
          key={`telefone-${index}`}
          className={`${styles['telefone-section']} ${
            className ? className : ''
          }`}
        >
          <Input
            placeholder="Ex: Contato pessoal"
            name={`telefone-nome-${index}`}
            label="Descrição Telefone"
            error={errors && errors[index]?.nome}
            value={telefone.nome}
            onChange={value => {
              const newTelefones = [...telefones];

              newTelefones[index] = {
                ...newTelefones[index],
                nome: value,
              };

              setNewTelefones(newTelefones);
            }}
          />
          <FlexContainer className={styles['align-end']}>
            <InputPhone
              id={`telefone-${index}`}
              isWhatsapp={telefone.whatsapp}
              error={errors && errors[index]?.telefone}
              value={telefone.telefone}
              onChange={value => {
                const newTelefones = [...telefones];

                newTelefones[index] = {
                  ...newTelefones[index],
                  telefone: value,
                  whatsapp: newTelefones[index].whatsapp,
                };

                setNewTelefones(newTelefones);
              }}
              toggleWhatsApp={() => toggleWhatsApp(index)}
            />
            <button
              type="button"
              style={errors ? { alignSelf: 'center' } : {}}
              onClick={() => onRemove(index)}
            >
              <FiX size={22} />
            </button>
          </FlexContainer>
        </section>
      ))}

      <Button
        type="button"
        background="secondary"
        style={{ width: 'max-content' }}
        onClick={() =>
          setNewTelefones([
            ...telefones,
            { nome: '', telefone: '', whatsapp: false },
          ])
        }
      >
        Novo Telefone
      </Button>
    </>
  );
};

import { gql } from 'functions/gql';
import { graphql } from 'functions/graphql';

import { GraphQLError } from 'utils/GraphQLError';
import { signInSchema } from './utils/signInSchema';
import { PerfilEnum } from 'utils/enums/PerfilEnum';

import { useAuth } from 'hooks/useAuth';
import { useForm } from 'hooks/useForm';

import { Input, Button, Form } from 'components/Form';

import styles from './styles.module.scss';

import logo from 'assets/logo-dark.svg';

export interface QuerySignIn {
  auth: {
    signIn: {
      refreshToken: string;
      accessToken: string;
      expiresAt: string;
      usuario: {
        usuarioId: string;
        login: string;
        nome: string;
        resetar: boolean;
        perfilId: PerfilEnum;
        avatar: {
          url: string;
        };
        grupoAcesso: {
          grupoAcessoId: string;
          nome: string;
          acessos: {
            totalCount: number;
            items: {
              acessoId: string;
              nome: string;
            }[];
          };
        };
      };
    };
  };
}

export const QUERY_SIGN_IN = gql`
  mutation ($input: SignInInput!) {
    auth {
      signIn(input: $input) {
        refreshToken
        accessToken
        expiresAt
        usuario {
          usuarioId
          login
          nome
          resetar
          perfilId
          avatar {
            url
          }
          grupoAcesso {
            grupoAcessoId
            nome
            acessos {
              totalCount
              items {
                acessoId
                nome
              }
            }
          }
        }
      }
    }
  }
`;

export const LoginView: React.FC = () => {
  const { setAuth } = useAuth();

  const { errors, fields, setFields, submitHandler } = useForm({
    login: '',
    senha: '',
  });

  const handleSubmit = submitHandler({
    validateSchema: signInSchema,
    callback: async values => {
      const { data, errors } = await graphql<QuerySignIn>({
        query: QUERY_SIGN_IN,
        variables: {
          input: {
            login: values.login,
            senha: values.senha,
          },
        },
      });

      if (errors) {
        throw new GraphQLError(`Falha ao fazer Login. \n`, errors);
      }

      setAuth(data.auth.signIn);
    },
  });

  return (
    <section className={styles['login-container']}>
      <div className={styles['content']}>
        <figure className={styles['img-container']}>
          <img src={logo} alt="Fibratech Logo" />
        </figure>
        <h1 className="title">Login</h1>

        <Form onSubmit={handleSubmit} columns={1}>
          <Input
            name="login"
            label="Login"
            value={fields.login}
            onChange={value => setFields({ ...fields, login: value })}
            error={errors.login}
          />
          <Input
            type="password"
            name="senha"
            label="Senha"
            value={fields.senha}
            onChange={value => setFields({ ...fields, senha: value })}
            error={errors.senha}
          />
          <Button background="primary" type="submit">
            Logar
          </Button>
        </Form>
        <span className="body4 text">
          Versão: {process.env.REACT_APP_VERSION}
        </span>
      </div>

      <div className={styles['square']} />
    </section>
  );
};

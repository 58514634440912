import { Layout } from 'components/Layout';
import { EditFinalidadeView } from 'views/finalidades/edit';

export const EditFinalidadePage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Finalidade', href: '/finalidades' },
          { label: 'Editar' },
        ],
      }}
    >
      <EditFinalidadeView />
    </Layout>
  );
};

import { Layout } from 'components/Layout';
import { NewPedidoView } from 'views/pedidos/new-pedido';

export const NewPedidoPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [{ label: 'Pedidos', href: '/pedidos' }, { label: 'Novo' }],
      }}
    >
      <NewPedidoView />
    </Layout>
  );
};

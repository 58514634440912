import * as Yup from 'yup';

export const pedidoInformacoesServicosSchema = Yup.object({
  diaVencimentoId: Yup.string().required('Dia Vencimento obrigatório.'),
  fidelidadeId: Yup.string().required('Fidelidade obrigatório.'),
  finalidades: Yup.array().of(Yup.string()),
  rede: Yup.string().required('Nome do wi-fi obrigatório.'),
  senha: Yup.string()
    .min(8, 'Mínimo de ${min} caracteres.')
    .required('Senha obrigatório.'),
});

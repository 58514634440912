import { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';

import { Fallback } from 'components/Fallback';
import { Form, FormFooter, Input } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

interface FidelidadeUpdateQuery {
  fidelidadeUpdate: {
    fidelidadeId: number;
    nome: string;
  };
}

interface FidelidadeUpdateVariables {
  id: { fidelidadeId: string };
  input: { nome: string };
}

const FIDELIDADE_UPDATE_QUERY = gql`
  mutation ($id: FidelidadeID!, $input: FidelidadeUpdateInput!) {
    fidelidadeUpdate(id: $id, input: $input) {
      fidelidadeId
      nome
    }
  }
`;

interface FidelidadeEditDataQuery {
  fidelidade: {
    fidelidadeId: string;
    nome: string;
    ativo: boolean;
  };
}

interface FidelidadeEditDataQueryVariables {
  id: {
    fidelidadeId: string;
  };
}

const FIDELIDADE_EDIT_DATA_QUERY = gql`
  query ($id: FidelidadeID!) {
    fidelidade(id: $id) {
      fidelidadeId
      nome
      ativo
    }
  }
`;

export const EditFidelidadeView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({ nome: '' });

  const fidelidadeId = params.fidelidadeId || '';

  const query = useQueryAuth<
    FidelidadeEditDataQuery,
    FidelidadeEditDataQueryVariables
  >({
    auth,
    setAuth,
    query: FIDELIDADE_EDIT_DATA_QUERY,
    variables: { id: { fidelidadeId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({ ...fields, nome: query.data.fidelidade.nome });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      nome: Yup.string().required('Nome obrigatório.'),
    }),
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { errors } = await graphqlAuth<
          FidelidadeUpdateQuery,
          FidelidadeUpdateVariables
        >({
          auth,
          setAuth,
          query: FIDELIDADE_UPDATE_QUERY,
          variables: { id: { fidelidadeId }, input: { nome: values.nome } },
        });

        if (errors) {
          throw new GraphQLError('Falha ao alterar fidelidade', errors);
        }

        successToast('Fidelidade alterado com sucesso!');

        navigate('/fidelidades');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
      </Form>
    </>
  );
};

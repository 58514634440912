import * as Yup from 'yup';

export const newPedidoDadosEmpregaticiosSchema = Yup.object({
  vinculoEmpregaticio: Yup.string().required(
    'Vínculo Empregatício obrigatório.',
  ),
  localTrabalho: Yup.string().when('vinculoEmpregaticio', {
    is: (value: any) => value !== 'AUTONOMO',
    then: Yup.string().required('Local de Trabalho obrigatório.'),
  }),
  renda: Yup.string().when('vinculoEmpregaticio', {
    is: (value: any) => value !== 'AUTONOMO',
    then: Yup.string()
      .test({
        test: value =>
          Number(value?.replace(/[R$.\s]/g, '').replace(',', '.')) >= 0.0,
        message: 'Renda deve ser maior ou igual à R$ 0.00.',
      })
      .required('Renda obrigatório.'),
  }),
  telefoneTrabalho: Yup.object({
    nome: Yup.string(),
    numero: Yup.string(),
  }),
  atividadeExercida: Yup.string().when('vinculoEmpregaticio', {
    is: (value: any) => value === 'AUTONOMO',
    then: Yup.string().required('Atividade Exercída obrigatório.'),
  }),
  tipoResidencia: Yup.string(),
  quantidadeMoradores: Yup.string(),
  tempoMoradia: Yup.string().when('tipoResidencia', {
    is: 'ALUGADA',
    then: Yup.string().required('Tempo de moradia obrigatório.'),
  }),
  possuiInternet: Yup.boolean(),
  operadora: Yup.string().when('possuiInternet', {
    is: true,
    then: Yup.string().required('Operadora obrigatório.'),
  }),
  motivoCancelamento: Yup.string().when('possuiInternet', {
    is: true,
    then: Yup.string().required('Motivo Cancelamento obrigatório.'),
  }),
});

import { Layout } from 'components/Layout';
import { NewUsuarioView } from 'views/usuarios/new';

export const NewUsuarioPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [{ label: 'Usuários', href: '/usuarios' }, { label: 'Novo' }],
      }}
    >
      <NewUsuarioView />
    </Layout>
  );
};

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { Log } from 'utils/Log';
import { gql } from 'functions/gql';
import { currencyFormat } from 'functions/formatters';

import { TabList } from 'components/TabList';
import { Fallback } from 'components/Fallback';
import { LogsTable } from 'components/LogsTable';
import { Button, Form, Input } from 'components/Form';

interface PlanoDataQueryVariables {
  id: { planoId: string };
}

interface PlanoDataQuery {
  plano: {
    planoId: string;
    nome: string;
    preco: number;
    logs: { items: Log[] };
  };
}

const PLANO_DATA_QUERY = gql`
  query ($id: PlanoID!) {
    plano(id: $id) {
      planoId
      nome
      preco
      logs {
        items {
          logId
          tipo
          descricao
          createdAt
          usuario {
            nome
            avatar {
              url
            }
          }
        }
      }
    }
  }
`;

export const DetailPlanoView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [fields, setFields] = useState({ nome: '', preco: '' });

  const planoId = params.planoId || '';

  const query = useQueryAuth<PlanoDataQuery, PlanoDataQueryVariables>({
    query: PLANO_DATA_QUERY,
    variables: { id: { planoId } },
    auth,
    setAuth,
  });

  useEffect(() => {
    if (query.data) {
      setFields({
        nome: query.data.plano.nome,
        preco: currencyFormat(query.data.plano.preco),
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  return (
    <TabList labels={['Detalhes', 'Logs']}>
      <Form
        columns={2}
        footerComponent={
          <Button
            type="button"
            style={{ alignSelf: 'flex-end', width: '200px' }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        }
      >
        <Input readOnly name="nome" label="Nome" value={fields.nome} />
        <Input
          readOnly
          name="preco"
          label="Valor do Plano (R$)"
          value={fields.preco}
        />
      </Form>
      <LogsTable logs={query.data.plano.logs.items} />
    </TabList>
  );
};

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { gql } from 'functions/gql';
import { graphqlAuth } from 'functions/graphqlAuth';

import { GraphQLError } from 'utils/GraphQLError';
import { successToast } from 'utils/toast';

import { Form, FormFooter, Input } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

interface FinalidadeUpdateQuery {
  finalidadeUpdate: {
    finalidadeId: number;
    nome: string;
  };
}

interface FinalidadeUpdateVariables {
  id: { finalidadeId: string };
  input: { nome: string };
}

const FINALIDADE_UPDATE_QUERY = gql`
  mutation ($id: FinalidadeID!, $input: FinalidadeUpdateInput!) {
    finalidadeUpdate(id: $id, input: $input) {
      finalidadeId
      nome
    }
  }
`;

interface FinalidadeEditDataQuery {
  finalidade: {
    finalidadeId: string;
    nome: string;
  };
}

interface FinalidadeEditDataQueryVariables {
  id: {
    finalidadeId: string;
  };
}

const FINALIDADE_EDIT_DATA_QUERY = gql`
  query ($id: FinalidadeID!) {
    finalidade(id: $id) {
      finalidadeId
      nome
    }
  }
`;

export const EditFinalidadeView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({ nome: '' });

  const finalidadeId = params.finalidadesId || '';

  const query = useQueryAuth<
    FinalidadeEditDataQuery,
    FinalidadeEditDataQueryVariables
  >({
    auth,
    setAuth,
    query: FINALIDADE_EDIT_DATA_QUERY,
    variables: { id: { finalidadeId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({ ...fields, nome: query.data.finalidade.nome });
    }
  }, [query.data]);

  const handleSubmit = submitHandler({
    validateSchema: Yup.object({
      nome: Yup.string().required('Nome obrigatório.'),
    }),
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { errors } = await graphqlAuth<
          FinalidadeUpdateQuery,
          FinalidadeUpdateVariables
        >({
          auth,
          setAuth,
          query: FINALIDADE_UPDATE_QUERY,
          variables: {
            id: { finalidadeId },
            input: { nome: values.nome },
          },
        });

        if (errors) {
          throw new GraphQLError('Falha ao alterar finalidade', errors);
        }

        successToast('Finalidade alterado com sucesso!');

        navigate('/finalidades');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
      </Form>
    </>
  );
};

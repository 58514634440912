import goldTrophy from 'assets/gold-trophy.svg';
import silverTrophy from 'assets/silver-trophy.svg';
import bronzeTrophy from 'assets/bronze-trophy.svg';

import styles from './RankingTopPosition.module.scss';

interface RankingTopPositionProps {
  trophyColor: 'gold' | 'silver' | 'bronze';
  item: {
    posicao: number;
    quantidade: number;
    usuario: { usuarioId: string; nome: string };
  };
}

export const RankingTopPosition: React.FC<RankingTopPositionProps> = ({
  item,
  trophyColor,
}) => {
  return (
    <div className={styles['ranking-top-position']}>
      <figure>
        <img
          src={
            trophyColor === 'gold'
              ? goldTrophy
              : trophyColor === 'silver'
              ? silverTrophy
              : bronzeTrophy
          }
          alt="Troféu"
        />
      </figure>
      <h5 className="heading5 text">{item.posicao}º</h5>
      <div>
        <p className="body2 text">{item.usuario.nome}</p>
        <span className="body3 textLight">Quantidade: {item.quantidade}</span>
      </div>
    </div>
  );
};

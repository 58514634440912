import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';

import { gql } from 'functions/gql';
import { currencyMask } from 'functions/mask';
import { graphqlAuth } from 'functions/graphqlAuth';
import { currencyFormat } from 'functions/formatters';

import { Fallback } from 'components/Fallback';
import { Form, FormFooter, Input } from 'components/Form';
import { ModalConfirm } from 'components/Modal/ModalConfirm';

interface PlanoDataQueryVariables {
  id: { planoId: string };
}

interface PlanoDataQuery {
  plano: {
    planoId: string;
    nome: string;
    preco: number;
  };
}

const PLANO_DATA_QUERY = gql`
  query ($id: PlanoID!) {
    plano(id: $id) {
      planoId
      nome
      preco
    }
  }
`;

interface PlanoUpdateQueryVariables {
  id: { planoId: string };
  input: {
    ativo?: boolean;
    nome?: string;
    preco?: number;
  };
}

interface PlanoUpdateQuery {
  planoUpdate: { planoId: string };
}

const PLANO_UPDATE_QUERY = gql`
  mutation ($id: PlanoID!, $input: PlanoUpdateInput!) {
    planoUpdate(id: $id, input: $input) {
      planoId
      nome
      preco
    }
  }
`;

export const EditPlanoView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    nome: '',
    preco: '',
  });

  const planoId = params.planoId || '';

  const query = useQueryAuth<PlanoDataQuery, PlanoDataQueryVariables>({
    query: PLANO_DATA_QUERY,
    variables: { id: { planoId } },
    auth,
    setAuth,
  });

  useEffect(() => {
    if (query.data) {
      setFields({
        ...fields,
        nome: query.data.plano.nome,
        preco: currencyFormat(query.data.plano.preco),
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const handleSubmit = submitHandler({
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const parsedPreco = Number(
          values.preco.replace(/[R$.\s]/g, '').replace(',', '.'),
        );

        const { errors } = await graphqlAuth<
          PlanoUpdateQuery,
          PlanoUpdateQueryVariables
        >({
          query: PLANO_UPDATE_QUERY,
          variables: {
            id: { planoId },
            input: { nome: values.nome, preco: parsedPreco },
          },
          auth,
          setAuth,
        });

        if (errors) {
          throw new GraphQLError('Falha ao alterar plano', errors);
        }

        successToast('Plano alterado com sucesso!');

        navigate('/planos');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        columns={2}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
        <Input
          inputMode="numeric"
          step="0.01"
          min="0.00"
          name="preco"
          label="Valor do Plano (R$)"
          placeholder="R$ 0.00"
          error={errors.preco}
          value={fields.preco}
          onChange={value =>
            setFields({ ...fields, preco: currencyMask(value) })
          }
        />
      </Form>
    </>
  );
};

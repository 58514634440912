import { FiEdit } from 'react-icons/fi';
import { IconLink } from 'components/IconLink';

interface EditOptionProps {
  disabled?: boolean;
  path: string;
  title?: string;
  toolTipDirection?: 'top' | 'bottom' | 'left' | 'right';
}

export const EditOption: React.FC<EditOptionProps> = ({
  disabled,
  path,
  title,
  toolTipDirection,
}) => (
  <IconLink
    path={path}
    icon={() => <FiEdit size={22} />}
    title={title ? title : 'Editar'}
    className="hover-blue"
    disabled={disabled}
    toolTipDirection={toolTipDirection}
  />
);

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FiAlertTriangle, FiLock } from 'react-icons/fi';

import { useAuth } from 'hooks/useAuth';
import { useForm } from 'hooks/useForm';
import { useQueryAuth } from 'hooks/useQueryAuth';

import { successToast } from 'utils/toast';
import { GraphQLError } from 'utils/GraphQLError';
import { editUsuarioSchema } from './utils/editUsuarioSchema';

import { gql } from 'functions/gql';
import { graphqlFormDataAuth } from 'functions/graphqlAuth';

import {
  Form,
  FormFooter,
  Input,
  InputFile,
  InputWithIcon,
  PreviewFile,
  Select,
} from 'components/Form';
import { Fallback } from 'components/Fallback';
import { ModalConfirm } from 'components/Modal/ModalConfirm';
import { StatusEmpregaticioEnum } from 'utils/enums/StatusEmpregaticioEnum';

interface GrupoAcesso {
  grupoAcessoId: string;
  nome: string;
}

interface Perfil {
  perfilId: string;
  nome: string;
}

interface UsuarioEditDataQuery {
  gruposAcessos: { items: GrupoAcesso[] };
  perfis: { items: Perfil[] };
  usuario: {
    usuarioId: string;
    ativo: boolean;
    avatar: {
      url: string;
      nome: string;
    };
    createdAt: string;
    login: string;
    nome: string;
    grupoAcesso: GrupoAcesso;
    perfil: Perfil;
    dataAdmissao: string;
    dataDesligamento: string;
    statusEmpregaticio: StatusEmpregaticioEnum;
  };
}

interface UsuarioEditDataQueryVariables {
  id: { usuarioId: string };
}

const USUARIO_EDIT_DATA_QUERY = gql`
  query ($id: UsuarioID!) {
    gruposAcessos {
      items {
        grupoAcessoId
        nome
      }
    }
    perfis {
      items {
        perfilId
        nome
      }
    }
    usuario(id: $id) {
      usuarioId
      ativo
      avatar {
        nome
        url
      }
      createdAt
      login
      nome
      grupoAcesso {
        grupoAcessoId
        nome
      }
      perfil {
        perfilId
        nome
      }
      dataAdmissao
      dataDesligamento
      statusEmpregaticio
    }
  }
`;

interface UsuarioUpdateQueryVariables {
  id: { usuarioId: string };
  input: {
    avatar?: File;
    grupoAcessoId?: string;
    login?: string;
    nome?: string;
    perfilId?: string;
    senha?: string;
    dataAdmissao?: string;
    dataDesligamento: string | null;
    statusEmpregaticio?: StatusEmpregaticioEnum;
  };
}

interface UsuarioUpdateQuery {
  usuarioUpdate: { usuarioId: string };
}

const USUARIO_UPDATE_QUERY = gql`
  mutation ($id: UsuarioID!, $input: UsuarioUpdateInput!) {
    usuarioUpdate(id: $id, input: $input) {
      usuarioId
    }
  }
`;

export const EditUsuarioView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);
  const { fields, setFields, errors, submitHandler } = useForm({
    nome: '',
    login: '',
    senha: '',
    confirmarSenha: '',
    grupoAcesso: '',
    perfil: '',
    dataAdmissao: '',
    dataDesligamento: '',
    statusEmpregaticio: '',
    avatar: null as File | null,
    perfis: [] as Perfil[],
    gruposAcessos: [] as GrupoAcesso[],
  });

  const usuarioId = params.usuarioId || '';

  const query = useQueryAuth<
    UsuarioEditDataQuery,
    UsuarioEditDataQueryVariables
  >({
    auth,
    setAuth,
    query: USUARIO_EDIT_DATA_QUERY,
    variables: { id: { usuarioId } },
  });

  useEffect(() => {
    if (query.data) {
      setFields({
        ...fields,
        nome: query.data.usuario.nome,
        login: query.data.usuario.login,
        perfil: query.data.usuario.perfil.perfilId,
        grupoAcesso: query.data.usuario.grupoAcesso.grupoAcessoId,
        perfis: query.data.perfis.items,
        dataAdmissao: query.data.usuario.dataAdmissao?.slice(0, 10),
        dataDesligamento: query.data.usuario.dataDesligamento?.slice(0, 10),
        statusEmpregaticio: query.data.usuario.statusEmpregaticio,
        gruposAcessos: query.data.gruposAcessos.items,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const handleSubmit = submitHandler({
    validateSchema: editUsuarioSchema,
    callback: async values => {
      setDisableButtonSubmit(true);

      try {
        const { errors } = await graphqlFormDataAuth<
          UsuarioUpdateQuery,
          UsuarioUpdateQueryVariables
        >({
          query: USUARIO_UPDATE_QUERY,
          variables: {
            id: { usuarioId },
            input: {
              grupoAcessoId: values.grupoAcesso,
              login: values.login,
              nome: values.nome,
              perfilId: values.perfil,
              dataAdmissao: values.dataAdmissao,
              dataDesligamento: values.dataDesligamento || null,
              statusEmpregaticio:
                values.statusEmpregaticio as StatusEmpregaticioEnum,
              senha: values.senha || undefined,
            },
          },
          files: values.avatar
            ? [['variables.input.avatar', values.avatar]]
            : undefined,
          auth,
          setAuth,
        });

        if (errors) {
          throw new GraphQLError('Falha ao alterar usuário.', errors);
        }

        successToast('Usuário alterado com sucesso!');

        navigate('/usuarios');
      } finally {
        setDisableButtonSubmit(false);
      }
    },
  });

  return (
    <>
      <ModalConfirm
        isVisible={isModalVisible}
        onClose={isConfirmed =>
          isConfirmed ? navigate(-1) : setIsModalVisible(false)
        }
        title="Atenção"
        description="Deseja realmente cancelar a operação?"
        icon={FiAlertTriangle}
      />
      <Form
        onSubmit={handleSubmit}
        columns={2}
        footerComponent={
          <FormFooter
            disableButtonSubmit={disableButtonSubmit}
            onCancel={() => setIsModalVisible(true)}
          />
        }
      >
        <Input
          autoFocus
          name="nome"
          label="Nome"
          error={errors.nome}
          value={fields.nome}
          onChange={value => setFields({ ...fields, nome: value })}
        />
        <Input
          autoComplete="new-password"
          name="login"
          label="Login"
          error={errors.login}
          value={fields.login}
          onChange={value => setFields({ ...fields, login: value })}
        />
        <InputWithIcon
          autoComplete="new-password"
          type="password"
          name="senha"
          label="Senha"
          icon={() => <FiLock size={22} />}
          error={errors.senha}
          value={fields.senha}
          onChange={value => setFields({ ...fields, senha: value })}
        />
        <InputWithIcon
          autoComplete="new-password"
          type="password"
          name="confirmarSenha"
          label="Confirmar Senha"
          icon={() => <FiLock size={22} />}
          error={errors.confirmarSenha}
          value={fields.confirmarSenha}
          onChange={value => setFields({ ...fields, confirmarSenha: value })}
        />
        <Select
          name="grupoAcesso"
          label="Grupo de Acesso"
          error={errors.grupoAcesso}
          value={fields.grupoAcesso}
          onChange={value => setFields({ ...fields, grupoAcesso: value })}
          options={fields.gruposAcessos.map(({ grupoAcessoId, nome }) => ({
            label: nome,
            value: grupoAcessoId,
          }))}
        />
        <Select
          name="perfil"
          label="Perfil"
          error={errors.perfil}
          value={fields.perfil}
          onChange={value => setFields({ ...fields, perfil: value })}
          options={fields.perfis.map(({ nome, perfilId }) => ({
            label: nome,
            value: perfilId,
          }))}
        />
        <Input
          type="date"
          name="dataAdmissao"
          label="Data de admissão"
          value={fields.dataAdmissao}
          onChange={value => setFields({ ...fields, dataAdmissao: value })}
        />
        <Input
          type="date"
          name="dataDesligamento"
          label="Data de desligamento"
          error={errors?.dataDesligamento}
          value={fields.dataDesligamento}
          onChange={value => setFields({ ...fields, dataDesligamento: value })}
        />
        <Select
          name="statusEmpregaticio"
          label="Status empregatício"
          error={errors?.statusEmpregaticio}
          value={fields.statusEmpregaticio}
          onChange={value =>
            setFields({
              ...fields,
              statusEmpregaticio: value as StatusEmpregaticioEnum,
            })
          }
          options={[
            { label: 'Admitido', value: 'ADMITIDO' },
            { label: 'Desligado', value: 'DESLIGADO' },
          ]}
        />
        <InputFile
          className="column-start"
          name="avatar"
          label="Adicionar Foto de Perfil"
          value={fields.avatar}
          onChange={files =>
            files && setFields({ ...fields, avatar: files[0] })
          }
        />

        <div style={{ gridColumnStart: 1 }}>
          {fields.avatar && (
            <PreviewFile
              files={[
                {
                  fileName: fields.avatar.name,
                  url: URL.createObjectURL(fields.avatar),
                  mimetype: fields.avatar.type,
                },
              ]}
              removeFiles={() => {
                setFields(state => ({ ...state, avatar: null }));
              }}
            />
          )}
        </div>
      </Form>
    </>
  );
};

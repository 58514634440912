import { Link } from 'react-router-dom';
import { MenuLinkItem } from './menuItems';

import styles from './MenuItem.module.scss';

type MenuItemProps = {
  menu: MenuLinkItem;
};

export const MenuItem: React.FC<MenuItemProps> = ({ menu }) => {
  return (
    <li className={styles['menu-item']}>
      <Link to={menu.path} className="body2" title={menu.label}>
        {menu.icon && <i>{menu.icon()}</i>}
        {menu.label}
      </Link>
    </li>
  );
};

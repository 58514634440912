import styles from './PrintText.module.scss';

interface PrintTextProps {
  label: string;
  text: string | undefined;
}

export const PrintText: React.FC<PrintTextProps> = ({ label, text }) => {
  return (
    <p className={styles['text-container']}>
      {label}
      <span className="body3">{text || 'Não informado'}</span>
    </p>
  );
};

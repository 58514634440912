import { Form, Input } from 'components/Form';
import { MateriaisTable } from 'components/MateriaisTable';
import { dateFormatWithHours } from 'functions/formatters';

import emptyData from 'assets/empty-data.svg';

interface PedidoDetailInstalacaoProps {
  dataInstalacao: string;
  numeroSerieOnu: string;
  cto: string;
  ctoPorta: string;
  tecnico: string;
  materiais: { materialId: string; nome: string; quantidade: number }[];
}

export const PedidoDetailInstalacao: React.FC<
  PedidoDetailInstalacaoProps
> = props => {
  return (
    <section>
      {props.dataInstalacao ? (
        <>
          <Form columns={2}>
            <Input
              readOnly
              name="dataInstalacao"
              label="Data instalação"
              value={dateFormatWithHours(props.dataInstalacao)}
            />
            <Input
              readOnly
              name="tecnico"
              label="Técnico"
              value={props.tecnico}
            />
            <Input
              readOnly
              className="span-2"
              name="numeroSerieOnu"
              label="Número de série ONU"
              value={props.numeroSerieOnu}
            />
            <Input readOnly name="cto" label="CTO" value={props.cto} />
            <Input
              readOnly
              name="ctoPorta"
              label="CTO Porta"
              value={props.ctoPorta}
            />
          </Form>

          <h5 className="heading5 title span-2" style={{ marginTop: '1rem' }}>
            Materiais utilizados:
          </h5>

          {props.materiais.length > 0 ? (
            <MateriaisTable materiais={props.materiais} />
          ) : (
            <p className="body2 text" style={{ marginTop: '1rem' }}>
              Nenhum material encontado.
            </p>
          )}
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <p className="body2 text">Pedido não instalado.</p>
          <figure style={{ marginTop: '2rem' }}>
            <img
              src={emptyData}
              alt="Ilustação - Sem dados."
              width={260}
              height={260}
            />
          </figure>
        </div>
      )}
    </section>
  );
};

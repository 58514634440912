import { useState } from 'react';
import { FiDownload, FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useError } from 'hooks/useError';
import { Sort } from 'utils/Sort';
import { Filter } from 'utils/Filter';
import { GraphQLError } from 'utils/GraphQLError';
import { gql } from 'functions/gql';
import { cnpjMask, cpfMask } from 'functions/mask';
import { graphqlAuth } from 'functions/graphqlAuth';
import { exportToCsv } from 'functions/exportToCSV';
import { dateFormatWithHours } from 'functions/formatters';

import { Button } from 'components/Form';
import { IconButton } from 'components/IconButton';
import { ConsultaUsuariosFilter } from './ConsultaUsuariosFilter';
import { sortArray } from 'views/relatorios/utils/sortArray';
import { RelatorioTable } from 'views/relatorios/components/RelatorioTable';

export interface FilterFields {
  between: {
    createdAt: {
      low: string;
      high: string;
    };
  };
  equals?: {
    usuarioId?: string;
    tipo?: string;
  };
}

interface ConsultaUsuariosListQueryVariables {
  filter?: Filter;
}

interface ConsultaUsuario {
  consultaUsuarioId: string;
  tipo: string;
  tipoPessoa: string;
  clientePessoaFisicaNome: string;
  clientePessoaFisicaCpf: string;
  clientePessoaJuridicaRazaoSocial: string;
  clientePessoaJuridicaCnpj: string;
  createdAt: string;
  usuario: { usuarioId: string; nome: string };
}

interface ConsultaUsuariosListQuery {
  relatorios: {
    consultasUsuarios: {
      consultasUsuarios: ConsultaUsuario[];
    };
  };
}

const CONSULTA_USUARIOS_LIST_QUERY = gql`
  query ($filter: ConsultaUsuarioFilter) {
    relatorios {
      consultasUsuarios {
        consultasUsuarios(filter: $filter) {
          consultaUsuarioId
          tipo
          tipoPessoa
          clientePessoaFisicaNome
          clientePessoaFisicaCpf
          clientePessoaJuridicaRazaoSocial
          clientePessoaJuridicaCnpj
          createdAt
          usuario {
            usuarioId
            nome
          }
        }
      }
    }
  }
`;

export const ListConsultaUsuariosView: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const { withErrorHandling } = useError();

  const [sort, setSort] = useState<Sort | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [consultasUsuarios, setConsultasUsuarios] = useState<ConsultaUsuario[]>(
    [],
  );

  const handleSearch = withErrorHandling(
    async (filterFelds: FilterFields | null) => {
      setIsLoading(true);

      try {
        const { data, errors } = await graphqlAuth<
          ConsultaUsuariosListQuery,
          ConsultaUsuariosListQueryVariables
        >({
          auth,
          setAuth,
          query: CONSULTA_USUARIOS_LIST_QUERY,
          variables: {
            filter: filterFelds ? filterFelds : undefined,
          },
        });

        if (errors) {
          throw new GraphQLError(
            'Erro ao pesquisar consultas usuários.',
            errors,
          );
        }

        setConsultasUsuarios(
          data.relatorios.consultasUsuarios.consultasUsuarios,
        );
      } finally {
        setIsLoading(false);
      }
    },
  );

  const handleExportCSV = () => {
    const keys = [
      {
        label: 'ID',
        key: 'consultaUsuarioId',
      },
      {
        label: 'Usuário',
        key: 'usuario',
        parser: (item: any) => item.usuario.nome,
      },
      {
        label: 'Tipo Consulta',
        key: 'tipo',
      },
      {
        label: 'Tipo Pessoa',
        key: 'tipoPessoa',
        parser: (item: any) =>
          item.tipoPessoa === 'PESSOA_FISICA'
            ? 'Pessoa Física'
            : 'Pessoa Jurídica',
      },
      {
        label: 'Documento',
        key: 'clientePessoaFisicaCpf',
        parser: (item: any) =>
          item.tipoPessoa === 'PESSOA_FISICA'
            ? cpfMask(item.clientePessoaFisicaCpf)
            : cnpjMask(item.clientePessoaJuridicaCnpj),
      },
      {
        label: 'Nome / Razão Social',
        key: 'clientePessoaJuridicaRazaoSocial',
        parser: (item: any) =>
          item.tipoPessoa === 'PESSOA_FISICA'
            ? item.clientePessoaFisicaNome
            : item.clientePessoaJuridicaRazaoSocial,
      },
      {
        label: 'Data Criação',
        key: 'createdAt',
        parser: (item: any) => new Date(item.createdAt).toLocaleDateString(),
      },
    ];

    const rows = [
      keys.map(key => key.label),
      ...consultasUsuarios.map(consulta =>
        keys.map(({ parser, key }) =>
          parser ? parser(consulta) : consulta[key as keyof typeof consulta],
        ),
      ),
    ];

    exportToCsv('relatorio-consultas-usuarios.csv', rows);
  };

  return (
    <>
      <ConsultaUsuariosFilter onSearch={handleSearch} />
      <RelatorioTable
        title="Consultas"
        sideComponent={() => (
          <Button
            type="button"
            disabled={consultasUsuarios.length === 0}
            style={{ width: 'max-content' }}
            onClick={handleExportCSV}
          >
            <FiDownload size={20} /> Exportar CSV
          </Button>
        )}
        columns={[
          {
            key: 'opcoes',
            label: 'Opções',
            render: ({ consultaUsuarioId }) => (
              <IconButton
                title="Visualizar"
                className="hover-green"
                toolTipDirection="right"
                icon={() => <FiEye size={22} />}
                onClick={() =>
                  navigate(
                    `/relatorios/consultas-usuarios/${consultaUsuarioId}`,
                  )
                }
              />
            ),
          },
          {
            key: 'consultaUsuarioId',
            label: 'ID',
            sortable: true,
          },
          {
            key: 'usuario',
            label: 'Usuário',
            sortable: true,
            render: ({ usuario }) => <p>{usuario.nome}</p>,
          },
          {
            key: 'tipo',
            label: 'Tipo Consulta',
            sortable: true,
          },
          {
            key: 'tipoPessoa',
            label: 'Tipo Pessoa',
            sortable: true,
            render: ({ tipoPessoa }) => (
              <p>
                {tipoPessoa === 'PESSOA_FISICA'
                  ? 'Pessoa Física'
                  : 'Pessoa Jurídica'}
              </p>
            ),
          },
          {
            key: 'documento',
            label: 'Documento',
            sortable: true,
            render: ({
              tipoPessoa,
              clientePessoaFisicaCpf,
              clientePessoaJuridicaCnpj,
            }) => (
              <p>
                {tipoPessoa === 'PESSOA_FISICA'
                  ? cpfMask(clientePessoaFisicaCpf)
                  : cnpjMask(clientePessoaJuridicaCnpj)}
              </p>
            ),
          },
          {
            key: 'nome',
            label: 'Nome / Razão Social',
            sortable: true,
            render: ({
              tipoPessoa,
              clientePessoaFisicaNome,
              clientePessoaJuridicaRazaoSocial,
            }) => (
              <p>
                {tipoPessoa === 'PESSOA_FISICA'
                  ? clientePessoaFisicaNome
                  : clientePessoaJuridicaRazaoSocial}
              </p>
            ),
          },
          {
            key: 'createdAt',
            label: 'Data Criação',
            sortable: true,
            render: ({ createdAt }) => <p>{dateFormatWithHours(createdAt)}</p>,
          },
        ]}
        items={sortArray(sort, consultasUsuarios)}
        isLoading={isLoading}
        getRowId={({ consultaUsuarioId }) => consultaUsuarioId}
        totalCount={consultasUsuarios.length}
        sort={sort}
        onChangeSort={sortField => setSort(sortField)}
      />
    </>
  );
};

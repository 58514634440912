import { Button } from 'components/Form';
import { FiArrowRight } from 'react-icons/fi';

import styles from './NextStepFooter.module.scss';

interface NextStepFooterProps {
  disableNextButton?: boolean;
  formId?: string;
  onNextStep?: () => void;
}

export const NextStepFooter: React.FC<NextStepFooterProps> = ({
  disableNextButton,
  formId,
  onNextStep,
}) => {
  return (
    <div className={styles['next-step-container']}>
      <Button
        type="submit"
        form={formId}
        disabled={disableNextButton}
        onClick={() => onNextStep && onNextStep()}
      >
        Próximo
        <FiArrowRight size={22} />
      </Button>
    </div>
  );
};

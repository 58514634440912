import { FiPlus } from 'react-icons/fi';

import { useForm } from 'hooks/useForm';
import { warnToast } from 'utils/toast';
import { PedidoPlano } from 'utils/types/PedidoPlano';

import { StepTitle } from '../components/StepTitle';
import { PlanosTable } from 'components/PlanosTable';
import { Button, Form, Select } from 'components/Form';
import { NextStepFooter } from 'views/pedidos/components/NextStepFooter';

interface PlanosProps {
  allPlanos: PedidoPlano[];
  planos: PedidoPlano[];
  setPlanos: (newPlanos: PedidoPlano[]) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export const PedidoPlanos: React.FC<PlanosProps> = ({
  allPlanos,
  planos,
  setPlanos,
  nextStep,
  prevStep,
}) => {
  const { fields, setFields, errors, submitHandler } = useForm({
    plano: '',
    planosSelecionados: planos || [],
  });

  const addPlano = (plano: PedidoPlano | undefined) => {
    if (!plano) {
      return;
    }

    setFields({
      ...fields,
      planosSelecionados: [...fields.planosSelecionados, plano],
    });
  };

  const removePlano = (index: number) => {
    setFields({
      ...fields,
      planosSelecionados: fields.planosSelecionados.filter(
        (_, i) => index !== i,
      ),
    });
  };

  const handleSubmit = submitHandler({
    callback: async values => {
      if (values.planosSelecionados.length === 0) {
        warnToast('No mínimo um plano deve ser selecionado.');
        return;
      }

      setPlanos(values.planosSelecionados);
      nextStep();
    },
  });

  return (
    <>
      <StepTitle title="Planos" onGoBack={prevStep} />
      <Form id="planos-form" onSubmit={handleSubmit} columns={2}>
        <Select
          autoFocus
          name="planos"
          label="Planos"
          error={errors.plano}
          value={fields.plano}
          onChange={value => setFields({ ...fields, plano: value })}
          options={allPlanos.map(({ nome, planoId }) => ({
            label: nome,
            value: planoId,
          }))}
        />
        <Button
          type="button"
          background="success"
          className="column-start"
          onClick={() =>
            addPlano(allPlanos.find(plano => plano.planoId === fields.plano))
          }
        >
          <FiPlus size={22} />
          Adicionar
        </Button>
      </Form>

      <h4 className="heading4 title" style={{ marginTop: '1rem' }}>
        Planos Selecionados:
      </h4>

      <PlanosTable
        planos={fields.planosSelecionados}
        onRemovePlano={removePlano}
      />

      <NextStepFooter formId="planos-form" />
    </>
  );
};

import { useState } from 'react';
import {
  FcBullish,
  FcComboChart,
  FcLineChart,
  FcWorkflow,
} from 'react-icons/fc';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';
import { currencyFormat } from 'functions/formatters';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'functions/dateUtils';

import { Log } from 'utils/Log';
import { parseBarChartData } from './utils/parseBarChartData';
import { makeDashboardQuery } from './utils/makeDashboardQuery';

import { Card } from './components/Card';
import { Fallback } from 'components/Fallback';
import { Ranking } from './components/Ranking';
import { CardBody } from './components/CardBody';
import { SearchForm } from './components/SearchForm';
import { BarChart } from 'components/Charts/BarChart';
import { ShortcutList } from './components/ShortcutList';
import { DefaultDashboard } from './components/DefaultDashboard';
import { UltimasAtividades } from './components/UltimasAtividades';

import styles from './dashboard.module.scss';

interface UsuarioRanking {
  posicao: number;
  quantidade: number;
  usuario: { usuarioId: string; nome: string };
}

export interface Statuses {
  quantidade: number;
  status: { statusId: string; nome: string };
}

interface DashboardQueryPeriod {
  dataInicio: string;
  dataTermino: string;
}

interface DashboardQueryVariables {
  consultasAggregationInput: DashboardQueryPeriod;
  consultasRankingUsuariosInput: DashboardQueryPeriod;
  pedidosAggregationInput: DashboardQueryPeriod;
  pedidosRankingUsuariosInput: DashboardQueryPeriod;
  pedidosStatusesInput: DashboardQueryPeriod;
}

interface DashboardQuery {
  dashboards?: {
    consultas?: {
      aggregation: { quantidade: number };
      rankings: { usuarios: UsuarioRanking[] };
    };
    pedidos?: {
      aggregation: { quantidade: number; total: number };
      rankings: { usuarios: UsuarioRanking[] };
      statuses: Statuses[];
    };
    ultimasAtividades?: Log[];
  };
}

export const DashboardView: React.FC = () => {
  const { auth, setAuth, acessos } = useAuth();

  const [periodo, setPeriodo] = useState({
    dataInicio: getFirstDayOfMonth().slice(0, 10),
    dataTermino: getLastDayOfMonth().slice(0, 10),
  });

  const DASHBOARD_QUERY = makeDashboardQuery(acessos);

  const query = useQueryAuth<DashboardQuery, DashboardQueryVariables>({
    auth,
    setAuth,
    query: DASHBOARD_QUERY,
    variables: {
      consultasAggregationInput: {
        dataInicio: periodo.dataInicio,
        dataTermino: periodo.dataTermino,
      },
      consultasRankingUsuariosInput: {
        dataInicio: periodo.dataInicio,
        dataTermino: periodo.dataTermino,
      },
      pedidosAggregationInput: {
        dataInicio: periodo.dataInicio,
        dataTermino: periodo.dataTermino,
      },
      pedidosRankingUsuariosInput: {
        dataInicio: periodo.dataInicio,
        dataTermino: periodo.dataTermino,
      },
      pedidosStatusesInput: {
        dataInicio: periodo.dataInicio,
        dataTermino: periodo.dataTermino,
      },
    },
  });

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  const { data } = query;

  return (
    <div className={styles['dashboard-container']}>
      <ShortcutList acessos={acessos} />

      {data.dashboards ? (
        <>
          <SearchForm
            periodo={periodo}
            setPeriodo={newPeriodo => setPeriodo(newPeriodo)}
          />

          <div className="col-3">
            {data.dashboards.pedidos && (
              <>
                <CardBody
                  description="Total de Pedidos"
                  quantity={`${data.dashboards.pedidos.aggregation.quantidade}`}
                  icon={() => <FcComboChart size={52} />}
                />
                <CardBody
                  description="Valor dos Pedidos"
                  quantity={currencyFormat(
                    data.dashboards.pedidos.aggregation.total,
                  )}
                  icon={() => <FcLineChart size={52} />}
                />
              </>
            )}
            {data.dashboards.consultas && (
              <CardBody
                description="Total de Consultas"
                quantity={`${data.dashboards.consultas.aggregation.quantidade}`}
                icon={() => <FcWorkflow size={52} />}
              />
            )}
          </div>

          {data.dashboards.pedidos && (
            <Card title="Quantidades de pedidos">
              <BarChart
                data={parseBarChartData(data.dashboards.pedidos.statuses)}
                keys={['value']}
                indexBy="label"
              />
            </Card>
          )}

          <div className="col-2">
            {data.dashboards.pedidos?.rankings && (
              <Ranking
                title="Ranking de vendas"
                headerIcon={() => <FcBullish size={26} />}
                items={data.dashboards.pedidos.rankings.usuarios}
              />
            )}
            {data.dashboards.consultas?.rankings && (
              <Ranking
                title="Ranking de consultas"
                headerIcon={() => <FcWorkflow size={26} />}
                items={data.dashboards.consultas.rankings.usuarios}
              />
            )}
          </div>

          {data.dashboards.ultimasAtividades && (
            <UltimasAtividades items={data.dashboards.ultimasAtividades} />
          )}
        </>
      ) : (
        <DefaultDashboard />
      )}
    </div>
  );
};

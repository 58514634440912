import { Layout } from 'components/Layout';
import { ListConsultaUsuariosView } from 'views/relatorios/consultas/usuarios/list';

export const ListConsultaUsuariosPage = () => {
  return (
    <Layout breadcrumb={{ items: [{ label: 'Consultas Usuários' }] }}>
      <ListConsultaUsuariosView />
    </Layout>
  );
};

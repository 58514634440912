import { Layout } from 'components/Layout';
import { NewGrupoAcessoView } from 'views/grupo-acesso/new';

export const NewGrupoAcessoPage = () => {
  return (
    <Layout
      breadcrumb={{
        items: [
          { label: 'Grupos de Acessos', href: '/grupo-acesso' },
          { label: 'Novo' },
        ],
      }}
    >
      <NewGrupoAcessoView />
    </Layout>
  );
};

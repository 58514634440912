import { Email } from 'utils/types/Email';
import { Telefone } from 'utils/types/Telefone';
import { cnpjMask, cpfMask } from 'functions/mask';

import { FlexContainer } from 'components/FlexContainer';
import { Form, Input, InputPhone, Select } from 'components/Form';

interface DadosProps {
  razaoSocial: string;
  nomeFantasia: string;
  cnpj: string;
  dataAbertura: string;
  inscricaoEstadual?: string;
  inscricaoMunicipal?: string;
  tipoRetencaoNotaFiscal?: string;
  ramoEmpresa: string;
  telefones: Telefone[];
  emails: Email[];
  representanteNome: string;
  representanteCpf: string;
  representanteRg?: string;
  representanteSexo?: string;
  representanteEstadoCivilId?: string;
  representanteDataNascimento: string;
}

export const DadosPessoaJuridica: React.FC<DadosProps> = props => {
  return (
    <>
      <Form columns={3}>
        <Input readOnly name="cnpj" label="CNPJ" value={cnpjMask(props.cnpj)} />
        <Input
          readOnly
          name="razaoSocial"
          label="Razão Social"
          value={props.razaoSocial}
        />
        <Input
          readOnly
          name="nomeFantasia"
          label="Nome Fantasia"
          value={props.nomeFantasia}
        />
        <Input
          readOnly
          name="inscricaoEstadual"
          label="Inscrição Estadual"
          value={props.inscricaoEstadual || ''}
        />
        <Input
          readOnly
          name="inscricaoMunicipal"
          label="Inscrição Municipal"
          value={props.inscricaoMunicipal || ''}
        />
        <Input
          readOnly
          type="date"
          name="dataAbertura"
          label="Data Abertura"
          value={props.dataAbertura}
        />
        <Input
          readOnly
          name="tipoRetencaoNotaFiscal"
          label="Tipo Retenção Nota Fiscal"
          value={props.tipoRetencaoNotaFiscal || ''}
        />
        <Input
          readOnly
          name="ramoEmpresa"
          label="Ramo da Empresa"
          value={props.ramoEmpresa}
        />

        <h4 className="heading4 title span-3">Telefones</h4>

        {props.telefones.length ? (
          props.telefones.map((telefone, index) => (
            <FlexContainer key={index} className="span-2">
              <Input
                readOnly
                name="descricaoTelefone"
                label="Descrição Telefone"
                value={telefone.nome}
              />
              <InputPhone
                readOnly
                id={`telefone-${index}`}
                isWhatsapp={telefone.whatsapp}
                value={telefone.telefone}
              />
            </FlexContainer>
          ))
        ) : (
          <p className="body2 text">Nenhum telefone adicionado.</p>
        )}

        <h4 className="heading4 title span-3">E-mails</h4>

        {props.emails.length ? (
          props.emails.map((email, index) => (
            <FlexContainer key={index} className="span-2">
              <Input
                readOnly
                name="descricaoEmail"
                label="Descrição E-mail"
                value={email.nome}
              />
              <Input
                readOnly
                name={`email-${index}`}
                label={`E-mail ${index + 1}`}
                value={email.nome}
              />
            </FlexContainer>
          ))
        ) : (
          <p className="body2 text">Nenhum e-mail adicionado.</p>
        )}

        <h4 className="heading4 title span-3">Representante Legal</h4>

        <Input
          readOnly
          name="representanteNome"
          label="Nome"
          value={props.representanteNome}
        />
        <Input
          readOnly
          name="representanteCpf"
          label="CPF"
          value={cpfMask(props.representanteCpf)}
        />
        <Input
          readOnly
          name="representanteRg"
          label="RG"
          value={props.representanteRg || ''}
        />
        <Input
          readOnly
          type="date"
          name="representanteDataNascimento"
          label="Data Nascimento"
          value={props.representanteDataNascimento}
        />
        <Select
          readOnly
          name="sexo"
          label="Sexo"
          value={props.representanteSexo || ''}
          options={[
            { label: 'Masculino', value: 'MASCULINO' },
            { label: 'Feminino', value: 'FEMININO' },
            { label: 'Outros', value: 'OUTROS' },
          ]}
        />
        <Select
          name="representanteEstadoCivil"
          label="Estado Civil"
          value={props.representanteEstadoCivilId || ''}
          options={[
            { value: 'CASADO', label: 'Casado(a)' },
            { value: 'DIVORCIADO', label: 'Divorciado(a)' },
            { value: 'OUTROS', label: 'Outros' },
            { value: 'SEPARADO', label: 'Separado(a)' },
            { value: 'SOLTEIRO', label: 'Solteiro(a)' },
            { value: 'VIUVO', label: 'Viúvo(a)' },
          ]}
        />
      </Form>
    </>
  );
};

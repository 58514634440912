import { Filter } from 'utils/Filter';

export const pedidoFilter = (status: string[], filterFields: Filter | null) => {
  if (filterFields === null && status.includes('TODOS')) {
    return undefined;
  }

  if (filterFields) {
    if (status.includes('TODOS')) {
      return filterFields;
    } else {
      return {
        includes: { statusId: status, ...filterFields.includes },
        equals: { ...filterFields.equals },
        likes: { ...filterFields.likes },
      };
    }
  } else {
    return { includes: { statusId: status } };
  }
};

import { Link } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';

type BreadcrumbItemProps = {
  label: string;
  href?: string;
};

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  href,
  label,
}) => (
  <li className={styles['breadcrumb-item']}>
    {href ? <Link to={href}>{label}</Link> : <p>{label}</p>}
  </li>
);

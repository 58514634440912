import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useAuth } from 'hooks/useAuth';
import { useQueryAuth } from 'hooks/useQueryAuth';
import { Log } from 'utils/Log';
import { gql } from 'functions/gql';

import { TabList } from 'components/TabList';
import { Fallback } from 'components/Fallback';
import { LogsTable } from 'components/LogsTable';
import { Button, Form, Input } from 'components/Form';

interface MaterialDetailQueryVariables {
  id: { materialId: string };
}

interface MaterialDetailQuery {
  material: {
    materialId: string;
    nome: string;
    logs: { items: Log[] };
  };
}

const MATERIAL_DETAIL_QUERY = gql`
  query ($id: MaterialID!) {
    material(id: $id) {
      materialId
      nome
      logs {
        items {
          logId
          tipo
          descricao
          createdAt
          usuario {
            nome
            avatar {
              url
            }
          }
        }
      }
    }
  }
`;

export const DetailMaterialView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [fields, setFields] = useState({ nome: '' });

  const materialId = params.materialId || '';

  const query = useQueryAuth<MaterialDetailQuery, MaterialDetailQueryVariables>(
    {
      auth,
      setAuth,
      query: MATERIAL_DETAIL_QUERY,
      variables: { id: { materialId } },
    },
  );

  useEffect(() => {
    if (query.data) {
      setFields({
        nome: query.data.material.nome,
      });
    }
  }, [query.data]);

  if (query.fallback) {
    return <Fallback errors={query.errors} loading={query.loading} />;
  }

  return (
    <TabList labels={['Detalhes', 'Logs']}>
      <Form
        footerComponent={
          <Button
            type="button"
            style={{ alignSelf: 'flex-end', width: '200px' }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        }
      >
        <Input name="nome" label="Nome" readOnly value={fields.nome} />
      </Form>
      <LogsTable logs={query.data.material.logs.items} />
    </TabList>
  );
};

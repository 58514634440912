import { IconButton } from 'components/IconButton';
import { FiTrash2 } from 'react-icons/fi';

import styles from './MateriaisTable.module.scss';

interface MateriaisTableProps {
  materiais: {
    materialId: string;
    nome: string;
    quantidade: number;
  }[];
  onRemoveMaterial?: (index: number) => void;
}

export const MateriaisTable: React.FC<MateriaisTableProps> = ({
  materiais,
  onRemoveMaterial,
}) => {
  return (
    <table className={styles['materiais-table']}>
      <thead>
        <tr>
          <td>Nome</td>
          <td>Quantidade</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {materiais.map((material, index) => (
          <tr key={`material-${index}`}>
            <td>{material.nome}</td>
            <td>{material.quantidade}</td>
            <td>
              {onRemoveMaterial && (
                <IconButton
                  title="Remover material"
                  icon={() => <FiTrash2 size={22} />}
                  onClick={() => onRemoveMaterial(index)}
                  toolTipDirection="left"
                  className="hover-red"
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

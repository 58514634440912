import { FiX } from 'react-icons/fi';
import styles from './ModalFilter.module.scss';

export interface ModalFilterProps {
  onClose: () => void;
  title: string;
}

export const ModalFilter: React.FC<ModalFilterProps> = ({
  title,
  onClose,
  children,
}) => {
  return (
    <div className={styles['modal-filter-overlay']}>
      <div
        className={styles['modal-filter-frame']}
        onClick={e => e.stopPropagation()}
      >
        <header className={styles['modal-filter-frame-header']}>
          <h3 className="heading3 title">{title}</h3>
          <button
            aria-label="Clique para fechar a janela"
            onClick={onClose}
            title="Fechar janela"
          >
            <FiX size={22} />
          </button>
        </header>
        <main className={styles['modal-filter-frame-content']}>{children}</main>
      </div>
    </div>
  );
};

import styles from './ModalOverlay.module.scss';

interface ModalOverlayProps {
  onClick?: () => void;
}

export const ModalOverlay: React.FC<ModalOverlayProps> = ({
  children,
  onClick,
}) => (
  <div className={styles['modal-overlay-container']} onClick={onClick}>
    {children}
  </div>
);

import { FiChevronDown } from 'react-icons/fi';

import styles from './ExibirMaisButton.module.scss';

interface ExibirMaisButtonProps {
  disabled: boolean;
  onClick: () => void;
}

export const ExibirMaisButton: React.FC<ExibirMaisButtonProps> = ({
  disabled,
  onClick,
}) => (
  <button
    type="button"
    className={styles['see-more-button']}
    disabled={disabled}
    onClick={onClick}
  >
    Exibir mais <FiChevronDown size={22} />
  </button>
);
